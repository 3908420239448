import { I18Languages } from 'types/i18nTypes';
import { IMenuItems } from 'types/MenuTypes';
import { languages } from './constants';

export const getCurrentLanguage = (locale: I18Languages): string => {
  const currentLanguage = languages.find(l => l.locale === locale);
  return `(${currentLanguage?.value})`;
};

export const getOtherLanguages = (locale: I18Languages) => {
  const otherLanguages = languages.reduce((data: IMenuItems[], language) => {
    language.locale !== locale &&
      data.push({ key: language.locale, value: language?.value, children: [] });
    return data;
  }, []);
  return otherLanguages;
};
