import { useCallback } from 'react';
import { IHandleClose } from './interfaces';

export const HandleClose = (data: IHandleClose) => {
  const { setAnchorEl } = data;

  return useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
};
