import { IBadgesMessages } from 'types/BadgeTypes';
import { notificationMessage } from './constants';
import { Trans } from '@lingui/macro';
import styles from './badgeNotification.module.css';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';
interface BadgeNotificationProps {
  badgeGranted: keyof IBadgesMessages | null;
}
export default function BadgeNotification(props: BadgeNotificationProps) {
  const { badgeGranted } = props;
  return (
    <div className={styles['badge-notification']}>
      <div className={styles['badge-notification__container']}>
        <MilitaryTechSharpIcon className={styles['badge-notification__badge_icon']} />
        <p className={styles['badge-notification__text']}>
          <Trans id="badgeNotification.message" />
          <span className={styles['badge-notification-div__badge-name']}>
            {badgeGranted && <Trans id={notificationMessage[badgeGranted]} />}
          </span>
        </p>
      </div>
    </div>
  );
}
