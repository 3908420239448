import { initializeApp } from 'firebase/app';
import { firebaseContentConfig, firebaseDefaultConfig } from 'store/firebase-config';

// default app
export const app = initializeApp(firebaseDefaultConfig);

// content
export const contentApp = initializeApp(firebaseContentConfig, '[CONTENT]');

// window firebase 8
export const windowFirebaseApp = window.firebase.initializeApp(firebaseDefaultConfig);
