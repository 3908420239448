import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { StyledBox } from '../../helpers/styled';
import { LoginHeader, Tabs, TechSupportModal } from './components';
import { Trans } from '@lingui/macro';
import { useAppContext } from 'providers/app/app.provider';
import CustomModal from 'components/organisms/CustomModal';
import LanguageSwitcher from 'components/organisms/LanguageSwitcher';
import LoginModal from '../../components/LoginModal';
import styles from './login.module.css';

export default function LoginPage() {
  const [techServiceModalShown, setTechServiceModalShown] = useState<boolean>(false);
  const openTechServiceModal = useCallback(() => setTechServiceModalShown(true), []);
  const closeTechServiceModal = useCallback(() => setTechServiceModalShown(false), []);
  const { loginWithPassword } = useAppContext();

  return (
    <>
      <LanguageSwitcher />
      <LoginModal>
        <StyledBox>
          <LoginHeader />
          {!loginWithPassword && <Tabs />}
          <Outlet />
        </StyledBox>
        <a className={styles['login-page__tech-service-link']} onClick={openTechServiceModal}>
          <Trans id="login.support.header" />
        </a>
      </LoginModal>
      <CustomModal
        show={techServiceModalShown}
        backdrop
        modalClassName={styles['login-page__tech-service-modal__content']}
        backdropClassName={styles['login-page__tech-service-modal__backdrop']}
        onClose={closeTechServiceModal}
      >
        <TechSupportModal handleClose={closeTechServiceModal} />
      </CustomModal>
    </>
  );
}
