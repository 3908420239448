import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { AccordionContext, useAccordionContext } from './context';
import { AccordionDetailsProps, AccordionProps, AccordionSummaryProps } from './types';
import styles from './accordion.module.css';

export function AccordionSummary({ className, children }: AccordionSummaryProps) {
  const { show, toggle } = useAccordionContext();
  const accordionSummarySpanClassNames = clsx(
    styles['accordion-summary__span'],
    className,
    show && styles['accordion-summary__span--selected'],
  );

  return children ? (
    <div
      onClick={toggle}
      className={styles['accordion-summary__content']}
      aria-controls="panel1d-content"
      id="panel1d-header"
    >
      <span className={accordionSummarySpanClassNames}>{children}</span>
    </div>
  ) : null;
}

export function AccordionDetails({ className, children }: AccordionDetailsProps) {
  const { show, ref } = useAccordionContext();
  const accordionDetailsClassNames = clsx(
    styles['accordion-details__content'],
    className,
    show && styles['accordion-details__content--expanded'],
  );

  return children ? (
    <div className={accordionDetailsClassNames}>
      <div ref={ref}>{children}</div>
    </div>
  ) : null;
}

export function Accordion({
  id,
  className,
  expandedClassName,
  children,
  show,
  setShow,
  setSelectedSection,
}: AccordionProps) {
  const [height, setHeight] = useState('0px');
  const ref = useRef<HTMLDivElement | null>(null);

  const toggle = useCallback(() => {
    setShow(!show);
    setSelectedSection(id);
  }, [setShow, show, setSelectedSection, id]);

  // height is dynamic depending on content
  // we need it to have smooth animation
  useLayoutEffect(() => {
    setHeight(`${ref.current?.clientHeight}px`);
  }, [children]);

  const value = useMemo(
    () => ({
      show,
      toggle,
      ref,
    }),
    [show, toggle],
  );

  const accordionClassNames = clsx(styles.accordion__content, className, show && expandedClassName);

  return (
    <AccordionContext.Provider value={value}>
      <div
        style={{ '--dynamic-height': height } as React.CSSProperties}
        className={accordionClassNames}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
}
