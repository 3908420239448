import { Controller, ControllerRenderProps } from 'react-hook-form';
import { SelectControllerProps } from './interface';
import Content from './Content';
import { UserCreationForm } from 'schemas/adminForms.schema';
import { useCallback } from 'react';

type renderPropsType = {
  field: ControllerRenderProps<UserCreationForm, keyof UserCreationForm>;
};

export default function SelectController(props: SelectControllerProps) {
  const { control, id, label, name, selectOptions } = props;
  const renderContent = useCallback(
    (renderProps: renderPropsType) => (
      <Content field={renderProps.field} id={id} label={label} selectOptions={selectOptions} />
    ),
    [id, label, selectOptions],
  );

  return <Controller name={name} control={control} render={renderContent} />;
}
