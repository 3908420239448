import { white, purple } from 'constants/Colors';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const StyledTab = styled(Tab)({
  textTransform: 'uppercase',
  fontWeight: 700,
  width: '50%',
  color: transformColorFromHexToRGBA(white, 0.7),
  '&.Mui-selected': {
    color: purple,
  },
  '&.Mui-focusVisible': {
    backgroundColor: purple,
  },
});

export default function MTab({ children, ...props }: TabProps) {
  return <StyledTab {...props}>{children}</StyledTab>;
}
