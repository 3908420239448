import { useCallback } from 'react';
import { Button, Stack, Typography, Container } from '@mui/material';
import { zeroResolvedTasks } from 'store/functions';

import SendIcon from '@mui/icons-material/Send';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Card from '@mui/material/Card';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Page from 'components/atoms/Page';

const cardStyles = { padding: '20px', marginBottom: '10px' };

export default function Schedule() {
  const sendEmailZeroResolvedTasks = useCallback(async () => {
    try {
      await zeroResolvedTasks();
    } catch (e) {
      throw e;
    }
  }, []);

  return (
    <Page title="Schedule">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            <CalendarMonthIcon /> Schedule
          </Typography>
        </Stack>
        <Stack>
          <Card sx={cardStyles}>
            <FormGroup>
              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Send parent raport zero resolved tasks every day in demo room"
              />
            </FormGroup>
            <Button
              variant="outlined"
              startIcon={<SendIcon />}
              color="error"
              onClick={sendEmailZeroResolvedTasks}
            >
              Send manual to parent email zero resolved tasks by child in demo room
            </Button>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
