import { forwardRef, memo } from 'react';
import { Question } from 'types/ContentTypes';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import { CompilerProps } from './interface';
import CodeBlocksCompiler from 'pages/CurrentRoom/components/Compilers/CodeBlocksCompiler';
import HtmlJsCssCompiler from 'pages/CurrentRoom/components/Compilers/HtmlJsCssCompiler';
import PythonCompiler from 'pages/CurrentRoom/components/Compilers/PythonCompiler';
import ChatGPTCompiler from 'pages/CurrentRoom/components/Compilers/ChatGPTCompiler';

const Compiler = forwardRef<{}, CompilerProps>((props, ref) => {
  const { ideMethods, question } = props;
  const { getCode, highlightActiveBreakpointLine, highlightErrorLine, removehighlightLine } =
    ideMethods;

  const { currentUserId } = useWorkspaceContext();

  const compilers = {
    [ProgrammingLanguage.Blocks]: (
      <CodeBlocksCompiler
        getCode={getCode}
        highlightErrorLine={highlightErrorLine}
        highlightActiveBreakpointLine={highlightActiveBreakpointLine}
        removehighlightLine={removehighlightLine}
        question={question as Question}
        pageUserId={currentUserId}
      />
    ),
    [ProgrammingLanguage.Python]: (
      <PythonCompiler
        getCode={getCode}
        highlightErrorLine={highlightErrorLine}
        highlightActiveBreakpointLine={highlightActiveBreakpointLine}
        removehighlightLine={removehighlightLine}
        question={question as Question}
        pageUserId={currentUserId}
      />
    ),
    [ProgrammingLanguage.HtmlJsCss]: (
      <HtmlJsCssCompiler
        getCode={getCode}
        question={question as Question}
        pageUserId={currentUserId}
      />
    ),
    [ProgrammingLanguage.ChatGPT]: <ChatGPTCompiler />,
  };

  return question && compilers[question.language as ProgrammingLanguage];
});

export default memo(Compiler);
