import { useRef, useState, useCallback, useEffect } from 'react';
import {
  ref as firebaseRef,
  getDatabase,
  Database,
  get,
  query,
  update,
  onValue,
} from 'firebase/database';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  PopoverOrigin,
  TypographyProps,
} from '@mui/material';
// component
import EditRoomForm from '../../../pages/Room/components/EditRoomForm';
import Iconify from 'components/atoms/Iconify';
import getItemArray from '../../../scripts/getItemArray';
import { Room } from 'types/RoomTypes';
import { User } from 'types/UserTypes';
import { memberSchema } from 'schemas/member.shema';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { getMembersData } from 'store/chunks/realtime/members';
import { z } from 'zod';
import { useErrorContext } from 'providers/error/error.provider';

// eslint-disable-next-line require-await
async function getUsers(db: Database) {
  const que = query(firebaseRef(db, 'users'));
  return get(que).then(snapshot => getItemArray(snapshot));
}

// ----------------------------------------------------------------------

const menuSx1 = { sx: { width: 200, maxWidth: '100%' } };
const menuSx2: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
const menuSx3: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const variantBodySx: TypographyProps = { variant: 'body2' };

export interface RoomMoreMenuProps {
  room: Room;
}

export default function RoomMoreMenu({ room }: RoomMoreMenuProps) {
  const { bug } = useErrorContext();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [users, setUsers] = useState<string[]>([]);

  const deletes: Record<string, null> = {};

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleEditModalClose = useCallback(() => {
    setShowEditModal(false);
  }, []);

  function deleteRoomFromUser(users: User[], roomId: string) {
    // eslint-disable-next-line no-loops/no-loops
    for (let i = 0; i < users.length; i += 1) {
      deletes[`users_rooms/${users[i].uid}/${roomId}`] = null;
    }
  }

  async function deleteRoom() {
    const db = getDatabase();
    const users = await getUsers(db);
    deletes[`/members/${room.id}`] = null;
    deletes[`/sessions_data/${room.id}`] = null;
    deletes[`/rooms/${room.id}`] = null;
    deletes[`/leader_mode_data/${room.id}`] = null;
    deleteRoomFromUser(users, room.id);
    await update(firebaseRef(db), deletes);
  }

  async function handleDeleteClick() {
    setIsOpen(false);
    // eslint-disable-next-line no-alert
    if (window.confirm(`Are You sure, You want to delete ${room.name} from database?`)) {
      await deleteRoom().then(() => window.location.reload());
    }
  }

  async function archiveRoom() {
    const db = getDatabase();
    const roomRef = firebaseRef(db, `rooms/${room.id}`);
    await update(roomRef, { isArchived: !room.isArchived });
  }

  async function handleArchiveClick() {
    setIsOpen(false);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are You sure, You want to ${room.isArchived ? 'recovery' : 'archive'} ${
          room.name
        } in database?`,
      )
    ) {
      await archiveRoom().then(() => window.location.reload());
    }
  }

  function handleEditClick() {
    setIsOpen(false);
    setShowEditModal(true);
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      const getUsers = async (usersIds: string[]) => {
        const _users = Object.entries(await getMembersData(usersIds)).reduce(
          (data: string[], [_, user]) => {
            data.push(user.email);
            return data;
          },
          [],
        );
        setUsers(_users);
      };
      const db = getAppDatabase(AppName.DEFAULT);
      const membersRef = firebaseRef(db, `members/${room.id}`);
      const unsubscribe = onValue(membersRef, snapshot => {
        if (!snapshot.exists()) {
          setUsers([]);
          return;
        }
        const result = snapshot.val();
        const roomSchema = z.record(memberSchema);
        const members = roomSchema.parse(result);
        const usersIds = Object.entries(members).reduce((data: string[], [_, member]) => {
          data.push(member.uid);
          return data;
        }, []);
        getUsers(usersIds);
      });
      return () => {
        unsubscribe();
      };
    } catch (e) {
      bug(e);
    }
    // eslint-disable-next-line no-use-before-define
  }, [room, bug]);

  return (
    <>
      <EditRoomForm
        open={showEditModal}
        handleClose={handleEditModalClose}
        room={room}
        users={users}
      />
      <IconButton ref={ref} onClick={handleOpen}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
        PaperProps={menuSx1}
        anchorOrigin={menuSx2}
        transformOrigin={menuSx3}
      >
        <MenuItem color="text.secondary">
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            // primaryTypographyProps={variantBodySx}
            onClick={handleDeleteClick}
          />
        </MenuItem>
        <MenuItem color="text.secondary">
          <ListItemIcon>
            <Iconify
              icon={room.isArchived ? 'game-icons:box-unpacking' : 'ph:archive'}
              width={24}
              height={24}
            />
          </ListItemIcon>
          <ListItemText
            primary={room.isArchived ? 'Recovery' : 'Archive'}
            // primaryTypographyProps={variantBodySx}
            onClick={handleArchiveClick}
          />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" color="text.secondary">
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            // primaryTypographyProps={variantBodySx}
            onClick={handleEditClick}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
