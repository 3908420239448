import AceEditor from 'react-ace';
import { DatabaseReference } from 'firebase/database';
import { getPublicFileRef, getPrivateFileRef } from '../store/FirebaseStore';
import { FileAccess } from './FileTypes';
import Firepad from '../@types/firepad';
import log from 'log';

export default class FirepadFile {
  private firepad: Firepad | undefined;
  private fileRef: firebase.default.database.Reference | undefined;
  aceEditor?: AceEditor;

  constructor(aceEditor?: AceEditor) {
    this.aceEditor = aceEditor;
  }

  public loadFile(
    roomId: string,
    questionId: string,
    fileAccess: FileAccess,
    userId: string,
    userColor: string,
  ) {
    this.reset();

    switch (fileAccess) {
      case FileAccess.Private:
        this.fileRef = getPrivateFileRef(roomId, questionId, userId);
        break;
      case FileAccess.Public:
        this.fileRef = getPublicFileRef(roomId, questionId);
        break;
      default:
        this.fileRef = undefined;
        break;
    }

    if (!this.fileRef) {
      log.error("LoadFile: Firepad file ref 'null'.");
      return;
    }
    if (!this.aceEditor) {
      log.error("LoadFile: Ace editor ref 'null'.");
      return;
    }

    this.firepad = window.Firepad.fromACE(this.fileRef, this.aceEditor.editor, {
      userId,
      userColor,
    });
  }

  public setAceEditor(aceEditor: AceEditor) {
    this.aceEditor = aceEditor;
  }

  public reset() {
    if (this.firepad !== undefined) {
      this.firepad.dispose();
      this.firepad = undefined;
    }
    this.aceEditor?.editor?.setValue('');
  }
}
