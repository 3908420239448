import { head } from 'helpers/ListHelpers';
import { ArrowDirection } from 'types/enums';
import { IMenuItems } from 'types/MenuTypes';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { PopoverOrigin, IconButton } from '@mui/material';

export const getArrowDirection = (anchorOrigin?: PopoverOrigin[]): ArrowDirection => {
  const direction = head(anchorOrigin)?.horizontal;
  return direction === 'left' ? ArrowDirection.LEFT : ArrowDirection.RIGHT;
};

export const getTabContent = (item: IMenuItems, showMenu: () => void) => ({
  [ArrowDirection.LEFT]: (
    <>
      <IconButton onClick={showMenu}>
        <KeyboardArrowLeftSharpIcon />
      </IconButton>
      <span>{item.value}</span>
    </>
  ),
  [ArrowDirection.RIGHT]: (
    <>
      <span>{item.value}</span>
      <IconButton onClick={showMenu}>
        <KeyboardArrowRightSharpIcon />
      </IconButton>
    </>
  ),
});
