import { StyledHeader } from '../../../../helpers/styled';
import { Trans } from '@lingui/macro';

export default function LoginHeader() {
  return (
    <StyledHeader variant="h6" align="center">
      <Trans id="login.header" />
    </StyledHeader>
  );
}
