import { useState, useCallback } from 'react';
import { AddUserForm, UserHeader, UserTable } from './components';
import { Container } from '@mui/material';
import Page from 'components/atoms/Page';

export default function UserComponent() {
  const [addUserModalShow, setAddUserModalShow] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setAddUserModalShow(false);
  }, []);

  return (
    <Page title="User">
      <Container>
        <UserHeader setAddUserModalShow={setAddUserModalShow} />
        <UserTable />
        <AddUserForm open={addUserModalShow} handleClose={closeModal} />
      </Container>
    </Page>
  );
}
