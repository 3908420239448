import { IDetectAndSwitchCodeMode } from './interfaces';
import { useEffect } from 'react';

export const DetectAndSwitchCodeMode = (data: IDetectAndSwitchCodeMode) => {
  const {
    isCodeMode,
    isLanguageBlocks,
    getAceInstance,
    transformBlocksToCode,
    transformCodeToBlocks,
  } = data;

  useEffect(() => {
    if (isLanguageBlocks && getAceInstance()) {
      isCodeMode ? transformBlocksToCode() : transformCodeToBlocks();
    }
  }, [isCodeMode, isLanguageBlocks, getAceInstance, transformBlocksToCode, transformCodeToBlocks]);
};
