import { useCallback } from 'react';
import { clsx } from 'clsx';
import { ChangeLessonButtonProps } from './interface';
import { Trans } from '@lingui/macro';
import CustomButton from 'components/atoms/CustomButton';
import styles from './changeLessonButton.module.css';
import DirectoryIcon from './DirectoryIcon';

export default function ChangeLessonButton(props: ChangeLessonButtonProps) {
  const { collapsedSidebar, disableContentBtn, isCoursesModalOpened, setIsCoursesModalOpened } =
    props;
  const buttonClassNames = clsx(
    styles['change-lesson-button__content'],
    isCoursesModalOpened && styles['change-lesson-button--active'],
    disableContentBtn && styles['change-lesson-button--disabled'],
    collapsedSidebar && styles['change-lesson-button--shortened'],
  );

  const handleOpen = useCallback(() => {
    setIsCoursesModalOpened(true);
  }, [setIsCoursesModalOpened]);

  return (
    <CustomButton className={buttonClassNames} disabled={disableContentBtn} onClick={handleOpen}>
      {!collapsedSidebar && (
        <span>
          <Trans id="dashboard.label.changeLesson">Change lesson</Trans>{' '}
        </span>
      )}
      <DirectoryIcon />
    </CustomButton>
  );
}
