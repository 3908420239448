import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import logo from 'assets/images/logo-white.png';
import CustomModal from 'components/organisms/CustomModal';
import styles from './loginModal.module.css';

export interface LoginModalProps {
  children: React.ReactNode;
}

const contentSx = { paddingBottom: 0 };

export default function LoginModal({ children }: LoginModalProps) {
  return (
    <CustomModal
      show
      backdrop
      backdropClassName={styles['login-modal__backdrop']}
      modalClassName={styles['login-modal__content']}
    >
      <>
        <DialogTitle>
          <img src={logo} alt="Logo" />
        </DialogTitle>
        <DialogContent sx={contentSx}>{children}</DialogContent>
      </>
    </CustomModal>
  );
}
