import { TasksSectionsProps } from './interface';
import Tasks from './Tasks';

export default function TasksSections(props: TasksSectionsProps) {
  const { 
    idActiveFile, 
    questions, 
    sections,
    selectedSection,
    solvedQuestions, 
    changeActiveFile,
    setSelectedSection,
  } = props;
  const sectionIds = Object.keys(sections).map(key => parseInt(key, 10));

  return (
    <>
      {Object.values(sections).map((section, idx) => (
        <Tasks
          key={section.sectionStart}
          idActiveFile={idActiveFile}
          itemIndex={idx}
          questions={questions}
          section={section}
          sectionIds={sectionIds}
          selectedSection={selectedSection}
          solvedQuestions={solvedQuestions}
          changeActiveFile={changeActiveFile}
          setSelectedSection={setSelectedSection}
        />
      ))}
    </>
  );
}
