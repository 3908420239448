import { createContext, useContext } from 'react';

export interface ICurrentRoomContext {
  showTaskModal: () => void;
  collapsedSidebar: boolean;
  toggleSidebar: () => void;
  isFileBroswerDisabled: boolean;
  setFileBroswerDisabled: (b: boolean) => void;
}

export const CurrentRoomContext = createContext<ICurrentRoomContext>(undefined!);

export function useCurrentRoomContext() {
  const context = useContext(CurrentRoomContext);

  if (!context) {
    throw new Error(`useCurrentRoomContext must be used within CurrentRoomContext Provdier`);
  }

  return context;
}
