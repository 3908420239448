import { useRef, useEffect } from 'react';
import { MessageLog } from 'types/ChatGPT';
import LoadingResponse from './components/LoadingResponse';
import WelcomeView from './components/WelcomeView';
import ChatMessageLog from './components/ChatMessageLog';
import styles from './chatText.module.css';

interface IChatText {
  logs: MessageLog[];
  loading: boolean;
  abort: Function;
}

export default function ChatText(props: IChatText) {
  const { logs, loading, abort } = props;
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
    }
  }, [logs, loading]);

  return (
    <div ref={boxRef} className={styles['chatText-container']}>
      {logs.length === 0 && <WelcomeView />}
      {logs.map(log => (
        <ChatMessageLog key={log.date.toString()} text={log.message} type={log.type} />
      ))}
      {loading && <LoadingResponse abort={abort} />}
    </div>
  );
}
