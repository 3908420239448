import { ContentProps } from './interface';
import { TextField } from '@mui/material';

export default function Content(props: ContentProps) {
  const { field, label, required, type = 'text', value, onChange } = props;
  const { value: fieldValue, onChange: defaultOnChange, ref } = field;

  const isError = Boolean(value?.message);
  const helperText = value ? `${value.message}` : '';
  const handleChange = onChange || defaultOnChange;

  return (
    <TextField
      error={isError}
      helperText={helperText}
      required={required}
      label={label}
      type={type}
      value={fieldValue}
      onChange={handleChange}
      ref={ref}
    />
  );
}
