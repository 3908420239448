import { useState } from 'react';
import { clsx } from 'clsx';
import { isStudent } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { CurrentLesson } from 'types/ContentTypes';
import { ChangeActiveFile, GetContent } from './hooks/callbacks';
import { ShowMemberFiles, UpdateMemberActiveFile } from './hooks/effects';
import styles from './tasksList.module.css';

export default function TasksList() {
  const { currentUser } = useAppContext();
  const { bug } = useErrorContext();
  const { locale: currentLocale } = useLocaleContext();
  const { currentUserId, leaderMode, localLessonId, room, setActiveFile } = useWorkspaceContext();
  const [idActiveFile, setIdActiveFile] = useState('');
  const [lessonContent, setLessonContent] = useState<CurrentLesson | null>(null);
  const [selectedSection, setSelectedSection] = useState<number>(1);
  const [solvedQuestions, setSolvedQuestions] = useState<string[]>([]);

  const changeActiveFile = ChangeActiveFile({ currentLocale, setActiveFile });
  const getContent = GetContent({
    idActiveFile,
    lessonContent,
    selectedSection,
    solvedQuestions,
    changeActiveFile,
    setSelectedSection,
  });
  try {
    ShowMemberFiles({
      currentUserId,
      localLessonId,
      room,
      changeActiveFile,
      setLessonContent,
      setSolvedQuestions,
    });
    UpdateMemberActiveFile({ currentUserId, room, setIdActiveFile });
  } catch (e) {
    bug(e);
  }

  const tasksListClassNames = clsx(
    styles['tasks-list__content'],
    isStudent(currentUser?.role) && leaderMode.isLeaderModeOn && styles['tasks-list--disabled'],
  );
  const content = getContent();

  return (
    <section data-testid="file-explorer" className={tasksListClassNames}>
      {content}
    </section>
  );
}
