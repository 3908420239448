import { purple } from 'constants/Colors';
import { styled } from '@mui/material/styles';
import { Box, Slider } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';

const sliderGapSize: number = 5;
const iconSize = { width: 38, height: 38 };

export const Content = styled(Box)({
  display: 'flex',
  alignItems: 'flexStart',
  flexDirection: 'column',
});

export const SliderRow = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  padding: '10px 0 10px 10px',
  gap: `${sliderGapSize}px`,
});

export const ColorSlider = styled(Slider)({
  color: purple,
  width: '145px',
});

const iconProps = {
  padding: '0 9px',
  width: `${iconSize.width}px`,
  height: `${iconSize.height}px`,
};

export const RedIcon = styled(SquareRoundedIcon)({
  ...iconProps,
  color: '#FF0000',
});

export const GreenIcon = styled(SquareRoundedIcon)({
  ...iconProps,
  color: '#00FF00',
});

export const BlueIcon = styled(SquareRoundedIcon)({
  ...iconProps,
  color: '#0000FF',
});
