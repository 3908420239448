import { useState } from 'react';
import { ColorSection, ImageSection, NavigationBar, SoundSection } from './components';
import { selectedSection } from 'types/ExtensionsMenuTypes';
import styles from './extensionsMenu.module.css';

interface ExtensionsMenuContentProps {
  isOpen: boolean;
}

export default function ExtensionsMenuContent(props: ExtensionsMenuContentProps) {
  const { isOpen } = props;
  const [selectedIcon, setSelectedIcon] = useState<number>(selectedSection.COLOR);

  const sections = {
    [selectedSection.COLOR]: <ColorSection />,
    [selectedSection.IMAGE]: <ImageSection />,
    [selectedSection.SOUND]: <SoundSection isMenuOpen={isOpen} />,
  };

  const theme = {
    container: {
      width: `${isOpen ? 216 : 0}px`,
      transform: `translate3d(${isOpen ? 0 : -216}px, 0, 0)`,
    },
  };

  return (
    <div className={styles['extensions-menu__container']} style={theme.container}>
      <NavigationBar selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
      <div className={styles['extensions-menu__content']}>{sections[selectedIcon] || null}</div>
    </div>
  );
}
