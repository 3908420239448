import { Question } from 'types/ContentTypes';
import { white } from 'constants/Colors';
import { getChar } from 'helpers/BlocksCodeTransformer';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import { Button, IconButton } from '@mui/material';
import enterIcon from 'assets/svg/keyboard/enter-icon.svg';
import backspaceIcon from 'assets/svg/keyboard/backspace-icon.svg';
import indentIcon from 'assets/svg/keyboard/tab-ident-icon.svg';
import pasteIcon from 'assets/svg/keyboard/paste-icon.svg';
import cutIcon from 'assets/svg/keyboard/сut-icon.svg';
import copyIcon from 'assets/svg/keyboard/copy-icon.svg';

/**
 * Function that allow to make Abbreviation from any word
 *
 * @param word property word
 * @param n defers how many letters you want to cut
 * @returns new lowercased abbreviation from word with @n characters
 */
const ABBR_CH = 3;

export function getAbbreviation(word: string, n: number): string {
  return word.slice(0, n).toLocaleLowerCase();
}

const keyboardStyles = {
  btn: {
    borderRadius: '19px',
    paddingLeft: '12px',
    paddingRight: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '45px',
    width: '45px',
    height: '34px',
    background: transformColorFromHexToRGBA(white, 0.08),
  },
  iconBtn: {
    padding: '2px',
    margin: '0px',
    width: '37px',
    height: '37px',
  },
  img: {
    width: '45px',
    padding: '0',
    margin: '-2px',
  },
};

export function getKeyIcon(word: string): JSX.Element {
  switch (word) {
    case 'Enter':
      return (
        <IconButton style={keyboardStyles.iconBtn}>
          <img style={keyboardStyles.img} src={enterIcon} alt={getAbbreviation(word, ABBR_CH)} />
        </IconButton>
      );
    case 'Tab':
      return (
        <IconButton style={keyboardStyles.iconBtn}>
          <img style={keyboardStyles.img} src={indentIcon} alt={getAbbreviation(word, ABBR_CH)} />
        </IconButton>
      );
    case 'Backspace':
      return (
        <IconButton style={keyboardStyles.iconBtn}>
          <img
            style={keyboardStyles.img}
            src={backspaceIcon}
            alt={getAbbreviation(word, ABBR_CH)}
          />
        </IconButton>
      );
    case 'Paste':
      return (
        <Button style={keyboardStyles.btn}>
          <img style={keyboardStyles.img} src={pasteIcon} alt={getAbbreviation(word, ABBR_CH)} />
        </Button>
      );
    case 'Cut':
      return (
        <Button style={keyboardStyles.btn}>
          <img style={keyboardStyles.img} src={cutIcon} alt={getAbbreviation(word, ABBR_CH)} />
        </Button>
      );
    case 'Copy':
      return (
        <Button style={keyboardStyles.btn}>
          <img style={keyboardStyles.img} src={copyIcon} alt={getAbbreviation(word, ABBR_CH)} />
        </Button>
      );

    default:
      return <div>{getAbbreviation(word, ABBR_CH)}</div>;
  }
}

export function transformKeywords(
  isCodeMode: boolean,
  isCutCopyMode: boolean,
  question: Question | null,
): string[] | null {
  if (!question?.keyWords) return null;
  const keywords = question?.keyWords
    .split(';')
    .filter(key => (Boolean(key) && isCodeMode ? key.startsWith('img[') : true))
    .map(key => getChar(key));

  return !isCutCopyMode ? [...new Set(keywords)] : null;
}

// clipboard simulator handler
export enum TextWorkerMethodEnum {
  GET = 'GET',
  SET = 'SET',
}

function textWorkerInitializator() {
  const cache = {
    text: '',
  };

  return (method: TextWorkerMethodEnum, text?: string): string | boolean => {
    if (method === TextWorkerMethodEnum.GET) return cache.text;

    if (text && text !== cache.text) {
      cache.text = text;
      return true;
    }

    return false;
  };
}

export const textWorker = textWorkerInitializator();
