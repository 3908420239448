import { LeaderModeType, LeaderModeQuestion, LeaderModeData } from './LeaderModeTypes';
import { DatabaseReference, onValue, ref, off, Database } from 'firebase/database';
import { Question } from './ContentTypes';
import {
  getAppDatabase,
  AppName,
  setLeaderMode,
  setLeaderModeDebugStep,
  setLeaderQuestion,
} from 'store/FirebaseStore';
import { CurrentUser, UserType } from './UserTypes';

export default class LeaderMode {
  public onStateChanged?: (state: boolean) => void;
  public onDataChanged?: (data: LeaderModeData) => void;
  public onDebugChanged?: (steps: number) => void;
  public isLeaderModeOn = false;
  public mode: LeaderModeType = LeaderModeType.None;
  public question: Question | null = null;
  public currentUserUid: string | null = null;
  private changeLeaderModeEvent: DatabaseReference | null = null;
  private leaderModeFileEvent: DatabaseReference | null = null;
  private leaderModeDebugEvent: DatabaseReference | null = null;
  private user: CurrentUser | null = null;
  private roomId: string = '';
  private leaderQuestion: LeaderModeQuestion | null = null;

  private listenToLeaderModeFile(database: Database, roomId: string) {
    this.leaderModeFileEvent && off(this.leaderModeFileEvent);
    this.leaderModeFileEvent = ref(database, `leader_mode_data` + `/${roomId}`);
    onValue(this.leaderModeFileEvent, async snapshot => {
      try {
        if (snapshot.exists()) {
          const leaderModeData: LeaderModeData = await snapshot.val();
          if (!this.onDataChanged) return;
          this.onDataChanged(leaderModeData);
        }
      } catch (e) {
        throw e;
      }
    });
  }

  private listenToLeaderModeDebug(database: Database, roomId: string) {
    this.leaderModeDebugEvent && off(this.leaderModeDebugEvent);
    this.leaderModeDebugEvent = ref(database, `leader_mode_debug` + `/${roomId}/steps`);
    onValue(this.leaderModeDebugEvent, async snapshot => {
      try {
        if (snapshot.exists()) {
          const steps = (await snapshot.val()) as number;
          if (!this.onDebugChanged) return;
          this.onDebugChanged(steps);
        }
      } catch (e) {
        throw e;
      }
    });
  }

  private listenToLeaderMode(roomId: string, user: CurrentUser) {
    const database = getAppDatabase(AppName.DEFAULT);
    this.changeLeaderModeEvent && off(this.changeLeaderModeEvent);
    this.changeLeaderModeEvent = ref(database, `rooms` + `/${roomId}/` + `isLeaderMode`);
    try {
      onValue(this.changeLeaderModeEvent, async snapshot => {
        const isLeaderMode = await snapshot.val();
        this.isLeaderModeOn = isLeaderMode;
        if (!this.onStateChanged) return;
        this.onStateChanged(isLeaderMode);
        this.setMode(user.role);
        if (isLeaderMode) {
          this.listenToLeaderModeFile(database, roomId);
          this.listenToLeaderModeDebug(database, roomId);
        }
      });
    } catch (e) {
      throw e;
    }
  }

  private setMode(userAccess: UserType) {
    switch (userAccess) {
      case UserType.Student:
        this.mode = LeaderModeType.StudentLeaderMode;
        break;
      case UserType.Tutor:
        this.mode = LeaderModeType.TutorLeaderMode;
        break;
      default:
        this.mode = LeaderModeType.None;
    }
  }

  public createLeaderMode(roomId: string, user: CurrentUser) {
    this.roomId = roomId;
    this.user = user;
    user.role === UserType.Student && this.listenToLeaderMode(roomId, user);
  }

  public async setLeaderModeType(mode: LeaderModeType) {
    if (this.user?.role === UserType.Tutor) {
      this.mode = mode;
      const isLeaderMode: boolean = mode !== LeaderModeType.None;
      this.isLeaderModeOn = isLeaderMode;
      const leaderMode = { roomId: this.roomId, isLeaderMode };
      try {
        await setLeaderMode(leaderMode);
      } catch (e) {
        throw e;
      }
    }
  }

  public async setDebugStep(step: number) {
    try {
      await setLeaderModeDebugStep(this.roomId, step);
    } catch (e) {
      throw e;
    }
  }

  public async setQuestion(question: LeaderModeQuestion) {
    this.leaderQuestion = question;
    try {
      await setLeaderQuestion(question);
    } catch (e) {
      throw e;
    }
  }

  public getLeaderQuestion() {
    if (this) return this.leaderQuestion;
    return null;
  }
}
