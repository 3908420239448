import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/app/app.provider';
import { useForm } from 'react-hook-form';
import { LoginSchemaData, loginSchema } from 'schemas/login.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Trans } from '@lingui/macro';
import { useErrorContext } from 'providers/error/error.provider';
import { StyledLoadingButton } from '../helpers/styled';
import { SubmitUserForm } from './submitHelper';
import { codeToMessage, isCustomValidationCode } from 'helpers/statusCodeValidation';
import { useLocaleContext } from 'providers/i18n/i18n.provider';

const { REACT_APP_DEFAULT_USER_PASSWORD } = process.env;

export default function StudentView() {
  const { setUser } = useAppContext();
  const { i18n } = useLocaleContext();
  const { bug } = useErrorContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<LoginSchemaData>({
    resolver: zodResolver(loginSchema.pick({ email: true })),
  });

  const getEmailErrorMessage = useCallback(() => {
    if (!errors.email) return '';
    return isCustomValidationCode(errors.email.message)
      ? codeToMessage(i18n, errors.email.message)
      : errors.email.message;
  }, [errors.email, i18n]);

  const setAuthError = useCallback(
    (name: keyof LoginSchemaData, message: string, shouldFocus = true) => {
      setError(
        name,
        {
          message,
        },
        {
          shouldFocus,
        },
      );
    },
    [setError],
  );

  const submit = async (data: LoginSchemaData) => {
    try {
      await SubmitUserForm({
        data: { ...data, password: REACT_APP_DEFAULT_USER_PASSWORD as string },
        setUser,
        setAuthError,
        navigate,
        i18n,
        tutorView: false,
      });
    } catch (e) {
      bug(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <TextField
        margin="dense"
        id="id-student-email"
        type="email"
        variant="filled"
        label={<Trans id="input.email">E-mail address</Trans>}
        error={Boolean(errors.email)}
        helperText={getEmailErrorMessage()}
        size="medium"
        disabled={isSubmitting ? true : false}
        {...register('email')}
        fullWidth
      />
      <Box width="100%">
        <Stack>
          <StyledLoadingButton loading={isSubmitting} type="submit" variant="contained">
            <Trans id="login.btn">login</Trans>
          </StyledLoadingButton>
        </Stack>
      </Box>
    </form>
  );
}
