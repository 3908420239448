import { MouseEvent, MutableRefObject, useCallback, useRef, useState } from 'react';
import { head } from 'helpers/ListHelpers';
import { ISelectedMenuItem } from 'types/MenuTypes';
import { generateMenuItems } from '../helpers';
import { getArrowDirection, getTabContent } from './helpers';
import { MenuItemProps } from './interface';
import { MenuItem as MenuItemMaterial } from '@mui/material';
import CustomMenu from 'components/atoms/CustomMenu';
import styles from './menuItem.module.css';

export default function MenuItem(props: MenuItemProps) {
  const { item, menuClassNames, anchorOrigin, transformOrigin, selectItem } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const itemRef = useRef() as MutableRefObject<HTMLLIElement>;
  const showMenuCallback = useCallback(() => setShowMenu(true), []);
  const isLastElement: boolean = item.children.length === 0;

  const arrowDirection = getArrowDirection(anchorOrigin);
  const tabContent = isLastElement
    ? item.value
    : getTabContent(item, showMenuCallback)[arrowDirection];

  const setSelectedItem = useCallback(
    (selectedMenuItem: ISelectedMenuItem | null) => {
      setShowMenu(false);
      const menuItem: ISelectedMenuItem = {
        key: item.key,
        value: item.value,
        child: selectedMenuItem,
      };
      selectItem(menuItem);
    },
    [item, selectItem],
  );

  const handleItemClick = useCallback(
    (e: MouseEvent) => {
      (e.target instanceof HTMLLIElement || e.target instanceof HTMLSpanElement) &&
        setSelectedItem(null);
    },
    [setSelectedItem],
  );

  const menuChildren: JSX.Element[] = generateMenuItems(
    item.children,
    setSelectedItem,
    menuClassNames,
    anchorOrigin,
    transformOrigin,
  );

  return (
    <>
      <MenuItemMaterial
        ref={itemRef}
        onClick={handleItemClick}
        className={styles['menu-item__content']}
      >
        {tabContent}
      </MenuItemMaterial>
      {!isLastElement && (
        <CustomMenu
          parentRef={itemRef.current}
          showMenu={showMenu}
          className={head(menuClassNames)}
          anchorOrigin={head(anchorOrigin)}
          transformOrigin={head(transformOrigin)}
          setShowMenu={setShowMenu}
          selectItem={setSelectedItem}
        >
          {menuChildren}
        </CustomMenu>
      )}
    </>
  );
}
