import { useCallback } from 'react';
import { IHighlightErrorLine } from './interfaces';

export const HighlightErrorLine = (data: IHighlightErrorLine) => {
  const { aceRef } = data;

  return useCallback(
    (line: number, message: string) => {
      aceRef.current?.highlightErrorLine(line, message);
    },
    [aceRef],
  );
};
