import { DataSnapshot } from 'firebase/database';

function getItemArray(snapshot: DataSnapshot) {
  if (snapshot.exists()) {
    return Object.keys(snapshot.val()).map(key => snapshot.val()[key]);
  }
  return [];
}

export default getItemArray;
