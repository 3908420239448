import { Question } from 'types/ContentTypes';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import ExtensionsMenuContent from './ExtensionsMenuContent';

interface ExtensionsMenuProps {
  isOpen: boolean;
  question: Question | null;
}

export default function ExtensionsMenu(props: ExtensionsMenuProps) {
  const { isOpen, question } = props;
  if (question?.language !== ProgrammingLanguage.Python) return null;
  return <ExtensionsMenuContent isOpen={isOpen} />;
}
