import { useCallback } from 'react';
import { ILogoutCurrentUser } from './interfaces';

export const LogoutCurrentUser = (data: ILogoutCurrentUser) => {
  const { fatal, logout, navigate } = data;
  return useCallback(async () => {
    try {
      await logout();
      navigate(`/`);
    } catch (e) {
      fatal(e);
    }
  }, [fatal, logout, navigate]);
};
