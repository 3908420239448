import { db } from '../../helpers/firestore';
import { getDoc, doc, setDoc, serverTimestamp, deleteDoc } from 'firebase/firestore';
import { ShareData } from 'types/ShareTypes';
import { shareDataSchema } from 'schemas/share.schema';

/**
 *
 * @param shareData contains data for shared app like (id - m5 from `${roomId}${userId}${questionId}${language}` etc)
 * @returns link id of the shared app
 */
export const addSharedLink = async (shareData: ShareData): Promise<void> => {
  try {
    await setDoc(doc(db, 'shares', shareData.id), {
      date: serverTimestamp(),
      ...shareData,
    });
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param shareDataId contains data for shared app like (id - m5 from `${roomId}${userId}${questionId}${language}` etc)
 * @returns void
 */
export const removeSharedLink = async (shareDataId: string): Promise<void> => {
  try {
    await deleteDoc(doc(db, 'shares', shareDataId));
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param shareId contains generated m5 from `${roomId}${userId}${questionId}${language}`
 * @returns boolen, true if found sharedId in database or false if not exist.
 */
export const getSharedLink = async (shareId: string): Promise<boolean> => {
  try {
    const docRef = doc(db, 'shares', shareId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return true;
    }
    return false;
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param shareId contains generated m5 from `${roomId}${userId}${questionId}${language}`
 * @returns ShareData model with all needed data.
 */
export const getSharedData = async (shareId: string): Promise<ShareData | null> => {
  try {
    const docRef = doc(db, 'shares', shareId);
    const docSnap = await getDoc(docRef);
    const result = shareDataSchema.safeParse(docSnap.data());
    return result.success ? result.data : null;
  } catch (e) {
    throw e;
  }
};
