import { RefObject, useEffect, useRef } from 'react';
import { off, on } from '../helpers/CodeHelper';

const defaultEvents = ['mousedown', 'touchstart'];

/**
 * Usually this hook is used with modals, selects stc.
 * @param ref html element reference
 * @param onClickAway function which is triggered on clicking outside the html element
 * @param events additional events (listeners)
 */
const useClickAway = <E extends Event = Event>(
  ref: RefObject<HTMLElement | null>,
  onClickAway: (event: E) => void,
  events: string[] = defaultEvents,
) => {
  const savedCallback = useRef(onClickAway);
  useEffect(() => {
    savedCallback.current = onClickAway;
  }, [onClickAway]);
  useEffect(() => {
    const handler = (event: E) => {
      const { current: el } = ref;
      el && !el.contains(event.target as Node) && savedCallback.current(event);
    };
    events.forEach(eventName => on(document, eventName, handler));
    return () => {
      events.forEach(eventName => off(document, eventName, handler));
    };
  }, [events, ref]);
};

export default useClickAway;
