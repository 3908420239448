import { useEffect } from 'react';
import { onValue, ref } from 'firebase/database';
import { userSchema } from 'schemas/user.schema';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { User } from 'types/UserTypes';
import { z } from 'zod';
import { IGetUsers } from './interfaces';

export const GetUsers = (props: IGetUsers) => {
  const { bug, setUserList } = props;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      const database = getAppDatabase(AppName.DEFAULT);
      const usersRef = ref(database, 'users');
      const unsubscribe = onValue(usersRef, snapshot => {
        const result = snapshot.val();
        const usersSchema = z.record(userSchema);
        const parsedUsers = usersSchema.parse(result);
        const users = Object.entries(parsedUsers).reduce((data: User[], [_, user]) => {
          data.push(user);
          return data;
        }, []);
        setUserList(users);
      });
      return () => {
        unsubscribe();
      };
    } catch (e) {
      bug(e);
    }
  }, [bug, setUserList]);
};
