import { useEffect } from 'react';
import { GetCurrentLesson, ShowFiles } from '../callbacks';
import { IShowMemberFiles } from './interfaces';

export const ShowMemberFiles = (data: IShowMemberFiles) => {
  const {
    currentUserId,
    localLessonId,
    room,
    changeActiveFile,
    setLessonContent,
    setSolvedQuestions,
  } = data;

  const getCurrentLesson = GetCurrentLesson({
    changeActiveFile,
    setLessonContent,
  });

  const showFiles = ShowFiles({
    room,
    setSolvedQuestions,
  });

  useEffect(() => {
    (async () => {
      try {
        if (localLessonId) {
          await getCurrentLesson(localLessonId);
        }
      } catch (e) {
        throw e;
      }
    })();
  }, [localLessonId, getCurrentLesson]);

  useEffect(() => {
    try {
      if (!localLessonId) return () => null;
      const unsubscribe = showFiles(currentUserId, localLessonId);
      return () => {
        unsubscribe();
      };
    } catch (e) {
      throw e;
    }
  }, [currentUserId, localLessonId, showFiles]);
};
