import { useEffect } from 'react';
import { isStudent } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { updateMemberData } from 'store/FirebaseStore';
import { FileDataType } from 'types/FileTypes';
import { IUpdateSessionDataForFile } from './interfaces';

export const UpdateSessionDataForFile = (data: IUpdateSessionDataForFile) => {
  const { currentFile, updateFileView, updateNavigationStack } = data;
  const { currentUser } = useAppContext();
  const { currentUserId, leaderMode, room } = useWorkspaceContext();

  useEffect(() => {
    if (currentFile && currentUserId) {
      const data: FileDataType = {
        id: currentFile.id,
        type: currentFile.type,
        userId: currentUserId,
      };
      currentUser && room && updateMemberData(room.id, currentUser.uid, data.id);
      !(isStudent(currentUser?.role) && leaderMode.isLeaderModeOn) && updateFileView(data);
      updateNavigationStack();
    }
  }, [
    currentUserId,
    currentFile,
    updateFileView,
    currentUser,
    room,
    updateNavigationStack,
    leaderMode.isLeaderModeOn,
  ]);
};
