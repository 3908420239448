import { z } from 'zod';

export const localeSchema = z.object({
  en: z.string(),
  es: z.string(),
  pl: z.string(),
  pt: z.string(),
});

export const localeUnionSchema = z.union(
  [z.literal('en'), z.literal('es'), z.literal('pl')],
  z.literal('pt'),
);
