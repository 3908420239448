import { useCallback, useEffect, useMemo } from 'react';
import { SelectController, TextareaController, TextController } from './components';
import { genderOptions, roleOptions, userTypeOptions, userCountryOptions } from './constants';
import { FormFieldsProps } from './interface';
import { Box, FormControl } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';

export default function FormFields(props: FormFieldsProps) {
  const { control, errors, isStudent, handleSubmit, setValue } = props;

  const handleAgeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('age', parseInt(e.target.value, 10));
    },
    [setValue],
  );

  const handleBirthDaChange = useCallback(
    (date: Dayjs | null) => {
      setValue('yearOfBirth', date?.year());
    },
    [setValue],
  );

  const datePicker = useMemo(() => ['DatePicker'], []);

  const yearDate = useMemo(
    () => dayjs(new Date(control._defaultValues.yearOfBirth as number, 0, 1)),
    [control],
  );

  return (
    <Box width="65%">
      <FormControl fullWidth onSubmit={handleSubmit}>
        <br />
        <SelectController
          control={control}
          id="role-field"
          label="Role"
          name="role"
          selectOptions={roleOptions}
        />
        <br />
        <TextController control={control} label="Name" name="name" required value={errors.name} />
        <br />
        <TextController
          control={control}
          label="Surname"
          name="surname"
          required
          value={errors.surname}
        />
        <br />
        <TextController
          control={control}
          label="E-mail"
          name="email"
          required
          value={errors.email}
        />
        <br />
        <SelectController
          control={control}
          id="country"
          label="Kraj"
          name="country"
          selectOptions={userCountryOptions}
        />
        <br />
        <TextController control={control} label="Miasto" name="city" required value={errors.city} />
        <br />
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
          <DemoContainer components={datePicker}>
            <DatePicker
              label="Data urodzenia" // eslint-disable-next-line
              views={['year']}
              value={yearDate}
              onChange={handleBirthDaChange}
            />
          </DemoContainer>
        </LocalizationProvider>
        <br />
        {isStudent && (
          <>
            <TextController
              control={control}
              label="Parent E-mail"
              name="parentEmail"
              required
              value={errors.parentEmail}
            />
            <br />
            <TextController
              control={control}
              label="Phone Number"
              name="phoneNumber"
              required
              value={errors.phoneNumber}
            />
            <br />
            <SelectController
              control={control}
              id="gender-field"
              label="Gender"
              name="gender"
              selectOptions={genderOptions}
            />
            <br />
            <TextController
              control={control}
              label="Age"
              name="age"
              required
              type="number"
              value={errors.age}
              onChange={handleAgeChange}
            />
            <br />
          </>
        )}
        <SelectController
          control={control}
          id="usertype-field"
          label="User type"
          name="userType"
          selectOptions={userTypeOptions}
        />
        <br />
        <TextareaController control={control} label="Rooms" name="rooms" rowsNumer={3} />
      </FormControl>
    </Box>
  );
}
