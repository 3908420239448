import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import DatePicker from 'react-datepicker';
// material
import { ISearch } from 'types/AdminTypes';
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Box,
  TextField,
} from '@mui/material';
// component
import Iconify from 'components/atoms/Iconify';
import MSelect from './components/MSelect';
import 'react-datepicker/dist/react-datepicker.css';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  padding: theme.spacing(0, 1, 0, 3),
}));

const FilterStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 'fit-content',
  marginRight: theme.spacing(6),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  // @ts-ignore
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    // @ts-ignore
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

const iconifySx = { color: 'text.disabled', width: 20, height: 20 };
const tooltipSx = { position: 'absolute', right: 14 };

// ----------------------------------------------------------------------

export interface TableFiltersProps {
  numSelected: number;
  searchName: string;
  placeholder?: string;
  onSearchName: (e: React.ChangeEvent<HTMLInputElement>) => void;
  filters: Record<string, { name: string; value: string | number }[]>;
  date?: { start: Date | null; end: Date | null };
  activeFilters?: ISearch;
  setActiveFilters: Dispatch<SetStateAction<ISearch | undefined>>;
  handleDateChange?: (dates: [Date | null, Date | null]) => void;
  clearFilters: () => void;
}

export default function TableFilters(props: TableFiltersProps) {
  const {
    setActiveFilters,
    numSelected,
    searchName,
    onSearchName,
    filters,
    date,
    activeFilters,
    handleDateChange,
    clearFilters,
    placeholder = 'Search...',
  } = props;
  const showClearButton =
    Object.values(activeFilters || {}).findIndex(value => value !== '') !== -1 ||
    (date && (date.start || date.end));

  const rootSx = useMemo(
    () => ({
      ...(numSelected > 0 && {
        color: 'primary.main',
        bgcolor: 'primary.lighter',
      }),
    }),
    [numSelected],
  );

  const handleFilterChange = useCallback(
    (name: string, value: string) => {
      setActiveFilters(prev => ({ ...prev, [name]: value }));
    },
    [setActiveFilters],
  );

  return (
    <RootStyle sx={rootSx}>
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <SearchStyle
          value={searchName}
          onChange={onSearchName}
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={iconifySx} />
            </InputAdornment>
          }
        />
      )}

      <FilterStyle>
        {Object.keys(filters).length !== 0 &&
          Object.entries(filters).map(([key, value]) => {
            const label = activeFilters && activeFilters[key as keyof ISearch];
            const currentValue = label !== undefined ? label : '';
            return (
              <Box key={key} ml={'24px'}>
                <MSelect
                  name={key}
                  currentValue={currentValue}
                  items={value}
                  handleChange={handleFilterChange}
                />
              </Box>
            );
          })}

        {date && handleDateChange && (
          <Box mr={'24px'}>
            <DatePicker
              onChange={handleDateChange}
              startDate={date.start}
              endDate={date.end}
              selectsRange
              customInput={<TextField disabled label="Time range" variant="outlined" />}
            />
          </Box>
        )}
      </FilterStyle>
      {showClearButton && (
        <Tooltip title="Clear filter" sx={tooltipSx}>
          <IconButton onClick={clearFilters}>
            <Iconify icon="ant-design:clear-outlined" />
          </IconButton>
        </Tooltip>
      )}
    </RootStyle>
  );
}
