import {
  en as enPlurals,
  pl as plPlurals,
  es as esPlurals,
  pt as ptPlurals,
} from 'make-plural/plurals';

import { messages as translationsEn } from '../locales/en/messages';
import { messages as translationsEs } from '../locales/es/messages';
import { messages as translationsPL } from '../locales/pl/messages';
import { messages as translationsPrg } from '../locales/pt/messages';
import versions from './version.json';

import { SetupInterface } from 'types/i18nTypes';

const setup: SetupInterface = {
  locales: {
    messages: {
      en: translationsEn,
      es: translationsEs,
      pl: translationsPL,
      pt: translationsPrg,
    },
    localeData: {
      en: { plurals: enPlurals },
      es: { plurals: esPlurals },
      pl: { plurals: plPlurals },
      pt: { plurals: ptPlurals },
    },
  },
  version: versions.version,
};

export default setup;
