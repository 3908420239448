import { useCallback } from 'react';
import { useErrorContext } from 'providers/error/error.provider';
import { getQuestion } from 'store/FirebaseStore';
import { Question } from 'types/ContentTypes';
import { FileData } from 'types/FileTypes';
import { ILoadFileAfterReset } from './interfaces';

export const LoadFileAfterReset = (data: ILoadFileAfterReset) => {
  const { setQuestion, setIsCodeMode } = data;
  const { fatal } = useErrorContext();

  return useCallback(
    async (file: FileData): Promise<Question | null> => {
      try {
        const newQuestion = await getQuestion(file.id);
        setQuestion(newQuestion);
        setIsCodeMode(false);
        return newQuestion;
      } catch (e) {
        fatal(e);
      }
      return null;
    },
    [fatal, setIsCodeMode, setQuestion],
  );
};
