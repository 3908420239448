import { useState } from 'react';
import { EDIT_STUDENT_FILE_ACTION } from 'classes/UserActions';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { IFirepadParameters } from 'types/EditorTypes';
import { FileData, FileEvent } from 'types/FileTypes';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import {
  ActivateLoadingHandler,
  LoadFileAfterReset,
  SetReadOnly,
  StopLoadingHandler,
  UpdateCurrentFile,
  UpdateFileView,
  UpdateNavigationStack,
} from './callbacks';
import {
  DetectAndSwitchCodeMode,
  SetFileState,
  SwitchLeaderMode,
  UpdateSessionDataForFile,
  useFileEvents,
  useFirepad,
  useLeaderMode,
} from './effects';
import { GetFirepadParameters } from './memos';
import { IRunHooks } from './interface';

export const RunHooks = (data: IRunHooks) => {
  const {
    ideMethods,
    isCodeMode,
    leaderModeSwitchValue,
    loadedAce,
    question,
    setIsCodeMode,
    setLoadedAce,
    setNavigationStack,
    setQuestion,
  } = data;
  const {
    getAceInstance,
    removehighlightLine,
    reset,
    transformBlocksToCode,
    transformCodeToBlocks,
  } = ideMethods;
  const { userActions } = useWorkspaceContext();

  const isLanguageBlocks = question?.language === ProgrammingLanguage.Blocks;
  const canEditStudentFile = userActions.askForAction(EDIT_STUDENT_FILE_ACTION);

  // ---------------------------- STATES ----------------------------
  const [currentFile, setCurrentFile] = useState<FileEvent>();
  const [fileData, setFileData] = useState<FileData | null>();
  const [isLoading, setIsLoading] = useState(false);

  // ---------------------------- MEMO ----------------------------
  const firepadHookParameters: IFirepadParameters = GetFirepadParameters({
    fileData,
    ideMethods,
    isCodeMode,
    isLanguageBlocks,
    question,
    setLoadedAce,
  });

  // ---------------------------- CALLBACKS ----------------------------------
  const activateLoadingHandler = ActivateLoadingHandler({ setIsLoading });
  const loadFileAfterReset = LoadFileAfterReset({ setQuestion, setIsCodeMode });
  const setReadOnly = SetReadOnly({ getAceInstance });
  const stopLoadingHandler = StopLoadingHandler({ setIsLoading });
  const updateCurrentFile = UpdateCurrentFile({ setCurrentFile, setLoadedAce });
  const updateFileView = UpdateFileView({
    currentFile,
    leaderModeSwitchValue,
    activateLoadingHandler,
    loadFileAfterReset,
    setFileData,
    stopLoadingHandler,
  });
  const updateNavigationStack = UpdateNavigationStack({ currentFile, setNavigationStack });

  // ---------------------------- EFFECTS ----------------------------------
  DetectAndSwitchCodeMode({
    isCodeMode,
    isLanguageBlocks,
    getAceInstance,
    transformBlocksToCode,
    transformCodeToBlocks,
  });
  SwitchLeaderMode({ leaderModeSwitchValue, question });
  UpdateSessionDataForFile({ currentFile, updateFileView, updateNavigationStack });
  useFileEvents({ updateCurrentFile, getAceInstance, removehighlightLine });
  useFirepad({ firepadHookParameters }); //TODO Refactor useFirepad hook
  SetFileState({ canEditStudentFile, fileData, loadedAce, setReadOnly });
  useLeaderMode({ currentFile, loadFileAfterReset, reset, setFileData, updateFileView }); // TODO refactor useLeaderMode hook
};
