import { useEffect } from 'react';
import { ISetStudentsDefaultFields } from './interfaces';

export const SetStudentsDefaultFields = (data: ISetStudentsDefaultFields) => {
  const { isStudent, user, getFieldState, setValue, rooms } = data;

  useEffect(() => {
    const { isDirty } = getFieldState('role');
    if (!isDirty) {
      setValue('rooms', rooms.join(',\n'));
    }
    if (isStudent && !isDirty) {
      const { age = 0, gender = '0', phoneNumber = '', parentEmail = '' } = user;
      setValue('gender', gender);
      setValue('age', age);
      setValue('phoneNumber', phoneNumber);
      setValue('parentEmail', parentEmail);
      return;
    }
    if (!isStudent) {
      setValue('gender', undefined);
      setValue('age', undefined);
      setValue('phoneNumber', undefined);
      setValue('parentEmail', undefined);
    }
  }, [isStudent, user, setValue, getFieldState, rooms]);
};
