import { memo, useCallback, useState } from 'react';
import { MessageLog } from 'types/ChatGPT';
import { useErrorContext } from 'providers/error/error.provider';
import { ChatLogType } from 'types/enums';
import { sendMessageToChatGPT } from 'store/functions';
import InputText from './components/InputText';
import ChatText from './components/ChatText';
import styles from './chatGPTCompiler.module.css';

function ChatGPTCompiler() {
  const { bug } = useErrorContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [abort, setAbort] = useState<boolean>(false);
  const [messageLogs, setMessageLogs] = useState<MessageLog[]>([]);

  const addMessage = useCallback((message: MessageLog) => {
    setMessageLogs(logs => [...logs, message]);
  }, []);

  const sendMessage = useCallback(
    async (userMessage: string) => {
      try {
        addMessage({ type: ChatLogType.User, message: userMessage, date: new Date() });
        setLoading(true);
        const responseGPT = await sendMessageToChatGPT(userMessage);
        if (abort) {
          setAbort(false);
          return;
        }
        setLoading(false);
        addMessage({ type: ChatLogType.GPT, message: responseGPT, date: new Date() });
      } catch (e) {
        bug(e);
        setLoading(false);
      }
    },
    [addMessage, bug, abort],
  );

  return (
    <div className={styles.chatGPT_container}>
      <ChatText logs={messageLogs} loading={loading} abort={setAbort} />
      <InputText sendNewMessage={sendMessage} loading={loading} />
    </div>
  );
}

export default memo(ChatGPTCompiler);
