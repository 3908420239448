import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';

export const StyledLoadingButton = styled(LoadingButton)({
  borderRadius: 1,
  backgroundColor: '#6A30FA',
  marginTop: '10px',
  fontSize: '18px',
  fontWeight: 700,
  height: '42px',
  color: 'white',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
});
