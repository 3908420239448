import { useMemo } from 'react';
import { IFirepadParameters } from 'types/EditorTypes';
import { IGetFirepadParameters } from './interfaces';

export const GetFirepadParameters = (data: IGetFirepadParameters): IFirepadParameters => {
  const { fileData, ideMethods, isCodeMode, isLanguageBlocks, question, setLoadedAce } = data;
  const { getAceInstance, getFirepadInstance, updateFirepad } = ideMethods;

  return useMemo(
    () => ({
      fileData,
      isCodeMode,
      isLanguageBlocks,
      question,
      getAceInstance,
      getFirepadInstance,
      setLoadedAce,
      updateFirepad,
    }),
    [
      fileData,
      isCodeMode,
      isLanguageBlocks,
      question,
      getAceInstance,
      getFirepadInstance,
      setLoadedAce,
      updateFirepad,
    ],
  );
};
