import { useCallback } from 'react';
import { IconButton } from '@mui/material';
import styles from './closeButton.module.css';
import CloseIcon from '@mui/icons-material/Close';

interface CloseButtonProps {
  onClose?: () => void;
}

export default function CloseButton(props: CloseButtonProps) {
  const { onClose } = props;

  const handleClick = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  return (
    <div className={styles['close-button__content']}>
      <IconButton onClick={handleClick}>
        <CloseIcon />
      </IconButton>
    </div>
  );
}
