import { Dispatch, useCallback, useState } from 'react';
import { clsx } from 'clsx';
import { BadgeType } from 'types/enums';
import { useAppContext } from 'providers/app/app.provider';
import { UserType } from 'types/UserTypes';
import { modalStatus } from '../../../constants';
import { Trans } from '@lingui/macro';
import styles from './badgeSection.module.css';
import ImageSection from '../../../ImageSection/ImageSection';

interface BadgeSectionProps {
  badge: BadgeType;
  badgesCounter?: number;
  status: number;
  styles: {
    selected: boolean;
    noBadgesGranted: boolean;
    active: boolean;
  };
  setSelectedBadge: Dispatch<BadgeType | ''>;
}

export default function BadgeSection(props: BadgeSectionProps) {
  const { badge, badgesCounter, status, setSelectedBadge } = props;
  const { active, noBadgesGranted, selected } = props.styles;
  const { currentUser } = useAppContext();
  const [hovered, setHovered] = useState<boolean>(false);
  const isTutor = currentUser?.role === UserType.Tutor;
  const isBadgeTransparent =
    isTutor &&
    !selected &&
    !active &&
    (status === modalStatus.GRANTED || (!noBadgesGranted && !hovered));
  const isGrantTextShown = hovered && status !== modalStatus.GRANTED;

  function onMouseEnter() {
    setHovered(true);
  }
  function onMouseLeave() {
    setHovered(false);
  }

  const handleGrant = useCallback(() => {
    setSelectedBadge(badge);
    setHovered(false);
  }, [badge, setSelectedBadge]);

  const badgeClassName = clsx(
    styles['badge-section__content'],
    isBadgeTransparent && styles['badge-section__content--transparent'],
    selected && styles['badge-section__content--hidden'],
  );

  const grantClassName = clsx(
    styles['badge-section__grant'],
    isGrantTextShown && styles['badge-section__grant--shown'],
  );

  return (
    <div className={badgeClassName} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <ImageSection active={active} badge={badge} hovered={hovered} status={status} />
      <p className={styles['badge-section__title']}>
        <Trans id={`badgeModal.badge.title.${badge}`} />
      </p>
      {status !== modalStatus.GRANTED && !active && (
        <p className={grantClassName} onClick={handleGrant}>
          <Trans id="badgeModal.grant" />
        </p>
      )}
    </div>
  );
}
