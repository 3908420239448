import { UserType } from 'types/UserTypes';

export function isStudent(role?: UserType | null) {
  return role === UserType.Student;
}

export function isTutor(role?: UserType | null) {
  return role === UserType.Tutor;
}

export function isAdmin(role?: UserType | null) {
  return role === UserType.Admin;
}
