import { useRef } from 'react';
import { white } from 'constants/Colors';
import { enableOpacity } from 'constants/Opacities';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { File } from 'types/FileTypes';
import { LanguageType } from 'types/generalTypes';
import CustomModal from 'components/organisms/CustomModal';
import useClickAway from 'hooks/useClickAway';
import styles from './taskDescriptionModal.module.css';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import QuestionIcon from '../../components/QuestionIcon';

const muiStyles = {
  icon: { color: white, opacity: enableOpacity },
  close: { color: white },
};

// ------------------------------------------

export interface TaskDescriptionModalProps {
  data?: File | null;
  show: boolean;
  onClose: () => void;
  onOpen?: () => void;
}

export default function TaskDescriptionModal({ data, show, onClose }: TaskDescriptionModalProps) {
  const { locale: currentLocale } = useLocaleContext();
  const locale = currentLocale.toUpperCase() as keyof LanguageType;

  const ref = useRef(null);

  useClickAway(ref, onClose);

  return (
    <CustomModal ref={ref} show={show} aria-labelledby="responsive-dialog-title">
      <>
        <h2 className={styles['task-description-modal__title']} id="responsive-dialog-title">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Stack direction="row" alignItems="center" gap={2}>
                {data?.title[locale]}
                <QuestionIcon
                  type={data?.type}
                  optional={data?.isOptionalTask}
                  isSolved={data?.isSolved}
                />
              </Stack>
            </Box>
            <Box>
              <IconButton data-testid="task-desc-close-icon" onClick={onClose}>
                <CloseIcon style={muiStyles.close} fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </h2>
        {data?.description && (
          <div className={styles['task-description-modal__text-block']}>
            <p dangerouslySetInnerHTML={{ __html: data.description[locale] }} />
          </div>
        )}
      </>
    </CustomModal>
  );
}
