import { z } from 'zod';
import { SessionMember } from 'types/MemberTypes';
import { sessionMemberSchema } from 'schemas/member.shema';
import { get, ref, update } from 'firebase/database';
import { getAppDatabase, AppName } from '../../helpers/database';
// firebase custom functions

/**
 *
 * @param roomId current room id
 * @returns session members data (not room members - it's current session)
 */
export const getSessionMembers = async (roomId: string): Promise<Record<string, SessionMember>> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const membersRef = ref(database, `sessions_data/${roomId}`);
    const snapshot = await get(membersRef);
    const sessionMemberRecordSchema = z.record(sessionMemberSchema);
    return sessionMemberRecordSchema.parse(snapshot.val());
  } catch (e) {
    throw e;
  }
};

export const updateMemberData = async (roomId: string, uid: string, questionId: string) => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const membersRef = ref(database, `sessions_data/${roomId}/${uid}`);
    await update(membersRef, {
      uid,
      idActiveQuestion: questionId,
      isActive: true,
    });
  } catch (e) {
    throw e;
  }
};

export const deactivateMember = async (roomId: string, uid: string) => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const membersRef = ref(database, `sessions_data/${roomId}/${uid}`);
    await update(membersRef, {
      isActive: false,
    });
  } catch (e) {
    throw e;
  }
};
