export enum BadgeType {
  Creative = 'creative',
  Debugger = 'debugger',
  Lightning = 'lightning',
  Perfect = 'perfect',
}

export enum ArrowDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum FileType {
  Sample = 'sample',
  Task = 'task',
  Sandpit = 'sandpit',
  Free = 'free',
}

export enum FileAccess {
  Private,
  Public,
}

export enum ProgrammingLanguage {
  Python = 'python',
  Blocks = 'blocks',
  HtmlJsCss = 'htmlJsCss',
  ChatGPT = 'chatGPT',
}

export enum SkillsRating {
  UNSELECTED,
  BAD,
  AVERAGE,
  GOOD,
}

export const AdminLanguage = {
  Python: 0,
  Blocks: 1,
  HtmlJsCss: 2,
  ChatGPT: 3,
} as const;

export enum LeaderModeType {
  None,
  TutorLeaderMode,
  StudentLeaderMode,
}

export enum DirectionType {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

export enum UserType {
  Student = '0',
  Tutor = '1',
  Admin = '2',
}

export const Seniority = {
  Primary: '0',
  Advanced: '1',
} as const;

// Enums objects
export const Gender = {
  0: 'Male',
  1: 'Female',
  2: 'Other',
} as const;

export const UserExemplary = {
  0: 'Tests & presentations',
  1: 'Real',
} as const;

export enum LicenseType {
  Full = 'Full',
  Lite = 'Lite',
}

export enum CourseType {
  Semestr15 = 'SemestrCourse15h',
  Short10 = 'ShortCourse10h',
  Mini5 = 'MiniCourse5h',
}

export enum RoomType {
  TestAndPresentation = 'Test&Presentation',
  Real = 'Real',
  Individual = 'Individual',
  Demo = 'Demo',
}

export enum JsPackage {
  Babylon = 'babylonjs',
  p5 = 'p5js',
}

export enum ChatLogType {
  User,
  GPT,
}

export enum Country {
  Poland = 'PL',
  UnitedKingdom = 'GB',
}
