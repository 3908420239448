import { z } from 'zod';
import { realtime__createDateSchema } from './firebase.schema';
import { languageSchema } from './general.schema';

export const skillsTypeSchema = z.object({
  id: z.string(),
  title: languageSchema,
});

export const lessonSkillsSchema = z.object({
  courseId: z.string(),
  date: realtime__createDateSchema,
  lessonId: z.string(),
  points: z.number(),
  roomId: z.string(),
  skill: skillsTypeSchema,
});
