import { Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { enableOpacity } from 'constants/Opacities';
import { white } from 'constants/Colors';

const iconSize = { width: 36, height: 36 };

export const NavigationIcons = styled(Tabs)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flexStart',
  minHeight: '0px',
});

const iconProps = {
  minWidth: '0px',
  minHeight: '0px',
  width: `${iconSize.width}px`,
  height: `${iconSize.height}px`,
};

export const NavIcon = styled(Tab)({
  ...iconProps,
  color: white,
  opacity: enableOpacity,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.08)',
  },
});
