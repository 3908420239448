import { useEffect } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { QuestionStack } from 'types/ContentTypes';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { wherebyVisibility } from './helpers/ComponentVisibility';
import WhereBy from '../WhereBy';
import Content from './Content';
import styles from './IDE.module.css';

export interface IDEProps {
  setNavigationStack: (s: QuestionStack) => void;
  leaderModeSwitchValue: boolean;
}

export default function IDE(props: IDEProps) {
  const { leaderModeSwitchValue, setNavigationStack } = props;
  const { currentUser } = useAppContext();
  const { room } = useWorkspaceContext();
  const isWherebyVisible = wherebyVisibility(room?.roomType, room?.meetLink);

  useEffect(() => {
    if (room?.activeChatWidget) {
      if (window.runChat) {
        window.runChat();
      }
    }
    return () => {
      if (window.removeChat) {
        window.removeChat();
      }
    };
  }, [room?.activeChatWidget]);

  return (
    <div className={styles.ide__content}>
      <div className={styles['ide__editor-wrapper']}>
        <ReflexContainer orientation="vertical">
          <ReflexElement flex={isWherebyVisible ? 0.95 : 1} minSize={300}>
            <Content
              leaderModeSwitchValue={leaderModeSwitchValue}
              setNavigationStack={setNavigationStack}
            />
          </ReflexElement>
          <ReflexSplitter className={styles['ide__reflex-splitter']} />
          {isWherebyVisible && (
            <ReflexElement
              className={styles['ide__reflex-whereby-wrapper']}
              flex={0.05}
              minSize={210}
              maxSize={550}
            >
              <WhereBy
                url={room?.meetLink as string}
                userFullName={`${currentUser?.name} ${currentUser?.lastName}`}
              />
            </ReflexElement>
          )}
        </ReflexContainer>
      </div>
    </div>
  );
}
