import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

// 600+ -> general codes(required, min, max,  etc.)
// 700+ -> custom validation codes (email, password, etc.)
//  800+ -> firebase cloud function codes (wrong password, custom message etc.)

export const EValidationCodes = {
  NOT_FOUND: '404',
  REQUIRED: '600',
  EMAIL_REQUIRED: '700',
  PASSWORD_REQUIRED: '701',
  WRONG_EMAIL: '702',
  WRONG_PASSWORD: '703',
  SELF_CREATION: '800',
  FIRST_TIME_LOGIN: '801',
  USER_DOES_NOT_EXIST: '802',
  TOO_MANY_REQUESTS: '804',
};

/**
 *
 * @param code string which can be already translated message or status code from EValidationCodes
 * @returns true, if it's custom validation code, false if other string
 */
export function isCustomValidationCode(code?: string | null) {
  if (!code) return false;
  return Object.values(EValidationCodes).includes(code);
}

/**
 *
 * @param i18n instance (lingui), to have translated messages
 * @param code representation of error message (we also have custom codes, see "EValidationCodes" interface)
 * @returns
 */
export function codeToMessage(i18n: I18n, code?: string): string {
  switch (code) {
    case EValidationCodes.NOT_FOUND:
      return t(i18n)`Not found!`;
    case EValidationCodes.REQUIRED:
      return t(i18n)`Required`;
    case EValidationCodes.EMAIL_REQUIRED:
      return t(i18n)`Email required`;
    case EValidationCodes.PASSWORD_REQUIRED:
      return t(i18n)`Password required`;
    case EValidationCodes.WRONG_EMAIL:
      return t(i18n)`Wrong email address`;
    case EValidationCodes.WRONG_PASSWORD:
      return t(i18n)`Wrong password`;
    case EValidationCodes.SELF_CREATION:
      return t(i18n)`Creation of accounts is disabled. Contact pixblocks at kontakt@pixblocks.com`;
    case EValidationCodes.FIRST_TIME_LOGIN:
      return t(i18n)`Log with your email and password for the first time!`;
    case EValidationCodes.USER_DOES_NOT_EXIST:
      return t(i18n)`User with this e-mail address does not exist`;
    case EValidationCodes.TOO_MANY_REQUESTS:
      return t(i18n)`Too many requests. Try again to login later`;
    default:
      return t(i18n)`Something went wrong!`;
  }
}
