import { useCallback } from 'react';
import { blocksToCode } from 'helpers/BlocksCodeTransformer';
import { ProgrammingLanguage } from 'types/enums';
import { IGetCode } from './interfaces';

export const GetCode = (data: IGetCode) => {
  const { aceRef, question } = data;
  return useCallback(() => {
    if (!aceRef.current) return '';
    const code: string = aceRef.current.getValue();
    switch (question?.language) {
      case ProgrammingLanguage.Blocks:
        return blocksToCode(code);
      case ProgrammingLanguage.HtmlJsCss:
      case ProgrammingLanguage.Python:
      default:
        return code;
    }
  }, [aceRef, question]);
};
