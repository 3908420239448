import React, { useState, useEffect } from 'react';

interface TextDisplayProps {
  text: string;
  delay: number;
}

function TextDisplay({ text, delay }: TextDisplayProps) {
  const [displayedText, setDisplayedText] = useState<string>('');

  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayedText(prevText => prevText + text[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, delay);

    return () => clearInterval(interval);
  }, [text, delay]);

  return <div>{displayedText}</div>;
}

export default TextDisplay;
