import { clsx } from 'clsx';
import { GetIsFullScreen } from '../../hooks/callbacks';
import { ChangeLessonModalProps } from './interface';
import CustomModal from 'components/organisms/CustomModal';
import ModalContent from './ModalContent';
import styles from './changeLessonModal.module.css';

export default function ChangeLessonModal(props: ChangeLessonModalProps) {
  const { canChangeLesson, courses, isCoursesModalOpened, isTutorActive, closeModal } = props;
  const getIsFullScreen = GetIsFullScreen();
  const modalClassName = clsx(
    styles['change-lesson-modal'],
    getIsFullScreen() && styles['change-lesson-modal--full'],
  );

  return (
    <CustomModal
      aria-labelledby="responsive-dialog-title"
      backdrop
      closeButton
      data-testtid="temp"
      modalClassName={modalClassName}
      show={isCoursesModalOpened}
      onClose={closeModal}
    >
      <ModalContent
        canChangeLesson={canChangeLesson}
        courses={courses}
        isTutorActive={isTutorActive}
        handleClose={closeModal}
      />
    </CustomModal>
  );
}
