import { TableRow } from './components';
import { TableBodyProps } from './interface';
import { TableBody as Body } from '@mui/material';

export default function TableBody(props: TableBodyProps) {
  const { selectedRooms, rooms, setSelectedRooms } = props;

  return (
    <Body>
      {rooms.map(room => (
        <TableRow
          key={room.id}
          room={room}
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
        />
      ))}
    </Body>
  );
}
