import { getFunctions, httpsCallable } from 'firebase/functions';
import { currentUserSchema } from 'schemas/user.schema';
import { CurrentUser, UserType } from 'types/UserTypes';
import { loginUser } from 'store/FirebaseStore';
import { LoginSchemaData } from 'schemas/login.schema';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { getFirebaseError } from 'providers/error/firebase.error';
// import { useAppContext } from 'providers/app/app.provider';

import {
  FUNCTIONS_NOT_FOUND,
  AUTH_INTERNAL_ERROR,
  AUTH_TOO_MANY_REQUESTS,
  AUTH_USER_NOT_FOUND,
  AUTH_WRONG_PASSWORD,
  FIREBASE_NOT_FOUND,
} from 'providers/error/const';

export interface SubmitArgs {
  data: LoginSchemaData;
  setUser: (u: CurrentUser) => void;
  setAuthError: (n: keyof LoginSchemaData, m: string) => void;
  navigate: NavigateFunction;
  tutorView?: boolean;
  i18n: I18n;
}

export async function SubmitUserForm(args: SubmitArgs) {
  // const { loginWithPassword } = useAppContext();
  const { data, setUser, setAuthError, navigate, i18n, tutorView = true } = args;
  try {
    const { email, password } = data;

    const functions = getFunctions();
    const isNewUser = httpsCallable(functions, 'isNewUser');
    const { data: needToChangePassword } = await isNewUser({ email });

    // when tutor logging if for the first time
    const { user } = await loginUser(email, password);

    const currentUser = currentUserSchema.parse({
      ...user,
      color: '',
      isLogged: true,
    });
    setUser(currentUser);
    //  || (user.role === UserType.Student && data.password === 'pixblocks1')
    if ((user.role === UserType.Tutor || user.role === UserType.Admin) && needToChangePassword) {
      navigate('/auth/changePassword');
    } else {
      navigate('/rooms');
    }
  } catch (error) {
    const [code, msg] = getFirebaseError(i18n, error);

    switch (code) {
      case AUTH_WRONG_PASSWORD:
        if (!tutorView) toast.info(t(i18n)`Login as tutor!`);
        else setAuthError('password', msg);
        break;

      case AUTH_USER_NOT_FOUND:
      case FUNCTIONS_NOT_FOUND:
      case FIREBASE_NOT_FOUND:
        setAuthError('email', msg);
        break;

      case AUTH_TOO_MANY_REQUESTS:
      case AUTH_INTERNAL_ERROR:
        toast.info(msg);
        break;
      default:
        throw error;
    }
  }
}
