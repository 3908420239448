import {
  collection,
  addDoc,
  doc,
  Timestamp,
  getDocs,
  query,
  where,
  CollectionReference,
  DocumentData,
  writeBatch,
  WriteBatch,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { presenceDataSchema } from 'schemas/form.schema';
import { FeedbackForm, PresenceData, PresenceFormData } from 'types/FormsTypes';
import { db } from '../../helpers/firestore';

/**
 *
 * @param form data that is saved inside forms collection
 */

export const sendForm = async (form: FeedbackForm): Promise<void> => {
  try {
    await addDoc(collection(db, 'forms'), form);
  } catch (e) {
    throw e;
  }
};

const getRepetitivePresence = async (
  ref: CollectionReference<DocumentData>,
  presence: PresenceData,
): Promise<QueryDocumentSnapshot<DocumentData> | null> => {
  try {
    const _query = query(
      ref,
      where('roomId', '==', presence.roomId),
      where('userUid', '==', presence.userUid),
    );
    const snapshot = await getDocs(_query);
    const currentPresence = snapshot.docs.find(document => {
      try {
        const currentDate = presenceDataSchema.parse(document.data()).date?.toDate();
        return (
          currentDate?.getUTCDate() === presence.date?.toDate().getUTCDate() &&
          currentDate?.getUTCMonth() === presence.date?.toDate().getUTCMonth() &&
          currentDate?.getUTCFullYear() === presence.date?.toDate().getUTCFullYear()
        );
      } catch {
        return false;
      }
    });
    return currentPresence?.exists() ? currentPresence : null;
  } catch (e) {
    throw e;
  }
};

const prepareMemberBatch =
  (batch: WriteBatch, ref: CollectionReference<DocumentData>, date: Timestamp) =>
  async (batchData: Promise<void>, presence: PresenceData) => {
    try {
      await batchData;
      presence.date = date;
      const repetitivePresence = await getRepetitivePresence(ref, presence);
      if (!repetitivePresence) {
        batch.set(doc(ref), presence);
        return;
      }
      if (presenceDataSchema.parse(repetitivePresence.data()).present !== presence.present) {
        batch.update(doc(ref, repetitivePresence.id), { present: presence.present });
        return;
      }
      return;
    } catch (e) {
      throw e;
    }
  };

const prepareBatches = async (data: PresenceFormData, batch: WriteBatch): Promise<void> => {
  try {
    const collectionRef = collection(db, 'presence');
    const date = Timestamp.now();
    await data.reduce(prepareMemberBatch(batch, collectionRef, date), Promise.resolve());
    return;
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param data {[key: student uid]: data {credentials, email, present, parentEmail}};
 *  see PresenceFormData interface
 */
export const submitStudentsPresence = async (data: PresenceFormData) => {
  try {
    const batch = writeBatch(db);
    await prepareBatches(data, batch);
    await batch.commit();
  } catch (e) {
    throw e;
  }
};
