import { useState, useCallback } from 'react';
import { AddRoomForm, RoomHeader, RoomTable } from './components';
import { Container } from '@mui/material';
import Page from 'components/atoms/Page';

export default function RoomComponent() {
  const [addRoomModalShow, setAddRoomModalShow] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setAddRoomModalShow(false);
  }, []);

  return (
    <Page title="Rooms">
      <Container>
        <RoomHeader setAddRoomModalShow={setAddRoomModalShow} />
        <RoomTable />
        <AddRoomForm open={addRoomModalShow} handleClose={closeModal} />
      </Container>
    </Page>
  );
}
