import React from 'react';
import GoogleBtnIcon from './GoogleBtnIcon';
import { clsx } from 'clsx';
import styles from './googleButton.module.css';

interface GoogleButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

export default function GoogleButton({ onClick, className, children }: GoogleButtonProps) {
  const googleButtonClassNames = clsx(styles['google-button__content'], className);

  return (
    <div className={googleButtonClassNames} onClick={onClick}>
      <GoogleBtnIcon />
      <p className={styles['google-button__paragraph']}>{children}</p>
    </div>
  );
}
