export async function areMediaDevicesAvailable() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    let permissions = true;

    // we can return a kind of device, but we don't need it
    devices.forEach(({ kind, deviceId, label }) => {
      if (
        (kind === 'audioinput' || kind === 'audiooutput' || kind === 'videoinput') &&
        (Boolean(!deviceId) || Boolean(!label))
      ) {
        permissions = false;
      }
    });

    return permissions;
  } catch (e) {
    throw e;
  }
}
