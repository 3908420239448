import { useCallback } from 'react';
import { getType } from 'helpers/FilesHelper';
import { getFileRef } from 'store/FirebaseStore';
import { FileData } from 'types/FileTypes';
import { IUpdateFirepad } from './interfaces';
import { createFirepadInstance } from '../../../../helpers/aceAndFirepad';

export const UpdateFirepad = (data: IUpdateFirepad) => {
  const { aceRef, firepadRef } = data;

  return useCallback(
    (fileData: FileData, userId: string, roomId: string, color: string) => {
      const type = getType(fileData.type);
      const _ref = getFileRef(roomId, fileData.id, type, fileData?.userId);
      aceRef.current?.setReadOnly(true);
      aceRef.current?.setValue('');
      const editor = aceRef.current?.getAceEditor();
      firepadRef.current = createFirepadInstance(_ref, editor, {
        // HERE
        userId,
        userColor: color.concat('99'),
      });
    },
    [aceRef, firepadRef],
  );
};
