import { BadgeType } from 'types/enums';
import { z } from 'zod';
import { realtime__createDateSchema } from './firebase.schema';

export const badgeSchema = z.object({
  badgeType: z.nativeEnum(BadgeType),
  createDate: realtime__createDateSchema,
  lessonId: z.string(),
  teacherId: z.string(),
});

export const badgesSchema = z.record(badgeSchema).nullable();
