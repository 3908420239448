import { useCallback } from 'react';
import { blocksToCode } from 'helpers/BlocksCodeTransformer';
import { ITransformBlocksToCode } from './interfaces';

export const TransformBlocksToCode = (data: ITransformBlocksToCode) => {
  const { aceRef } = data;
  return useCallback(() => {
    if (!aceRef.current) return;
    const value = blocksToCode(aceRef.current.getValue());
    aceRef.current.setValue(value);
  }, [aceRef]);
};
