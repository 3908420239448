import { TechServiceModalProps } from './interface';
import CustomButton from 'components/atoms/CustomButton';
import { Trans } from '@lingui/macro';
import styles from './techServiceModal.module.css';

export default function TechSupportModal(props: TechServiceModalProps) {
  const { handleClose } = props;

  return (
    <div className={styles['tech-service-modal__content']}>
      <h1 className={styles['tech-service-modal__header']}>
        <Trans id="login.support.header" />
      </h1>
      <p className={styles['tech-service-modal__subheader']}>
        <Trans id="login.support.subheader.1" />
        <br />
        <Trans id="login.support.subheader.2" />
      </p>
      <p className={styles['tech-service-modal__content-paragraph']}>
        <Trans id="login.support.paragraph.1.left" />
        <span>
          <Trans id="login.support.paragraph.1.right" />
        </span>
      </p>
      <p className={styles['tech-service-modal__content-paragraph']}>
        <Trans id="login.support.paragraph.2.left" />
        <span>
          <Trans id="login.support.paragraph.2.right" />
        </span>
      </p>
      <p className={styles['tech-service-modal__content-paragraph']}>
        <Trans id="login.support.paragraph.3.left" />
        <span>
          <Trans id="login.support.paragraph.3.right" />
        </span>
      </p>
      <CustomButton className={styles['tech-service-modal__close-button']} onClick={handleClose}>
        <Trans id="login.support.button" />
      </CustomButton>
    </div>
  );
}
