import { iconsList } from './icons';
import styles from './imageSection.module.css';

export default function ImageSection() {
  return (
    <div className={styles['image-section__content']}>
      {iconsList.map(icon => (
        <div className={styles['image-section__icon']} key={icon.key}>
          <svg viewBox="0 0 24 24">
            {/*TODO make svg icons responsive */}
            <path className={styles['image-section__path']} d={icon.path} />
          </svg>
          <span>{icon.key}</span>
        </div>
      ))}
    </div>
  );
}
