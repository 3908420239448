import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocaleContext } from './providers/i18n/i18n.provider';
import { ToastContainer } from 'react-toastify';
import { containerOptions } from 'constants/ToastOptions';
import Router from 'layouts/routes';
import 'react-toastify/dist/ReactToastify.css';

export default function AppRoutes() {
  const { locale } = useLocaleContext();

  const helmetAttrs = useMemo(() => ({ lang: locale }), [locale]);

  return (
    <>
      <Helmet htmlAttributes={helmetAttrs} />
      <Router />
      <ToastContainer
        autoClose={containerOptions.autoClose}
        theme="dark"
        limit={containerOptions.limit}
        hideProgressBar={containerOptions.hideProgressBar}
      />
    </>
  );
}
