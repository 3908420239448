import { db } from '../../helpers/firestore';
import { collection, addDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { RoomLog } from 'types/RoomTypes';

/**
 *
 * @param log contains userId and roomId for storing user login date
 * @returns id of the new document
 */
export const addRoomLog = async (log: RoomLog): Promise<string> => {
  try {
    const respone = await addDoc(collection(db, 'roomsLogs'), {
      loginDate: serverTimestamp(),
      ...log,
    });
    return respone.id;
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param logId id of the log to update
 */
export const updateRoomLog = async (logId: string) => {
  try {
    const logRef = doc(db, 'roomsLogs', logId);
    await updateDoc(logRef, {
      logoutDate: serverTimestamp(),
    });
  } catch (e) {
    throw e;
  }
};
