import { useEffect } from 'react';
import { GetActiveCourse, GetActiveLesson } from '../callbacks';
import { ISetLessonOnTutorEnter } from './interfaces';

export const SetLessonOnTutorEnter = (data: ISetLessonOnTutorEnter) => {
  const { isStudentRole, isTutorActive, roomId, closeModal, setLocalCourseId, setLocalLessonId } =
    data;
  const getActiveLesson = GetActiveLesson({ roomId, closeModal, setLocalLessonId });
  const getActiveCourse = GetActiveCourse({ roomId, closeModal, setLocalCourseId });

  useEffect(() => {
    const execute = async () => {
      if (isTutorActive && isStudentRole) {
        try {
          await getActiveLesson();
          await getActiveCourse();
        } catch (e) {
          throw e;
        }
      }
    };
    execute();
  }, [isTutorActive, isStudentRole, getActiveCourse, getActiveLesson]);
};
