import React from 'react';
import { ApiError, FatalError, PermissionError, ValidationError, unknownToError } from './error';
import { ErrorTransfer } from 'types/error.types';
import { toast } from 'react-toastify';
import { logError } from './logService';
import { toastOptions } from 'constants/ToastOptions';
import ErrorPage from '../../pages/ErrorPage/ErrorPage.screen';
import log from 'log';

type ErrorContext = {
  bug: InstanceType<typeof ErrorProvider>['bug'];
  fatal: InstanceType<typeof ErrorProvider>['fatal'];
  error?: ErrorTransfer | null;
  setError: InstanceType<typeof ErrorProvider>['setError'];
};

export const errorContext = React.createContext<ErrorContext>(undefined!);

type Props = {
  error: ErrorTransfer | null;
  children: React.ReactNode;
};

type State = {
  context: ErrorContext;
};

export class ErrorProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      context: {
        bug: this.bug,
        fatal: this.fatal,
        error: this.props.error,
        setError: this.setError,
      },
    };
  }

  // eslint-disable-next-line
  bug = (rawError: unknown): void => {
    const error = unknownToError(rawError);
    // eslint-disable-next-line no-console
    console.error(error);
    toast.warn(error.message, toastOptions);
  };

  // --------------------------------------------------------------------------------
  fatal = (rawError: unknown): void => {
    const error = unknownToError(rawError);
    log.error('Fatal', error);
    this.setError(error);
    // log to firebase (can switch to another log management system)
    logError(error);
  };

  setError = (
    error: Error | PermissionError | ApiError | ValidationError | FatalError | null,
  ): void => {
    this.setState({
      context: {
        ...this.state?.context,
        error,
      },
    });
  };

  static getDerivedStateFromError(error: Error, prevState: State): State {
    return {
      ...prevState,
      context: {
        ...prevState?.context,
        error,
      },
    };
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    this.fatal(error);
  }

  render(): JSX.Element {
    const isCriticalError =
      this.state?.context.error &&
      !(
        this.state?.context.error instanceof PermissionError &&
        this.state?.context.error.payload.code === 401
      );

    return (
      <errorContext.Provider value={this.state?.context}>
        <div
          style={{
            minWidth: '100%',
          }}
        >
          {isCriticalError ? <ErrorPage /> : this.props.children}
        </div>
      </errorContext.Provider>
    );
  }
}

export function useErrorContext() {
  const context = React.useContext(errorContext);
  if (context === undefined) {
    throw new Error(`useErrorContext() must be used within ErrorProvider`);
  }
  return context;
}
