import { z } from 'zod';

export const shareDataSchema = z.object({
  id: z.string(),
  roomId: z.string(),
  userId: z.string(),
  questionId: z.string(),
  language: z.string(),
});

export const sharedCompilerData = shareDataSchema.extend({
  code: z.string(),
});
