import { useCallback } from 'react';
import { lessonTreeSchema } from 'schemas/content.schema';
import { updateActivedLesson, updateActiveLessonsHistory } from 'store/FirebaseStore';
import { ILessonTree } from 'types/ContentTypes';
import { z } from 'zod';
import { IHandleTutorSubmit } from './interfaces';

export const HandleTutorSubmit = (data: IHandleTutorSubmit) => {
  const { roomId, handleClose } = data;

  return useCallback(
    async (selectedItem: ILessonTree) => {
      try {
        const lesson = lessonTreeSchema.parse(selectedItem);
        const _roomId = z.string().parse(roomId);
        const courseId = z.string().parse(lesson.course);
        const lessonId = z.string().parse(lesson.guid);

        await updateActivedLesson(_roomId, lesson);
        await updateActiveLessonsHistory(_roomId, courseId, lessonId);
        handleClose();
      } catch (e) {
        throw e;
      }
    },
    [roomId, handleClose],
  );
};
