import { memo, MouseEvent, useLayoutEffect, useState, useCallback, useRef } from 'react';
import { clsx } from 'clsx';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { areMediaDevicesAvailable } from './helpers';
import { Trans } from '@lingui/macro';
import { useAppContext } from 'providers/app/app.provider';
import { UserType } from 'types/UserTypes';
import styles from './whereBy.module.css';

export interface WhereByProps {
  url: string;
  userFullName: string;
}

function WhereBy({ url, userFullName }: WhereByProps) {
  const { bug } = useErrorContext();
  const { locale } = useLocaleContext();
  const { currentUser } = useAppContext();
  const [permissions, setPermissions] = useState(true);
  const [collapseWherebyIframe, setCollapseWherebyIframe] = useState(permissions);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const iframe = useRef<HTMLIFrameElement>(null);
  const wherebyContentClassNames = clsx(
    !permissions && !collapseWherebyIframe && styles['whereby__content--portal-style'],
    (permissions || collapseWherebyIframe) && styles['whereby__content--iframe-style'],
  );
  const wherebyIframeWrapperClassNames = clsx(
    styles['whereby__iframe-wrapper'],
    !isIframeLoading && styles['whereby__iframe-wrapper--height-reduced'],
    collapseWherebyIframe && styles['whereby__iframe-wrapper--height-full'],
  );
  const wherebyButtonWrapper = clsx(
    styles['whereby__button-wrapper'],
    !isIframeLoading && !collapseWherebyIframe && styles['whereby__button-wrapper--shown'],
  );

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    setCollapseWherebyIframe(true);
  }

  const handleIframeLoad = useCallback(() => {
    setIsIframeLoading(false);
  }, []);

  useLayoutEffect(() => {
    async function execute() {
      try {
        const areAvailable = await areMediaDevicesAvailable();
        setPermissions(areAvailable);
        setCollapseWherebyIframe(areAvailable);
      } catch (e) {
        bug(e);
      }
    }

    execute();
  }, [bug]);

  return (
    <div className={wherebyContentClassNames}>
      <div className={wherebyIframeWrapperClassNames}>
        <iframe
          id="iframe-whereby"
          ref={iframe}
          onLoad={handleIframeLoad}
          title={`${url} ${userFullName}`}
          height={'100%'}
          width={'100%'}
          src={`${url}?screenshare=off&people=off&minimal&logo=on&settingsButton=off&pipButton=off&moreButton=off&subgridLabels=off&metadata=mojIDzFirebase&displayName="${
            currentUser?.role === UserType.Admin ? '' : userFullName
          }"&lang=${locale}`}
          allow="camera; microphone; fullscreen; display-capture"
        />
      </div>
      <div className={wherebyButtonWrapper}>
        <button
          data-testid="whereby-confirm-button"
          type="button"
          className={styles['whereby__button-confirm']}
          onClick={handleClick}
        >
          <Trans id="whereby.btn">
            I Confirm that my CAMERA and MIC has BEEN properly ACTIVATED
          </Trans>
        </button>
      </div>
    </div>
  );
}

export default memo(WhereBy);
