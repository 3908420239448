import { useCallback } from 'react';
import { IRemoveHighlightLine } from './interfaces';

export const RemoveHighlightLine = (data: IRemoveHighlightLine) => {
  const { aceRef } = data;

  return useCallback(() => {
    aceRef.current?.removehighlightLine();
  }, [aceRef]);
};
