import { memo, useRef, useCallback, useState, useEffect } from 'react';
import { Question } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';
import { useLocation } from 'react-router-dom';
import { theme } from './constants/Styles';
import { getPackages, getBlobURL } from './helpers/PackageHelper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import FlagIcon from '@mui/icons-material/Flag';
import Stack from '@mui/material/Stack';
import styles from './htmlJsCssCompiler.module.css';

interface HtmlJsCssCompilerProps {
  getCode: Function;
  question: Question;
  pageUserId: string;
}

function HtmlJsCssCompiler(props: HtmlJsCssCompilerProps) {
  const { getCode: getCode, question } = props;
  const [url, setUrl] = useState<string>('');
  const compilerIframe = useRef<HTMLIFrameElement>(null);
  const location = useLocation();

  const getGeneratedPageURL = useCallback(
    (html: string, css: string, js: string) => {
      const source = getPackages(question, html, css, js);
      return getBlobURL(source, 'text/html');
    },
    [question],
  );

  const generate = useCallback(
    (code: string) => {
      setUrl(getGeneratedPageURL(code, '', ''));
    },
    [getGeneratedPageURL],
  );

  const debug = useCallback(() => {
    setUrl(getGeneratedPageURL(getCode(), '', ''));
  }, [getCode, getGeneratedPageURL]);

  const handleSolution = useCallback(() => {
    generate(question?.code);
  }, [generate, question?.code]);

  const handleStop = useCallback(() => {
    setUrl('');
  }, [setUrl]);

  const openInNewTab = useCallback(() => {
    window.open(
      `${location.pathname}/html?code=${getGeneratedPageURL(getCode(), '', '')}`,
      '_blank',
      'noreferrer',
    );
  }, [location.pathname, getCode, getGeneratedPageURL]);

  useEffect(() => {
    setUrl('');
  }, [question]);

  return (
    <div className={styles['html-js-css-compiler__container']}>
      <div className={styles['html-js-css-compiler__buttons']}>
        <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-start">
          <Tooltip title="Debug" placement="bottom">
            <IconButton aria-label="start" onClick={debug}>
              <PlayArrowIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset" placement="bottom">
            <IconButton aria-label="stop" onClick={handleStop}>
              <StopIcon style={theme.stop} />
            </IconButton>
          </Tooltip>
          {question?.type === FileType.Task && (
            <Tooltip title="Show solution" placement="bottom">
              <IconButton aria-label="solution" onClick={handleSolution} style={theme.solution}>
                <FlagIcon style={theme.flag} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Open in new tab" placement="bottom">
            <IconButton aria-label="newTab" onClick={openInNewTab} style={theme.openInNewTab}>
              <OpenInNewIcon style={theme.openInNew} />
            </IconButton>
          </Tooltip>
        </Stack>
      </div>
      <div className={styles['html-js-css-compiler__iframe-container']}>
        <iframe
          ref={compilerIframe}
          id="compilerPage"
          className={styles['html-js-css-compiler__iframe']}
          src={url}
          title="compiler"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
}

export default memo(HtmlJsCssCompiler);
