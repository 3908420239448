import { useCallback } from 'react';
import { white } from 'constants/Colors';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import { QuestionStack } from 'types/ContentTypes';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export interface NavigationStackProps {
  navigationData?: QuestionStack;
}

const style = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}; // TODO : MOVE TO createTheme IN AppContext

export default function NavigationStack(props: NavigationStackProps) {
  const { navigationData } = props;

  const getTypographies = useCallback(
    () => [
      { key: 1, opacity: 0.5, child: navigationData?.course },
      { key: 2, opacity: 0.5, child: navigationData?.lesson },
      { key: 3, opacity: 0.7, child: navigationData?.question },
    ],
    [navigationData],
  );

  return (
    <Stack spacing={1}>
      {navigationData !== undefined && (
        <Breadcrumbs
          separator="&gt;"
          aria-label="breadcrumb"
          color={transformColorFromHexToRGBA(white, 0.5)}
        >
          {getTypographies().map(({ key, opacity, child }) => (
            <Typography key={key} color={transformColorFromHexToRGBA(white, opacity)} sx={style}>
              {child}
            </Typography>
          ))}
        </Breadcrumbs>
      )}
    </Stack>
  );
}
