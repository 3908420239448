import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import { ValidationError } from 'providers/error/error';
import {
  courseSchema,
  currentLessonDataSchema,
  currentLessonRawSchema,
  navigationStackSchema,
  parsedCourseSchema,
  questionSchema,
} from 'schemas/content.schema';
import { lessonSkillsSchema, skillsTypeSchema } from 'schemas/skills.schema';
import { db, getDocumentReference } from 'store/helpers/firestore';
import {
  CurrentCourse,
  CurrentLesson,
  NavigationStackReturnType,
  ParsedCourse,
  Question,
  IFilteredCourse,
  LessonInfoType,
} from 'types/ContentTypes';
import {
  ContentSkillsType,
  ISkillsSelection,
  LessonSkillType,
  SkillsType,
} from 'types/SkillsTypes';
import { getRoom } from '../realtime/room';
import { z } from 'zod';

/**
 *
 * @returns gets content tree for navigation between courses and lessons (see component ContentBrowser)
 */
export const getCourses = async (roomId: string): Promise<ParsedCourse[]> => {
  try {
    const room = await getRoom(roomId);
    const ref = collection(db, 'courses');
    const q = room?.contentVisibility
      ? query(ref, where('language', 'in', room.contentVisibility))
      : ref;
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      throw new ValidationError('getContentData: No data available', {
        code: 424,
        data: {
          message: 'Cannot get data for courses',
        },
      });
    }
    const courses: ParsedCourse[] = querySnapshot.docs.map(course =>
      parsedCourseSchema.parse({
        ...course.data(),
        guid: course.id,
      }),
    );
    return courses;
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param courseId current course id
 * @returns course with it's content (lessons, title etc. - see CurrentCourse interface)
 */
export const getCourse = async (courseId: string): Promise<CurrentCourse | null> => {
  try {
    if (!courseId) throw new Error('No course id!');
    const ref = doc(db, 'courses', courseId);
    const snapshot = await getDoc(ref);
    if (!snapshot.exists()) return null;
    const course = snapshot.data();
    return courseSchema.parse(course);
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param lessonId current lesson id
 * @returns lesson with it's content (questions, title etc. - see CurrentLesson interface)
 */
export const getLesson = async (lessonId: string): Promise<CurrentLesson | null> => {
  try {
    if (!lessonId) throw new Error('No lesson id!');
    const ref = doc(db, 'lessons', lessonId);
    const snapshot = await getDoc(ref);
    if (!snapshot.exists()) return null;
    const lesson = snapshot.data();
    if (!lesson?.questions) return null;
    const questions = lesson.questions.reduce(
      (data: LessonInfoType[], question: LessonInfoType) => {
        if (question) data.push(question);
        return data;
      },
      [],
    );
    const parsedLesson = currentLessonRawSchema.parse({ ...lesson, questions, id: lessonId });
    const skills = parsedLesson.skills
      ? await Promise.all(
          parsedLesson.skills?.map(async skillRef => {
            const skill = await getDocumentReference(skillRef);
            return { ...skill, id: skillRef.id };
          }),
        )
      : [];
    return currentLessonDataSchema.parse({ ...parsedLesson, skills });
  } catch (e) {
    throw e;
  }
};

export const getLessonSkills = async (lessonPath: string): Promise<ContentSkillsType[]> => {
  try {
    if (!lessonPath) throw new Error('No lesson id!');
    const lesson = await getLesson(lessonPath);
    return lesson?.skills || [];
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param room current room id
 * @returns object with selected course and lesson
 */
export const getNavigationStack = async (roomId: string): Promise<NavigationStackReturnType> => {
  try {
    const error = new ValidationError('getnavigationStack: No data available', {
      code: 424,
      data: {
        message: 'Cannot get data for navigation stack',
      },
    });
    const room = await getRoom(roomId);
    if (!room) throw error;
    const course = await getCourse(room.activeCourse);
    const lesson = await getLesson(room.activeLesson);
    if (!course || !lesson) throw error;
    return navigationStackSchema.parse({
      course: course.title,
      lesson: lesson.title,
    });
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param questionGuid selected question id
 * @returns question object data from realtime db
 */
export const getQuestion = async (questionGuid: string): Promise<Question> => {
  try {
    const ref = doc(db, 'questions', questionGuid);
    const snapshot = await getDoc(ref);
    if (snapshot.exists()) {
      return questionSchema.parse(snapshot.data());
    }
    throw new ValidationError('getQuestion: No data available', {
      code: 424,
      data: {
        message: 'Cannot get question data',
      },
    });
  } catch (e) {
    throw e;
  }
};
