import { insertStyles } from '@emotion/utils';
import { string } from 'zod';

const charMap: Map<string, string> = new Map([
  ['=', 'Ό'],
  ['==', 'Ύ'],
  ['steps', 'ΐ'],
  ['sound', '΄'],
  ['right', 'ϊ'],
  ['left', 'ύ'],
  ['up', 'ϋ'],
  ['down', 'ό'],
  ['width', 'Ά'],
  ['height', 'Έ'],
  ['my.x', 'Ή'],
  ['my.y', 'Ί'],
  ['get', 'Ώ'],
  ['for', 'υ'],
  ['if', 'φ'],
  ['return', 'ψ'],
  ['my.img', 'ϗ'],

  ['play', 'χ'],

  ['img[0]', 'Α'],
  ['img[1]', 'Β'],
  ['img[2]', 'Γ'],
  ['img[3]', 'Δ'],
  ['img[4]', 'Ε'],
  ['img[5]', 'Ζ'],
  ['img[6]', 'Η'],
  ['img[7]', 'Θ'],
  ['img[8]', 'Ι'],
  ['img[9]', 'Κ'],
  ['img[10]', 'Λ'],
  ['img[11]', 'Μ'],

  ['img[12]', 'Ν'],
  ['img[13]', 'Ξ'],
  ['img[14]', 'Ο'],
  ['img[15]', 'Π'],
  ['img[16]', 'Ρ'],
  ['img[17]', 'Σ'],
  ['img[18]', 'Τ'],
  ['img[19]', 'Υ'],
  ['img[20]', 'Φ'],
  ['img[21]', 'Χ'],
  ['img[22]', 'Ψ'],
  ['img[23]', 'Ω'],
  ['img[24]', 'Ϊ'],
  ['img[25]', 'Ϋ'],
  ['img[26]', 'ά'],
  ['img[27]', 'έ'],

  ['img[28]', 'ή'],
  ['img[29]', 'ί'],
  ['img[30]', 'ΰ'],
  ['img[31]', 'α'],
  ['img[32]', 'β'],
  ['img[33]', 'γ'],
  ['img[34]', 'δ'],
  ['img[35]', 'ε'],
  ['img[36]', 'ζ'],
  ['img[37]', 'η'],
  ['img[38]', 'θ'],
  ['img[39]', 'κ'],
  ['img[40]', 'ι'],
  ['img[41]', 'λ'],
  ['img[42]', 'μ'],

  ['colors.black', 'ξ'],
  ['colors.white', 'ν'],
  ['colors.red', 'ο'],
  ['colors.green', 'π'],
  ['colors.blue', 'ρ'],
  ['colors.cyan', 'ς'],
  ['colors.magenta', 'σ'],
  ['colors.yellow', 'τ'],
]);

/**
 * helper method
 * @param value some key from "charMap" map
 * @returns value associated with key
 */
export const getChar = (value: string): string => {
  const result = charMap.get(value);
  return result ? result : value;
};

/**
 *
 * @param code string representation of python code
 * @returns transformed python code as "blocks code"
 */
export const codeToBlocks = (code: string): string => {
  let s: string = code;
  s = s.replaceAll('    ', '\t');

  s = s.replaceAll('  ', ' ');
  s = s.replaceAll('  ', ' ');
  s = s.replaceAll('left()', getChar('left'));
  s = s.replaceAll('right()', getChar('right'));
  s = s.replaceAll('up()', getChar('up'));
  s = s.replaceAll('down()', getChar('down'));

  s = s.replaceAll('colors.black', getChar('colors.black'));
  s = s.replaceAll('colors.white', getChar('colors.white'));
  s = s.replaceAll('colors.red', getChar('colors.red'));
  s = s.replaceAll('colors.green', getChar('colors.green'));
  s = s.replaceAll('colors.blue', getChar('colors.blue'));
  s = s.replaceAll('colors.cyan', getChar('colors.cyan'));
  s = s.replaceAll('colors.magenta', getChar('colors.magenta'));
  s = s.replaceAll('colors.yellow', getChar('colors.yellow'));

  s = s.replaceAll('!=', '_NOT_EQUAL_');
  s = s.replaceAll('>=', '_GREATER_EQ_');
  s = s.replaceAll('<=', '_LESS_EQ_');
  s = s.replaceAll('+=', '_PLUS_');
  s = s.replaceAll('-=', '_MINUS_');
  s = s.replaceAll('*=', '_MULTIPLY_');
  s = s.replaceAll('/=', '_DIVIDE_');

  s = s.replaceAll('==', getChar('=='));
  s = s.replaceAll('=', getChar('='));
  s = s.replaceAll('_NOT_EQUAL_', '!=');
  s = s.replaceAll('_GREATER_EQ_', '>=');
  s = s.replaceAll('_LESS_EQ_', '<=');
  s = s.replaceAll('_PLUS_', '+=');
  s = s.replaceAll('_MINUS_', '-=');
  s = s.replaceAll('_MULTIPLY_', '*=');
  s = s.replaceAll('_DIVIDE_', '/=');

  s = s.replaceAll('width', getChar('width'));
  s = s.replaceAll('game.stop()', getChar('return'));
  s = s.replaceAll('height', getChar('height'));
  s = s.replaceAll('my.x', getChar('my.x'));
  s = s.replaceAll('my.y', getChar('my.y'));
  s = s.replaceAll('my.img', getChar('my.img'));
  s = s.replaceAll('def play():', getChar('play'));
  s = s.replaceAll('if ', getChar('if'));

  const splited: string[] = s.split(/\r\n|\r|\n/u);

  let res: string = '';
  splited.forEach((value: string) => {
    if (value.indexOf('for ') >= 0) {
      res = res.concat(value.replace('for ', getChar('for')).replace('):', '').concat('\r\n'));
    } else {
      if (value.indexOf('sound.play(') >= 0) {
        let sound: string = value.replace('sound.play(', getChar('sound'));
        sound = sound.substring(0, sound.length - 1);
        res = res.concat(sound).concat('\r\n');
      } else {
        res = res.concat(value).concat('\r\n');
      }
    }
  });
  s = res.substring(0, res.length - 1);

  s = s.replaceAll('_ in seq(', '');
  s = s.replaceAll(' in seq(', getChar('steps'));
  s = s.replaceAll(':', '');
  const specialGet: string = 'Ą';
  const specialImg: string = 'Ź';
  s = s.replaceAll(').img', specialImg);
  s = s.replaceAll('get(', specialGet);

  const chars: string[] = Array.from(s);

  let ind: number = 0;
  let insideGet = false;
  chars.forEach((value: string) => {
    if (value === specialGet) {
      insideGet = true;
    }
    if (value === specialImg) {
      insideGet = false;
    }
    if (value === ',' && insideGet) {
      chars[ind] = getChar('get');
    }
    ind += 1;
  });

  s = chars.join('');

  s = s.replaceAll(specialImg, '');
  s = s.replaceAll(specialGet, '');
  // s= s.replaceAll(")","");
  s = s.replaceAll('"', '');
  charMap.forEach((value: string, key: string) => {
    if (key.indexOf('mg[') > 0) {
      s = s.replaceAll(key, value);
    }
  });
  s = s.substring(0, s.length - 1);

  return s;
};

/**
 *
 * @param blocksCode string representation of "blocks code"
 * @returns transformed "blocks code" as "python code
 */
export const blocksToCode = (blocksCode: string): string => {
  let s: string = blocksCode;
  s = s.replaceAll('    ', '\t');
  s = s.replaceAll('  ', ' ');
  s = s.replaceAll('  ', ' ');
  s = s.replaceAll(getChar('left'), 'left()');
  s = s.replaceAll(getChar('right'), 'right()');
  s = s.replaceAll(getChar('up'), 'up()');
  s = s.replaceAll(getChar('down'), 'down()');
  s = s.replaceAll(getChar('=='), '==');
  s = s.replaceAll(getChar('='), '=');
  s = s.replaceAll(getChar('width'), 'width');
  s = s.replaceAll(getChar('height'), 'height');
  s = s.replaceAll(getChar('return'), 'game.stop()');
  s = s.replaceAll(getChar('my.x'), 'my.x');
  s = s.replaceAll(getChar('my.y'), 'my.y');
  s = s.replaceAll(getChar('my.img'), 'my.img');
  s = s.replaceAll(getChar('play'), 'def play():');

  s = s.replaceAll(getChar('colors.black'), 'colors.black');
  s = s.replaceAll(getChar('colors.white'), 'colors.white');
  s = s.replaceAll(getChar('colors.red'), 'colors.red');
  s = s.replaceAll(getChar('colors.green'), 'colors.green');
  s = s.replaceAll(getChar('colors.blue'), 'colors.blue');
  s = s.replaceAll(getChar('colors.cyan'), 'colors.cyan');
  s = s.replaceAll(getChar('colors.magenta'), 'colors.magenta');
  s = s.replaceAll(getChar('colors.yellow'), 'colors.yellow');

  charMap.forEach((value: string, key: string) => {
    if (key.indexOf('mg[') > 0) {
      s = s.replaceAll(value, '"'.concat(value.concat('"')));
      s = s.replaceAll('""', '"');
    }
  });
  const splited: string[] = s.split(/\r\n|\r|\n/u);
  let res: string = '';
  splited.forEach((value: string) => {
    res = res.concat(transformSingleLine(value)).concat('\r\n');
  });
  s = res.substring(0, res.length - 2);

  return s;
};

/**
 * helper method for "blocksToCode"
 * @s "blocks code" string
 * @returns transformed "blocks code line" as python line
 */
const transformSingleLine = (s: string): string => {
  let res: string = s;
  if (res.indexOf(getChar('for')) >= 0 && res.indexOf(getChar('steps')) >= 0) {
    res = res.replace(getChar('for'), 'for ').replace(getChar('steps'), ' in seq(').concat('):');
  }
  if (res.indexOf(getChar('for')) >= 0 && res.indexOf(getChar('steps')) === -1) {
    res = res.replace(getChar('for'), 'for _ in seq(').concat('):');
  }
  if (res.indexOf(getChar('if')) >= 0) {
    res = res.replace(getChar('if'), 'if ').concat(':');
  }

  if (res.indexOf(getChar('sound')) >= 0) {
    res = res.replace(getChar('sound'), 'sound.play(').concat(')');
  }

  if (res.indexOf(getChar('get')) >= 0) {
    let ind: number = res.length;
    const chars: string[] = Array.from(res).reverse();
    let indexToInserdGet: number = res.length;
    let indexToInserdImg: number = res.length;
    //0 searching for end 1 searching for beginning
    let state: number = 0;
    chars.forEach((value: string) => {
      if (value === '\t' || value === ' ' || value === '=' || value === '!' || ind === 0) {
        if (state === 1) {
          indexToInserdGet = ind;
          let before: string = res.substring(0, indexToInserdImg);
          let after: string = res.substring(indexToInserdImg);
          res = before.concat(').img').concat(after);
          before = res.substring(0, indexToInserdGet);
          after = res.substring(indexToInserdGet);
          res = before.concat('get(').concat(after);

          state = 0;
        }
        if (state === 0) {
          indexToInserdImg = ind - 1;
        }
      }
      if (value === getChar('get')) {
        state = 1;
      }
      ind--;
    });
    if (state === 1) {
      indexToInserdGet = 0;
      let before: string = res.substring(0, indexToInserdImg);
      let after: string = res.substring(indexToInserdImg);
      res = before.concat(').img').concat(after);
      before = res.substring(0, indexToInserdGet);
      after = res.substring(indexToInserdGet);
      res = before.concat('get(').concat(after);
    }
    res = res.replaceAll(getChar('get'), ',');
  }

  return res;
};
