import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { BadgeType } from 'types/enums';
import styles from './studentBadges.module.css';
import StudentBadge from './StudentBadge/StudentBadge';

export default function StudentBadges() {
  const { badgesCounter } = useWorkspaceContext();

  return (
    <div className={styles['student-badges__content']}>
      {Object.entries(BadgeType).map(([_, badgeType]) => (
        <StudentBadge
          key={badgeType}
          badge={badgeType}
          badgesCounter={badgesCounter?.[badgeType]}
        />
      ))}
    </div>
  );
}
