import { MutableRefObject, useRef, useState } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Firepad } from 'global/firepad';
import { Question } from 'types/ContentTypes';
import { IEditor, IIDEMethods } from 'types/EditorTypes';
import { RunHooks } from './hooks/RunHooks';
import { GetIDEMethods } from './hooks/memos';
import { ContentProps } from './interface';
import Compiler from './components/Compiler';
import EditorSection from './components/EditorSection';
import styles from './ideContent.module.css';
import { ProgrammingLanguage } from 'types/enums';

export default function Content(props: ContentProps) {
  const { leaderModeSwitchValue, setNavigationStack } = props;
  const aceRef = useRef() as MutableRefObject<IEditor>;
  const firepadRef = useRef<Firepad | null>(null);
  const [isCodeMode, setIsCodeMode] = useState<boolean>(true);
  const [loadedAce, setLoadedAce] = useState(false);
  const [question, setQuestion] = useState<Question | null>(null);

  const ideMethods: IIDEMethods = GetIDEMethods({ aceRef, firepadRef, question });

  RunHooks({
    ideMethods,
    isCodeMode,
    leaderModeSwitchValue,
    loadedAce,
    question,
    setIsCodeMode,
    setLoadedAce,
    setNavigationStack,
    setQuestion,
  });

  return (
    <ReflexContainer orientation="vertical">
      {question?.language !== ProgrammingLanguage.ChatGPT && (
        <ReflexElement flex={0.7} minSize={600}>
          <EditorSection
            editorRef={aceRef}
            ideMethods={ideMethods}
            isCodeMode={isCodeMode}
            loadedAce={loadedAce}
            question={question}
            setIsCodeMode={setIsCodeMode}
          />
        </ReflexElement>
      )}
      {question?.language !== ProgrammingLanguage.ChatGPT && (
        <ReflexSplitter className={styles['ide-content__spliter']} />
      )}
      <ReflexElement
        flex={question?.language === ProgrammingLanguage.ChatGPT ? 1 : 0.3}
        minSize={250}
      >
        <Compiler ideMethods={ideMethods} question={question} />
      </ReflexElement>
    </ReflexContainer>
  );
}
