import { MutableRefObject, useCallback, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { head } from 'helpers/ListHelpers';
import { ISelectedMenuItem } from 'types/MenuTypes';
import { generateMenuItems } from './helpers';
import { MenuButtonProps } from './interface';
import CustomButton from 'components/atoms/CustomButton';
import CustomMenu from 'components/atoms/CustomMenu';
import styles from './menuButton.module.css';

export default function MenuButton(props: MenuButtonProps) {
  const {
    children,
    menuItems,
    buttonClassName,
    menuClassNames,
    anchorOrigin,
    transformOrigin,
    onItemSelected,
    onMenuClose,
    onMenuOpen,
  } = props;
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const buttonRef = useRef() as MutableRefObject<HTMLButtonElement>;
  const menuButtonClassNames = clsx(styles['menu-button__custom-button'], buttonClassName);

  const handleButtonClick = useCallback(() => {
    setShowMenu(true);
    onMenuOpen && onMenuOpen();
  }, [onMenuOpen]);

  const selectItem = useCallback(
    (selectedMenuItem: ISelectedMenuItem) => {
      setShowMenu(false);
      onItemSelected(selectedMenuItem);
      onMenuClose && onMenuClose();
    },
    [onItemSelected, onMenuClose],
  );

  const menuChildren: JSX.Element[] = generateMenuItems(
    menuItems,
    selectItem,
    menuClassNames,
    anchorOrigin,
    transformOrigin,
  );

  return (
    <>
      <CustomButton
        onClick={handleButtonClick}
        reference={buttonRef}
        className={menuButtonClassNames}
      >
        {children}
      </CustomButton>
      <CustomMenu
        parentRef={buttonRef.current}
        showMenu={showMenu}
        className={head(menuClassNames)}
        anchorOrigin={head(anchorOrigin)}
        transformOrigin={head(transformOrigin)}
        closeMenu={onMenuClose}
        setShowMenu={setShowMenu}
        selectItem={selectItem}
      >
        {menuChildren}
      </CustomMenu>
    </>
  );
}
