import { Question } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';

const isCharNumber = (char: number): boolean => char >= 48 && char <= 57;

const isCharCapitalLetter = (char: number): boolean => char >= 65 && char <= 90;

const isCharLowercaseLetter = (char: number): boolean => char >= 97 && char <= 122;

const isCharUnderscore = (char: number): boolean => char === 95;

const isCharAlphanumeric = (mark: string): boolean => {
  if (mark === undefined) return false;
  const char: number = mark.charCodeAt(0);
  return (
    isCharNumber(char) ||
    isCharCapitalLetter(char) ||
    isCharLowercaseLetter(char) ||
    isCharUnderscore(char)
  );
};

const searchInCode = (code: string, keywords: Array<string>) => {
  const isDrawingKeywords = keywords.some((word: string, _, __) => {
    const index: number = code.indexOf(word);
    if (
      (index === 0 && !isCharAlphanumeric(code[index + word.length])) ||
      (index > 0 &&
        !isCharAlphanumeric(code[index - 1]) &&
        !isCharAlphanumeric(code[index + word.length]))
    ) {
      return true;
    }
    return false;
  });

  return isDrawingKeywords;
};

export const doDrawingKeywordsAppear = (code: string): boolean => {
  const drawingKeywords = ['arrow', 'Sprite'];
  return searchInCode(code, drawingKeywords);
};

export const searchGraphicKeywords = (question: Question): boolean => {
  const drawingKeywords = ['arrow', 'Sprite', 'game'];
  return question.type === FileType.Sandpit || searchInCode(question.code, drawingKeywords);
};

export const search = (code: string, language: string): boolean => {
  const blocksGameKeywords = ['play'];
  const pythonGameKeywords = ['game.start', 'game.key', 'random.randint', 'message.show', 'input'];
  return searchInCode(code, language === 'python' ? pythonGameKeywords : blocksGameKeywords);
};

/**
 * (works with "useClickAway hook", also you can use it separately)
 * listen to some event on "obj" (in most cases it will html element or ref to it)
 */
export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['addEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement['addEventListener']>));
  }
}

/**
 * (works with "useClickAway hook", also you can use it separately)
 * unsubscribe from event listener on some "obj" (in most cases it will html element or ref to it)
 */
export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T['removeEventListener']> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement['removeEventListener']>));
  }
}
