import { IFilters } from 'types/FilterTypes';
import { UserType } from 'types/UserTypes';

export const ROWS = [5, 10, 25];

export const userFilters: Record<string, IFilters[]> = {
  role: [
    { name: Object.keys(UserType)[0], value: UserType.Student },
    { name: Object.keys(UserType)[1], value: UserType.Tutor },
    { name: Object.keys(UserType)[2], value: UserType.Admin },
  ],
};
