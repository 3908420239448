import { AppName, getAppDatabase } from '../helpers/database';
import { child, Database, get, ref } from 'firebase/database';
import { db } from '../../store/helpers/firestore';
import { collection, addDoc, doc, setDoc, Timestamp, serverTimestamp } from 'firebase/firestore';

const database: Database = getAppDatabase(AppName.DEFAULT);

export async function BadgesExportToFirestore() {
  try {
    await get(child(ref(database), `badges`))
      .then(async snapshot => {
        if (snapshot.exists()) {
          const realtimeBadges = await snapshot.val();
          Object.entries(realtimeBadges).forEach(async ([userId, value]) => {
            // eslint-disable-next-line
            const badgeData: object = Object.entries(
              Object.entries(value as object)[0][1],
            )[0][1] as object;
            const badgeForFirestore = {
              ...Object.values(badgeData)[0],
              userId,
              roomId: Object.keys(value as object)[0],
              creadtedDate: Timestamp.fromDate(
                new Date(Object.values(badgeData)[0].createDate.seconds),
              ),
            };
            await setDoc(doc(db, 'badges', Object.keys(badgeData as object)[0]), badgeForFirestore);
          });
        }
      })
      .catch(error => {
        throw error;
      });
  } catch (e) {
    throw e;
  }
}

export async function membersExportToFirestore() {
  try {
    await get(child(ref(database), `members`)).then(async snapshot => {
      const membersRealtimeDb = await snapshot.val();
      Object.entries(membersRealtimeDb).forEach(async ([roomId, value]) => {
        await Object.entries(value as object).forEach(async ([userId, value]) => {
          const memberToFirestore = { roomId, userId, createdDate: serverTimestamp() };
          await addDoc(collection(db, 'members'), memberToFirestore);
        });
      });
    });
  } catch (e) {
    throw e;
  }
}

export async function usersExportToFirestore() {
  try {
    await get(child(ref(database), `users`)).then(async snapshot => {
      const usersRealtime = await snapshot.val();
      Object.entries(usersRealtime).forEach(async ([userId, user]) => {
        await addDoc(collection(db, 'users', userId), {
          ...(user as object),
          createdDate: serverTimestamp(),
        });
      });
    });
  } catch (e) {
    throw e;
  }
}

export async function roomsExportToFirestore() {
  try {
    await get(child(ref(database), `rooms`)).then(async snapshot => {
      const roomsRealtime = await snapshot.val();
      Object.entries(roomsRealtime).forEach(async ([roomId, room]) => {
        await addDoc(collection(db, 'rooms', roomId), {
          ...(room as object),
          createdDate: serverTimestamp(),
        });
      });
    });
  } catch (e) {
    throw e;
  }
}
