import { useState, useEffect } from 'react';
import styles from './appInNewWindow.module.css';

export default function AppInNewWindow() {
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const [url, setUrl] = useState<string | null>('');

  useEffect(() => {
    setUrl(code);
  }, [code]);

  return (
    <div className={styles['app-in-new-window__container']}>
      <iframe id="compilerPage" src={url as string} title="compiler" width="100%" height="100%" />
    </div>
  );
}
