import { Suspense, useCallback, useRef, useState } from 'react';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { renderSurveyButton } from '../../../helpers';
import { t } from '@lingui/macro';
import Loader from 'components/atoms/Loader';
import useClickAway from 'hooks/useClickAway';
import FormModal from '../../../../../modals/FormModal';

export default function TutorSection() {
  const { i18n } = useLocaleContext();
  const { room } = useWorkspaceContext();
  const [formModalShow, setFormModalShow] = useState<boolean>(false);
  const formModalRef = useRef(null);

  const closeModalHandler = useCallback(() => {
    setFormModalShow(false);
  }, []);

  const openModalHandler = useCallback(() => {
    setFormModalShow(true);
  }, [setFormModalShow]);

  useClickAway(formModalRef, closeModalHandler);

  return (
    <>
      <div ref={formModalRef}>
        <Suspense fallback={<Loader size={50} />}>
          <FormModal isShow={formModalShow} onClose={closeModalHandler} roomId={room?.id} />
        </Suspense>
      </div>
      {renderSurveyButton(
        t(i18n)({ id: 'evaluationSurvey.label', message: 'Questionnaire' }),
        openModalHandler,
      )}
    </>
  );
}
