import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { anchorPosition, menuContainerSx, menuIconSx, sxRight } from './constants';
import { HandleClose, HandleMenu, LogoutCurrentUser } from './hooks/callbacks';
import { Trans } from '@lingui/macro';
import { Box, IconButton, Paper, Menu } from '@mui/material';
import LanguageSelector from 'components/organisms/LanguageSelector';
import styles from './accountMenu.module.css';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

export default function AccountMenu() {
  const { logout, currentUser } = useAppContext();
  const { fatal } = useErrorContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleMenu = HandleMenu({ setAnchorEl });
  const handleClose = HandleClose({ setAnchorEl });
  const logoutCurrentUser = LogoutCurrentUser({ fatal, logout, navigate });

  return (
    <>
      <IconButton
        sx={menuIconSx}
        color="inherit"
        role="button"
        aria-haspopup="true"
        aria-controls="menu-appbar"
        aria-label="account"
        onClick={handleMenu}
      >
        <PersonIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={anchorPosition}
        keepMounted
        disableAutoFocus
        disableRestoreFocus
        disableScrollLock
        transformOrigin={anchorPosition}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* eslint-disable-next-line react-perf/jsx-no-new-object-as-prop */}
        <Paper sx={menuContainerSx}>
          <Box className={styles['account-menu__header']}>
            <ManageAccountsIcon />
            <Box className={styles['account-menu__user-name']}>
              {`${currentUser?.name} ${currentUser?.lastName}`}
            </Box>
          </Box>
          <MenuItem data-testid="logout-menu-item" onClick={logoutCurrentUser}>
            <LogoutIcon sx={sxRight} />
            <Typography>
              <Trans id="accountDropdown.logout">logout</Trans>
            </Typography>
          </MenuItem>
          <LanguageSelector />
        </Paper>
      </Menu>
    </>
  );
}
