import { CurrentUser } from 'types/UserTypes';
import { isTutor, isStudent, isAdmin } from 'helpers/userHelper';
import { TUserActions, mainTutorActions, secondaryTutorActions, studentActions } from './consts';

/**
 * takes currentUser object data and room activeTutor id
 * checks which kind of actions specific role can do [student, tutor, admin, secondary tutor]
 */
export default class UserActions {
  private user: CurrentUser | null;
  private activeTutorId: string;

  constructor(user: CurrentUser | null, activeTutorId: string) {
    this.user = user;
    this.activeTutorId = activeTutorId;
  }

  private isMainTutor() {
    if (!this.user) return false;
    return this.user.uid === this.activeTutorId && isTutor(this.user.role);
  }

  private isSecondaryTutor() {
    if (!this.user) return false;
    return this.user.uid !== this.activeTutorId && isTutor(this.user.role);
  }

  private isStudent() {
    if (!this.user) return false;
    return isStudent(this.user.role);
  }

  public askForAction(action: TUserActions) {
    if (this.isMainTutor()) {
      return mainTutorActions.includes(action);
    }

    if (this.isSecondaryTutor()) {
      return secondaryTutorActions.includes(action);
    }

    if (this.isStudent()) {
      return studentActions.includes(action);
    }

    return false;
  }

  public askForMultipleActions(actions: TUserActions[]) {
    return !actions.map(action => this.askForAction(action)).includes(false);
  }
}
