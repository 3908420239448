import { UserCreationForm } from 'schemas/adminForms.schema';
import { UserType as UserRole } from 'types/enums';
import { User } from 'types/UserTypes';
import { GetFormProps, SetStudentsDefaultFields } from './hooks';
import FormModalWrapper from '../../../../modals/FormModalWrapper';
import editUser from '../../../../scripts/user/editUser';
import FormFields from './FormFields';
import { useState } from 'react';

export interface EditUserFormProps {
  user: User;
  rooms: string[];
  open: boolean;
  handleClose: () => void;
}

export default function EditUserForm(props: EditUserFormProps) {
  const { user, rooms, open, handleClose } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const {
    control,
    handleSubmit,
    watch,
    getFieldState,
    setValue,
    formState: { errors },
  } = GetFormProps({ rooms, user });
  const isStudent = watch('role', UserRole.Student) === UserRole.Student;
  SetStudentsDefaultFields({ isStudent, user, setValue, getFieldState, rooms });
  const submit = async (data: UserCreationForm) => {
    try {
      setLoading(true);
      await editUser({ ...data, uid: user.uid });
      setLoading(false);
      handleClose();
    } catch (e) {
      setLoading(false);
      throw e;
    }
  };

  return (
    <FormModalWrapper
      open={open}
      handleSubmit={handleSubmit(submit)}
      handleClose={handleClose}
      header="Edit user"
      btnText="Edit"
      loading={loading}
    >
      <FormFields
        control={control}
        errors={errors}
        isStudent={isStudent}
        handleSubmit={handleSubmit(submit)}
        setValue={setValue}
      />
    </FormModalWrapper>
  );
}
