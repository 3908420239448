import { useCallback } from 'react';
import { IHighlightActiveBreakpoint } from './interfaces';

export const HighlightActiveBreakpoint = (data: IHighlightActiveBreakpoint) => {
  const { aceRef } = data;

  return useCallback(
    (line: number) => {
      aceRef.current?.highlightActiveBreakpointLine(line);
    },
    [aceRef],
  );
};
