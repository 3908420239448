import { Dispatch, useMemo } from 'react';
import { isAdmin } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { MemberData } from 'types/MemberTypes';
import { sortMembers } from '../helpers';
import styles from './membersSection.module.css';
import UsersTabs from './UserTabs';
interface MembersSectionProps {
  fileBroswerDisabled: boolean;
  setBadgeUser: Dispatch<MemberData | null>;
}
export default function MembersSection(props: MembersSectionProps) {
  const { fileBroswerDisabled, setBadgeUser } = props;
  const { currentUser } = useAppContext();
  const { members, activeTutor } = useWorkspaceContext();
  const sortedMembers = useMemo(
    () =>
      sortMembers(
        members.filter(m => !isAdmin(m.role)),
        activeTutor,
        currentUser?.uid,
      ),
    [members, activeTutor, currentUser?.uid],
  );
  return (
    <div className={styles['members-section__content']}>
      {sortedMembers.length > 0 && (
        <UsersTabs
          fileBroswerDisabled={fileBroswerDisabled}
          membersData={sortedMembers}
          setBadgeUser={setBadgeUser}
        />
      )}
    </div>
  );
}
