import { useCallback } from 'react';
import { get, ref } from 'firebase/database';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { z } from 'zod';
import { IGetActiveCourse } from './interfaces';

export const GetActiveCourse = (data: IGetActiveCourse) => {
  const { roomId, closeModal, setLocalCourseId } = data;

  return useCallback(async () => {
    try {
      const database = getAppDatabase(AppName.DEFAULT);
      const activeLessonRef = ref(database, `rooms/${roomId}/activeCourse`);
      const lessonSnapshot = await get(activeLessonRef);
      const activeLesson = z.string().parse(lessonSnapshot.val());
      setLocalCourseId(activeLesson);
      closeModal();
    } catch (e) {
      throw e;
    }
  }, [roomId, closeModal, setLocalCourseId]);
};
