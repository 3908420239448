import { useState, useCallback } from 'react';
import { Button, Stack, Typography, Container, Modal, Box } from '@mui/material';
import {
  BadgesExportToFirestore,
  membersExportToFirestore,
  usersExportToFirestore,
  roomsExportToFirestore,
} from 'store/synchronize/RealtimeToFirestore';
import { ParsePresents, CopyToPresenceFromTemp } from '../../../../store/synchronize/Parser';
import Page from 'components/atoms/Page';
import {
  getIncorrectData,
  getPrivateFiles,
  getQuestions,
  exchangePrivateImagesForPublicImages,
} from 'store/synchronize/privateAndPublicFiles';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Synch() {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const synch = useCallback(async () => {
    try {
      await BadgesExportToFirestore();
      await membersExportToFirestore();
      await usersExportToFirestore();
      await roomsExportToFirestore();
      setOpen(false);
    } catch (e) {
      throw e;
    }
  }, []);

  const parsePresents = useCallback(() => {
    try {
      ParsePresents();
    } catch (e) {
      throw e;
    }
  }, []);

  const copyPresents = useCallback(() => {
    try {
      CopyToPresenceFromTemp();
    } catch (e) {
      throw e;
    }
  }, []);

  const synchPrivateAndPublicFiles = useCallback(async () => {
    try {
      const privateFiles = await getPrivateFiles();
      if (!privateFiles) {
        throw new Error('No private files!');
      }
      const questionsData = await getQuestions();
      const incorrectFiles = getIncorrectData(privateFiles, questionsData);
      await exchangePrivateImagesForPublicImages(incorrectFiles);
    } catch (e) {
      throw e;
    }
  }, []);

  return (
    <Page title="Synch">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Synch
          </Typography>
        </Stack>
        <Stack>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Are you sure ?
              </Typography>
              <Typography id="modal-modal-description">You must be careful.</Typography>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Button onClick={synch}>Yes</Button>
                <Button onClick={handleClose}>No</Button>
              </Stack>
            </Box>
          </Modal>
          <Button variant="outlined" color="error" onClick={handleOpen}>
            Synchronize users, rooms, members, badges data from Realtime to Firestore database
          </Button>
          {/* <Button variant="outlined" color="error" onClick={parsePresents}>
            Parse presence in Firestore
          </Button>
          <Button variant="outlined" color="error" onClick={copyPresents}>
            Copy temp presences to new
          </Button> */}
          <Button variant="outlined" color="error" onClick={synchPrivateAndPublicFiles}>
            Synchronize private and public files in realtime database
          </Button>
        </Stack>
      </Container>
    </Page>
  );
}
