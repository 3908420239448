import { useCallback } from 'react';
import { get, ref } from 'firebase/database';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { z } from 'zod';
import { IGetActiveLesson } from './interfaces';

export const GetActiveLesson = (data: IGetActiveLesson) => {
  const { roomId, closeModal, setLocalLessonId } = data;

  return useCallback(async () => {
    try {
      const database = getAppDatabase(AppName.DEFAULT);
      const activeLessonRef = ref(database, `rooms/${roomId}/activeLesson`);
      const lessonSnapshot = await get(activeLessonRef);
      const activeLesson = z.string().parse(lessonSnapshot.val());
      setLocalLessonId(activeLesson);
      closeModal();
    } catch (e) {
      throw e;
    }
  }, [roomId, closeModal, setLocalLessonId]);
};
