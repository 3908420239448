import { useCallback, useState } from 'react';
import { Stack, Typography, Container } from '@mui/material';
import { sendMessageToChatGPT } from 'store/functions';
import { useErrorContext } from 'providers/error/error.provider';
import ChatIcon from '@mui/icons-material/Chat';
import ShortTextIcon from '@mui/icons-material/ShortText';
import SendIcon from '@mui/icons-material/Send';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from 'components/atoms/Page';

const cardStyles = { padding: '20px', marginBottom: '10px' };

export default function ChatGPT() {
  const { bug } = useErrorContext();
  const [htmlField, setHtmlField] = useState<string>('');

  const [isSending, setIsSending] = useState<boolean>(false);

  const sendEmail = useCallback(async () => {
    try {
      setIsSending(true);
      const response = await sendMessageToChatGPT(htmlField);
      // eslint-disable-next-line
      alert(response);
      setIsSending(false);
    } catch {
      setIsSending(false);
      bug('Error while sending emails');
    }
  }, [htmlField, bug]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handlehtmlFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setHtmlField(event.target.value);
    },
    [],
  );

  return (
    <Page title="Schedule">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            <ChatIcon /> Chat GPT
          </Typography>
        </Stack>
        <Stack>
          <Card sx={cardStyles}>
            <Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <ShortTextIcon />
                <Typography>Send message to chat GPT</Typography>
              </Stack>
              <br />
              <TextField
                id="standard-multiline-static"
                label="Message"
                multiline
                rows={7}
                defaultValue=""
                variant="standard"
                onChange={handlehtmlFieldChange}
              />
              <br />
              <LoadingButton
                variant="contained"
                endIcon={<SendIcon />}
                loading={isSending}
                onClick={sendEmail}
              >
                Send
              </LoadingButton>
            </Stack>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
