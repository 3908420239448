import { Dispatch, useCallback } from 'react';
import { clsx } from 'clsx';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { MemberData } from 'types/MemberTypes';
import { countStudentsBadges } from '../../../../../helpers/studentBadgesHelper';
import { IconButton } from '@mui/material';
import styles from './badgeButton.module.css';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';

interface BadgeButtonProps {
  badgeHighlighted: boolean;
  setBadgeUser: Dispatch<MemberData>;
}

export default function BadgeButton(props: BadgeButtonProps) {
  const { badgesCounter, members } = useWorkspaceContext();
  const { badgeHighlighted, setBadgeUser } = props;
  const badgeButtonClassNames: string = clsx(
    styles['badge-button__icon'],
    badgeHighlighted && styles['badge-button__icon--highlighted'],
  );

  const handleBadgeClick = useCallback(() => {
    setBadgeUser(members[0]);
  }, [members, setBadgeUser]);

  return (
    <IconButton className={styles['badge-button__content']} onClick={handleBadgeClick}>
      <MilitaryTechSharpIcon className={badgeButtonClassNames} />
      <span className={styles['badge-button__number']}>{countStudentsBadges(badgesCounter)}</span>
    </IconButton>
  );
}
