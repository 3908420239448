const Colors = [
  '#6081FB',
  '#F5CF47',
  '#2EC4B6',
  '#FF7775',
  '#E08E45',
  '#F67FD2',
  '#94B0DA',
  '#F2FF5F',
  '#A07178',
  '#6A30FA',
  '#008EC5',
  '#E56399',
  '#FD7C34',
  '#78C0E0',
  '#08696B',
  '#FF1B1C',
  '#845FF9',
];

export const getColor = (id: number) => Colors[id % Colors.length];
