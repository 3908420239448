import React, { SyntheticEvent } from 'react';
import { Box, Rating, Stack } from '@mui/material';
import styles from './question.module.css';
import { FormQuestion } from 'types/FormsTypes';

export interface QuestionProps {
  setValue: Function;
  question: FormQuestion;
}

function Question(props: QuestionProps) {
  function setValueHandler(event: SyntheticEvent<Element, Event>, newValue: number | null) {
    props.setValue(props.question.id, newValue);
  }

  return (
    <Box className={styles.question__container}>
      <Stack>
        <Box className={styles.question__title}>{props.question.title}</Box>
        <Rating
          aria-label="rating-input-star"
          name="half-rating"
          defaultValue={0}
          value={props.question.value}
          onChange={setValueHandler}
          precision={1}
        />
      </Stack>
    </Box>
  );
}

export default React.memo(Question);
