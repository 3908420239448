import { useCallback, useEffect } from 'react';
import { SharedCompilerData } from 'types/ShareTypes';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import styles from './publicCompilers.module.css';

interface PublicCompilersProps {
  compilerData: SharedCompilerData | null;
  url: string;
  setUrl: Function;
}

export default function PublicCompilers(props: PublicCompilersProps) {
  const { compilerData, url, setUrl } = props;
  const executeHtmlJsCss = useCallback(() => {
    const getGeneratedPageURL = (html: string) => {
      const getBlobURL = (code: string, type: string) => {
        const blob = new Blob([code], { type });
        return URL.createObjectURL(blob);
      };

      const source = `
              <html>
                <head>
                </head>
                <body>
                  ${html || ''}
                </body>
              </html>
            `;

      return getBlobURL(source, 'text/html');
    };
    return getGeneratedPageURL(compilerData?.code as string);
  }, [compilerData?.code]);

  const renderCompiler = useCallback(() => {
    switch (compilerData?.language as ProgrammingLanguage) {
      case ProgrammingLanguage.Blocks:
        return <div>BLOCKS</div>;
      case ProgrammingLanguage.Python:
        return <div>PYTHON</div>;
      case ProgrammingLanguage.HtmlJsCss:
        setUrl(executeHtmlJsCss());
        return (
          <div className={styles['public-compilers__content']}>
            <iframe id="compilerPage" src={url} title="compiler" width="100%" height="100%" />
          </div>
        );
      default:
        return '';
    }
  }, [compilerData, executeHtmlJsCss, setUrl, url]);

  return <div>{renderCompiler()}</div>;
}
