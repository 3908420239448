import { useEffect } from 'react';
import { onValue, ref } from 'firebase/database';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { IWatchTutorActivity } from './interfaces';

export const WatchTutorActivity = (data: IWatchTutorActivity) => {
  const { roomId, setIsTutorActive } = data;

  useEffect(() => {
    try {
      const database = getAppDatabase(AppName.DEFAULT);
      const activeTutorRef = ref(database, `rooms/${roomId}/activeTutor`);

      const unsubscribe = onValue(activeTutorRef, snapshot => {
        if (snapshot.exists()) {
          const _isTutorActive = Boolean(snapshot.val());
          setIsTutorActive(_isTutorActive);
        }
      });

      return () => {
        unsubscribe();
      };
    } catch (e) {
      throw e;
    }
  }, [roomId, setIsTutorActive]);
};
