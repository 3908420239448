import { Timestamp } from 'firebase/firestore';
import { filter } from 'lodash';
import { AdminLanguage } from 'types/enums';
import { Room } from 'types/RoomTypes';

const descendingComparator = (a: Room, b: Room, orderBy: keyof Room) => {
  const item1 = a[orderBy];
  const item2 = b[orderBy];

  if (!item1) return -1;
  if (!item2) return 1;
  if (item2 < item1) {
    return -1;
  }
  if (item2 > item1) {
    return 1;
  }
  return 0;
};

const filterProperty = (property: string, text: string) =>
  property.toLowerCase().indexOf(text.toLowerCase()) !== -1;

const filterRooms = (query: string) => (_room: Room) => {
  const programmingLanguage = Object.entries(AdminLanguage).find(
    ([_, value]) => value === _room.language,
  );
  const programmingLanguageName = programmingLanguage ? programmingLanguage[0] : '';
  return (
    filterProperty(_room.id, query) ||
    filterProperty(_room.name, query) ||
    filterProperty(_room.SKU as string, query) ||
    filterProperty(_room.description, query) ||
    filterProperty(_room.activeLesson, query) ||
    filterProperty(_room.activeCourse, query) ||
    filterProperty(_room.meetLink, query) ||
    filterProperty(programmingLanguageName, query) ||
    filterProperty(validateDate(_room.creationDate), query)
  );
};

export const validateDate = (date: { nanoseconds: number; seconds: number }) => {
  try {
    const { seconds, nanoseconds } = date;
    const creationDate = new Timestamp(seconds, nanoseconds).toDate();

    const month =
      creationDate.getUTCMonth() + 1 >= 10
        ? `${creationDate.getUTCMonth() + 1}`
        : `0${creationDate.getUTCMonth() + 1}`;
    const day =
      creationDate.getDate() >= 10 ? `${creationDate.getDate()}` : `0${creationDate.getDate()}`;
    const hour =
      creationDate.getHours() >= 10 ? `${creationDate.getHours()}` : `0${creationDate.getHours()}`;
    const minute =
      creationDate.getMinutes() >= 10
        ? `${creationDate.getMinutes()}`
        : `0${creationDate.getMinutes()}`;
    const second =
      creationDate.getSeconds() >= 10
        ? `${creationDate.getSeconds()}`
        : `0${creationDate.getSeconds()}`;
    return `${creationDate.getFullYear()}-${month}-${day}\n${hour}:${minute}:${second}`;
  } catch (_) {
    return 'DATE ERROR';
  }
};

export const filterByAll = (array: Record<string, any>[], activeFilters: Record<string, any>) => {
  let filteredArray = [...array];
  // eslint-disable-next-line no-restricted-syntax, no-loops/no-loops
  for (const key in activeFilters) {
    if (activeFilters[key] !== '') {
      filteredArray = filteredArray.filter(
        item => item[key].toString() === activeFilters[key].toString(),
      );
    }
  }

  return filteredArray;
};

export const getComparator = (isOrderReversed: boolean, orderBy: keyof Room) =>
  isOrderReversed
    ? (a: Room, b: Room) => descendingComparator(a, b, orderBy)
    : (a: Room, b: Room) => -descendingComparator(a, b, orderBy);

export const applySortFilter = (
  array: Room[],
  comparator: (a: Room, b: Room) => number,
  query: string,
): Room[] => {
  const stabilizedThis: [Room, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  try {
    if (query) {
      return filter(array, filterRooms(query));
    }
  } catch (e) {
    throw e;
  }
  return stabilizedThis.map(el => el[0]);
};
