import { white, purple } from 'constants/Colors';
import { forwardRef } from 'react';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField)({
  width: '100%',
  '& label.Mui-focused': {
    color: transformColorFromHexToRGBA(white, 0.7),
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: purple,
  },

  '& .MuiInputBase-input': {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    overflow: 'auto',

    letterSpacing: '0.15px',

    color: white,
  },

  '& .MuiInputBase-root:after': {
    borderBottom: `2px solid ${purple}`,
  },

  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: purple,
    },
    '&:hover fieldset': {
      borderColor: purple,
    },
    '&.Mui-focused fieldset': {
      borderColor: purple,
    },
  },
});
const Textarea = forwardRef<HTMLInputElement, TextFieldProps>(({ rows = 3, ...props }, ref) => (
  <StyledTextField ref={ref} variant="filled" multiline rows={rows} {...props} />
));

export default Textarea;
