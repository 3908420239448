import { useCallback, useEffect, useState } from 'react';
import { soundsSource } from './soundsLists';
import { Sounds } from './style';
import { SoundButton } from './SoundButton/SoundButton';
import styles from './soundSection.module.css';

interface SoundSectionProps {
  isMenuOpen: boolean;
}

export default function SoundSection(props: SoundSectionProps) {
  const { isMenuOpen } = props;
  const [selectedSound, setSelectedSound] = useState<string>();
  const [url, setUrl] = useState<string>();

  const stop = useCallback(() => {
    setUrl('');
    setSelectedSound('');
  }, [setUrl, setSelectedSound]);

  const playSound = useCallback(
    (soundName: string) => {
      const stop = () => {
        setUrl('');
        setSelectedSound('');
      };

      const play = () => {
        setSelectedSound(soundName);
        const path = `${window.location.origin}/sounds/${soundName}.mp3`;
        setUrl(path);
      };

      if (url && url.trim().length > 0) {
        const currentSound = selectedSound;
        stop();
        if (currentSound === soundName) return;
      }
      play();
    },
    [selectedSound, url],
  );

  useEffect(() => {
    !isMenuOpen && stop();
  }, [isMenuOpen, stop]);

  return (
    <div className={styles['sound-section__content']}>
      <audio src={url} preload="auto" autoPlay onEnded={stop} />
      {soundsSource.map(sounds => (
        <Sounds key={sounds.key} value={0} TabIndicatorProps={undefined}>
          {sounds.list.map(sound => (
            <SoundButton
              key={sound.name}
              playSound={playSound}
              selectedSound={selectedSound}
              sound={sound}
            />
          ))}
        </Sounds>
      ))}
    </div>
  );
}
