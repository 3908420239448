import { z } from 'zod';
import { IsLeaderMode, LeaderModeQuestion } from 'types/LeaderModeTypes';
import { child, get, ref, update } from 'firebase/database';
import { getAppDatabase, AppName } from '../../helpers/database';

// firebase custom functions
import { getRoom } from './room';

/**
 * sets room id for leader mode
 * @param leaderModeQuestion leader mode question data (see interface LeaderModeQuestion)
 */
export const setLeaderQuestion = async (leaderModeQuestion: LeaderModeQuestion): Promise<void> => {
  const database = getAppDatabase(AppName.DEFAULT);
  const leaderModeRef = ref(database, `leader_mode_data/${leaderModeQuestion.roomId}`);
  await update(leaderModeRef, leaderModeQuestion);
};

/**
 * updates room leader mode value
 * @param data {roomId, isLeaderMode}
 * @returns void
 */
export const setLeaderMode = async (data: IsLeaderMode): Promise<void> => {
  const database = getAppDatabase(AppName.DEFAULT);
  try {
    if (!(await getRoom(data.roomId))) return;
    const roomsRef = ref(database, `rooms/${data.roomId}`);
    await update(roomsRef, {
      isLeaderMode: data.isLeaderMode,
    });
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param roomId current room id
 * @returns boolean value for the room leader mode
 */
export const getLeaderMode = async (roomId: string): Promise<boolean> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const isRoomLeader = await get(child(ref(database), `rooms/${roomId}/isLeaderMode`));
    const mode = isRoomLeader.val();
    const isLeaderMode = z.boolean({
      invalid_type_error: 'isLeaderMode must be a boolean',
    });
    return isLeaderMode.parse(mode);
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param roomId current room id
 * @param step number representation of step from -1.
 */
export const setLeaderModeDebugStep = async (roomId: string, step: number): Promise<void> => {
  try {
    if (!roomId) return;
    const database = getAppDatabase(AppName.DEFAULT);
    const leaderModDebugeRef = ref(database, `leader_mode_debug/${roomId}`);
    await update(leaderModDebugeRef, { steps: step });
  } catch (e) {
    throw e;
  }
};
