import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ref as firebaseRef,
  getDatabase,
  Database,
  get,
  query,
  update,
  onValue,
} from 'firebase/database';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  PopoverOrigin,
  TypographyProps,
} from '@mui/material';
// component
import EditUserForm from '../../../pages/User/components/EditUserForm';
import Iconify from 'components/atoms/Iconify';
import { useErrorContext } from 'providers/error/error.provider';
import { userRoomSchema } from 'schemas/room.schema';
import { deleteUserFromAdmin } from 'store/functions';
import { User } from 'types/UserTypes';
import { Room } from 'types/RoomTypes';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import getItemArray from '../../../scripts/getItemArray';
import { z } from 'zod';

const menuSx1 = { sx: { width: 200, maxWidth: '100%' } };
const menuSx2: PopoverOrigin = { vertical: 'top', horizontal: 'right' };
const menuSx3: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const variantBodySx: TypographyProps = { variant: 'body2' };

// ----------------------------------------------------------------------

// eslint-disable-next-line require-await
async function getRooms(db: Database) {
  try {
    const que = query(firebaseRef(db, 'rooms'));
    return get(que).then(snapshot => getItemArray(snapshot));
  } catch (e) {
    throw e;
  }
}

export interface UserMoreMenuProps {
  user: User;
}

export default function UserMoreMenu({ user }: UserMoreMenuProps) {
  const { bug } = useErrorContext();
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [rooms, setRooms] = useState<string[]>([]);

  const deletes: Record<string, null> = {};

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleEditModalClose = useCallback(() => {
    setShowEditModal(false);
  }, []);

  function deleteUserFromRooms(allRooms: Room[]) {
    // eslint-disable-next-line no-loops/no-loops
    for (let i = 0; i < allRooms.length; i += 1) {
      deletes[`members/${allRooms[i].id}/${user.uid}`] = null;
    }
  }

  async function deleteUser() {
    const db = getDatabase();
    const rooms = await getRooms(db);
    deletes[`users_rooms/${user.uid}`] = null;
    deletes[`users/${user.uid}`] = null;
    await deleteUserFromRooms(rooms);
    await update(firebaseRef(db), deletes);
  }

  async function handleDeleteClick() {
    setIsOpen(false);
    if (
      // eslint-disable-next-line no-alert
      window.confirm(
        `Are You sure, You want to delete ${user.name} ${user.lastName} from database?`,
      )
    ) {
      await deleteUser();
      await deleteUserFromAdmin(user.email);
      window.location.reload();
    }
  }

  function handleEditClick() {
    setIsOpen(false);
    setShowEditModal(true);
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      const db = getAppDatabase(AppName.DEFAULT);
      const membersRef = firebaseRef(db, `users_rooms/${user.uid}`);
      const unsubscribe = onValue(membersRef, snapshot => {
        if (!snapshot.exists()) {
          setRooms([]);
          return;
        }
        const result = snapshot.val();
        const usersSchema = z.record(userRoomSchema);
        const members = usersSchema.parse(result);
        const _rooms = Object.entries(members).reduce((data: string[], [_, member]) => {
          data.push(member.id);
          return data;
        }, []);
        setRooms(_rooms);
      });
      return () => {
        unsubscribe();
      };
    } catch (e) {
      bug(e);
    }
  }, [user, bug]);

  return (
    <>
      <EditUserForm
        open={showEditModal}
        handleClose={handleEditModalClose}
        user={user}
        rooms={rooms}
      />
      <IconButton ref={ref} onClick={handleOpen}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
        PaperProps={menuSx1}
        anchorOrigin={menuSx2}
        transformOrigin={menuSx3}
      >
        <MenuItem color="text.secondary">
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            // primaryTypographyProps={variantBodySx}
            onClick={handleDeleteClick}
          />
        </MenuItem>

        <MenuItem component={RouterLink} to="#" color="text.secondary">
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            // primaryTypographyProps={variantBodySx}
            onClick={handleEditClick}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
