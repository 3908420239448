import { useState } from 'react';
import { purple } from 'constants/Colors';
import {
  RGB,
  transformColorFromHexToObject,
  transformColorFromObjectToHex,
  transformColorFromObjectToPix,
} from 'helpers/ColorHelper';
import { BlueIcon, ColorSlider, GreenIcon, RedIcon } from './style';
import { Trans } from '@lingui/macro';
import copy from 'copy-to-clipboard';
import Tooltip from 'components/organisms/Tooltip';
import styles from './colorSection.module.css';

export default function ColorSection() {
  const [redValue, setRedValue] = useState<number>(transformColorFromHexToObject(purple).red);
  const [greenValue, setGreenValue] = useState<number>(transformColorFromHexToObject(purple).green);
  const [blueValue, setBlueValue] = useState<number>(transformColorFromHexToObject(purple).blue);
  const [tooltipVisibility, setTooltipVisibility] = useState<boolean>(false);

  const customRgb: RGB = { red: redValue, green: greenValue, blue: blueValue };

  const tooltip = {
    position: {
      marginLeft: '76px',
      marginBottom: '40px',
    },
  };

  const colorRange = {
    marks: [
      { value: 0, label: '0' },
      { value: 255, label: '255' },
    ],
  };

  const resultColorStyle = {
    backgroundColor: transformColorFromObjectToHex(customRgb),
  };

  const sliderRows = [
    {
      label: 'color_red',
      state: { get: redValue, set: changeRedValue },
      square: <RedIcon />,
    },
    {
      label: 'color_green',
      state: { get: greenValue, set: changeGreenValue },
      square: <GreenIcon />,
    },
    {
      label: 'color_blue',
      state: { get: blueValue, set: changeBlueValue },
      square: <BlueIcon />,
    },
  ];

  const paletteColors = [
    { backgroundColor: '#F5CF47' },
    { backgroundColor: '#F2FF5F' },
    { backgroundColor: '#08696B' },
    { backgroundColor: '#2EC4B6' },
    { backgroundColor: '#FF1B1C' },
    { backgroundColor: '#E56399' },
    { backgroundColor: '#F67FD2' },
    { backgroundColor: '#FD7C34' },
    { backgroundColor: '#6081FB' },
  ];

  const showTooltip = async () => {
    setTooltipVisibility(true);
    await new Promise((resolve, _) => {
      setTimeout(() => resolve(true), 1000);
    });
    setTooltipVisibility(false);
  };

  function changeRedValue(_: Event, value: number | number[]) {
    setRedValue(Array.isArray(value) ? value[0] : value);
  }

  function changeGreenValue(_: Event, value: number | number[]) {
    setGreenValue(Array.isArray(value) ? value[0] : value);
  }

  function changeBlueValue(_: Event, value: number | number[]) {
    setBlueValue(Array.isArray(value) ? value[0] : value);
  }

  function copyColor() {
    copy(transformColorFromObjectToPix(customRgb));
    showTooltip();
  }

  return (
    <div className={styles['color-section__content']}>
      {sliderRows.map(row => (
        <div className={styles['color-section__slider-row']} key={row.label}>
          <ColorSlider
            size="small"
            aria-label={row.label}
            marks={colorRange.marks}
            min={0}
            max={255}
            value={row.state.get}
            onChange={row.state.set}
          />
          {row.square}
        </div>
      ))}
      <div
        className={styles['color-section__result-color']}
        style={resultColorStyle}
        onClick={copyColor}
      >
        <p>{transformColorFromObjectToPix(customRgb)}</p>
        <Tooltip show={tooltipVisibility} position={tooltip.position}>
          <Trans id="extensionsMenu.colorSection.tooltip" />
        </Tooltip>
      </div>
      <div className={styles['color-section__palette']}>
        {paletteColors.map(colorStyles => (
          <div
            key={colorStyles.backgroundColor}
            className={styles['color-section__palette-color']}
            style={colorStyles}
            onClick={() => {
              const color: RGB = transformColorFromHexToObject(colorStyles.backgroundColor);
              setRedValue(color.red);
              setGreenValue(color.green);
              setBlueValue(color.blue);
            }}
          />
        ))}
      </div>
    </div>
  );
}
