/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { useCallback, useState, RefObject, MutableRefObject } from 'react';
import { gray, purple } from 'constants/Colors';
import { UserType } from 'types/UserTypes';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import styles from './slider.module.css';
import Slider from '@mui/material/Slider';
import BlocksCompiler from 'pages/CurrentRoom/components/Compilers/CodeBlocksCompiler/helpers/BlocksCompiler';
import PythonCompiler from 'pages/CurrentRoom/components/Compilers/PythonCompiler/helpers/Python';

const skipNextStyles = {
  volume: { color: '#0d0a16' },
};

interface SliderProps {
  compiler?: BlocksCompiler | PythonCompiler;
  compilerIframe: HTMLIFrameElement | null;
  speed: MutableRefObject<number>;
}

export default function SliderComponent(props: SliderProps) {
  const { compiler, speed } = props;
  const [speedValue, setSpeedValue] = useState<number>(50);
  const { currentUser } = useAppContext();
  const { leaderMode } = useWorkspaceContext();

  const sliderDisabled = currentUser?.role === UserType.Student && leaderMode.isLeaderModeOn;

  skipNextStyles.volume.color = sliderDisabled ? gray : purple; // TODO disabled color doesn't work (default disable color appears)

  const handleChangeSliderHelper = useCallback(
    (newValue: number = -1) => {
      const sliderValue = newValue < 0 ? speedValue : newValue;
      speed.current = (100 - sliderValue) * 10;
      compiler && compiler.changeSpeed(speed.current);
    },
    [compiler, speed, speedValue],
  );

  const handleChangeSlider = (_: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    if (value !== speedValue) {
      setSpeedValue(value);
      handleChangeSliderHelper(value);
      compiler?.getIframe()?.focus();
    }
  };

  return (
    <div className={styles.slider__content}>
      <Slider
        sx={skipNextStyles.volume}
        className="slider"
        aria-label="Volume"
        step={5}
        value={speedValue}
        onChange={handleChangeSlider}
        disabled={sliderDisabled}
      />
    </div>
  );
}
