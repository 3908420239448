import { clsx } from 'clsx';
import { CustomButtonProps } from './types';
import styles from './custom-button.module.css';

export default function CustomButton({
  className,
  children,
  variant = 'filled',
  disabled = false,
  reference,
  ...props
}: CustomButtonProps) {
  const variantClassName =
    variant === 'filled' ? styles['custom-button--filled'] : styles['custom-button--outlined'];
  const customButtonClassNames = clsx(
    styles['custom-button__content'],
    variantClassName,
    disabled && styles['custom-button--disabled'],
    className,
  );

  return (
    <button className={customButtonClassNames} ref={reference} type="button" {...props}>
      {children}
    </button>
  );
}
