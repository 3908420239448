/* eslint-disable no-loops/no-loops */
import { getAuth, updateEmail } from 'firebase/auth';
import {
  getDatabase,
  query,
  ref,
  get,
  orderByChild,
  equalTo,
  limitToFirst,
  remove,
  update,
} from 'firebase/database';

import getItemArray from '../getItemArray';
import { User } from 'types/UserTypes';
import { Room } from 'types/RoomTypes';
import { UserCreationForm } from 'schemas/adminForms.schema';
import { UserType as Role, UserType } from 'types/enums';

async function editUser(user: UserCreationForm & { uid: string }) {
  const deletes: Record<string, null> = {};
  const updates: Record<string, unknown> = {};
  // eslint-disable-next-line require-unicode-regexp
  const rooms = user.rooms.trim() === '' ? [] : user.rooms.split(/[\s,]+/);

  const member = () => ({
    isActive: false,
    idActiveQuestion: '',
    uid: user.uid,
  });

  const userRoom = (room: string) => ({
    id: room,
  });

  function assignUser(rooms: string[], i: number) {
    updates[`members/${rooms[i]}/${user.uid}`] = member();
    updates[`users_rooms/${user.uid}/${rooms[i]}`] = userRoom(rooms[i]);
  }

  function assignUserToRooms(rooms: string[]) {
    for (let i = 0; i < rooms.length; i += 1) {
      assignUser(rooms, i);
    }
  }

  function deleteRoomFromUser(allRooms: Room[]) {
    for (let i = 0; i < allRooms.length; i += 1) {
      deletes[`sessions_data/${allRooms[i].id}/${user.uid}`] = null;
      deletes[`members/${allRooms[i].id}/${user.uid}`] = null;
    }
  }

  function deleteUserFromRooms(allRooms: Room[]) {
    deleteRoomFromUser(allRooms);
    deletes[`users_rooms/${user.uid}`] = null;
  }

  const editedUser = () => {
    const _user: User = {
      name: user.name,
      lastName: user.surname,
      email: user.email,
      role: user.role,
      uid: user.uid,
      userType: user.userType,
    };
    if (user.role === UserType.Student) {
      // only for student
      _user.age = user.age;
      _user.parentEmail = user.parentEmail;
      _user.gender = user.gender;
      _user.phoneNumber = user.phoneNumber;
    }

    return _user;
  };

  async function editFirebase(rooms: string[], allRooms: Room[]) {
    const db = getDatabase();
    updates[`users/${user.uid}`] = editedUser();
    await deleteUserFromRooms(allRooms);
    await assignUserToRooms(rooms);
    await update(ref(db), deletes);
    await update(ref(db), updates);
  }

  function incorrectRooms(rooms: string[], result: Room[]) {
    let roomInFirestore;
    const incorrectRoomsList = [];
    for (let i = 0; i < rooms.length; i += 1) {
      roomInFirestore = false;
      for (let j = 0; j < result.length; j += 1) {
        if (rooms[i] === result[j].id) {
          roomInFirestore = true;
          break;
        }
      }
      if (!roomInFirestore) {
        incorrectRoomsList.push(rooms[i]);
      }
    }
    return incorrectRoomsList;
  }

  function checkRooms() {
    const db = getDatabase();
    const que = query(ref(db, 'rooms'));
    return get(que)
      .then(snapshot => getItemArray(snapshot))
      .then(result => {
        const incorrectRoomsList = incorrectRooms(rooms, result);
        if (incorrectRoomsList.length > 0)
          throw Error(`Wrong room IDs!\n${incorrectRoomsList.join('\n')}`);
        return result;
      });
  }

  async function isEmailInUse() {
    const db = getDatabase();
    const que = query(ref(db, 'users'), orderByChild('email'), equalTo(user.email));
    await get(que).then(snapshot => {
      if (snapshot.exists()) {
        throw Error('This email is already in use');
      }
    });
  }

  function didEmailChange() {
    const db = getDatabase();
    const que = query(ref(db, 'users'), orderByChild('uid'), equalTo(user.uid), limitToFirst(1));
    return get(que)
      .then(snapshot => getItemArray(snapshot)[0])
      .then(result => result.email !== user.email);
  }

  await didEmailChange()
    .then(changed => {
      if (!changed) {
        return checkRooms();
      }
      return isEmailInUse().then(() => checkRooms());
    })
    .then(async result => {
      await editFirebase(rooms, result);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
}

export default editUser;
