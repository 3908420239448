import { z } from 'zod';
import { getType } from '../helpers/FilesHelper';
export { FileType, FileAccess } from './enums';
import {
  courseSchema,
  fileDataTypeSchema,
  fileEventSchema,
  fileSchema,
  FileTypeEnum,
} from '../schemas/file.schema';

export class FileData {
  id: string;
  type: string;
  userId: string;
  title: string | undefined;

  constructor() {
    this.id = '';
    this.type = '';
    this.userId = '';
  }

  setFileFromObj(data: FileDataType) {
    this.id = data.id;
    this.type = data.type;
    this.userId = data.userId;
  }

  getFileType() {
    return FileTypeEnum.parse(getType(this.type));
  }
}

export type File = z.infer<typeof fileSchema>;
export type FileDataType = z.infer<typeof fileDataTypeSchema>;
export type Course = z.infer<typeof courseSchema>;
export type FileEvent = z.infer<typeof fileEventSchema>;
