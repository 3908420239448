import { PopoverOrigin } from '@mui/material';
import { locales } from 'helpers/i18n';

export const languages = [
  { locale: 'en', value: locales.en },
  { locale: 'es', value: locales.es },
  { locale: 'pl', value: locales.pl },
  { locale: 'pt', value: locales.pt },
];

export const languagesMenuAnchorOrigin: PopoverOrigin[] = [
  {
    vertical: 'bottom',
    horizontal: 'left',
  },
];

export const languagesMenuTransformOrigin: PopoverOrigin[] = [
  {
    vertical: 'top',
    horizontal: 'left',
  },
];
