import { darkViolet } from 'constants/Colors';
import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/material/styles';

const StyledRadio = styled(Radio)({
  '&.Mui-checked': {
    color: darkViolet,
  },
  '& .MuiSvgIcon-root': {
    fontSize: 32,
  },
});

export default function RadioButton(props: RadioProps) {
  return <StyledRadio {...props} />;
}
