import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { Submit } from './hooks';
import { ModalFooterProps } from './interface';
import { Trans } from '@lingui/macro';
import { Button, DialogActions } from '@mui/material';

export default function ModalFooter(props: ModalFooterProps) {
  const { canChangeLesson, isTutorActive, selectedItem, handleClose } = props;
  const { fatal } = useErrorContext();
  const { setLocalLessonId } = useWorkspaceContext();
  const { roomId } = useParams();

  const submit = Submit({
    canChangeLesson,
    isTutorActive,
    roomId,
    selectedItem,
    handleClose,
    setLocalLessonId,
  });

  const handleClick = useCallback(async () => {
    try {
      await submit();
    } catch (e) {
      fatal(e);
    }
  }, [fatal, submit]);

  return (
    <DialogActions>
      <Button autoFocus onClick={handleClose}>
        <Trans id="actions.cancel">cancel</Trans>
      </Button>
      <Button data-testid="content-select-btn" onClick={handleClick} autoFocus>
        <Trans id="actions.select">select</Trans>
      </Button>
    </DialogActions>
  );
}
