import { DocumentData, DocumentReference, getDoc, getFirestore } from 'firebase/firestore';
import { app } from './app';

export const db = getFirestore(app);

export const getDocumentReference = async (
  document: unknown,
): Promise<DocumentData | undefined> => {
  try {
    const rawDocument = document as DocumentReference;
    const snapshot = await getDoc(rawDocument);
    return snapshot.data();
  } catch (e) {
    throw e;
  }
};

export const getCollectionReference = async (collection: unknown) => {
  const rawCollection = collection as DocumentReference[];
  const snapshot = await rawCollection.reduce(
    async (_data: Promise<DocumentData[]>, reference: DocumentReference) => {
      const data = await _data;
      const documentSnapshot = await getDoc(reference);
      const documentData = documentSnapshot.data();
      documentData && data.push(documentData);
      return data;
    },
    Promise.resolve([]),
  );
  return snapshot;
};
