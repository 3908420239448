import { WordCompleterListType } from 'types/WordCompleterListTypes';

const mathWordList = [
  'math',
  'math.cos',
  'math.sin',
  'math.pi',
  'math.pow',
  'math.sqrt',
  'random',
  'random.randint',
];

const pythonWordList = [
  'Color',
  'Sprite',
  'Vector',

  'arrow',
  'game',
  'game.add',
  'game.background',
  'game.key',
  'game.mouse',
  'game.mouse.position',
  'game.mouse.pressed',
  'game.play',
  'game.remove',
  'game.stop',
  'message',
  'message.show',
  'sound',
  'sound.play',

  'angle',
  'collide',
  'color',
  'image',
  'move',
  'pen',
  'pen.color',
  'pen.on',
  'pen.size',
  'position',
  'size',
  'text',

  'update',
  '__init__',
];

const getWordList = (listType: number): string[] => {
  switch (listType) {
    case WordCompleterListType.MATH:
      return mathWordList;
    case WordCompleterListType.PIXBLOCKS_PYTHON:
      return pythonWordList;
    default:
      return [];
  }
};

export const getCompleter = (listType: number, meta: string = 'keyword') => {
  const wordList: string[] = getWordList(listType);
  return {
    getCompletions(
      _editor: unknown,
      _session: unknown,
      _pos: unknown,
      _prefix: unknown,
      callback: any,
    ) {
      return callback(
        null,
        wordList.map(word => ({
          caption: word,
          value: word,
          meta,
        })),
      );
    },
  };
};
