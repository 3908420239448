import { Dispatch } from 'react';
import { purple, white } from 'constants/Colors';
import { enableOpacity } from 'constants/Opacities';
import { useAppContext } from 'providers/app/app.provider';
import { MemberData } from 'types/MemberTypes';
import { UserType } from 'types/UserTypes';
import styles from './userLabel.module.css';
import MilitaryTechSharpIcon from '@mui/icons-material/MilitaryTechSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { isTutor } from 'helpers/userHelper';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';

interface UserLabelProps {
  fileBroswerDisabled: boolean;
  user: MemberData;
  setBadgeUser: Dispatch<MemberData | null>;
  selected: boolean;
}

export function UserLabel(props: UserLabelProps) {
  const { currentUser } = useAppContext();
  const { activeTutor } = useWorkspaceContext();
  const { fileBroswerDisabled, user, setBadgeUser, selected } = props;

  function handleBadgeClick() {
    setBadgeUser(user);
  }

  const color = {
    userColor: { backgroundColor: user.color },
    visibilityIcon: { color: purple },
    badge: {
      color: white,
      opacity: enableOpacity,
      '&:hover': {
        color: purple,
        opacity: 1,
      },
    },
  };

  const icon =
    isTutor(user.role) && user.uid === activeTutor && fileBroswerDisabled ? (
      <VisibilityIcon data-testid="student-leader-mode-icon" sx={color.visibilityIcon} />
    ) : currentUser?.role === UserType.Tutor && user.uid !== currentUser.uid && selected ? (
      <MilitaryTechSharpIcon sx={color.badge} onClick={handleBadgeClick} />
    ) : null;

  return (
    <div className={styles['user-label__content']}>
      <div className={styles['user-label__tab-circle']} style={color.userColor} />
      <div className={styles['user-label__user_name']}>{`${user.name} ${user.lastName}`}</div>
      {icon}
    </div>
  );
}
