import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/app/app.provider';
import { useForm } from 'react-hook-form';
import { EmailFormData, emailLoginSchema } from 'schemas/login.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useErrorContext } from 'providers/error/error.provider';
import LoginModal from '../../components/LoginModal';
import { StyledLoadingButton, StyledBox, StyledHeader } from '../../helpers/styled';
import { sendResetPasswordLinkToEmail } from 'store/FirebaseStore';
import { unknownToError } from 'providers/error/error';
import { Trans } from '@lingui/macro';
import { getFirebaseError } from 'providers/error/firebase.error';
import { AUTH_USER_NOT_FOUND } from 'providers/error/const';
import { useLocaleContext } from 'providers/i18n/i18n.provider';

export default function ForgotPasswordView() {
  const { bug } = useErrorContext();
  const { i18n } = useLocaleContext();
  const navigate = useNavigate();
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<EmailFormData>({
    resolver: zodResolver(emailLoginSchema),
  });

  async function submit(data: EmailFormData) {
    try {
      if (isEmailSent) {
        navigate('/auth/login/tutor');
      }
      await sendResetPasswordLinkToEmail(data.email);
      setIsEmailSent(true);
    } catch (error) {
      const [code, message] = getFirebaseError(i18n, error);
      if (code === AUTH_USER_NOT_FOUND) {
        setError(
          'email',
          {
            message,
          },
          {
            shouldFocus: true,
          },
        );
      } else {
        bug(error);
      }
    }
  }

  return (
    <LoginModal>
      {isEmailSent && (
        <StyledBox>
          <StyledHeader variant="h6" align="center">
            <Trans id="forgotPassword.info.message">
              Password has been reset, follow the instructions in the email
            </Trans>
          </StyledHeader>
        </StyledBox>
      )}
      <form onSubmit={handleSubmit(submit)}>
        {!isEmailSent && (
          <TextField
            margin="dense"
            id="id-send-email"
            type="email"
            variant="filled"
            label={<Trans id="input.email">E-mail address</Trans>}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : ''}
            size="medium"
            disabled={isSubmitting ? true : false}
            {...register('email')}
            fullWidth
          />
        )}

        <Box width="100%">
          <Stack>
            <StyledLoadingButton
              loading={isSubmitting ? true : false}
              type="submit"
              variant="contained"
            >
              {isEmailSent ? (
                <Trans id="label.backToLogin">Back to login page</Trans>
              ) : (
                <Trans id="label.sendEmail">Send E-mail</Trans>
              )}
            </StyledLoadingButton>
          </Stack>
        </Box>
      </form>
    </LoginModal>
  );
}
