import { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { getI18nInstance } from 'helpers/i18n';
import { AppProvider } from './providers/app/app.provider';
import { ErrorProvider } from './providers/error/error.provider';
import { I18nProviderWrapper } from './providers/i18n/i18n.provider';
import AppRoutes from './AppRoutes';
import ScrollToTop from './helpers/ScrollToTop';
import setup from './__setup__/setup.app';
import { WorkspaceProvider } from 'providers/workspace/workspace.provider';

export default function App() {
  const locale = I18nProviderWrapper.validateLanguage(
    document.getElementsByTagName('html')[0].getAttribute('lang'),
  );

  const i18n = getI18nInstance(setup, locale);

  return (
    <I18nProviderWrapper i18n={i18n}>
      <ErrorProvider error={null}>
        <HelmetProvider>
          <AppProvider>
            <WorkspaceProvider>
              <ScrollToTop />
              <AppRoutes />
            </WorkspaceProvider>
          </AppProvider>
        </HelmetProvider>
      </ErrorProvider>
    </I18nProviderWrapper>
  );
}
