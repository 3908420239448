import { clsx } from 'clsx';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import iconStyles from 'shared/styles/icon.module.css';
import styles from './fileExplorerTopBar.module.css';

export default function FileExplorerTopBar() {
  const { collapsedSidebar, toggleSidebar } = useCurrentRoomContext();

  const topBarClassNames = clsx(
    styles['file-explorer-top-bar__content'],
    collapsedSidebar && styles['file-explorer-top-bar--collapsed'],
  );

  return (
    <div className={topBarClassNames}>
      <IconButton onClick={toggleSidebar} className={iconStyles.icon}>
        {collapsedSidebar ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
    </div>
  );
}
