import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ContentProps } from './interface';

export default function Content(props: ContentProps) {
  const { field, id, label, selectOptions } = props;
  return (
    <FormControl>
      <InputLabel id={id}>{label}</InputLabel>
      <Select labelId={id} label={label} {...field}>
        {selectOptions.map(selectOption => (
          <MenuItem key={selectOption.value} value={selectOption.value}>
            {selectOption.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
