import { setVariablesTextareaContent } from 'helpers/StringParseHelper';
import { sleep } from 'helpers/TimeHelper';
import { isStudent } from 'helpers/userHelper';
import {
  errorMessageSchema,
  endLineMessageSchema,
  responseMessageSchema,
  inputMessageSchema,
  outputMessageSchema,
} from 'schemas/compiler.schema';
import {
  BoardCompilerStatus,
  EndLineMessage,
  ErrorMessage,
  InputMessage,
  OutputMessage,
  ResponseMessage,
  ConsolePythonResultType,
} from 'types/CompilerTypes';
import { Question } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';
import { CurrentUser } from 'types/UserTypes';
import log from 'log';
import LeaderMode from 'types/LeaderMode';

interface IResponse {
  TYPE: string;
  LINE?: number;
  MESSAGE?: string;
  OUTPUT?: string;
  IS_END_LINE?: boolean;
  RUN_LINE?: number;
  TICK?: number;
  VARIABLES?: string;
}

export default class Python {
  private status: number = BoardCompilerStatus.STOP_MODE;
  private started: boolean = false;
  private currentUser: CurrentUser | null = null;
  private leaderMode: LeaderMode | null = null;
  private iframeLoaded: boolean = false;
  private iframe: HTMLIFrameElement | null = null;
  private question: Question | null = null;

  public onErrorChange?: (error: ErrorMessage) => void;
  public onInputChange?: (value: InputMessage) => void;
  public onOutputChange?: (value: string) => void;
  public onResponse?: (value: ResponseMessage) => void;
  public onSolutionCodeChecked?: (value: ConsolePythonResultType) => void;
  public onStatusChange?: (value: number) => void;
  public onVariablesChange?: (value: string) => void;

  private highlightedLine: number = -1;
  private outputValue: string = '';
  private solutionOutput: string = '';
  private variables: string = '';
  private error = {
    line: -1,
    message: '',
  };
  private inputValue: string = '';

  constructor(currentUser: CurrentUser | null, leaderMode: LeaderMode) {
    if (!this.started) {
      this.startListeningEvents();
      this.currentUser = currentUser;
      this.leaderMode = leaderMode;
      this.started = true;
    }
  }

  // GETTERS
  public getStatus() {
    return this.status;
  }

  public getIframe() {
    return this.iframe;
  }

  public getQuestion() {
    return this.question;
  }

  // SETTERS
  public setIframe(iframe: HTMLIFrameElement) {
    this.iframe = iframe;
  }

  public setQuestion(question: Question) {
    this.resetOutputs();
    this.question = question;
  }

  private setHighlightedLine(value: number) {
    this.highlightedLine = value;
  }
  private setOutput(value: string) {
    this.outputValue = value;
  }
  private setVariables(value: string) {
    this.variables = value;
    this.onVariablesChange && this.onVariablesChange(value);
  }
  private setStatus(value: number) {
    this.status = value;
    this.onStatusChange && this.onStatusChange(value);
  }
  private setError(line: number, message: string) {
    this.error.line = line;
    this.error.message = message;
  }
  private setInput(value: string) {
    this.inputValue = value;
  }

  // PRIVATE METHODS
  private resetOutputs() {
    this.setHighlightedLine(-1);
    this.setError(-1, '');
    this.setOutput('');
    this.setVariables('');
    this.setInput('');
  }

  private async sendPostMessage(object: unknown): Promise<null> {
    if (!this.iframeLoaded || !this.iframe?.contentWindow) {
      await new Promise(r => {
        setTimeout(r, 100);
      });
      return this.sendPostMessage(object);
    }
    this.iframe.contentWindow.postMessage(JSON.stringify(object), '*');
    return null;
  }

  // PUBLIC METHODS
  public setIframeFocus() {
    this.iframe?.contentWindow?.focus();
  }

  public setQuestionInput(input: string) {
    this.question && (this.question.input = input);
  }

  public didQuestionChange(question: Question): boolean {
    return this.question?.guid !== question?.guid;
  }

  public async debugStep(code: string, resetInput: boolean = true): Promise<null> {
    switch (this.status) {
      case BoardCompilerStatus.RESULT:
        await sleep(1);
        return this.debugStep(code, false);
      case BoardCompilerStatus.STOP_MODE:
        this.setStatus(BoardCompilerStatus.DEBUG_STEP);
        this.debugCodeStep(code, resetInput);
        return null;
      case BoardCompilerStatus.DEBUG:
      case BoardCompilerStatus.DEBUG_STEP:
        this.setStatus(BoardCompilerStatus.DEBUG_STEP);
        this.debugNextStep();
        return null;
      default:
        return null;
    }
  }

  public async debug(code: string, speed: number, resetInput: boolean = true): Promise<null> {
    switch (this.status) {
      case BoardCompilerStatus.RESULT:
        await sleep(1);
        return this.debug(code, speed, false);
      case BoardCompilerStatus.STOP_MODE:
        this.setStatus(BoardCompilerStatus.DEBUG);
        this.debugCode(code, speed, resetInput);
        return null;
      case BoardCompilerStatus.DEBUG:
        return null;
      case BoardCompilerStatus.DEBUG_STEP:
        this.setStatus(BoardCompilerStatus.DEBUG);
        this.debugCodeContinue(speed);
        return null;
      default:
        return null;
    }
  }

  // LISTENER
  private startListeningEvents() {
    setTimeout(() => {
      const onIframeLoad = () => {
        this.iframeLoaded = true;
      };
      const responseError = (response: ErrorMessage) => {
        this.setError(response.ERROR_LINE, response.ERROR_MESSAGE);
        this.onErrorChange && this.onErrorChange(response);
      };
      const responseCompiler = (response: ResponseMessage) => {
        this.setHighlightedLine(response.RUN_LINE);
        this.setOutput(this.outputValue + response.OUTPUT);
        this.setVariables(
          response.VARIABLES ? setVariablesTextareaContent(JSON.parse(response.VARIABLES)) : '',
        );
        this.onResponse && this.onResponse(response);
      };
      const responseEndLine = (response: EndLineMessage) => {
        this.setStatus(BoardCompilerStatus.END_LINE);
        responseCompiler(responseMessageSchema.parse(response));
        const isStudentOnLeaderMode =
          isStudent(this.currentUser?.role) && this.leaderMode?.isLeaderModeOn;
        if (
          this.question?.type === FileType.Task &&
          this.onSolutionCodeChecked &&
          !isStudentOnLeaderMode
        ) {
          this.onSolutionCodeChecked({
            isResultCorrect: response.TASK_IS_CORRECT,
            errorList: response.ERROR_LIST,
          });
        }
      };
      const responseInput = (response: InputMessage) => {
        if (response.INPUT) {
          this.setInput(response.INPUT);
          this.onInputChange && this.onInputChange(response);
        }
      };
      const responseOutput = (response: OutputMessage) => {
        if (response.MODE) {
          this.solutionOutput = response.OUTPUT;
          this.setStatus(BoardCompilerStatus.STOP_MODE);
          return;
        }
        this.setOutput(response.OUTPUT);
        this.onOutputChange && this.onOutputChange(response.OUTPUT);
      };

      try {
        window.onmessage = function (e) {
          try {
            if (e.data) {
              const response: IResponse = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
              switch (response.TYPE) {
                case 'IFRAME_LOADED':
                  onIframeLoad();
                  break;
                case 'ERROR':
                  responseError(
                    errorMessageSchema.parse({
                      ERROR_MESSAGE: response.MESSAGE,
                      ERROR_LINE: response.LINE,
                    }),
                  );
                  break;
                case 'COMPILER_RESPONSE':
                  responseCompiler(responseMessageSchema.parse(response));
                  break;
                case 'COMPILER_END_LINE_RESPONSE':
                  responseEndLine(endLineMessageSchema.parse(response));
                  break;
                case 'INPUT_RESPONSE':
                  responseInput(inputMessageSchema.parse({ INPUT: response.OUTPUT }));
                  break;
                case 'OUTPUT_RESPONSE':
                  responseOutput(outputMessageSchema.parse(response));
                  break;
                default:
                  return;
              }
            }
          } catch (excepcion) {
            log.error(excepcion);
          }
        };
      } catch (excepcion) {
        log.error(excepcion);
      }
    }, 100);
  }

  //MESSAGES
  public changeSpeed(value: number) {
    this.sendPostMessage({ type: 'DEBUG_SPEED', speed: value });
  }

  private debugNextStep() {
    this.sendPostMessage({ type: 'DEBUG_NEXT_STEP' });
    this.setIframeFocus();
  }

  private debugCodeStep(code: string, resetInput: boolean) {
    this.sendPostMessage({
      type: 'DEBUG_CODE_STEP',
      code,
      input: this.question?.input,
      resetInput,
      question: this.question,
    });
    this.setIframeFocus();
  }

  private debugCode(code: string, speed: number, resetInput: boolean) {
    this.sendPostMessage({
      type: 'DEBUG_CODE',
      code,
      input: this.question?.input,
      resetInput,
      speed,
      question: this.question,
    });
    this.setIframeFocus();
    this.iframe?.contentWindow?.document?.body?.focus();
  }

  private debugCodeContinue(speed: number) {
    this.sendPostMessage({
      type: 'DEBUG_CODE_CONTINUE',
      speed,
    });
  }

  public stop() {
    this.resetOutputs();
    this.setStatus(BoardCompilerStatus.STOP_MODE);
    this.sendPostMessage({
      type: 'DEBUG_STOP',
      question: this.question,
    });
  }

  public showSolution(question: Question, typeOutput: boolean) {
    this.setStatus(typeOutput ? BoardCompilerStatus.SOLUTION : BoardCompilerStatus.RESULT);
    this.sendPostMessage({
      type: 'SHOW_SOLUTION',
      question,
      typeOutput,
    });
  }

  public showSolutionInLeaderMode(code: string, typeOutput: boolean) {
    this.setStatus(typeOutput ? BoardCompilerStatus.SOLUTION : BoardCompilerStatus.RESULT);
    this.sendPostMessage({
      type: 'SHOW_SOLUTION_IN_LEADER_MODE',
      code,
      typeOutput,
    });
  }

  public resetSolution() {
    this.setStatus(BoardCompilerStatus.SOLUTION);
    this.sendPostMessage({
      type: 'RESET',
    });
  }

  public debugSolutionCode(
    code: string,
    question: Question,
    debug: boolean,
    graphicTask?: boolean,
  ) {
    this.sendPostMessage({
      type: 'DEBUG_SOLUTION_CODE',
      question,
      code,
      debug,
      graphicTask,
    });
  }

  public debugInputCode(code: string) {
    this.sendPostMessage({
      type: 'INPUT_CODE',
      code,
    });
  }

  public debugLeaderMode(code: string, input: string, steps: number) {
    this.sendPostMessage({
      type: 'DEBUG_LEADER_MODE',
      code,
      input,
      steps,
    });
  }
}
