class Logger {
  // eslint-disable-next-line class-methods-use-this
  info(...args: unknown[]) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }

  // eslint-disable-next-line class-methods-use-this
  error(...args: unknown[]) {
    // eslint-disable-next-line no-console
    console.error(...args);
  }

  // eslint-disable-next-line class-methods-use-this
  warn(...args: unknown[]) {
    // eslint-disable-next-line no-console
    console.warn(...args);
  }

  // eslint-disable-next-line class-methods-use-this
  table(...args: unknown[]) {
    // eslint-disable-next-line no-console
    console.table(...args);
  }

  // eslint-disable-next-line class-methods-use-this
  dir(...args: unknown[]) {
    // eslint-disable-next-line no-console
    console.dir(...args);
  }
}

export default new Logger();
