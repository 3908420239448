import { useEffect } from 'react';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { ChangeUserFiles, ChangeFile } from 'types/CustomEvents';
import { fileEventSchema } from 'schemas/file.schema';
import { IUseFileEvents } from './interfaces';

/**
 *
 * @param getAceInstance ace instance from reference current
 * @param removehighlightLine merhods from "useIDEInterface" hook
 * @param callback custom callback which you can define outside this hook (f.e. to update some state)
 */
export function useFileEvents(data: IUseFileEvents) {
  const { updateCurrentFile, getAceInstance, removehighlightLine } = data;
  const { setCurrentUserId } = useWorkspaceContext();

  useEffect(() => {
    const currentAce = getAceInstance();
    const handleChangeFileEvent = (event: CustomEvent<ChangeFile>) => {
      try {
        currentAce?.setReadOnly(true);
        const file = fileEventSchema.parse(event.detail);
        updateCurrentFile(file);

        removehighlightLine();
      } catch (e) {
        throw e;
      }
    };

    const handleUserFilesChangeEvent = (event: CustomEvent<ChangeUserFiles>) => {
      const id = event.detail.userUID;
      setCurrentUserId(id);
    };
    window.addEventListener('changeFile', handleChangeFileEvent);

    window.addEventListener('changeUserFiles', handleUserFilesChangeEvent);

    currentAce?.addCompleters();
    return () => {
      window.removeEventListener('changeFile', handleChangeFileEvent);

      window.removeEventListener('changeUserFiles', handleUserFilesChangeEvent);
    };
  }, [updateCurrentFile, getAceInstance, removehighlightLine, setCurrentUserId]);
}
