import { z } from 'zod';
import { userSchema } from '../schemas/user.schema';

export const memberSchema = z.object({
  uid: z.string(),
});

export const sessionMemberSchema = z.object({
  isActive: z.boolean(),
  idActiveQuestion: z.string().optional(),
});

export const memberSchemaWithID = userSchema.extend({
  id: z.string().optional(),
  color: z.string(),
});

export const memberBadgesSchema = userSchema.extend({
  badges: z.object({
    creative: z.number(),
    debugger: z.number(),
    lightning: z.number(),
    perfect: z.number(),
  }),
});

export const sessionMemberWithUIDSchema = memberSchema.merge(sessionMemberSchema);

export const membersSchema = z.array(memberSchemaWithID);

export const memberDataSchema = memberSchemaWithID.merge(userSchema);
