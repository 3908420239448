import { useCallback, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

interface IChatMessageLog {
  sendNewMessage: (text: string) => void;
  loading: boolean;
}

export default function InputText(props: IChatMessageLog) {
  const { sendNewMessage, loading } = props;
  const [message, setMessage] = useState<string>('');

  const send = useCallback(() => {
    sendNewMessage(message);
  }, [message, sendNewMessage]);
  const boxStyle = useMemo(
    () => ({
      width: '100% - 30px',
      margin: '30px',
    }),
    [],
  );
  const inputStyle = useMemo(() => ({ width: '100%', backgroundColor: '#2E2B36' }), []);
  const iconStyles = useMemo(() => ({ color: '#895AFD' }), []);
  const inputProps = useMemo(
    () => ({
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip title="Wyślij">
            <IconButton aria-label="send" onClick={send} disabled={loading}>
              <SendIcon sx={iconStyles} />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      ),
    }),
    [iconStyles, send, loading],
  );

  const onValueChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  }, []);

  const keyPress = useCallback(
    (e: any) => {
      if (e.key === 'Enter' && !loading) {
        e.preventDefault();
        sendNewMessage(message);
        setMessage('');
      }
    },
    [sendNewMessage, message, loading],
  );

  return (
    <Box sx={boxStyle}>
      <TextField
        sx={inputStyle}
        value={message}
        fullWidth
        onChange={onValueChange}
        label="Wyślij wiadomość"
        id="fullWidth"
        multiline
        onKeyDown={keyPress}
        maxRows={5}
        InputProps={inputProps}
      />
    </Box>
  );
}
