import { useCallback, useEffect, useState } from 'react';
import { useErrorContext } from 'providers/error/error.provider';
import { ISearch } from 'types/AdminTypes';
import { User } from 'types/UserTypes';
import { TableFilters } from '../../../../sections/@dashboard/components';
import { ROWS, userFilters } from './constants';
import { FilterUsers, GetUsers } from './hooks/effects';
import { Card, TablePagination } from '@mui/material';
import TableContent from './TableContent';

export default function UserTable() {
  const { bug } = useErrorContext();
  const [userList, setUserList] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isOrderReversed, setIsOrderReversed] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [orderBy, setOrderBy] = useState<keyof User>('uid');
  const [searchName, setSearchName] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [activeFilters, setActiveFilters] = useState<ISearch>();

  GetUsers({ bug, setUserList });
  FilterUsers({
    activeFilters,
    isOrderReversed,
    orderBy,
    page,
    rowsPerPage,
    searchName,
    userList,
    setFilteredUsers,
  });
  useEffect(() => setPage(0), [activeFilters]);

  const clearFilters = useCallback(() => {
    setActiveFilters({
      role: '',
    });
  }, []);

  const handleChangePage = useCallback((_: unknown, newPage: number) => setPage(newPage), []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleSearchByName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value),
    [],
  );

  return (
    <Card className="qwerty">
      <TableFilters
        numSelected={selectedUsers.length}
        searchName={searchName}
        onSearchName={handleSearchByName}
        filters={userFilters}
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        clearFilters={clearFilters}
      />
      <TableContent
        users={filteredUsers}
        isOrderReversed={isOrderReversed}
        orderBy={orderBy}
        searchName={searchName}
        selectedUsers={selectedUsers}
        setIsOrderReversed={setIsOrderReversed}
        setOrderBy={setOrderBy}
        setSelectedUsers={setSelectedUsers}
      />
      <TablePagination
        rowsPerPageOptions={ROWS}
        component="div"
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}
