import { Dispatch, useCallback, useEffect, useState } from 'react';
import { sleep } from 'helpers/TimeHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { giveBadge } from 'store/chunks/realtime/badges';
import { getActiveBadges } from 'store/FirebaseStore';
import { IActiveBadges } from 'types/BadgeTypes';
import { BadgeType } from 'types/enums';
import { MemberData } from 'types/MemberTypes';
import { modalStatus } from '../constants';
import { areThereActiveBadges, shouldBadgeBeUnset } from './helpers';
import styles from './tutorBadges.module.css';
import TutorBadge from './TutorBadge/TutorBadge';

interface TutorBadgesProps {
  status: number;
  user: MemberData | null;
  setStatus: Dispatch<number>;
}

export default function TutorBadges(props: TutorBadgesProps) {
  const { status, user, setStatus } = props;
  const { currentUser } = useAppContext();
  const { fatal } = useErrorContext();
  const { localLessonId, room } = useWorkspaceContext();
  const [selectedBadge, setSelectedBadge] = useState<BadgeType | ''>();
  const [activeBadges, setActiveBadges] = useState<IActiveBadges>();
  const noBadgesGranted = !areThereActiveBadges(activeBadges);

  useEffect(() => {
    const run = async () => {
      try {
        if (shouldBadgeBeUnset(status, selectedBadge, user, room, localLessonId, currentUser)) {
          room && user && setActiveBadges(await getActiveBadges(localLessonId, room, user));
          await sleep(250);
          setSelectedBadge('');
        }
      } catch (e) {
        fatal(e);
      }
    };
    run();
  }, [fatal, currentUser, localLessonId, room, status, selectedBadge, user]);

  useEffect(() => {
    const run = async () => {
      if (selectedBadge && room && user) {
        await giveBadge(localLessonId, currentUser, selectedBadge, room, user);
        setStatus(modalStatus.GRANTED);
      }
    };
    run();
  }, [localLessonId, currentUser, selectedBadge, room, user, setStatus]);

  useEffect(() => {
    const run = async () => {
      if (user && room && localLessonId) {
        setActiveBadges(await getActiveBadges(localLessonId, room, user));
      }
    };
    run();
  }, [localLessonId, room, user]);

  const getBadgeStyles = useCallback(
    (badgeName: BadgeType) => ({
      selected: selectedBadge === badgeName,
      noBadgesGranted,
      active: Boolean(activeBadges && activeBadges[badgeName]),
    }),
    [activeBadges, noBadgesGranted, selectedBadge],
  );

  return (
    <div className={styles['tutor-badges__content']}>
      {Object.entries(BadgeType).map(([_, badgeType]) => (
        <TutorBadge
          key={badgeType}
          badge={badgeType}
          status={status}
          styles={getBadgeStyles(badgeType)}
          setSelectedBadge={setSelectedBadge}
        />
      ))}
    </div>
  );
}
