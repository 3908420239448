import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { shouldILoadDefaultFont } from './helpers';
import { WordCompleterListType } from 'types/WordCompleterListTypes';
import { getCompleter } from 'constants/wordCompleter';
import { IEditor } from 'types/EditorTypes';
import { FileType, JsPackage, ProgrammingLanguage } from 'types/enums';
import AceEditor from 'react-ace';
import styles from './editor.module.css';
import CustomHtmlJsCssMode from './modes/mode-custom-htmlJsCss';
import CustomJsMode from './modes/mode-custom-js.js';
import CustomPythonMode from './modes/mode-custom-python.js';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/theme-terminal';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';

interface EditorProps {
  isCodeMode: boolean;
  isLanguageBlocks: boolean;
  questionType: FileType;
  height: string;
  mode: string;
  jsLibrary?: JsPackage;
  onTaskEdit: () => void;
}

const Editor = forwardRef<IEditor, EditorProps>((props, ref) => {
  const { height, isCodeMode, isLanguageBlocks, jsLibrary, mode, questionType, onTaskEdit } = props;
  const [error, setError] = useState('');
  const [lastErrorMarker, setLastErrorMarker] = useState(-1);
  const [lastMarker, setLastMarker] = useState(-1);
  const aceEditorRef = useRef() as React.MutableRefObject<AceEditor>;
  const aceWrapperRef = useRef(null) as React.MutableRefObject<HTMLDivElement | null>;
  const fontSize = isLanguageBlocks ? 40 : 20;
  const fontFamily = !isLanguageBlocks
    ? 'Source Code Pro'
    : shouldILoadDefaultFont()
    ? 'pixBlocksDefault'
    : 'pixBlocksMozillaSafari';
  let lastLine = 0;

  const highlightErrorLine = (line: number, message: string) => {
    lastErrorMarker !== -1 && aceEditorRef.current.editor.session.removeMarker(lastErrorMarker);
    lastMarker !== -1 && aceEditorRef.current.editor.session.removeMarker(lastMarker);

    const { Range } = ace.require('ace/range');
    const marker = aceEditorRef.current.editor.session.addMarker(
      new Range(line - 1, 0, line - 1, 1),
      styles['editor__error-marker'],
      'fullLine',
    );
    setLastErrorMarker(marker);
    setError(`Line ${line}: ${message}`);

    const markers = {
      lastErrorMarker: marker,
      lastMarker,
      lastLine,
    };
    sessionStorage.setItem('markers', JSON.stringify(markers));
  };

  const highlightActiveBreakpointLine = (line: number) => {
    removehighlightLine();
    const { Range } = ace.require('ace/range');
    const marker = aceEditorRef.current.editor.session.addMarker(
      new Range(line, 0, line, 1),
      styles['editor__my-marker'],
      'fullLine',
    );
    setLastMarker(marker);
    lastLine = marker;

    const markers = {
      lastErrorMarker,
      lastMarker: marker,
      lastLine: marker,
    };
    sessionStorage.setItem('markers', JSON.stringify(markers));
  };

  const removehighlightLine = () => {
    const markers = getMarkers();
    aceEditorRef.current.editor.session.removeMarker(markers.lastLine);
    markers.lastLine = -1;
    aceEditorRef.current.editor.session.removeMarker(markers.lastMarker);
    markers.lastMarker = -1;
    aceEditorRef.current.editor.session.removeMarker(markers.lastErrorMarker);
    markers.lastErrorMarker = 0;
    setError('');
    sessionStorage.setItem('markers', JSON.stringify(markers));
  };

  const getMarkers = () => {
    const markersString = sessionStorage.getItem('markers');
    return !markersString
      ? { lastErrorMarker: -1, lastMarker: -1, lastLine: 0 }
      : JSON.parse(markersString);
  };

  useImperativeHandle(ref, () => ({
    getAceEditor() {
      return aceEditorRef.current.editor;
    },
    getAceWrapperRef(): React.MutableRefObject<HTMLDivElement | null> {
      return aceWrapperRef;
    },
    removehighlightLine() {
      removehighlightLine();
    },
    highlightActiveBreakpointLine(line: number) {
      highlightActiveBreakpointLine(line);
    },
    highlightErrorLine(line: number, message: string) {
      highlightErrorLine(line, message);
    },
    setReadOnly(isReadOnly: boolean) {
      aceEditorRef.current.editor.setReadOnly(isReadOnly);
    },
    getValue(): string {
      return aceEditorRef.current.editor.getValue();
    },
    setValue(code: string) {
      aceEditorRef.current.editor.setValue(code);
    },
    addCompleters() {
      const { completers } = aceEditorRef.current.editor;
      if (completers.length <= 3) {
        completers.push(getCompleter(WordCompleterListType.MATH, 'math'));
        completers.push(getCompleter(WordCompleterListType.PIXBLOCKS_PYTHON, 'pixblocks'));
      }
    },
  }));

  const defaultMode = useMemo(() => {
    switch (mode) {
      case ProgrammingLanguage.HtmlJsCss:
        if (jsLibrary) {
          return new CustomJsMode();
        }
        return new CustomHtmlJsCssMode();
      case ProgrammingLanguage.Blocks:
      case ProgrammingLanguage.Python:
        return new CustomPythonMode();
      default:
        return new CustomPythonMode();
    }
  }, [jsLibrary, mode]);

  const aceEditorOptions = useMemo(
    () => ({
      enableBasicAutocompletion: false,
      enableLiveAutocompletion: !isLanguageBlocks,
      enableSnippets: false,
      showLineNumbers: !isLanguageBlocks || isCodeMode,
      fontFamily,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fontFamily, isCodeMode, isLanguageBlocks],
  );

  useEffect(() => {
    if (aceEditorRef.current) {
      switch (mode as ProgrammingLanguage) {
        case ProgrammingLanguage.HtmlJsCss: {
          if (jsLibrary) {
            const customJsMode = new CustomJsMode();
            // @ts-ignore
            aceEditorRef.current.editor.session.setMode(customJsMode);
            break;
          }
          const customHtmlJsCss = new CustomHtmlJsCssMode();
          // @ts-ignore
          aceEditorRef.current.editor.session.setMode(customHtmlJsCss);
          break;
        }
        case ProgrammingLanguage.Python: {
          const customPythonMode = new CustomPythonMode();
          // @ts-ignore
          aceEditorRef.current.editor.session.setMode(customPythonMode);
          break;
        }
        case ProgrammingLanguage.Blocks: {
          break;
        }
        default: {
          aceEditorRef.current.editor.session.setMode(mode);
          break;
        }
      }
    }
  }, [jsLibrary, mode]);

  const handleChange = useCallback(
    (value: string) => {
      questionType === FileType.Task && value && onTaskEdit();
    },
    [questionType, onTaskEdit],
  );

  return (
    <div ref={aceWrapperRef}>
      <AceEditor
        mode={defaultMode}
        onChange={handleChange}
        data-testid="ace-editor"
        ref={aceEditorRef}
        theme="pastel_on_dark"
        height={error.length ? `calc(${height} - 147px)` : `calc(${height} - 87px)`}
        width="auto"
        name="code_editor_id"
        highlightActiveLine
        fontSize={fontSize}
        tabSize={4}
        setOptions={aceEditorOptions}
      />
      {error.length ? (
        <div className={styles.editor__errors}>
          <p className={styles.editor__error}> {error}</p>
        </div>
      ) : null}
    </div>
  );
});

export default React.memo(Editor);
