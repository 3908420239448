import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { t, Trans } from '@lingui/macro';
import { serverTimestamp } from 'firebase/firestore';
import Box from '@mui/material/Box';
import { white } from 'constants/Colors';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import { formFeedbackSchema } from 'schemas/form.schema';
import styles from './studentFeedbakModal.module.css';
import Question from './Question/Question';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { FormQuestions, FeedbackForm } from 'types/FormsTypes';
import { Button, Typography } from '@mui/material';
import { ref, onValue } from 'firebase/database';
import { sendForm, AppName, getAppDatabase } from 'store/FirebaseStore';
import { User, UserType } from 'types/UserTypes';
import { StudentFeedbackQuestions } from 'constants/FormQuestions';
import CustomModal from 'components/organisms/CustomModal';
import MatrixRadioButtonForm, {
  TValues,
  TLabels,
  TState,
} from 'components/templates/forms/MatrixRadioButtonForm';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import DisapperaingPopup from 'components/templates/popups/DisappearingPopup';
import { dictionary, generateFormData } from './helpers';
import Textarea from 'components/atoms/Textarea';

const muiStyles = {
  confirm: {
    cursor: 'pointer',
    display: 'flex',
    width: '200px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    transition: 'color 0.3s',
    fontWeight: 700,
    background: '#6a30fa',
    borderRadius: 0,
    border: `1px solid ${transformColorFromHexToRGBA(white, 0.7)}`,
    color: '#ffffff',
    padding: '6px 20px',
    marginTop: '24px',
  },
  confirmText: {
    textTransform: 'uppercase',
    fontFamily: 'Space Grotesk',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.46px',
  },
  header: {
    marginBottom: '16px',
    textAlign: 'center',
  },
  subheader: {
    marginBottom: '48px',
    textAlign: 'center',
  },
};

// --------------------------------------------------
export interface FileDescriptionModalProps {
  isShow: boolean;
  onClose: () => void;
  onOpen: () => void;
}

// save data to db based in this dictionary (to avoid language mismatch)

const initialState = (): TState =>
  Object.keys(dictionary.labels).reduce((obj: TState, key) => {
    obj[key as keyof TState] = '';
    return obj;
  }, {});

export default function StudentFeedbackModal({
  isShow,
  onClose,
  onOpen,
}: FileDescriptionModalProps) {
  const { currentUser } = useAppContext();
  const { room, members } = useWorkspaceContext();
  const { bug } = useErrorContext();
  const { i18n } = useLocaleContext();
  const [questions, setQuestions] = useState<FormQuestions>(StudentFeedbackQuestions);
  const [radioData, setRadioData] = useState<TState>(initialState);

  const [errors, setErrors] = useState({
    radioData: '',
    textArea: '',
  });
  // "thnak you" modal
  const [show, setShow] = useState(false);
  const tutor = members.find(m => m.role === UserType.Tutor);

  const [text, setText] = useState('');

  // radio button values
  const values: TValues = useMemo(
    () => ({
      v1: t(i18n)({ id: 'studentFeedback.r1', message: 'Nie, wcale :(' }),
      v2: t(i18n)({ id: 'studentFeedback.r2', message: 'Nie, raczej nie' }),
      v3: t(i18n)({ id: 'studentFeedback.r3', message: 'Czasami' }),
      v4: t(i18n)({ id: 'studentFeedback.r4', message: 'Tak, starał się' }),
      v5: t(i18n)({ id: 'studentFeedback.r5', message: 'Tak, bardzo!' }),
    }),
    [i18n],
  );

  // table left labels
  const labels: TLabels = useMemo(
    () => ({
      l1: t(i18n)({ id: 'studentFeedback.l1', message: 'Helpful' }),
      l2: t(i18n)({ id: 'studentFeedback.l2', message: 'Involved' }),
      l3: t(i18n)({ id: 'studentFeedback.l3', message: 'He had a lot of knowledge' }),
      l4: t(i18n)({ id: 'studentFeedback.l4', message: 'Smiling' }),
    }),
    [i18n],
  );

  const handleOpen = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const updateRadioDataValues = useCallback(
    (valueId: string, labelId: string) => {
      const _state = { ...radioData, [labelId]: valueId };

      setRadioData(_state);

      if (errors.radioData && !Object.values(_state).includes('')) {
        setErrors(prev => ({ ...prev, radioData: '' }));
      }
    },
    [errors.radioData, radioData],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const { value } = e.target;
      setText(value);

      if (errors.textArea && value.length > 0) {
        setErrors(prev => ({ ...prev, textArea: '' }));
      }
    },
    [errors.textArea],
  );

  const updateQuestion = useCallback(
    (questionId: number, newValue: number) => {
      const updatedQuestions: FormQuestions = questions.map(q => {
        if (q.id === questionId) return { ...q, value: newValue };
        return q;
      });
      setQuestions(updatedQuestions);
    },
    [questions],
  );

  function reset() {
    setErrors({ radioData: '', textArea: '' });
    setRadioData(initialState);
    setQuestions(StudentFeedbackQuestions);
    setText('');
  }

  async function redirectToForm() {
    const tutor: User = members.filter(m => m.role === UserType.Tutor)['0'];
    const formData: FeedbackForm = {
      userId: currentUser?.uid as string,
      userEmail: currentUser?.email as string,
      tutorId: tutor.uid,
      tutorEmail: tutor.email,
      roomId: room?.id as string,
      creationDate: serverTimestamp(),
      questions,
      askedQuestion: text,
      feedBackFormData: generateFormData(radioData),
    };

    const radioRequired = Object.values(radioData).includes('');
    if (radioRequired) {
      setErrors(prev => ({ ...prev, radioData: t(i18n)`Required` }));
    }

    const textRequired = text.length === 0;

    if (textRequired) {
      setErrors(prev => ({ ...prev, textArea: t(i18n)`Required` }));
    }

    if (!radioRequired && !textRequired) {
      try {
        const data = formFeedbackSchema.parse(formData);
        await sendForm(data);
        reset();
        onClose();
        handleOpen();
      } catch (e) {
        bug(e);
      }
    }
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (room?.id) {
      try {
        const db = getAppDatabase(AppName.DEFAULT);
        const roomRef = ref(db, `rooms/${room.id}/forcedFeedback`);
        const unsubscribe = onValue(roomRef, snapshot => {
          const data = snapshot.val();
          data && onOpen();
        });

        return () => {
          unsubscribe();
        };
      } catch (e) {
        bug(e);
      }
    }
  }, [room?.id, onOpen, bug]);

  return (
    <>
      <CustomModal
        modalClassName={styles['student-feedback-modal__modal']}
        show={isShow}
        backdrop
        onClose={onClose}
      >
        <>
          <div className={styles['student-feedback-modal__main-header']}>
            <Typography variant="h3" sx={muiStyles.header} fontSize={34}>
              <Trans id="questionnaire.header">Your opinion matters!</Trans>
            </Typography>
            <Box>
              <Typography sx={muiStyles.subheader} fontSize={16}>
                <Trans id="questionnaire.description">
                  Thanks to your opinion, we can develop our Academy in the right direction
                </Trans>
              </Typography>
            </Box>
          </div>
          <div className={styles['student-feedback-modal__container']}>
            <div className={styles['student-feedback-modal__subsection']}>
              <div>
                {questions.map(question => (
                  <Question
                    key={question.id}
                    question={questions[question.id]}
                    setValue={updateQuestion}
                  />
                ))}
              </div>
            </div>
            <div className={styles['student-feedback-modal__subsection']}>
              <Typography className={styles['student-feedback-modal__additional-subheader']}>
                <Trans id="student.feedback.teacher">Teacher was:</Trans>
              </Typography>
              <MatrixRadioButtonForm
                labels={labels}
                values={values}
                state={radioData}
                errorMsg={errors.radioData}
                callback={updateRadioDataValues}
              />
              <Typography mb={3} align="left">
                <Trans id="questionnaire.textarea.header">
                  Want to add something? We’d love to hear your feedback!
                </Trans>
              </Typography>
              <Textarea
                value={text}
                onChange={handleChange}
                required
                placeholder={t(
                  i18n,
                )`Ask a question or just write something at the end of the class!`}
                error={Boolean(errors.textArea)}
              />
            </div>
          </div>
          <Button
            data-testid="student-feedback-submit-btn"
            sx={muiStyles.confirm}
            className={styles['student-feedback-modal__button-purple']}
            onClick={redirectToForm}
          >
            <Typography sx={muiStyles.confirmText}>
              <Trans id="actions.submit">Prześlij</Trans>
            </Typography>
          </Button>
        </>
      </CustomModal>
      <DisapperaingPopup show={show} onClose={handleClose}>
        <div className={styles['student-feedback-modal__thank-you-wrapper']}>
          <div>
            <div className={styles['student-feedback-modal__thank-you-wrapper__header']}>
              <Trans id="popup.thankYou.header">Thank you for your feedback!</Trans>
            </div>
            <div className={styles['student-feedback-modal__thank-you-wrapper__subheader']}>
              <Trans id="popup.thankYou.subheader">
                Thanks to your opinion, our Application will be better
              </Trans>
            </div>
          </div>
        </div>
      </DisapperaingPopup>
    </>
  );
}
