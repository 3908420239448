import { z } from 'zod';
import {
  errorMessageSchema,
  responseMessageSchema,
  inputMessageSchema,
  outputMessageSchema,
  blocksImageMessageSchema,
  endLineMessageSchema,
} from 'schemas/compiler.schema';

export type ErrorMessage = z.infer<typeof errorMessageSchema>;
export type ResponseMessage = z.infer<typeof responseMessageSchema>;
export type InputMessage = z.infer<typeof inputMessageSchema>;
export type OutputMessage = z.infer<typeof outputMessageSchema>;
export type BlocksImageMessage = z.infer<typeof blocksImageMessageSchema>;
export type EndLineMessage = z.infer<typeof endLineMessageSchema>;

type Position = {
  x: number;
  y: number;
};

export type ConsolePythonResultType = {
  isResultCorrect: boolean;
  errorList: Array<number | Position>;
};

export const BoardCompilerStatus = {
  BOARD_UNSET: 0,
  DEBUG_STEP: 1,
  DEBUG: 2,
  END_LINE: 3,
  STOP_MODE: 4,
  SOLUTION: 5,
  EDIT_MODE: 6,
  ERROR_MODE: 7,
  RESULT: 8,
};
