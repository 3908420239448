import { Tabs } from '@mui/material';
import { styled } from '@mui/styles';

export const Sounds = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});
