// blue
export const blue = '#0F8EFF';
export const lightBlue = '#5bacf5';
export const lightBlue2 = '#4285F4';
export const mediumBlue = '#0072ea';
export const darkBlue = '#6666FF';
// dark
export const dark = '#0D0A16';
export const gray = '#757575';
// green
export const green = '#03a32e';
export const defaultPointerColor = '#a2e697';
export const deepGreen = '#34A853';
export const darkGreen = '#08696B';
// light
export const white = '#FFFFFF';
// orange
export const orange = '#EA4335';
// primary
export const darkPrimary = '#6A30FA1F';
// purple
export const purple = '#895AFD';
export const darkPurple = '#5a3ba7';
// violet
export const violet = '#9900CC';
export const darkViolet = '#6A30FA';
// yellow
export const yellow = '#F5CF47';
export const lightYellow = '#F2FF5F';
export const darkYellow = '#FBBC05';
// red
export const red = '#FC4119';
