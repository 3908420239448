import { getColor } from 'constants/UserColors';
import { get, ref } from 'firebase/database';
import { memberDataSchema, memberSchema } from 'schemas/member.shema';
import { IBadgesCounter } from 'types/BadgeTypes';
import { Member, MemberData, MemberBadges } from 'types/MemberTypes';
import { User } from 'types/UserTypes';
import { z } from 'zod';
import { getAppDatabase, AppName } from '../../helpers/database';
import { getUser } from './auth';
import { getUserBadges } from './badges';
import { RoomType } from 'types/enums';

/**
 *
 * @param members array of user id's
 * @returns MemberData array
 */
export async function getMembersData(members: string[]): Promise<MemberData[]> {
  try {
    const membersData: MemberData[] = await Promise.all(
      members.map(async member => {
        const user: User | null = await getUser(member);
        return memberDataSchema.parse({
          ...user,
          color: '#FFFFFF',
        });
      }),
    );
    membersData.sort((a, b) => b.lastName.localeCompare(a.lastName));
    membersData.sort((a, b) => parseInt(a.role.toString(), 10) - parseInt(b.role.toString(), 10));
    membersData.forEach((member, key) => {
      member.id = key.toString();
      member.color = getColor(key);
    });
    const parsedMembers = z.array(memberDataSchema);
    return parsedMembers.parse(membersData);
  } catch (e) {
    throw e;
  }
}

export async function getMembersBadges(
  members: MemberData[],
  roomId: string,
): Promise<MemberBadges[]> {
  const membersBadges: MemberBadges[] = await Promise.all(
    members.map(async member => {
      const memberBadges: IBadgesCounter = await getUserBadges(member.uid, roomId);
      return { ...member, badges: memberBadges };
    }),
  );
  return membersBadges;
}

/**
 *
 * @param roomId current room id
 * @returns room members
 */
export const getMembers = async (
  roomId: string,
  userUid: string,
  roomType: RoomType,
): Promise<Record<string, Member>> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const membersRef = ref(
      database,
      roomType !== RoomType.Demo ? `members/${roomId}` : `members/${roomId}/${userUid}`,
    );
    const snapshot = await get(membersRef);
    const memberRecordSchema = z.record(memberSchema);
    const result = snapshot.val();
    return memberRecordSchema.parse(
      roomType !== RoomType.Demo ? result : { [result.uid]: { ...result } },
    );
  } catch (e) {
    throw e;
  }
};
