import { ISelectOption } from 'types/AdminTypes';
import { UserExemplary, UserType } from 'types/enums';

export const roleOptions: ISelectOption[] = [
  { value: UserType.Student, text: 'Student' },
  { value: UserType.Tutor, text: 'Tutor' },
  { value: UserType.Admin, text: 'Admin' },
];

export const genderOptions: ISelectOption[] = [
  { value: '0', text: 'Male' },
  { value: '1', text: 'Female' },
  { value: '2', text: 'Other' },
];

export const userTypeOptions: ISelectOption[] = [
  { value: '0', text: UserExemplary[0] },
  { value: '1', text: UserExemplary[1] },
];

export const userCountryOptions: ISelectOption[] = [
  { value: 'PL', text: 'Polska' },
  { value: 'GB', text: 'United Kingdom' },
];
