import { db } from '../../store/helpers/firestore';
import { collection, addDoc, Timestamp, getDocs } from 'firebase/firestore';

export async function ParsePresents() {
  const querySnapshot2 = await getDocs(collection(db, 'rooms'));
  await querySnapshot2.forEach(async docc => {
    const querySnapshot = await getDocs(collection(db, 'presence', docc.id, 'date'));
    await querySnapshot.forEach(async doc2 => {
      const users = await doc2.data();
      Object.entries(users).forEach(async ([userId, value]) => {
        const newRecord = {
          ...value,
          userUid: userId,
          roomId: docc.id,
          date: Timestamp.fromDate(new Date(doc2.id)),
        };
        await addDoc(collection(db, 'presence2'), newRecord);
      });
    });
  });
}

export async function CopyToPresenceFromTemp() {
  const querySnapshot = await getDocs(collection(db, 'presence2'));
  await querySnapshot.forEach(async presence => {
    const presenceData = await presence.data();
    await addDoc(collection(db, 'presence'), presenceData);
  });
}
