import { Dispatch } from 'react';
import { Unsubscribe } from 'firebase/auth';
import { Database, onValue, ref } from 'firebase/database';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { BadgeType } from 'types/enums';
import { IBadgesCounter } from 'types/BadgeTypes';
import { CurrentUser } from 'types/UserTypes';

// TODO check if we need move it to firebase store chunks
export const getStudentsBadges = (
  currentUser: CurrentUser,
  roomId: string,
  setBadgesCounter: Dispatch<IBadgesCounter>,
): Unsubscribe => {
  const database: Database = getAppDatabase(AppName.DEFAULT);
  const reference = ref(database, `badges/${currentUser.uid}/${roomId}`);
  return onValue(reference, result => {
    const badges: IBadgesCounter = {
      debugger: 0,
      creative: 0,
      lightning: 0,
      perfect: 0,
    };
    if (result.exists()) {
      const lessons: object = result.val();
      Object.entries(lessons).forEach(([_, lesson]) => {
        Object.entries(lesson as object).forEach(([_, value]) => {
          badges[value.badgeType as keyof IBadgesCounter] += 1;
        });
      });
    }
    setBadgesCounter(badges);
  });
};

export const countStudentsBadges = (badgesCounter?: IBadgesCounter): number => {
  if (!badgesCounter) return 0;
  return Object.entries(badgesCounter).reduce(([attributeName, prevValue], [_, currentValue]) => [
    attributeName,
    prevValue + currentValue,
  ])[1];
};

export const getGrantedBadge = (
  badgesCounter1?: IBadgesCounter,
  badgesCounter2?: IBadgesCounter,
): keyof IBadgesCounter | null => {
  const getChangedBadge = (): string => {
    if (badgesCounter1 && badgesCounter2) {
      if (badgesCounter1.creative > badgesCounter2.creative) return BadgeType.Creative;
      if (badgesCounter1.debugger > badgesCounter2.debugger) return BadgeType.Debugger;
      if (badgesCounter1.lightning > badgesCounter2.lightning) return BadgeType.Lightning;
      if (badgesCounter1.perfect > badgesCounter2.perfect) return BadgeType.Perfect;
    }
    return '';
  };
  const changedBadge = getChangedBadge();
  if (changedBadge) return changedBadge as keyof IBadgesCounter;
  return null;
};
