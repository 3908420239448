/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { useEffect, useState, useMemo, ChangeEvent, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  Seniority,
  AdminLanguage,
  LicenseType,
  CourseType,
  RoomType,
  ProgrammingLanguage,
} from 'types/enums';
import { TextField, Select, MenuItem, InputLabel, FormControl, Box, Checkbox } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { roomCreationSchema, RoomCreationForm } from 'schemas/adminForms.schema';
import FormControlLabel from '@mui/material/FormControlLabel';
import { getDatabase, ref as firebaseRef, child, push } from 'firebase/database';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import format from 'date-fns/format';
import dayjs, { Dayjs } from 'dayjs';
import DatePicker from 'react-datepicker';
import TextArea from 'components/atoms/Textarea';
import addRoom from '../../../../scripts/room/addRoom';
import FormModalWrapper from '../../../../modals/FormModalWrapper';
import MultiSelect from '../MultiSelect';

export interface AddRoomFormProps {
  open: boolean;
  handleClose: () => void;
}

export default function AddRoomForm({ open, handleClose }: AddRoomFormProps) {
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RoomCreationForm>({
    resolver: zodResolver(roomCreationSchema),
    shouldFocusError: true,
    defaultValues: {
      users: '',
      startDate: new Date(),
      seniority: Seniority.Primary,
      language: 0,
      licenseType: LicenseType.Full,
      courseType: CourseType.Semestr15,
      roomType: RoomType.Real,
      contentVisibility: [],
      videoUrl: '',
    },
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedContentVisibility, setContentVisibility] = useState<string[]>([]);
  const [activeChatWidget, setActiveChatWidget] = useState<boolean>(false);
  const [scenariosVisibility, setScenariosVisibility] = useState<boolean>(false);
  const [videoVisibility, setVideoVisibility] = useState<boolean>(false);
  const [lenghtValue, setLenghtValue] = useState<string>('');
  const [time, setTime] = useState<Dayjs | null>(null);

  const videoHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVideoVisibility(event.target.checked);
  };

  const scenariosHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScenariosVisibility(event.target.checked);
  };

  const chatWidgetHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveChatWidget(event.target.checked);
  };

  async function submit(data: RoomCreationForm) {
    try {
      setLoading(true);
      data.contentVisibility = selectedContentVisibility;
      data.scenarios = scenariosVisibility;
      data.video = videoVisibility;
      data.activeChatWidget = activeChatWidget;
      data.time = dayjs(time).format('HH:mm');
      data.lenght = lenghtValue;
      await addRoom(data);
      // window.location.reload();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (/^\d*$/u.test(inputValue)) {
      setLenghtValue(inputValue);
    }
  };

  useEffect(() => {
    function createRoomId() {
      const db = getDatabase();
      const reference = push(child(firebaseRef(db), 'rooms'));
      if (!reference.key) throw new Error('Error while creating a room');
      setValue('id', reference.key);
    }

    createRoomId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputStyles = useMemo(() => ({ style: { color: 'black' } }), []);
  const timePickerComponents = useMemo(() => ['TimePicker'], []);

  return (
    <FormModalWrapper
      open={open}
      handleSubmit={handleSubmit(submit)}
      handleClose={handleClose}
      header="Add Room"
      btnText="Add"
      loading={loading}
    >
      <Box width="65%">
        <FormControl fullWidth onSubmit={handleSubmit(submit)}>
          <br />
          <Controller
            name="id"
            control={control}
            render={({ field }) => <TextField disabled required label="ID" {...field} />}
          />
          <br />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                error={Boolean(errors.name?.message)}
                helperText={errors.name ? `${errors.name.message}` : ''}
                label="Room name"
                {...field}
              />
            )}
          />
          <br />
          <Controller
            name="SKU"
            control={control}
            render={({ field }) => (
              <TextField
                error={Boolean(errors.SKU?.message)}
                helperText={errors.SKU ? `${errors.SKU.message}` : ''}
                label="SKU"
                {...field}
                required
              />
            )}
          />
          <br />
          <Controller
            name="seniority"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="seniority-field">Seniority</InputLabel>
                <Select labelId="seniority-field" label="Role" {...field} required>
                  <MenuItem value={Seniority.Primary}>Primary</MenuItem>
                  <MenuItem value={Seniority.Advanced}>Advanced</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea inputProps={inputStyles} label="Description" rows={3} {...field} />
            )}
          />
          <br />
          <Controller
            name="language"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="language-field">Language</InputLabel>
                <Select labelId="language-field" label="Role" {...field} required>
                  <MenuItem value={AdminLanguage.Blocks}>Blocks</MenuItem>
                  <MenuItem value={AdminLanguage.Python}>Python</MenuItem>
                  <MenuItem value={AdminLanguage.HtmlJsCss}>HtmlJsCss</MenuItem>
                  <MenuItem value={AdminLanguage.ChatGPT}>ChatGPT</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="contentVisibility"
            control={control}
            render={({ field }) => (
              <FormControl>
                <MultiSelect
                  title="Content visibylity"
                  values={Object.values(ProgrammingLanguage)}
                  selected={selectedContentVisibility}
                  setSelected={setContentVisibility}
                />
              </FormControl>
            )}
          />

          <br />
          <Controller
            name="video"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox {...field} checked={videoVisibility} onChange={videoHandleChange} />
                }
                label="Video visibility"
              />
            )}
          />
          <br />
          <Controller
            name="scenarios"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={scenariosVisibility}
                    onChange={scenariosHandleChange}
                  />
                }
                label="Scenarios visibility"
              />
            )}
          />
          <br />
          <Controller
            name="activeChatWidget"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    {...field}
                    checked={activeChatWidget}
                    onChange={chatWidgetHandleChange}
                  />
                }
                label="Active chat widget"
              />
            )}
          />
          <br />
          <Controller
            name="videoUrl"
            control={control}
            render={({ field }) => (
              <TextField
                label="* Video w skecji 'Pomoc' dla pokoju Demo oraz Indywidualny"
                rows={3}
                {...field}
              />
            )}
          />
          <br />
          <Controller
            name="courseType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="language-field">Course type</InputLabel>
                <Select labelId="language-field" label="Role" {...field} required>
                  <MenuItem value={CourseType.Semestr15}>Kurs semestralny (15h)</MenuItem>
                  <MenuItem value={CourseType.Short10}>Kurs skrócony (10h)</MenuItem>
                  <MenuItem value={CourseType.Mini5}>Kurs mini (5h)</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="licenseType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="language-field">License type</InputLabel>
                <Select labelId="language-field" label="Role" {...field} required>
                  <MenuItem value={LicenseType.Full}>Pełna</MenuItem>
                  <MenuItem value={LicenseType.Lite}>Lite</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="roomType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="language-field">Room type</InputLabel>
                <Select labelId="language-field" label="Role" {...field} required>
                  <MenuItem value={RoomType.Real}>Prawdziwy pokój</MenuItem>
                  <MenuItem value={RoomType.Individual}>Indywidualny</MenuItem>
                  <MenuItem value={RoomType.Demo}>Demo</MenuItem>
                  <MenuItem value={RoomType.TestAndPresentation}>Testowy</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={timePickerComponents}>
              <TimePicker
                ampm={false}
                label="Wybierz godzinę zajęć"
                value={time}
                onChange={setTime}
              />
            </DemoContainer>
          </LocalizationProvider>
          <br />
          <Controller
            name="dayOfWeek"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="language-field">Dzień tygodnia</InputLabel>
                <Select labelId="language-field" label="Role" {...field} required>
                  <MenuItem value={1}>Poniedziałek</MenuItem>
                  <MenuItem value={2}>Wtorek</MenuItem>
                  <MenuItem value={3}>Środa</MenuItem>
                  <MenuItem value={4}>Czwartek</MenuItem>
                  <MenuItem value={5}>Piątek</MenuItem>
                  <MenuItem value={6}>Sobota</MenuItem>
                  <MenuItem value={7}>Niedziela</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="lenght"
            control={control}
            render={({ field }) => (
              <TextField
                label="Długość zajęć w godzinach"
                value={lenghtValue}
                onChange={handleInputChange}
                type="text"
              />
            )}
          />
          <br />
          <Controller
            name="startDate"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <DatePicker
                ref={ref}
                selected={value}
                onChange={date => onChange(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={
                  <TextField
                    error={Boolean(errors.startDate?.message)}
                    helperText={errors.startDate ? `${errors.startDate.message}` : ''}
                    label="Start date"
                  />
                }
              />
            )}
          />
          <br />
          <Controller
            name="endDate"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <DatePicker
                ref={ref}
                selected={value}
                onChange={date => onChange(date)}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
                customInput={
                  <TextField
                    error={Boolean(errors.endDate?.message)}
                    helperText={errors.endDate ? `${errors.endDate.message}` : ''}
                    label="End date"
                  />
                }
              />
            )}
          />
          <br />
          <Controller
            name="users"
            control={control}
            render={({ field }) => (
              <TextArea inputProps={inputStyles} label="Users" rows={3} {...field} />
            )}
          />
        </FormControl>
      </Box>
    </FormModalWrapper>
  );
}
