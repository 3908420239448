import { useCallback } from 'react';
import { isTutor } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { leaderModeQuestionSchema } from 'schemas/leaderMode.schema';
import { FileData, FileDataType } from 'types/FileTypes';
import { IUpdateFileView } from './interfaces';

export const UpdateFileView = (props: IUpdateFileView) => {
  const {
    currentFile,
    leaderModeSwitchValue,
    activateLoadingHandler,
    loadFileAfterReset,
    setFileData,
    stopLoadingHandler,
  } = props;
  const { currentUser } = useAppContext();
  const { fatal } = useErrorContext();
  const { currentUserId, leaderMode, room } = useWorkspaceContext();

  return useCallback(
    async (data: FileDataType) => {
      if (currentFile && currentUserId) {
        activateLoadingHandler();
        const file = new FileData();
        file.setFileFromObj(data);
        setFileData(file);
        const fileQuestion = await loadFileAfterReset(file);
        stopLoadingHandler();

        if (isTutor(currentUser?.role) && leaderModeSwitchValue) {
          const leaderQuestion = {
            roomId: room?.id,
            leaderQuestionId: fileQuestion?.guid,
            userId: currentUserId,
            tutorId: currentUser?.uid,
            questionType: fileQuestion?.type,
          };
          try {
            await leaderMode.setQuestion(leaderModeQuestionSchema.parse(leaderQuestion));
          } catch (e) {
            fatal(e);
          }
        }
      }
    },
    [
      activateLoadingHandler,
      currentFile,
      currentUser,
      currentUserId,
      leaderMode,
      leaderModeSwitchValue,
      room,
      fatal,
      loadFileAfterReset,
      setFileData,
      stopLoadingHandler,
    ],
  );
};
