import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const StyledLoadingButton = styled(LoadingButton)({
  borderRadius: 1,
  backgroundColor: '#6A30FA',
  marginTop: '10px',
  fontSize: '18px',
  fontWeight: 700,
  height: '42px',
  color: 'white',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
});

export const StyledBox = styled(Box)({
  paddingTop: '48px',
  paddingBottom: '38px',
});
export const StyledHeader = styled(Typography)({
  color: '#e1e1e7',
  fontFamily: 'Space Grotesk',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '18px',
  lineHeight: '24px',
  marginBottom: '20px',
});
