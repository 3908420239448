// component
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';

// ----------------------------------------------------------------------

export interface INavConfig {
  title: string;
  path: string;
  icon: JSX.Element;
}

const navConfig: INavConfig[] = [
  {
    title: 'dashboard',
    path: '/admin/dashboard',
    icon: <DashboardIcon />,
  },
  {
    title: 'user',
    path: '/admin/user',
    icon: <PeopleAltIcon />,
  },
  {
    title: 'room',
    path: '/admin/room',
    icon: <MeetingRoomIcon />,
  },
  {
    title: 'Synch',
    path: '/admin/synch',
    icon: <CloudSyncIcon />,
  },
  {
    title: 'Schedule',
    path: '/admin/schedule',
    icon: <CalendarMonthIcon />,
  },
  {
    title: 'Emails',
    path: '/admin/emails',
    icon: <EmailIcon />,
  },
  {
    title: 'Chat GPT',
    path: '/admin/chatGPT',
    icon: <ChatIcon />,
  },
];

export default navConfig;
