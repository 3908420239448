import { useEffect } from 'react';
import { Room } from 'types/RoomTypes';
import { filterByAll } from '../../../../../helpers/filters';
import { applySortFilter, getComparator } from '../../helpers';
import { IFilterRooms } from './interfaces';

export const FilterRooms = (props: IFilterRooms) => {
  const {
    activeFilters,
    isOrderReversed,
    orderBy,
    page,
    rowsPerPage,
    searchName,
    roomList,
    setFilteredRooms,
  } = props;
  useEffect(() => {
    const searchedUsers: Room[] = applySortFilter(
      roomList,
      getComparator(isOrderReversed, orderBy),
      searchName,
    );
    const filteredUsers: Room[] = filterByAll(searchedUsers, activeFilters);
    const slicedList: Room[] = filteredUsers.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    setFilteredRooms(slicedList);
  }, [
    activeFilters,
    isOrderReversed,
    orderBy,
    page,
    rowsPerPage,
    searchName,
    roomList,
    setFilteredRooms,
  ]);
};
