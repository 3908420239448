export default function DirectoryIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33331 13.3334V2.66669H6.66665L7.99998 4.00002H14.6666V5.33335H7.44998L6.11665 4.00002H2.66665V12L4.26665 6.66669H15.6666L13.6666 13.3334H1.33331ZM4.06665 12H12.6666L13.8666 8.00002H5.26665L4.06665 12Z"
        fillOpacity="0.7"
      />
    </svg>
  );
}
