import { Suspense, useCallback, useRef, useState } from 'react';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { renderSurveyButton } from '../../../helpers';
import { t } from '@lingui/macro';
import Loader from 'components/atoms/Loader';
import useClickAway from 'hooks/useClickAway';
import StudentFeedbackModal from '../../../../../modals/StudentFeedbakModal';

export default function StudentSection() {
  const { i18n } = useLocaleContext();
  const [studentFeedbackModalShow, setStudentFeedbackShow] = useState(false);
  const studentFeedbackRef = useRef(null);

  const closeStudentFeedbackModalHandler = useCallback(() => {
    setStudentFeedbackShow(false);
  }, []);

  const openStudentFeedbackModalHandler = useCallback(() => {
    setStudentFeedbackShow(true);
  }, []);

  useClickAway(studentFeedbackRef, closeStudentFeedbackModalHandler);
  return (
    <>
      <div ref={studentFeedbackRef}>
        <Suspense fallback={<Loader size={50} />}>
          <StudentFeedbackModal
            isShow={studentFeedbackModalShow}
            onClose={closeStudentFeedbackModalHandler}
            onOpen={openStudentFeedbackModalHandler}
          />
        </Suspense>
      </div>
      {renderSurveyButton(
        t(i18n)({ id: 'evaluationSurvey.label', message: 'Questionnaire' }),
        openStudentFeedbackModalHandler,
      )}
    </>
  );
}
