export const SWITCH_LEADER_MODE_ACTION = 'SWITCH_LEADER_MODE_ACTION';
export const CHANGE_LESSON_ACTION = 'CHANGE_LESSON_ACTION';
export const SWITCH_BETWEEN_TABS_ACTION = 'SWITCH_BETWEEN_TABS_ACTION';
export const SHOW_FEEDBACK_FORM_ACTION = 'SHOW_FEEDBACK_FORM_ACTION';
export const EDIT_STUDENT_FILE_ACTION = 'EDIT_STUDENT_FILE';

export type TUserActions =
  | typeof SWITCH_LEADER_MODE_ACTION
  | typeof CHANGE_LESSON_ACTION
  | typeof SWITCH_BETWEEN_TABS_ACTION
  | typeof SHOW_FEEDBACK_FORM_ACTION
  | typeof EDIT_STUDENT_FILE_ACTION;

export const userActions: TUserActions[] = [
  SWITCH_LEADER_MODE_ACTION,
  CHANGE_LESSON_ACTION,
  SWITCH_BETWEEN_TABS_ACTION,
  SHOW_FEEDBACK_FORM_ACTION,
  EDIT_STUDENT_FILE_ACTION,
];

export const mainTutorActions: TUserActions[] = [
  SWITCH_LEADER_MODE_ACTION,
  CHANGE_LESSON_ACTION,
  SWITCH_BETWEEN_TABS_ACTION,
  SHOW_FEEDBACK_FORM_ACTION,
  EDIT_STUDENT_FILE_ACTION,
];

export const secondaryTutorActions = [SWITCH_BETWEEN_TABS_ACTION];
export const studentActions: TUserActions[] = [];
