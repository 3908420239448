import { darkPurple, purple } from 'constants/Colors';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

const StyledSwitch = styled(Switch)({
  '& .MuiSwitch-track': {
    color: darkPurple,
    backgroundColor: darkPurple,
  },
  '& .Mui-checked .MuiSwitch-thumb': {
    color: purple,
    backgroundColor: purple,
  },
  '& .Mui-checked + .MuiSwitch-track': {
    color: purple,
    backgroundColor: purple,
  },
});

interface MSwitchProps extends SwitchProps {
  text: string;
  gridClassName?: string; // css module
  textClassName?: string; // css module
}

export default function MSwitchWithText({
  text,
  gridClassName,
  textClassName,
  size = 'small',
  ...props
}: MSwitchProps) {
  return (
    <Grid
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      className={gridClassName}
      container
    >
      <p className={textClassName}>{text}</p>
      <StyledSwitch size={size} {...props} />
    </Grid>
  );
}
