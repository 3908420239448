import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const boxSx = {
  m: '6px',
};

const CustomSearchStyled = styled(GridToolbarQuickFilter)({
  '& label.Mui-focused': {
    color: '#6a30fa',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#6a30fa',
  },
  '& .MuiOutlinedInput-root': {
    color: '#fff',
    '& fieldset': {
      borderColor: '#6a30fa',
    },
    '&:hover fieldset': {
      borderColor: '#6a30fa',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6a30fa',
    },
  },
});

export default function CustomSearch() {
  return (
    <Box sx={boxSx}>
      <CustomSearchStyled data-testid="rooms-admin-search" variant="outlined" />
    </Box>
  );
}
