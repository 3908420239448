import { useCallback } from 'react';
import { File } from 'types/FileTypes';
import { LanguageType } from 'types/generalTypes';
import { IChangeActiveFile } from './interfaces';

export const ChangeActiveFile = (data: IChangeActiveFile) => {
  const { currentLocale, setActiveFile } = data;

  return useCallback(
    (file: File) => {
      const locale = currentLocale.toUpperCase() as keyof LanguageType;
      window.dispatchEvent(
        new CustomEvent('changeFile', {
          detail: {
            id: file.guid,
            type: file.type,
            title: file.title[locale],
          },
        }),
      );

      setActiveFile(file);
    },
    [currentLocale, setActiveFile],
  );
};
