import { ILessonData, ILessonTree } from 'types/ContentTypes';
import { z } from 'zod';
import { JsPackage } from 'types/enums';
import { FileTypeEnum } from './file.schema';
import { languageSchema } from './general.schema';
import { skillsTypeSchema } from './skills.schema';
import { firestoreReferenceSchema } from './firebase.schema';

// lesson question content
export const lessonInfoSchema = z.object({
  description: languageSchema,
  guid: z.string(),
  language: z.string(),
  type: FileTypeEnum,
  title: languageSchema,
  isOptionalTask: z.boolean().optional(),
});

// sections
export const lessonSingleSectionSchema = z.object({
  docs: z.array(z.string()).optional(),
  sectionStart: z.number().optional(),
  title: languageSchema,
  video: z.array(z.string()).optional(),
});

export const lessonSectionSchema = z.record(lessonSingleSectionSchema);

// lesson content including question (above)
export const currentLessonRawSchema = z.object({
  id: z.string(),
  description: languageSchema,
  docs: z.array(z.string()).optional(),
  questions: z.array(lessonInfoSchema),
  sections: lessonSectionSchema.optional(),
  skills: z.array(firestoreReferenceSchema).optional(),
  title: languageSchema,
  video: z.array(z.string()).optional(),
});

export const currentLessonDataSchema = currentLessonRawSchema.extend({
  skills: z.array(skillsTypeSchema),
});

export const lessonDataSchema: z.ZodType<ILessonData> = z.lazy(() =>
  z.object({
    description: languageSchema,
    title: languageSchema,
    children: z.array(lessonDataSchema).optional(),
  }),
);

export const lessonTreeSchema: z.ZodType<ILessonTree> = z.lazy(() =>
  z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    guid: z.string().optional(),
    course: z.string().optional(),
    courseTitle: z.string().optional(),
    children: z.array(lessonTreeSchema).optional(),
  }),
);

export const courseSchema = z.object({
  description: languageSchema,
  lessons: z.record(lessonDataSchema),
  title: languageSchema,
});

export const parsedCourseSchema = courseSchema.extend({
  guid: z.string(),
});

export const questionSchema = z.object({
  code: z.string(),
  description: languageSchema.optional(),
  guid: z.string(),
  inputGeneratorCode: z.string().optional(),
  language: z.string(), // TODO change to enum
  type: FileTypeEnum,
  // Python Compiler
  input: z.string().optional(),
  //Blocks Compiler
  isBlocksGame: z.boolean().optional(),
  canEditBlocks: z.boolean().optional(),
  isColors: z.boolean().optional(),
  keyWords: z.string().optional(),
  imageWidth: z.number().optional(),
  imageHeight: z.number().optional(),
  image: z.string().optional(),
  patternImage: z.string().optional(),
  // HtmlJsCss
  jsLibrary: z.nativeEnum(JsPackage).optional(),
});

export const questionStackSchema = z.object({
  course: z.string().optional(),
  lesson: z.string().optional(),
  question: z.string().optional(),
});

export const navigationStackSchema = z.object({
  course: languageSchema,
  lesson: languageSchema,
});

// solved_tasks

export const solvedTaskDataSchema = z.object({
  userId: z.string(),
  roomId: z.string(),
  lessonId: z.string(),
  questionId: z.string(),
  points: z.number(),
});

export const solvedTaskSchema = z
  .object({
    createDate: z.number(),
    points: z.number(),
  })
  .nullable();

export const solvedQuestion = z.object({
  createDate: z.number(),
  points: z.number(),
});

export const solvedQuestionsSchema = z.record(solvedQuestion);

export const solvedLessonsSchema = z.record(solvedQuestionsSchema);

export const solvedRoomsSchema = z.record(solvedLessonsSchema);
