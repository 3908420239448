import clsx from 'clsx';
import styles from './tooltip.module.css';

interface TooltipProps {
  position?: {
    marginBottom?: string;
    marginLeft?: string;
    marginRight?: string;
    marginTop?: string;
  };
  className?: string;
  disappear?: boolean;
  distance?: number;
  reverseFadeDirection?: boolean;
  show: boolean;
  children?: JSX.Element;
}

export default function Tooltip(props: TooltipProps) {
  const {
    children,
    className,
    disappear = true,
    distance = 50,
    position,
    reverseFadeDirection,
    show,
  } = props;
  const fadeDirectionMark = reverseFadeDirection && show ? '' : '-';
  const fadeDistance = show ? '0' : `${distance}px`;

  const tooltipStyle = {
    opacity: show || !disappear ? '1' : '0',
    transform: `translate3d(${fadeDirectionMark}${fadeDistance}, 0, 0)`,
    ...position,
  };

  const tooltipClassNames = clsx(styles.tooltip__content, className);

  return (
    <span className={tooltipClassNames} style={tooltipStyle}>
      {children}
    </span>
  );
}
