import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { remove, setItem } from 'helpers/LocalStorageHelper';
import { errorContext } from 'providers/error/error.provider';
import { auth, contentAuth } from 'store/FirebaseStore';
import { ErrorTransfer } from 'types/error.types';
import { Trans } from '@lingui/macro';
import CustomButton from 'components/atoms/CustomButton';
import styles from './errorPage.module.css';

const validateErrorMessage = (error?: ErrorTransfer | null): string => {
  try {
    const [errorMessage] = JSON.parse(error?.message || '');
    return errorMessage?.message || '';
  } catch {
    return error?.message || '';
  }
};

function ErrorPage() {
  const { setError, error } = useContext(errorContext);
  const location = useLocation();
  const navigate = useNavigate();
  const errorCode = (error?.payload?.code || 404).toString();
  const [lastErrorPageLocation] = useState(location.pathname);
  const errorBody: string = validateErrorMessage(error);

  const cleanError = useCallback(() => {
    if (Number(errorCode) === 424) {
      remove('currentUser');
    }
    setError(null);
  }, [setError, errorCode]);

  useEffect(() => {
    if (lastErrorPageLocation !== location.pathname) {
      cleanError();
    }
  }, [location.pathname, lastErrorPageLocation, cleanError]);

  const handleClick = useCallback(async () => {
    await signOut(auth);
    await signOut(contentAuth);
    setItem('currentUser', null);
    navigate('/');
  }, [navigate]);

  return (
    <div className={styles['error-page__content']}>
      <div>
        <div className={styles['error-page__error-code']}>
          <Trans id="errorPage.header" /> {errorCode}
        </div>
        <CustomButton className={styles['error-page__login-again-button']} onClick={handleClick}>
          <Trans id="errorPage.button" />
        </CustomButton>
        {process.env.BUILD_ENV !== 'production' && (
          <div className={styles['error-page__details']}>
            <div>
              <p>
                <Trans id="errorPage.body" />
              </p>
              <span className={styles['error-page__message-content']}>{errorBody}</span>
            </div>
            <div>
              <p>
                <Trans id="errorPage.stack" />
              </p>
              <span className={styles['error-page__error-stack']}>
                {error?.stack ? <pre>{error.stack}</pre> : null}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ErrorPage;
