import { CheckboxCellProps } from './interface';
import { Checkbox, TableCell } from '@mui/material';

export default function CheckboxCell(props: CheckboxCellProps) {
  const { isItemSelected, onClick } = props;

  return (
    <TableCell padding="checkbox">
      <Checkbox checked={isItemSelected} onChange={onClick} />
    </TableCell>
  );
}
