import { useEffect } from 'react';
import { onValue, ref } from 'firebase/database';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { z } from 'zod';
import { IUpdateActiveLessonId } from './interface';

export const UpdateActiveLessonId = (data: IUpdateActiveLessonId) => {
  const { currentUser, room, setLocalCourseId, setLocalLessonId } = data;
  useEffect(() => {
    try {
      const roomActiveLessonRef = ref(
        getAppDatabase(AppName.DEFAULT),
        `rooms/${room?.id}/activeLesson`,
      );
      const roomActiveCourseRef = ref(
        getAppDatabase(AppName.DEFAULT),
        `rooms/${room?.id}/activeCourse`,
      );

      const lessonUnsubscribe = onValue(roomActiveLessonRef, snapshot => {
        if (snapshot.exists()) {
          const result = z.string().safeParse(snapshot.val());
          if (result.success) {
            try {
              setLocalLessonId(result.data);
            } catch (e) {
              throw e;
            }
          }
        }
      });

      const courseUnsubscribe = onValue(roomActiveCourseRef, snapshot => {
        if (snapshot.exists()) {
          const result = z.string().safeParse(snapshot.val());
          if (result.success) {
            try {
              setLocalCourseId(result.data);
            } catch (e) {
              throw e;
            }
          }
        }
      });

      const unsubscribes = [lessonUnsubscribe, courseUnsubscribe];

      return () => {
        unsubscribes.forEach(unsubscribe => unsubscribe());
      };
    } catch (e) {
      throw e;
    }
  }, [currentUser, room, setLocalCourseId, setLocalLessonId]);
};
