import { User } from 'types/UserTypes';
import { userSchema } from 'schemas/user.schema';
import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  AdditionalUserInfo,
  updatePassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { child, get, ref } from 'firebase/database';
import { ValidationError } from 'providers/error/error';
import { auth, contentAuth } from 'store/helpers/database';
import { getAppDatabase, AppName } from '../../helpers/database';

/**
 *
 * @param email user email
 * @param password user password
 * @returns logged in user and additional firebase data (like isNewUser etc.)
 */
export async function loginUser(
  email: string,
  password: string,
): Promise<{ user: User; additionalUserInfo: AdditionalUserInfo | null }> {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    await signInWithEmailAndPassword(contentAuth, email, password);
    const { user: credUser } = userCredential;
    const user = await getUser(credUser.uid);
    const additionalUserInfo = getAdditionalUserInfo(userCredential);
    return { user, additionalUserInfo };
  } catch (e) {
    throw e;
  }
}

/**
 * logins user with google oauth window popup
 * @returns logged in user
 */
export async function loginWithGoogle(): Promise<User> {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    login_hint: 'user@example.com',
    prompt: 'select_account',
  });

  try {
    const credential = await signInWithPopup(auth, provider);
    const { user } = credential;
    return await getUser(user.uid);
  } catch (e) {
    throw e;
  }
}

/**
 *
 * @param uid user id
 * @returns firebase user (from realtime db)
 */
export async function getUser(uid: string): Promise<User> {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const snapshot = await get(child(ref(database), `users/${uid}`));
    if (snapshot.exists()) {
      const dbUser = snapshot.val();
      return userSchema.parse({ ...dbUser, uid });
    }
    throw new ValidationError('Login: No data available', {
      code: 424,
      data: {
        message: `There is no user with current id ${uid}`,
      },
    });
  } catch (e) {
    throw e;
  }
}

/**
 *
 * @param password new password
 * @returns true if succeed, false if not
 */
export async function updateUserPassword(password: string): Promise<boolean> {
  try {
    const user = auth.currentUser;
    if (!user) return false;
    await updatePassword(user, password);
    return true;
  } catch (e) {
    throw e;
  }
}

/**
 * send's to email reset password link if user forgot a password
 * @param email current user email
 */
export async function sendResetPasswordLinkToEmail(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (e) {
    throw e;
  }
}
