import { useCallback } from 'react';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { getNavigationStack } from 'store/FirebaseStore';
import { LanguageType } from 'types/generalTypes';
import { IUpdateNavigationstack } from './interfaces';

export const UpdateNavigationStack = (data: IUpdateNavigationstack) => {
  const { currentFile, setNavigationStack } = data;
  const { bug } = useErrorContext();
  const { locale: currentLocale } = useLocaleContext();
  const { room } = useWorkspaceContext();

  const locale = currentLocale.toUpperCase() as keyof LanguageType;

  return useCallback(async () => {
    try {
      const nav = room?.id ? await getNavigationStack(room.id) : null;
      nav &&
        setNavigationStack({
          course: nav.course[locale],
          lesson: nav.lesson[locale],
          question: currentFile?.title,
        });
    } catch (e) {
      bug(e);
    }
  }, [currentFile, locale, room, bug, setNavigationStack]);
};
