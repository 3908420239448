import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';
import Iconify from 'components/atoms/Iconify';
import { UserHeaderProps } from './interface';

export default function UserHeader(props: UserHeaderProps) {
  const { setAddUserModalShow } = props;

  const showModal = useCallback(() => {
    setAddUserModalShow(true);
  }, [setAddUserModalShow]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Typography variant="h4" gutterBottom>
        User
      </Typography>
      <Button
        variant="contained"
        component={Link}
        to="#"
        startIcon={<Iconify icon="eva:plus-fill" />}
        onClick={showModal}
      >
        New User
      </Button>
    </Stack>
  );
}
