import { ChangeEvent, forwardRef, memo, useCallback, useState } from 'react';
import { isStudent } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import { EditorSectionProps } from './interface';
import { IDEContent, IDETopbar } from './components';

const EditorSection = forwardRef<{}, EditorSectionProps>((props, ref) => {
  const { editorRef, ideMethods, isCodeMode, loadedAce, question, setIsCodeMode } = props;
  const { getAceInstance, showSolution, transformBlocksToCode, transformCodeToBlocks } = ideMethods;
  const { currentUser } = useAppContext();
  const { leaderMode } = useWorkspaceContext();
  const [extensionsMenuOpened, setExtensionsMenuOpened] = useState<boolean>(false);
  const isLanguageBlocks = question?.language === ProgrammingLanguage.Blocks;
  const shouldNotShowCodeSwitch =
    Boolean(currentUser) &&
    isLanguageBlocks &&
    !(leaderMode.isLeaderModeOn && isStudent(currentUser?.role));
  const shouldShowEditor =
    Boolean(question) && Boolean(question?.language !== ProgrammingLanguage.ChatGPT);

  const showSolutionCode = useCallback(() => {
    if (isLanguageBlocks && isCodeMode) setIsCodeMode(false);
    showSolution(isLanguageBlocks, question?.code as string);
  }, [isLanguageBlocks, isCodeMode, question?.code, setIsCodeMode, showSolution]);

  const switchCodeModeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        transformBlocksToCode();
        setIsCodeMode(true);
        return;
      }
      transformCodeToBlocks();
      setIsCodeMode(false);
    },
    [setIsCodeMode, transformCodeToBlocks, transformBlocksToCode],
  );

  return (
    <>
      <IDETopbar
        extensionsMenuOpened={extensionsMenuOpened}
        question={question}
        setExtensionsMenuOpened={setExtensionsMenuOpened}
        showSolutionCode={showSolutionCode}
        shouldNotShowCodeSwitch={shouldNotShowCodeSwitch}
        isCodeMode={isCodeMode}
        switchCodeModeHandler={switchCodeModeHandler}
      />
      {shouldShowEditor && (
        <IDEContent
          editorRef={editorRef}
          extensionsMenuOpened={extensionsMenuOpened}
          isCodeMode={isCodeMode}
          loadedAce={loadedAce}
          question={question}
          getAceInstance={getAceInstance}
        />
      )}
    </>
  );
});

export default memo(EditorSection);
