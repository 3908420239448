import { useMemo } from 'react';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, ListItemText, ListItemIcon, ButtonBaseProps } from '@mui/material';
import ListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';

import { INavConfig } from '../../layouts/adminLayout/NavConfig';

// ----------------------------------------------------------------------

const ListItemStyle = styled(props => <ListItemButton disableGutters {...props} />)<
  ListItemButtonProps<any, ButtonBaseProps>
>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

export interface NavItemProps {
  item: INavConfig;
  active: (p: string) => boolean;
}

function NavItem({ item, active }: NavItemProps) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon } = item;

  const activeRootStyle = useMemo(
    () => ({
      color: 'primary.main',
      fontWeight: 'fontWeightMedium',
      bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    }),
    [theme],
  );

  const itemSx = useMemo(
    () => ({
      ...(isActiveRoot && activeRootStyle),
    }),
    [isActiveRoot, activeRootStyle],
  );

  return (
    <ListItemStyle component={RouterLink} to={path} sx={itemSx}>
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

export interface NavSectionProps {
  navConfig: INavConfig[];
}

const ListSx = { p: 1 };

export default function NavSection({ navConfig, ...other }: NavSectionProps) {
  const { pathname } = useLocation();

  const match = (path: string) =>
    path ? Boolean(matchPath({ path, end: false }, pathname)) : false;

  return (
    <Box {...other}>
      <List disablePadding sx={ListSx}>
        {navConfig.map(item => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
