import { useState } from 'react';
import { BadgeType } from 'types/enums';
import { Trans } from '@lingui/macro';
import ImageSection from '../../ImageSection/ImageSection';
import styles from './studentBadge.module.css';

interface StudentBadgeProps {
  badge: BadgeType;
  badgesCounter?: number;
}

export default function StudentBadge(props: StudentBadgeProps) {
  const { badge, badgesCounter } = props;
  const [hovered, setHovered] = useState<boolean>(false);
  function onMouseEnter() {
    setHovered(true);
  }
  function onMouseLeave() {
    setHovered(false);
  }
  return (
    <div
      className={styles['student-badge__content']}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ImageSection badge={badge} hovered={hovered} />
      <p className={styles['student-badge__counter']}>{badgesCounter}</p>
      <p className={styles['student-badge__title']}>
        <Trans id={`badgeModal.badge.title.${badge}`} />
      </p>
    </div>
  );
}
