import { useCallback } from 'react';
import { LinkCellProps } from './interface';
import { IconButton, TableCell } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';

export default function LinkCell(props: LinkCellProps) {
  const { meetLink } = props;

  const copyToClipboard = useCallback((text: string) => {
    navigator.clipboard.writeText(text);
  }, []);

  return (
    <TableCell align="left">
      {meetLink ? (
        <>
          <a href={meetLink}>Link</a>
          <IconButton
            aria-label="copy"
            size="small"
            // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
            onClick={() => copyToClipboard(meetLink)}
          >
            <ContentCopyRoundedIcon />
          </IconButton>
        </>
      ) : (
        <>None</>
      )}
    </TableCell>
  );
}
