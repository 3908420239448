import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';

const iconStyles = {
  margin: '2px',
};

export const generateScrollButton = (props: any) => {
  if (props.direction === 'left' && !props.disabled) {
    return (
      <IconButton {...props}>
        <KeyboardArrowLeftIcon style={iconStyles} />
      </IconButton>
    );
  } else if (props.direction === 'right' && !props.disabled) {
    return (
      <IconButton {...props}>
        <KeyboardArrowRightIcon style={iconStyles} />
      </IconButton>
    );
  }
  return null;
};
