import { getDatabase, Database } from 'firebase/database';
import { FirebaseApp, getApps } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { contentApp, app } from './app';

export enum AppName {
  DEFAULT,
  CONTENT,
}

export const auth = getAuth(app);
export const contentAuth = getAuth(contentApp);

/**
 *
 * @param appId [DEFAULT, CONTENT] realttime db
 * @returns db reference
 */
export const getAppDatabase = (appId: AppName): Database => {
  const app: FirebaseApp = getApps()[appId];
  return getDatabase(app);
};
