// icons
import { Icon, IconifyIcon } from '@iconify/react';
// @mui
import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

export interface IconifyProps extends BoxProps {
  icon: string | IconifyIcon;
}

export default function Iconify({ icon, sx, ...other }: IconifyProps) {
  return <Box component={Icon} icon={icon} sx={sx} {...other} />;
}
