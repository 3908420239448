import { useCallback } from 'react';
import { ILessonTree } from 'types/ContentTypes';
import { EmbeddedMenuItemProps } from './interface';
import { TreeItem } from '@mui/lab';
import { DialogContentText } from '@mui/material';

export default function EmbeddedMenuItem(props: EmbeddedMenuItemProps) {
  const { nodes, selectMenuItem } = props;

  const itemContent = Array.isArray(nodes.children)
    ? nodes.children.map((node: ILessonTree) => (
        <EmbeddedMenuItem key={node.id} nodes={node} selectMenuItem={selectMenuItem} />
      ))
    : null;

  const handleClick = useCallback(() => {
    selectMenuItem(nodes);
  }, [nodes, selectMenuItem]);

  return (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id}
      data-testid={`content-tree-item-${nodes.id}`}
      label={<DialogContentText>{nodes.name}</DialogContentText>}
      onClick={handleClick}
    >
      {itemContent}
    </TreeItem>
  );
}
