import { useCallback, useState } from 'react';
import { Button, Stack, Typography, Container } from '@mui/material';
import { sendEmailsToParentsInRoom } from 'store/functions';
import { useErrorContext } from 'providers/error/error.provider';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import SendIcon from '@mui/icons-material/Send';
import EmailIcon from '@mui/icons-material/Email';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import MailIcon from '@mui/icons-material/Mail';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from 'components/atoms/Page';
import styles from './emails.module.css';

const cardStyles = { padding: '20px', marginBottom: '10px' };

export default function Emails() {
  const { bug } = useErrorContext();
  const [roomField, setRoomField] = useState<string>('');
  const [htmlField, setHtmlField] = useState<string>('');
  const [fromField, setFromField] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);

  const sendEmail = useCallback(async () => {
    try {
      const data = {
        from: fromField,
        roomId: roomField,
        subject,
        html: htmlField,
      };
      setIsSending(true);
      await sendEmailsToParentsInRoom(data);
      setIsSending(false);
    } catch {
      setIsSending(false);
      bug('Error while sending emails');
    }
  }, [fromField, roomField, htmlField, subject, bug]);

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const handleRoomIdFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRoomField(event.target.value);
    },
    [],
  );

  const handleSubjectFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSubject(event.target.value);
    },
    [],
  );

  const handleSetFormFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFromField(event.target.value);
    },
    [],
  );
  const handleSetHTMLFieldChange = useCallback((event: any, editor: any) => {
    setHtmlField(editor.getData());
  }, []);

  const editorProps = useCallback((editor: any) => {
    const editableElement = editor.ui.getEditableElement();
    if (editableElement) {
      editableElement.parentElement?.classList.add(styles.emails__editor);
    }
  }, []);

  return (
    <Page title="Schedule">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            <MailIcon /> Emails
          </Typography>
        </Stack>
        <Stack>
          <Card sx={cardStyles}>
            <Stack>
              <Stack direction="row" alignItems="center" gap={1}>
                <EmailIcon />
                <Typography>Send emails to parents</Typography>
              </Stack>
              <br />
              <Stack direction="row" alignItems="center" gap={1}>
                {/* <Typography>Room types</Typography>
                <FormControlLabel control={<Checkbox defaultChecked />} label="Production" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Individual" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Demo" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Test" />
                <FormControlLabel control={<Checkbox defaultChecked />} label="Only one room" /> */}
                <TextField
                  size="small"
                  required
                  id="outlined-required"
                  label="Room id"
                  defaultValue=""
                  onChange={handleRoomIdFieldChange}
                />
              </Stack>
              <br />
              <TextField
                required
                id="outlined-required"
                label="Title"
                defaultValue=""
                onChange={handleSubjectFieldChange}
              />
              <br />
              <TextField
                required
                id="outlined-required"
                label="From"
                onChange={handleSetFormFieldChange}
                defaultValue="support@pixblocks.pl"
                disabled
              />
              <br />
              {/* <Stack direction="row" spacing={2}>
                <Button size="small">name</Button> <Button size="small">full name</Button>
              </Stack> */}

              <CKEditor
                editor={ClassicEditor}
                data=""
                onChange={handleSetHTMLFieldChange}
                onReady={editorProps}
              />
              <br />
              <LoadingButton
                variant="contained"
                endIcon={<SendIcon />}
                loading={isSending}
                onClick={sendEmail}
              >
                Send
              </LoadingButton>
            </Stack>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
