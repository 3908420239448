import { useEffect } from 'react';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { FileType } from 'types/FileTypes';
import { LeaderModeType } from 'types/LeaderModeTypes';
import { ISwitchLeaderMode } from './interfaces';

export const SwitchLeaderMode = (data: ISwitchLeaderMode) => {
  const { leaderModeSwitchValue, question } = data;
  const { currentUser } = useAppContext();
  const { fatal } = useErrorContext();
  const { currentUserId, leaderMode, room } = useWorkspaceContext();

  useEffect(() => {
    const switchLeaderMode = async () => {
      if (leaderModeSwitchValue && question) {
        try {
          await leaderMode.setQuestion({
            roomId: room?.id as string,
            leaderQuestionId: question?.guid as string,
            userId: currentUserId ? currentUserId : (currentUser?.uid as string),
            tutorId: currentUser?.uid as string,
            questionType: question?.type as FileType,
          });
          await leaderMode.setLeaderModeType(LeaderModeType.TutorLeaderMode);
        } catch (e) {
          fatal(e);
        }
      } else {
        try {
          await leaderMode.setDebugStep(-1);
          await leaderMode.setLeaderModeType(LeaderModeType.None);
        } catch (e) {
          fatal(e);
        }
      }
    };

    switchLeaderMode();
  }, [
    question,
    room?.id,
    currentUser?.uid,
    leaderMode,
    leaderModeSwitchValue,
    currentUserId,
    fatal,
  ]);
};
