import { useCallback, useState } from 'react';
import { clearMembersDataInRoom } from 'store/functions';
import { inputStyles, buttonStyles } from './constants';
import { Trans } from '@lingui/macro';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomModal from 'components/organisms/CustomModal';
import TextField from '@mui/material/TextField';
import ClearMembersDataModalProps from './interface';

export default function ClearMembersDataModal(props: ClearMembersDataModalProps) {
  const { roomId, onClose, show } = props;
  const [canClearData, setCanClearData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const clear = useCallback(async () => {
    try {
      setIsLoading(true);
      await clearMembersDataInRoom(roomId);
      setIsLoading(false);
    } catch (e) {
      throw e;
    }
  }, [roomId]);

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setCanClearData(roomId === e.target.value);
    },
    [roomId],
  );

  return (
    <CustomModal backdrop onClose={onClose} show={show}>
      <Trans id="clearMembersModal.info">
        Are you sure you want to delete student data? To confirm enter your room number:
      </Trans>
      <b>{roomId}</b>
      <TextField
        id="outlined-basic"
        label={<Trans id="room.number">Room number</Trans>}
        onChange={onChangeValue}
        variant="outlined"
        sx={inputStyles}
      />
      <LoadingButton
        variant="outlined"
        color="error"
        startIcon={<DeleteIcon />}
        sx={buttonStyles}
        disabled={!canClearData}
        onClick={clear}
        loading={isLoading}
      >
        <Trans id="delete">Delete</Trans>
      </LoadingButton>
    </CustomModal>
  );
}
