import { countStudentsBadges } from 'pages/CurrentRoom/helpers/studentBadgesHelper';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { Trans } from '@lingui/macro';

export default function StudentSubHeader() {
  const { badgesCounter } = useWorkspaceContext();

  return (
    <>
      <Trans id="badgeModal.subHeader.student" />
      {countStudentsBadges(badgesCounter)}
    </>
  );
}
