import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CHANGE_LESSON_ACTION } from 'classes/UserActions';
import { isStudent } from 'helpers/userHelper';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { IContentBrowserProps } from 'types/ContentBrowserTypes';
import { RoomType } from 'types/enums';
import { ILessonTree } from 'types/ContentTypes';
import { ChangeLessonButton, ChangeLessonModal } from './components';
import { filterUserLessons } from './helpers/filterUserLessons';
import { CloseModal } from './hooks/callbacks';
import { GetContent, SetLessonOnTutorEnter, WatchTutorActivity } from './hooks/effects';
import styles from './content-browser.module.css';

export default function ContentBrowser(props: IContentBrowserProps) {
  const { collapsedSidebar } = props;
  const { currentUser } = useAppContext();
  const { fatal, bug } = useErrorContext();
  const { locale: currentLocale } = useLocaleContext();
  const { content, setLocalCourseId, setLocalLessonId, userActions, room } = useWorkspaceContext();
  const [contentData, setContentData] = useState<ILessonTree[]>([]);
  const [isCoursesModalOpened, setIsCoursesModalOpened] = useState<boolean>(false);
  const [isTutorActive, setIsTutorActive] = useState<boolean>(false);
  const [lessonsHistory, setLessonsHistory] = useState<Record<string, string[]>>({});
  const { roomId } = useParams();
  // derived
  const canChangeLesson = userActions.askForAction(CHANGE_LESSON_ACTION);
  const isStudentRole = isStudent(currentUser?.role);
  const _courses: ILessonTree[] =
    isStudentRole && room?.roomType !== RoomType.Individual
      ? filterUserLessons(contentData, lessonsHistory)
      : contentData;
  const disableContentBtn =
    (_courses.length === 0 || (isTutorActive && !canChangeLesson)) &&
    room?.roomType !== RoomType.Individual &&
    room?.roomType !== RoomType.Demo;
  const closeModal = CloseModal({ setIsCoursesModalOpened });

  try {
    SetLessonOnTutorEnter({
      isStudentRole,
      isTutorActive,
      roomId,
      closeModal,
      setLocalCourseId,
      setLocalLessonId,
    });
    WatchTutorActivity({ roomId, setIsTutorActive });
  } catch (e) {
    bug(e);
  }
  try {
    GetContent({ content, currentLocale, roomId, setContentData, setLessonsHistory });
  } catch (e) {
    fatal(e);
  }

  return (
    <div className={styles['content-browser__content']}>
      <ChangeLessonButton
        collapsedSidebar={collapsedSidebar}
        disableContentBtn={disableContentBtn}
        isCoursesModalOpened={isCoursesModalOpened}
        setIsCoursesModalOpened={setIsCoursesModalOpened}
      />
      <ChangeLessonModal
        canChangeLesson={canChangeLesson}
        courses={_courses}
        isCoursesModalOpened={isCoursesModalOpened}
        isTutorActive={isTutorActive}
        closeModal={closeModal}
      />
    </div>
  );
}
