import React from 'react';

import { IAccordionContext } from './types';

export function useAccordionContext() {
  const context = React.useContext(AccordionContext);

  if (!context) {
    throw new Error(`useAccordionContext must be used within AccordionContext Provider`);
  }

  return context;
}

export const AccordionContext = React.createContext<IAccordionContext>(undefined!);
AccordionContext.displayName = 'AccordionContext';
