import { languageSchema } from './general.schema';
import { z } from 'zod';
import { FileAccess, FileType } from 'types/enums';

export const FileTypeEnum = z.nativeEnum(FileType);
export const FileAccessEnum = z.nativeEnum(FileAccess);

export const fileSchema = z.object({
  title: languageSchema,
  description: languageSchema,
  guid: z.string(),
  type: FileTypeEnum,
  isOptionalTask: z.boolean().optional(),
  isSolved: z.boolean().optional(),
});

export const fileDataTypeSchema = z.object({
  id: z.string(),
  type: z.string(),
  userId: z.string(),
});

export const courseSchema = z.object({
  title: z.string(),
  description: z.string(),
  questions: fileSchema.array(),
});

export const fileEventSchema = z.object({
  id: z.string(),
  type: z.string(),
  title: z.string(),
});
