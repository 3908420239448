import { useCallback } from 'react';
import { IHandleStudentSubmit } from './interfaces';

export const HandleStudentSubmit = (data: IHandleStudentSubmit) => {
  const { handleClose, setLocalLessonId } = data;

  return useCallback(
    (id: string) => {
      setLocalLessonId(id);
      handleClose();
    },
    [handleClose, setLocalLessonId],
  );
};
