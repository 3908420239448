import { Dispatch, useCallback, useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { sleep } from 'helpers/TimeHelper';
import { useAppContext } from 'providers/app/app.provider';
import { MemberData } from 'types/MemberTypes';
import { UserType } from 'types/UserTypes';
import { modalStatus } from './constants';
import CustomModal from 'components/organisms/CustomModal';
import styles from './badgeModal.module.css';
import StudentBadges from './StudentBadges/StudentBadges';
import TutorBadges from './TutorBadges/TutorBadges';
import Title from './Title/Title';

interface BadgeModalProps {
  badgeUser: MemberData | null;
  setBadgeUser: Dispatch<MemberData | null>;
}

export default function BadgeModal(props: BadgeModalProps) {
  const { badgeUser, setBadgeUser } = props;
  const { currentUser } = useAppContext();
  const [status, setStatus] = useState<number>(modalStatus.UNSET);
  const currentStatus = useRef<number>(modalStatus.UNSET);

  useEffect(() => {
    const handleStatusChange = async () => {
      switch (status) {
        case modalStatus.UNSET:
          currentStatus.current = modalStatus.UNSET;
          await sleep(250);
          setBadgeUser(null);
          break;
        case modalStatus.GRANTED:
          currentStatus.current = modalStatus.GRANTED;
          await sleep(3000);
          currentStatus.current === modalStatus.GRANTED && setStatus(modalStatus.UNSET);
          break;
        default:
          currentStatus.current = modalStatus.SET;
          break;
      }
    };

    handleStatusChange();
  }, [status, setBadgeUser]);

  useEffect(() => {
    badgeUser && setStatus(modalStatus.SET);
  }, [badgeUser]);

  const onClose = useCallback(() => {
    setStatus(modalStatus.UNSET);
  }, [setStatus]);

  const modalClassNames = clsx(
    styles['badge-modal__content'],
    currentUser?.role === UserType.Tutor
      ? styles['badge-modal__content--tutor']
      : styles['badge-modal__content--student'],
  );

  return (
    <CustomModal
      backdrop
      show={status !== modalStatus.UNSET}
      onClose={onClose}
      modalClassName={modalClassNames}
      closeButton
    >
      <>
        <Title user={badgeUser} />
        {currentUser?.role === UserType.Tutor ? (
          <TutorBadges status={status} user={badgeUser} setStatus={setStatus} />
        ) : (
          <StudentBadges />
        )}
      </>
    </CustomModal>
  );
}
