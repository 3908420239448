import { BadgeType } from 'types/enums';
import { useAppContext } from 'providers/app/app.provider';
import { UserType } from 'types/UserTypes';
import { modalStatus } from '../constants';
import badgeImage from 'assets/images/badges/badge.png';
import creative from 'assets/images/badges/creative.png';
import debug from 'assets/images/badges/debugger.png';
import lightning from 'assets/images/badges/lightning.png';
import perfect from 'assets/images/badges/perfect.png';
import clsx from 'clsx';
import styles from './imageSection.module.css';

interface ImageSectionProps {
  active?: boolean;
  badge: BadgeType;
  hovered: boolean;
  status?: number;
}
const images = {
  [BadgeType.Creative]: creative,
  [BadgeType.Debugger]: debug,
  [BadgeType.Lightning]: lightning,
  [BadgeType.Perfect]: perfect,
};
export default function ImageSection(props: ImageSectionProps) {
  const { active = true, badge, hovered, status = modalStatus.UNSET } = props;
  const { currentUser } = useAppContext();
  const raise =
    hovered &&
    ((!active && status !== modalStatus.GRANTED) || currentUser?.role === UserType.Student);
  const imageItemClassName = clsx(
    styles['image-section__image-item'],
    raise
      ? styles['image-section__image-item--raised']
      : styles['image-section__image-item--dropped'],
  );

  return (
    <div className={styles['image-section__content']}>
      <img src={badgeImage} alt="badge" className={styles['image-section__image-badge']} />
      <img src={images[badge]} alt={badge} className={imageItemClassName} />
    </div>
  );
}
