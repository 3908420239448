import { useCallback } from 'react';
import { FileEvent } from 'types/FileTypes';
import { IUpdateCurrentFile } from './interfaces';

export const UpdateCurrentFile = (data: IUpdateCurrentFile) => {
  const { setCurrentFile, setLoadedAce } = data;
  return useCallback(
    (file: FileEvent) => {
      setLoadedAce(false);
      setCurrentFile(file);
    },
    [setCurrentFile, setLoadedAce],
  );
};
