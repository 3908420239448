import { useErrorContext } from 'providers/error/error.provider';
import { setForcedFeedbackForRoom } from 'store/FirebaseStore';
import { Trans } from '@lingui/macro';
import CustomModal from 'components/organisms/CustomModal';
import styles from './formModal.module.css';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
// Wyślij
export interface FileDescriptionModalProps {
  isShow: boolean;
  roomId?: string;
  onClose: () => void;
}

export default function FormModal(props: FileDescriptionModalProps) {
  const { isShow, onClose, roomId } = props;
  const { bug } = useErrorContext();

  async function showFeedbackFormForStudents() {
    try {
      await setForcedFeedbackForRoom(roomId, true);
      onClose();
    } catch (e) {
      bug(e);
    } finally {
      // set forcedFeedback again to false
      await setForcedFeedbackForRoom(roomId);
    }
  }

  return (
    <CustomModal backdrop onClose={onClose} show={isShow}>
      <>
        <h2 id="responsive-dialog-title" className={styles['form-modal__header']}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Stack direction="row" alignItems="center" gap={2}>
                <Trans id="evaluationSurvey.header">Evaluation survey</Trans>
              </Stack>
            </Box>
          </Box>
        </h2>
        <div>
          <p className={styles['form-modal__subheader']}>
            <Trans id="evaluationSurvey.description">
              By clicking "send" you will now allow students to complete the evaluation
              questionnaire.
            </Trans>
          </p>
        </div>
        <div className={styles['form-modal__button-group']}>
          <div
            data-testid="form-modal-cancel-btn"
            onClick={onClose}
            className={styles['form-modal__button']}
          >
            <Trans id="actions.cancel" />
          </div>
          <div
            data-testid="form-modal-send-btn"
            onClick={showFeedbackFormForStudents}
            className={styles['form-modal__button']}
          >
            <Trans id="actions.send">Send</Trans>
          </div>
        </div>
      </>
    </CustomModal>
  );
}
