import { ILessonTree } from 'types/ContentTypes';

export function filterUserLessons(
  courses: ILessonTree[],
  lessons: Record<string, string[]>,
): ILessonTree[] {
  const filteredCourses = courses.filter(child => Boolean(lessons[child.guid as string]));

  const filteredCoursesLessons = filteredCourses.map(course => {
    const lessonsIds = lessons[course.guid as string] ?? [];
    const courseLessons =
      course.children?.filter(child => lessonsIds.includes(child.guid as string)) ?? [];

    return {
      ...course,
      children: courseLessons,
    };
  });

  return filteredCoursesLessons;
}
