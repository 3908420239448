import { IFilteredCourse, ILessonTree, ParsedCourse } from 'types/ContentTypes';
import { LanguageType } from 'types/generalTypes';
import { I18Languages } from 'types/i18nTypes';

const createLessonsTree = (
  courseData: ParsedCourse,
  i: number,
  locale: keyof LanguageType,
): [number, ILessonTree[]] => {
  let id = i + 1;
  const lessonsObject = Object.entries(courseData.lessons).sort(([lesson1], [lesson2]) =>
    lesson1 > lesson2 ? 1 : -1,
  );
  const lessons = lessonsObject.map(([lessonId, lesson]) => {
    const lessonData = {
      id: id.toString(),
      name: `${lesson.title[locale]} (${lesson.description[locale]})`,
      description: lesson.description[locale],
      guid: lessonId,
      course: courseData.guid,
    };
    id++;
    return lessonData;
  });
  return [id, lessons];
};

export const createCoursesTree = (
  content: ParsedCourse[],
  locale: keyof LanguageType,
): ILessonTree[] => {
  if (!content) return [];
  let i: number = 0;
  const coursesTree = content.map(_course => {
    const [id, lessons] = createLessonsTree(_course, i, locale);
    const name = `${_course.title[locale]} (${_course.description[locale]})`;
    const course: ILessonTree = {
      id: i.toString(),
      name,
      description: _course.description[locale],
      guid: _course.guid,
      children: lessons,
    };
    i = id;
    return course;
  });
  return coursesTree;
};

export const getFirstLessonPath = (courses: ParsedCourse[]): IFilteredCourse => {
  const [defaultCourse] = courses;
  const defaultLesson = Object.entries(defaultCourse.lessons)
    .reverse()
    .sort((a, b) => (a[0] < b[0] ? 1 : -1))
    .pop();
  return {
    coursePath: defaultCourse.guid,
    lessonPath: defaultLesson?.[0],
  };
};

export const getScopeFromFirstLesson = (
  courses: ParsedCourse[],
  locale: I18Languages,
  separator: string,
  extendedLabels: boolean = true,
) => {
  const [defaultCourse] = courses;
  const defaultLesson = Object.entries(defaultCourse.lessons)
    .reverse()
    .sort((a, b) => (a[0] < b[0] ? 1 : -1))
    .pop();
  const courseTitle = defaultCourse.title[locale.toUpperCase() as keyof LanguageType];
  const courseDescription = defaultCourse.description[locale.toUpperCase() as keyof LanguageType];
  const courseScope = extendedLabels ? `${courseTitle} (${courseDescription})` : courseTitle;
  const lessonTitle = defaultLesson
    ? defaultLesson[1].title[locale.toUpperCase() as keyof LanguageType]
    : '';
  const lessonDescription = defaultLesson
    ? defaultLesson[1].description[locale.toUpperCase() as keyof LanguageType]
    : '';
  const lessonScope = extendedLabels ? `${lessonTitle} (${lessonDescription})` : lessonTitle;
  return `${courseScope} ${separator} ${lessonScope}`;
};

export const getDescriptionFromFirstLesson = (courses: ParsedCourse[], locale: I18Languages) => {
  const [defaultCourse] = courses;
  const defaultLesson = Object.entries(defaultCourse.lessons)
    .reverse()
    .sort((a, b) => (a[0] < b[0] ? 1 : -1))
    .pop();
  const lessonDescription = defaultLesson
    ? defaultLesson[1].description[locale.toUpperCase() as keyof LanguageType]
    : '';
  return lessonDescription;
};
