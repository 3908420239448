import { FileType } from 'types/FileTypes';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import RocketIcon from '@mui/icons-material/RocketLaunch';
import ShovelIcon from 'assets/svg/ShovelIcon';
import styles from './questionIcon.module.css';

interface QuestionIconProps {
  type?: FileType;
  optional?: boolean;
  isSolved?: boolean;
}

export default function QuestionIcon({
  type,
  optional = false,
  isSolved = false,
}: QuestionIconProps) {
  const classNameGrey = styles['question-icon--grey'];
  const classNamePurple = styles['question-icon--purple'];

  switch (type) {
    case FileType.Sample:
      return <FiberManualRecordIcon className={classNameGrey} />;
    case FileType.Free:
      return <RocketIcon className={classNameGrey} />;
    case FileType.Sandpit:
      return <ShovelIcon />;
    case FileType.Task:
      if (optional) {
        return isSolved ? (
          <StarIcon className={classNamePurple} />
        ) : (
          <StarBorderOutlinedIcon className={classNamePurple} />
        );
      }
      return isSolved ? (
        <FiberManualRecordIcon className={classNamePurple} />
      ) : (
        <FiberManualRecordOutlinedIcon className={classNamePurple} />
      );

    default:
      return <FiberManualRecordIcon className={classNameGrey} />;
  }
}
