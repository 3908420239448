import { useCallback } from 'react';
import { clsx } from 'clsx';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { LanguageType } from 'types/generalTypes';
import { QuestionProps } from './interface';
import QuestionIcon from 'pages/CurrentRoom/components/QuestionIcon';
import alignStyles from 'shared/styles/alignment.module.css';
import styles from './question.module.css';

export default function Question(props: QuestionProps) {
  const { question, isSolved, idActiveFile, changeActiveFile } = props;
  const { activeFile } = useWorkspaceContext();
  const { collapsedSidebar } = useCurrentRoomContext();
  const { locale: currentLocale } = useLocaleContext();
  const locale = currentLocale.toUpperCase() as keyof LanguageType;
  const questionClassNames = clsx(
    styles.question,
    (activeFile?.guid === question.guid || idActiveFile === question.guid) &&
      styles['question--selected'],
    collapsedSidebar && alignStyles['flex-center'],
  );
  const dataTestId = `question-${question.type}`;

  const handleClick = useCallback(() => {
    changeActiveFile({ ...question, isSolved });
  }, [question, isSolved, changeActiveFile]);

  return (
    <div className={questionClassNames} onClick={handleClick} data-testid={dataTestId}>
      <QuestionIcon
        type={question.type}
        optional={Boolean(question.isOptionalTask)}
        isSolved={isSolved}
      />
      {!collapsedSidebar && (
        <span className={styles.question__content}>{question.title[locale]}</span>
      )}
    </div>
  );
}
