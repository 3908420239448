import { white, purple } from 'constants/Colors';
import { Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './loader.module.css';

export interface LoaderProps {
  text?: string;
  size?: number;
  isLoading?: boolean;
}

const backdropStyle = { color: white, zIndex: (theme: any) => theme.zIndex.drawer + 1 };
const spinerStyle = { color: purple, border: '400px' };

export default function Loader(props: LoaderProps) {
  const { text, size, isLoading = false } = props;

  return (
    <Backdrop sx={backdropStyle} open={isLoading}>
      <CircularProgress sx={spinerStyle} size={size} />
      <Typography variant="h6" className={styles['loader__spiner-text']}>
        {text}
      </Typography>
    </Backdrop>
  );
}
