import { z } from 'zod';
import { UserType } from 'types/UserTypes';
import { Country } from 'types/enums';

export const userTypeEnum = z.nativeEnum(UserType);
export const countrysTypeEnum = z.nativeEnum(Country);

export const userSchema = z.object({
  name: z.string(),
  lastName: z.string(),
  email: z.string(),
  uid: z.string(),
  role: userTypeEnum,
  gender: z.string().optional(), // TODO change to enum later,
  age: z.number().optional(),
  parentEmail: z.string().optional(),
  phoneNumber: z.string().optional(),
  userType: z.string().optional(), //change to enum later
  yearOfBirth: z.number().optional(),
  country: countrysTypeEnum.optional(),
  city: z.string().optional(),
  creadtedBy: z.string().optional(),
});

export const userDataSchema = z.object({
  name: z.string(),
  surname: z.string(),
  color: z.string(),
  uid: z.string(),
});

export const currentUserSchema = userSchema.extend({
  color: z.string(),
  isLogged: z.boolean(),
});

export const changeUserDataEventSchema = z.object({
  userUID: z.string(),
});
