import { FileType } from 'types/FileTypes';

// TODO use enum, not hardcoded strings
export function getType(type: string) {
  if (type === 'sandpit' || type === '2') {
    return FileType.Sandpit;
  } else if (type === 'sample' || type === '0') {
    return FileType.Sample;
  } else if (type === 'task' || type === '1') {
    return FileType.Task;
  } else if (type === 'free' || type === '3') {
    return FileType.Free;
  }

  throw new Error(
    `Unknown type: ${type}, please provide only these types: ${FileType.Sandpit}, ${FileType.Free}, ${FileType.Sample}, ${FileType.Task}`,
  );
}
