import {
  TextField,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { useCallback } from 'react';

export interface MultiSelectProps {
  title: string;
  values: string[];
  selected: string[];
  setSelected: Function;
}

export default function MultiSelect(props: MultiSelectProps) {
  const { values, title, selected, setSelected } = props;

  const handleOptionsChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: string[],
      reason: AutocompleteChangeReason,
      details?: {
        option: string;
      },
    ) => {
      setSelected(value);
    },
    [setSelected],
  );

  const renderTextField = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField {...params} variant="outlined" label={title} placeholder={title} />
    ),
    [title],
  );

  const returnOption = useCallback((option: any) => option, []);

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={values}
      getOptionLabel={returnOption}
      value={selected}
      onChange={handleOptionsChange}
      renderInput={renderTextField}
    />
  );
}
