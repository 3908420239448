import { Trans } from '@lingui/macro';
interface ITutorSubHeader {
  userName: string;
}
export default function TutorSubHeader(props: ITutorSubHeader) {
  const { userName } = props;
  return (
    <>
      {userName} <Trans id="badgeModal.subHeader.tutor" />
    </>
  );
}
