import { useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

interface LoadingResponseProps {
  abort: Function;
}

export default function LoadingResponse(props: LoadingResponseProps) {
  const { abort } = props;
  const styles = useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: '30px',
    }),
    [],
  );

  return (
    <Box sx={styles}>
      <CircularProgress />
      {/* <Button onClick={abort(true)} variant="text">
        Przerwij
      </Button> */}
    </Box>
  );
}
