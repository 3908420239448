import { useMemo, useCallback } from 'react';
import { Box, Avatar } from '@mui/material';
import { ChatLogType } from 'types/enums';
import { ReactComponent as GptLogo } from 'assets/svg/chatGPTLogo.svg';
import TextDisplay from '../TextDisplay/TextDisplay';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';

interface IChatMessageLog {
  type: ChatLogType;
  text: string;
}

export default function ChatMessageLog(data: IChatMessageLog) {
  const { type, text } = data;
  const avatarStyles = useMemo(() => ({ bgcolor: '#895AFD' }), []);
  const copyBtnStyles = useMemo(() => ({ alignContent: 'flex-end' }), []);
  const textStyles = useMemo(() => ({ marginLeft: '10px' }), []);
  const boxStyles = useMemo(
    () => ({
      display: 'flex',
      color: '#FFFFFF',
      backgroundColor: type === ChatLogType.User ? '#2E2B36' : '#353f4b',
      width: '100% -30px',
      flexDirection: 'row',
      padding: '20px',
      borderRadius: '5px',
      marginBottom: '10px',
    }),
    [type],
  );

  const copyToclipboard = useCallback(() => {
    navigator.clipboard.writeText(text);
  }, [text]);

  return (
    <Box sx={boxStyles}>
      <Box>
        {type === ChatLogType.User ? (
          <Avatar sx={avatarStyles}>
            <PersonIcon />
          </Avatar>
        ) : (
          <GptLogo height={40} width={40} fill="#895AFD" />
        )}
      </Box>
      <Box sx={textStyles}>
        {type === ChatLogType.GPT ? <TextDisplay text={text} delay={100} /> : text}
      </Box>
      {type === ChatLogType.GPT && (
        <Box sx={copyBtnStyles}>
          <Tooltip title="Kopiuj">
            <IconButton aria-label="delete" size="small" onClick={copyToclipboard}>
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
