import { purple } from 'constants/Colors';
import { PopoverOrigin } from '@mui/material';

export const anchorPosition: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

export const sxRight = { marginRight: '16px' };

export const menuContainerSx = {
  minWidth: '300px',
};

export const menuIconSx = {
  backgroundColor: purple,
  margin: '8px',
  padding: '8px',
  gap: '10px',
  width: '32px',
  height: '32px',
  borderRadius: '64px',
};
