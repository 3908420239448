import { HeaderCellProps } from './interface';
import { Avatar, Stack, TableCell, Typography } from '@mui/material';

export default function HeaderCell(props: HeaderCellProps) {
  const { children, renderAvatar } = props;

  return (
    <TableCell component="th" scope="row" padding="none">
      <Stack direction="row" alignItems="center" spacing={2}>
        {renderAvatar && <Avatar alt={children?.toString()} />}
        <Typography variant="subtitle2" noWrap>
          {children}
        </Typography>
      </Stack>
    </TableCell>
  );
}
