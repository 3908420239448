import { EmailData, UserData } from 'schemas/email';
import { getResolvedTasksInRoom } from 'store/FirebaseStore';
import { SolvedTaskData } from 'types/ContentTypes';
import { sendEmailWithSendGridTemplate } from 'store/functions';

const from = 'support@pixblocks.pl';

function polishPluralStars(value: number) {
  if (value === 1) {
    return 'gwiazdka';
  } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
    return 'gwiazdek';
  }
  return 'gwiazdek';
}

export async function sendProgressEmail(task: SolvedTaskData, userData: UserData) {
  try {
    const resolvedTasks = await getResolvedTasksInRoom(task.userId, task.roomId);
    if (resolvedTasks === 5 || resolvedTasks % 5 === 0) {
      const starsPlural = polishPluralStars(resolvedTasks);
      await sendEmailWithSendGridTemplate(
        {
          to: userData.parentEmail,
          from,
          subject: `${userData.name} ${userData.lastName} ma już ${resolvedTasks} ${starsPlural} w akcji PixMasters! Gratulujemy!`,
          reply_to: from,
          template_id:
            resolvedTasks === 5
              ? 'd-e3977256218f42b38415d4c68370fef1'
              : 'd-f5d77f16fe1d43e7bc8016270aded55c',
        },
        {
          points: resolvedTasks,
          name: userData.name,
          lastName: userData.lastName,
          starsPlural,
        },
      );
    }
  } catch (e) {
    throw new Error(`Can't send progress email`);
  }
}
