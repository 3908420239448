import fetch, { fetch as crossFetch } from 'cross-fetch';

const GUID = '0052c8e6b77e4017b168a0e538c8a99db8e38e241c42948b9c7d5e49a5cdba8b';

function getMeetLink() {
  const data = {
    token: GUID,
  };

  function getResponse() {
    return fetch('https://pixblocks-whereby-api-test.azurewebsites.net/api/meetLink', {
      method: 'POST',
      credentials: 'include',
      // @ts-ignore
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': true,
      },
      body: JSON.stringify(data),
    });
  }

  return getResponse()
    .then(res => {
      if (res.status !== 200) throw Error("Couldn't connect with WhereBy");
      return res.json();
    })
    .then(data => data.roomUrl);
}

export default getMeetLink;
