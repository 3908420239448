import { tail } from 'helpers/ListHelpers';
import { IMenuItems, ISelectedMenuItem } from 'types/MenuTypes';
import { PopoverOrigin } from '@mui/material';
import MenuItem from './MenuItem';

export const generateMenuItems = (
  items: IMenuItems[],
  prevCallback: (v: ISelectedMenuItem) => void,
  classNames?: string[],
  anchorOrigins?: PopoverOrigin[],
  transformOrigins?: PopoverOrigin[],
): JSX.Element[] =>
  items.map(item => (
    <MenuItem
      key={item.key}
      item={item}
      menuClassNames={tail(classNames)}
      anchorOrigin={tail(anchorOrigins)}
      transformOrigin={tail(transformOrigins)}
      selectItem={prevCallback}
    />
  ));
