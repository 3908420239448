import { useCallback } from 'react';
import { codeToBlocks } from 'helpers/BlocksCodeTransformer';
import { IShowSolution } from './interfaces';

export const ShowSolution = (data: IShowSolution) => {
  const { aceRef } = data;

  return useCallback(
    (isLanguageBlocks: boolean, code: string) => {
      if (isLanguageBlocks && aceRef.current) {
        aceRef.current.setValue(codeToBlocks(code));
        return;
      }
      aceRef.current?.setValue(code);
    },
    [aceRef],
  );
};
