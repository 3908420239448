import { Controller, ControllerRenderProps } from 'react-hook-form';
import { UserCreationForm } from 'schemas/adminForms.schema';
import { TextareaControllerProps } from './interface';
import TextArea from 'components/atoms/Textarea';
import { useCallback, useMemo } from 'react';

type renderPropsType = {
  field: ControllerRenderProps<UserCreationForm, keyof UserCreationForm>;
};

export default function TextareaController(props: TextareaControllerProps) {
  const { control, label, name, rowsNumer } = props;

  const inputStyles = useMemo(() => ({ style: { color: 'black' } }), []);

  const renderContent = useCallback(
    (renderProps: renderPropsType) => (
      <TextArea inputProps={inputStyles} label={label} rows={rowsNumer} {...renderProps.field} />
    ),
    [label, rowsNumer, inputStyles],
  );

  return <Controller name={name} control={control} render={renderContent} />;
}
