import { isTutor } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { MemberData } from 'types/MemberTypes';
import { Trans } from '@lingui/macro';
import styles from './title.module.css';
import StudentSubHeader from './SubHeader/StudentSubHeader';
import TutorSubHeader from './SubHeader/TutorSubHeader';

interface TitleProps {
  user: MemberData | null;
}

export default function Title(props: TitleProps) {
  const { user } = props;
  const { currentUser } = useAppContext();

  return (
    <div className={styles.title__content}>
      <div className={styles.title__text}>
        <h2>
          {isTutor(currentUser?.role) ? (
            <Trans id="badgeModal.header.tutor" />
          ) : (
            <Trans id={'badgeModal.header.student'} />
          )}
        </h2>
        {user && (
          <p>
            {isTutor(currentUser?.role) ? (
              <TutorSubHeader userName={user.name} />
            ) : (
              <StudentSubHeader />
            )}
          </p>
        )}
      </div>
    </div>
  );
}
