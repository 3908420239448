import { useEffect, useState } from 'react';
import { getPrivateFileRef } from 'store/FirebaseStore';
import { useParams } from 'react-router-dom';
import { getSharedData } from 'store/chunks/store/shares';
import { SharedCompilerData } from 'types/ShareTypes';
import PublicCompilers from './components/PublicCompilers';

export default function ShareApp() {
  const { shareId } = useParams();
  const [url, setUrl] = useState<string>('');
  const [compilerData, setCompilerData] = useState<SharedCompilerData | null>(null);

  useEffect(() => {
    const generateApp = async () => {
      try {
        const data = await getSharedData(shareId as string);
        if (data) {
          const ref = getPrivateFileRef(
            data?.roomId as string,
            data?.questionId as string,
            data?.userId as string,
          );
          const headless = new window.Firepad.Headless(ref);
          headless.getText(text => {
            setCompilerData({ code: text, ...data });
          });
        }
      } catch (e) {
        throw e;
      }
    };
    generateApp();
  }, [shareId, setCompilerData]);

  return <PublicCompilers url={url} setUrl={setUrl} compilerData={compilerData} />;
}
