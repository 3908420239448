import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { LanguageType } from 'types/generalTypes';
import { TasksProps } from './interface';
import QuestionBlock from '../../QuestionBlock';

export default function Tasks(props: TasksProps) {
  const {
    idActiveFile,
    itemIndex,
    questions,
    section,
    sectionIds,
    selectedSection,
    solvedQuestions,
    changeActiveFile,
    setSelectedSection,
  } = props;
  const { locale: currentLocale } = useLocaleContext();
  const locale = currentLocale.toUpperCase() as keyof LanguageType;

  const currentSectionId: number = sectionIds[itemIndex];
  const nextSectionId: number = sectionIds[itemIndex + 1];
  const start = currentSectionId - 1;
  const end = nextSectionId ? nextSectionId - 1 : questions.length;

  const _questions = questions.slice(start, end);

  return (
    <QuestionBlock
      blockNumber={itemIndex + 1}
      idActiveFile={idActiveFile}
      isExpanded={itemIndex === 0}
      questions={_questions}
      scenarios={section.docs}
      selectedSection={selectedSection}
      solvedQuestions={solvedQuestions}
      title={section.title[locale]}
      videos={section.video}
      changeActiveFile={changeActiveFile}
      setSelectedSection={setSelectedSection}
    />
  );
}
