import { useCallback, useState } from 'react';
import { clsx } from 'clsx';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { I18Languages } from 'types/i18nTypes';
import { ISelectedMenuItem } from 'types/MenuTypes';
import { languagesMenuAnchorOrigin, languagesMenuTransformOrigin } from './constants';
import { getCurrentLanguage, getOtherLanguages } from './helpers';
import { Trans } from '@lingui/macro';
import MenuButton from 'components/molecules/MenuButton';
import styles from './languageSwitcher.module.css';
import FlagSharpIcon from '@mui/icons-material/FlagSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';

export default function LanguageSwitcher() {
  const { locale, languageSwitcher } = useLocaleContext();
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
  const selectedLanguage = getCurrentLanguage(locale);
  const languageList = getOtherLanguages(locale);

  const handleLanguageChange = useCallback(
    (value: ISelectedMenuItem) => {
      languageSwitcher(value.key as I18Languages);
    },
    [languageSwitcher],
  );

  const handleMenuOpen = useCallback(() => {
    setMenuCollapsed(false);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuCollapsed(true);
  }, []);

  const menuButtonContentClassName = clsx(
    styles['language-switcher__button'],
    !menuCollapsed && styles['language-switcher__button--extended'],
  );

  return (
    <MenuButton
      menuItems={languageList}
      anchorOrigin={languagesMenuAnchorOrigin}
      transformOrigin={languagesMenuTransformOrigin}
      onItemSelected={handleLanguageChange}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      buttonClassName={styles['language-switcher__content']}
    >
      <div className={menuButtonContentClassName}>
        <FlagSharpIcon />
        {!menuCollapsed && (
          <>
            <p>
              <Trans id="login.language" /> {selectedLanguage}
            </p>
            <KeyboardArrowRightSharpIcon />
          </>
        )}
      </div>
    </MenuButton>
  );
}
