import { z } from 'zod';
import { FileTypeEnum } from './file.schema';

export const leaderModeDataSchema = z.object({
  isLeaderMode: z.boolean(),
  tutorId: z.string(),
  userId: z.string(),
  leaderQuestionId: z.string(),
  questionType: FileTypeEnum,
});

export const leaderModeQuestionSchema = z.object({
  roomId: z.string(),
  tutorId: z.string(),
  userId: z.string(),
  leaderQuestionId: z.string(),
  questionType: FileTypeEnum,
});

export const IsLeaderModeSchema = z.object({
  roomId: z.string(),
  isLeaderMode: z.boolean(),
});
