import { Seniority, AdminLanguage } from 'types/enums';

export const ROWS = [5, 10, 25];

export const roomFilters = {
  language: [
    { name: Object.keys(AdminLanguage)[0], value: AdminLanguage.Python },
    { name: Object.keys(AdminLanguage)[1], value: AdminLanguage.Blocks },
    { name: Object.keys(AdminLanguage)[2], value: AdminLanguage.HtmlJsCss },
  ],
  seniority: [
    { name: Object.keys(Seniority)[0], value: Seniority.Primary },
    { name: Object.keys(Seniority)[1], value: Seniority.Advanced },
  ],
};
