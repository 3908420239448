import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { getLesson } from 'store/FirebaseStore';
import { CurrentLesson } from 'types/ContentTypes';
import { IGetCurrentLesson } from './interfaces';

export const GetCurrentLesson = (data: IGetCurrentLesson) => {
  const { changeActiveFile, setLessonContent } = data;

  return useCallback(
    async (lessonId: string) => {
      try {
        const lesson: CurrentLesson | null = await getLesson(lessonId);
        if (!lesson) {
          toast.warn('Jesteś na lekcji, która nie istnieje! Poproś nauczyciela o zmianę lekcji.', {
            autoClose: false,
          });
        }
        setLessonContent(lesson);
        lesson && changeActiveFile(lesson.questions[0]);
      } catch (e) {
        throw e;
      }
    },
    [changeActiveFile, setLessonContent],
  );
};
