import { Trans } from '@lingui/macro';
import StarIcon from '@mui/icons-material/Star';
import styles from './taskNotification.module.css';

export default function TaskNotification() {
  return (
    <div className={styles['task-notification__content']}>
      <div className={styles['task-notification__container']}>
        <StarIcon className={styles['task-notification__badge_icon']} />
        <p className={styles['task-notification__text']}>
          <Trans id="toast.resolvedTask" />
        </p>
      </div>
    </div>
  );
}
