import { forwardRef, ReactNode } from 'react';
import { clsx } from 'clsx';
import CloseButton from './components/CloseButton';
import styles from './customModal.module.css';

export interface CustomModalProps {
  show?: boolean;
  modalClassName?: string;
  backdropClassName?: string;
  children: ReactNode;
  backdrop?: boolean;
  closeButton?: boolean;
  onClose?: () => void;
}

const CustomModal = forwardRef<HTMLDivElement, CustomModalProps>(
  (
    {
      show = false,
      backdrop = false,
      onClose,
      modalClassName,
      backdropClassName,
      children,
      closeButton = false,
    },
    ref,
  ) => {
    const backdropClassNames = clsx(
      styles['custom-modal__backdrop'],
      backdropClassName,
      backdrop && show && styles['custom-modal__backdrop--shown'],
    );
    const modalClassNames = clsx(
      styles['custom-modal__container'],
      show && styles['custom-modal__container--shown'],
      modalClassName,
    );

    return (
      <div ref={ref}>
        <div data-testid="custom-modal-backdrop" className={backdropClassNames} onClick={onClose} />
        <div data-testid="custom-modal" className={modalClassNames}>
          {closeButton && <CloseButton onClose={onClose} />}
          {children}
        </div>
      </div>
    );
  },
);

export default CustomModal;
