import { ISearch } from 'types/AdminTypes';

export const filterByAll = <T>(array: T[], activeFilters?: ISearch) => {
  let filteredArray = array;
  Object.entries(activeFilters || {}).forEach(([filterName, filterValue]) => {
    if (filterValue) {
      filteredArray = filteredArray.filter(
        item => item[filterName as keyof T]?.toString() === filterValue.toString(),
      );
    }
  });

  return filteredArray;
};

export function filterByDate(array: Record<string, any>[], start: Date | null, end: Date | null) {
  if (!start || !end) return array;
  return array
    .filter(item => compareDates(item.startDate, start))
    .filter(item => compareDates(end, item.endDate));
}

export function compareDates(d1: Date, d2: Date) {
  return new Date(d1).getTime() > new Date(d2).getTime();
}
