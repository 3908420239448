import { en, es, pl, pt } from 'make-plural/plurals';
import { I18Languages, SetupInterface } from 'types/i18nTypes';
import { I18n, setupI18n } from '@lingui/core';

export const locales = {
  en: 'English',
  es: 'Español (Mexico)',
  pl: 'Polski',
  pt: 'Português (Brasil)',
};

export const localeCodes = Object.keys(locales);

export const defaultLocale: I18Languages = 'pl';

/**
 * We do a dynamic import of just the catalog that we need
 * @param i18n it's 18n instance
 * @param locale any locale I18Languages type
 */
export async function dynamicActivate(i18n: I18n, locale: I18Languages) {
  const { messages } = await import(`../locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

/**
 *
 * @param setup interface for i18n
 * @param locale to load
 * @returns i18n instance for app (it is changed every time we switch locale)
 */
export function getI18nInstance(setup: SetupInterface, locale: I18Languages) {
  const i18n = setupI18n({
    messages: setup.locales.messages,
    locale,
    localeData: setup.locales.localeData,
  });

  return i18n;
}
