import { Trans } from '@lingui/macro';
import { Box, DialogTitle, Divider } from '@mui/material';

export default function ModalHeader() {
  return (
    <DialogTitle id="responsive-dialog-title">
      <Box display="flex" alignItems="center">
        <Trans id="dashboard.contentModal.header">Choose Room for Lesson</Trans>
      </Box>
      <Divider />
    </DialogTitle>
  );
}
