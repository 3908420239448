import { TValues, TLabels, TState } from 'components/templates/forms/MatrixRadioButtonForm';
import { FeedbackInternalTable } from 'types/FormsTypes';

export const dictionary: {
  values: TValues;
  labels: TLabels;
} = {
  values: {
    v1: 'Not at all :(',
    v2: 'No, rather not',
    v3: 'Yes, often',
    v4: 'Yes, definitely',
    v5: 'So much',
  },
  labels: {
    l1: 'Helpful',
    l2: 'Involved',
    l3: 'He had a lot of knowledge',
    l4: 'Smiling',
  },
};

export function generateFormData(data: TState): FeedbackInternalTable {
  return Object.entries(data).reduce((obj: any, currentIds, idx) => {
    const [labelId, valueId] = currentIds;
    obj[idx] = {
      id: idx.toString(),
      title: dictionary.labels[labelId],
      value: dictionary.values[valueId],
    };
    return obj;
  }, {});
}
