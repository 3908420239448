import { FireBaseCloudFunctionError } from 'types/error.types';
import { codeToMessage, EValidationCodes } from 'helpers/statusCodeValidation';
import { I18n } from '@lingui/core';
import {
  FUNCTIONS_NOT_FOUND,
  AUTH_INTERNAL_ERROR,
  AUTH_TOO_MANY_REQUESTS,
  AUTH_USER_NOT_FOUND,
  AUTH_WRONG_PASSWORD,
  FIREBASE_NOT_FOUND,
} from './const';

export function getErrorFromFirebaseFunctionMessage(message: string): FireBaseCloudFunctionError {
  return JSON.parse(message.substring(message.indexOf('{'), message.lastIndexOf('}') + 1));
}

export type IGenerateError = {
  code?: string;
  message?: string;
};

export function generateMessage(i18n: I18n, message: string) {
  const { error } = getErrorFromFirebaseFunctionMessage(message);
  return codeToMessage(i18n, error.details?.code);
}

/**
 *
 * @param i18n lingui method to translate error message
 * @param error error object which containg firebase code (in most cases)
 * @returns const array where the first argument is error code (like -> auth/wrong-password),
 *  and the second argument is translated message
 */
export function getFirebaseError(i18n: I18n, error: unknown) {
  const e = error as IGenerateError;

  switch (e.code) {
    case FUNCTIONS_NOT_FOUND:
    case AUTH_USER_NOT_FOUND:
      return [e.code, codeToMessage(i18n, EValidationCodes.WRONG_EMAIL)] as const;
    case AUTH_WRONG_PASSWORD:
      return [e.code, codeToMessage(i18n, EValidationCodes.WRONG_PASSWORD)] as const;
    case AUTH_TOO_MANY_REQUESTS:
      return [e.code, codeToMessage(i18n, EValidationCodes.TOO_MANY_REQUESTS)] as const;
    // this one is only for firebase function codes [700, 800, etc.]
    case AUTH_INTERNAL_ERROR:
      return [e.code, generateMessage(i18n, e?.message ? e.message : '404')] as const;
    default:
      // in case when there is NO error code
      return [FIREBASE_NOT_FOUND, generateMessage(i18n, e?.message ? e.message : '404')] as const;
  }
}
