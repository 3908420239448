import { Box, MenuItem, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { locales } from 'helpers/i18n';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import FlagIcon from '@mui/icons-material/Flag';
import { I18Languages } from 'types/i18nTypes';

const sxRight = { marginRight: '16px' };
// ----------------------------------------------------

export default function LanguageSelector() {
  const { languageSwitcher, locale } = useLocaleContext();

  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    languageSwitcher(value as I18Languages);
  };

  return (
    <MenuItem>
      <FlagIcon sx={sxRight} />
      <Box>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={locale}
            label="Language"
            onChange={handleChange}
            disableUnderline
            data-testid="language-selector"
          >
            {Object.entries(locales).map(([loc, lng]) => (
              <MenuItem key={loc} value={loc}>
                {lng}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </MenuItem>
  );
}
