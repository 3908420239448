import { z } from 'zod';

/**
 * Function that allow to get data from localStorage
 *
 * @param key property name
 * @param schema validation schema from zod to check if item from localStorage mathces all requirements
 * @returns data from localStorage, error if failed validation with given schema or null if no item in storage
 */
export function getItem<T>(key: string, schema: z.ZodSchema<T> = z.any()) {
  try {
    const item = localStorage.getItem(key);

    if (!item) return null;
    const result = schema.safeParse(JSON.parse(item));

    return result.success ? result.data : null;
  } catch (e) {
    return null;
  }
}

export function setItem<T>(key: string, value: T): boolean {
  try {
    const _val = JSON.stringify(value);
    localStorage.setItem(key, _val);
    return true;
  } catch (_e) {
    return false;
  }
}

export function remove(key: string) {
  localStorage.removeItem(key);
}
