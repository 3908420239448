import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Drawer, Typography, Avatar, Stack } from '@mui/material';
// hooks
import useResponsive from 'hooks/useResponsive';
// components
import Logo from 'components/atoms/Logo';
import Scrollbar from 'components/atoms/Scrollbar';
import NavSection from 'components/atoms/NavSection';
//
import navConfig from './NavConfig';
import { useAppContext } from 'providers/app/app.provider';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  // @ts-ignore TODO add ts to theme!
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

const scrollBarSx = {
  height: 1,
  '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
};

const box1Sx = { px: 2.5, py: 3, display: 'inline-flex' };
const box2Sx = { mb: 5, mx: 2.5 };
const drawer1Props = {
  sx: { width: DRAWER_WIDTH },
};
const drawer2Props = {
  sx: {
    width: DRAWER_WIDTH,
    bgcolor: 'background.default',
    borderRightStyle: 'dashed',
  },
};

// ----------------------------------------------------------------------

export interface DashboardSidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const { currentUser } = useAppContext();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Box>
      <Scrollbar sx={scrollBarSx}>
        <Box sx={box1Sx}>
          <Logo />
        </Box>

        <Box sx={box2Sx}>
          <Link underline="none" component={RouterLink} to="#">
            <AccountStyle>
              <Avatar src={''} alt="photoURL" />
              <Box ml={2}>
                <Typography variant="subtitle2" color="text.primary">
                  {`${currentUser?.name} ${currentUser?.lastName}`}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {currentUser?.role}
                </Typography>
              </Box>
            </AccountStyle>
          </Link>
        </Box>
        <NavSection navConfig={navConfig} />
      </Scrollbar>
    </Box>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer open={isOpenSidebar} onClose={onCloseSidebar} PaperProps={drawer1Props}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer open variant="persistent" PaperProps={drawer2Props}>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
