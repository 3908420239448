import { z } from 'zod';
import { EValidationCodes } from 'helpers/statusCodeValidation';

export const emailLoginSchema = z.object({
  email: z.string().nonempty(EValidationCodes.EMAIL_REQUIRED).email(EValidationCodes.WRONG_EMAIL),
});

export const loginSchema = z.object({
  email: z.string().nonempty(EValidationCodes.EMAIL_REQUIRED).email(EValidationCodes.WRONG_EMAIL),
  password: z.string(),
});

export const changePasswordSchema = z.object({
  password: z.string().nonempty(EValidationCodes.PASSWORD_REQUIRED),
  passwordRepeat: z.string().nonempty(EValidationCodes.PASSWORD_REQUIRED),
});

export type LoginSchemaData = z.infer<typeof loginSchema>;
export type EmailFormData = z.infer<typeof emailLoginSchema>;
export type ChangePasswordFormData = z.infer<typeof changePasswordSchema>;
