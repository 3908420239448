const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_CONTENT_DATABASE_URL,
} = process.env;

interface IFirebaseConfig {
  apiKey: typeof REACT_APP_FIREBASE_API_KEY;
  authDomain: typeof REACT_APP_FIREBASE_AUTH_DOMAIN;
  databaseURL: typeof REACT_APP_FIREBASE_DATABASE_URL;
  projectId: typeof REACT_APP_FIREBASE_PROJECT_ID;
  storageBucket: typeof REACT_APP_FIREBASE_STORAGE_BUCKET;
  messagingSenderId: typeof REACT_APP_FIREBASE_MESSAGING_SENDER_ID;
  appId: typeof REACT_APP_FIREBASE_APP_ID;
  measurementId: typeof REACT_APP_FIREBASE_MEASUREMENT_ID;
}

export const firebaseDefaultConfig: IFirebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const firebaseContentConfig: IFirebaseConfig = {
  ...firebaseDefaultConfig,
  databaseURL: REACT_APP_FIREBASE_CONTENT_DATABASE_URL,
};
