import { memo, useMemo, useRef, useEffect, useState, useCallback, MutableRefObject } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { toast } from 'react-toastify';
import { white } from 'constants/Colors';
import { enableOpacity, disableOpacity } from 'constants/Opacities';
import { toastOptions } from 'constants/ToastOptions';
import { getToastProps } from 'constants/ToastProps';
import { doDrawingKeywordsAppear, search, searchGraphicKeywords } from 'helpers/CodeHelper';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import { setVariablesTextareaContent } from 'helpers/StringParseHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import {
  setLeaderModeDebugStep,
  resolveTaskInRealTime,
  resolveTaskInFirestore,
} from 'store/FirebaseStore';
import {
  BoardCompilerStatus,
  ResponseMessage,
  InputMessage,
  ErrorMessage,
  ConsolePythonResultType,
} from 'types/CompilerTypes';
import { Question, SolvedTaskData } from 'types/ContentTypes';
import { FileAccess, FileType } from 'types/FileTypes';
import { LeaderModeType } from 'types/LeaderModeTypes';
import { UserType } from 'types/UserTypes';
import { getDebugButtonDisabled } from '../../../helpers/CompilerHelper';
import { t } from '@lingui/macro';
import { isStudent } from 'helpers/userHelper';
import { Divider } from '@mui/material';
import { RoomType } from 'types/enums';
import { sendProgressEmail } from 'helpers/emailProgressSender';
import Python from './helpers/Python';
import AceEditor from 'react-ace';
import FirepadFile from 'types/FirepadFile';
import styles from './pythonCompiler.module.css';
import CompilerSlider from '../../Slider';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FlagIcon from '@mui/icons-material/Flag';
import StopIcon from '@mui/icons-material/Stop';
import Stack from '@mui/material/Stack';
import TaskNotification from '../../TaskNotification';

const aceOptions = {
  enableBasicAutocompletion: false,
  enableLiveAutocompletion: false,
  enableSnippets: false,
  showLineNumbers: false,
  readOnly: true,
};

interface PythonCompilerProps {
  getCode: Function;
  highlightErrorLine: Function;
  highlightActiveBreakpointLine: Function;
  removehighlightLine: Function;
  question: Question;
  pageUserId: string;
  defaultIconSize?: number;
  defaultSpeed?: number;
}

function PythonCompiler(props: PythonCompilerProps) {
  // ---------------------------- PROPS -----------------------------
  const {
    getCode,
    highlightErrorLine,
    highlightActiveBreakpointLine,
    removehighlightLine,
    question,
    defaultIconSize = 30,
    defaultSpeed = 500,
  } = props;
  // ------------------------- CONTEXTS -----------------------------------
  const { currentUser } = useAppContext();
  const { i18n } = useLocaleContext();
  const { leaderMode, localCourseId, localLessonId, room, color } = useWorkspaceContext();
  const { bug, fatal } = useErrorContext();
  // ------------------------- STATES -------------------------------------
  const [startedLeaderModeDebug, setStartedLeaderModeDebug] = useState(false);
  const [compilerStatus, setCompilerStatus] = useState<number>(BoardCompilerStatus.STOP_MODE);
  const [compilerIframe, setCompilerIframe] = useState<HTMLIFrameElement | null>(null);
  const [svgDisplay, setSvgDisplay] = useState(true);
  // --------------------------- REFS --------------------------------------
  const outputRef = useRef() as MutableRefObject<AceEditor>;
  const variablesRef = useRef() as MutableRefObject<HTMLTextAreaElement>;
  const inputEditorRef = useRef() as MutableRefObject<AceEditor>;
  const inputFunFile = useRef(new FirepadFile());
  const speed = useRef(defaultSpeed);
  // ---------------------------- MEMOS ------------------------------------
  const pythonCompiler = useMemo(
    () => new Python(currentUser, leaderMode),
    [currentUser, leaderMode],
  );
  // ----------------------- DERIVED STATES --------------------------------
  const isStopButtonDisabled =
    compilerStatus === BoardCompilerStatus.RESULT ||
    compilerStatus === BoardCompilerStatus.STOP_MODE ||
    getDebugButtonDisabled(currentUser?.role as UserType, leaderMode.isLeaderModeOn);
  const isSolutionButtonDisabled =
    compilerStatus === BoardCompilerStatus.RESULT ||
    getDebugButtonDisabled(currentUser?.role as UserType, leaderMode.isLeaderModeOn);
  const areRunButtonsDisabled =
    compilerStatus === BoardCompilerStatus.END_LINE ||
    compilerStatus === BoardCompilerStatus.ERROR_MODE ||
    compilerStatus === BoardCompilerStatus.RESULT ||
    compilerStatus === BoardCompilerStatus.SOLUTION ||
    getDebugButtonDisabled(currentUser?.role as UserType, leaderMode.isLeaderModeOn);

  // --------------------------- FUNCTIONS ---------------------------------------
  const clearOutputs = () => {
    if (outputRef.current) outputRef.current.editor.setValue('');
    if (variablesRef.current) variablesRef.current.value = '';
    const prevMarkers = outputRef?.current?.editor?.session?.getMarkers();
    if (prevMarkers) {
      const prevMarkersArr: string[] = Object.keys(prevMarkers);
      prevMarkersArr.forEach(item => {
        outputRef.current.editor.session.removeMarker(Number(item));
      });
    }
  };

  // ---------------------------- BUTTONS -----------------------------------------
  const reset = useCallback(
    (clear = true) => {
      clear && clearOutputs();
      removehighlightLine();
    },
    [removehighlightLine],
  );

  const stop = useCallback(() => {
    try {
      leaderMode.mode === LeaderModeType.TutorLeaderMode &&
        setLeaderModeDebugStep(room?.id as string, -1);
      reset();
      pythonCompiler.stop();
    } catch (e) {
      bug(e);
    }
  }, [leaderMode.mode, room?.id, reset, bug, pythonCompiler]);

  const handleStop = useCallback(() => {
    stop();
  }, [stop]);

  const showSolution = useCallback(
    (typeOutput: boolean = true) => {
      handleStop();
      currentUser?.role === UserType.Tutor &&
        leaderMode.isLeaderModeOn &&
        setLeaderModeDebugStep(room?.id as string, -3);
      leaderMode.isLeaderModeOn && currentUser?.role === UserType.Student
        ? pythonCompiler.showSolutionInLeaderMode(leaderMode.question?.code as string, typeOutput)
        : pythonCompiler.showSolution(question, typeOutput);
    },
    [leaderMode, currentUser, room, handleStop, pythonCompiler, question],
  );

  const handleSolution = useCallback(() => {
    showSolution();
  }, [showSolution]);

  const prepareToDebug = useCallback(
    (code: string): boolean => {
      if (leaderMode.isLeaderModeOn && search(code, question.language)) {
        toast.warn(
          t(i18n)({
            id: 'leaderMode.messages.debug',
            message: "You can't run game in Leader Mode",
          }),
          toastOptions,
        );
        return false;
      }
      const isQuestionTypeTask = FileType.Task === question.type;
      const debug = doDrawingKeywordsAppear(question.code);
      question.input =
        question?.type === FileType.Sandpit ? inputEditorRef.current.editor.getValue() : '';
      compilerStatus === BoardCompilerStatus.STOP_MODE &&
        question.type === FileType.Task &&
        !doDrawingKeywordsAppear(question.code) &&
        pythonCompiler.debugSolutionCode(
          question.code,
          question,
          true,
          isQuestionTypeTask && debug,
        );
      return true;
    },
    [compilerStatus, i18n, leaderMode.isLeaderModeOn, pythonCompiler, question],
  );

  const oneStep = useCallback(() => {
    const code: string = getCode();
    prepareToDebug(code) && pythonCompiler.debugStep(code);
  }, [getCode, prepareToDebug, pythonCompiler]);

  const debug = useCallback(() => {
    const code = getCode();
    prepareToDebug(code) && pythonCompiler.debug(code, speed.current);
  }, [getCode, prepareToDebug, pythonCompiler]);

  // ------------------- COMPILER EVENTS RESPONSE FROM BRYTHON API (Python.ts) -----------------------
  const response = useCallback(
    (response: ResponseMessage) => {
      response.RUN_LINE < 0
        ? removehighlightLine()
        : highlightActiveBreakpointLine(response.RUN_LINE);
      const output = outputRef.current.editor.getValue()
        ? outputRef.current.editor.getValue() + response.OUTPUT
        : response.OUTPUT;
      outputRef.current.editor.setValue(output);
      variablesRef.current.value = response.VARIABLES
        ? setVariablesTextareaContent(JSON.parse(response.VARIABLES))
        : '';
      try {
        leaderMode.mode === LeaderModeType.TutorLeaderMode &&
          setLeaderModeDebugStep(room?.id as string, response.TICK);
      } catch (e) {
        bug(e);
      }
    },
    [removehighlightLine, highlightActiveBreakpointLine, bug, leaderMode.mode, room?.id],
  );

  const showError = useCallback(
    (response: ErrorMessage) => {
      highlightErrorLine(response.ERROR_LINE, response.ERROR_MESSAGE);
    },
    [highlightErrorLine],
  );

  const changeInput = useCallback(
    (response: InputMessage) => {
      inputEditorRef.current.editor.setValue(response.INPUT);
    },
    [inputEditorRef],
  );

  const onStatusChanged = useCallback(
    (value: number) => {
      setCompilerStatus(value);
    },
    [setCompilerStatus],
  );

  const onOutputChange = useCallback(
    (response: string) => {
      outputRef?.current && outputRef.current.editor.setValue(response);
    },
    [outputRef],
  );

  const resolveTask = useCallback(
    async (task: SolvedTaskData) => {
      await resolveTaskInRealTime(task);
      if (room?.roomType === RoomType.Demo && currentUser?.userType === UserType.Student) {
        try {
          await sendProgressEmail(task, {
            name: currentUser?.name as string,
            lastName: currentUser?.lastName as string,
            parentEmail: currentUser?.parentEmail as string,
          });
        } catch (e) {
          bug(e);
        }
      }
    },
    [bug, currentUser, room],
  );

  const onSolutionCodeChecked = useCallback(
    async (response: ConsolePythonResultType) => {
      if (
        response.isResultCorrect &&
        !(isStudent(currentUser?.role) && leaderMode.isLeaderModeOn)
      ) {
        try {
          const task: SolvedTaskData = {
            userId: currentUser?.uid as string,
            roomId: room?.id as string,
            lessonId: localLessonId,
            points: 1,
            questionId: question.guid,
          };
          await Promise.all([
            await resolveTaskInFirestore(
              localCourseId,
              localLessonId,
              question.guid,
              room?.id as string,
              currentUser?.uid as string,
              1,
            ),
            await resolveTask(task),
          ]);
          toast(<TaskNotification />, getToastProps());
        } catch (e) {
          fatal(e);
        }
      } else {
        if (response.errorList) {
          const Range = outputRef?.current.editor.Selection.getRange();
          response.errorList.forEach(line => {
            outputRef.current.editor.session.addMarker(
              new Range((line as number) - 1, 0, (line as number) - 1, 1),
              styles['python-compiler__errorMarker'],
              'fullLine',
            );
          });
        }
      }
    },
    [
      currentUser,
      room,
      question,
      leaderMode.isLeaderModeOn,
      localCourseId,
      localLessonId,
      fatal,
      resolveTask,
    ],
  );

  useEffect(() => {
    pythonCompiler.onErrorChange = showError;
    pythonCompiler.onInputChange = changeInput;
    pythonCompiler.onOutputChange = onOutputChange;
    pythonCompiler.onResponse = response;
    pythonCompiler.onSolutionCodeChecked = onSolutionCodeChecked;
    pythonCompiler.onStatusChange = onStatusChanged;
  }, [
    response,
    onOutputChange,
    showError,
    changeInput,
    onSolutionCodeChecked,
    onStatusChanged,
    pythonCompiler,
  ]);

  // ---------------------- LEADER MODE --------------------------------
  useEffect(() => {
    if (
      question &&
      currentUser?.role === UserType.Student &&
      leaderMode.mode === LeaderModeType.StudentLeaderMode
    ) {
      leaderMode.onDebugChanged = (steps: number) => {
        if (steps === -1) {
          handleStop();
        } else if (steps === -2) {
          handleStop();
        } else if (steps === -3) {
          showSolution();
        } else if (steps > -1) {
          const code: string = getCode();
          const input: string =
            question.type === FileType.Sandpit ? inputEditorRef.current.editor.getValue() : '';
          pythonCompiler.debugLeaderMode(code, input, steps);
        }
      };
      setStartedLeaderModeDebug(true);
    }
  }, [
    leaderMode.mode,
    question,
    currentUser?.role,
    leaderMode,
    startedLeaderModeDebug,
    handleStop,
    getCode,
    showSolution,
    pythonCompiler,
  ]);

  // ------------------------ UPDATE FIREPAD FILE AND STATUS -----------------------------
  useEffect(() => {
    pythonCompiler.resetSolution();
    if (question) {
      reset();
      pythonCompiler.stop();
      const isQuestionTypeTask = FileType.Task === question.type;
      const debug = doDrawingKeywordsAppear(question.code);
      if (!question.code) question.code = '';
      pythonCompiler.debugSolutionCode(
        question.code,
        question,
        isQuestionTypeTask && debug,
        isQuestionTypeTask && debug,
      );
      switch (question.type) {
        case FileType.Sample:
          inputFunFile.current?.reset();
          inputEditorRef.current?.editor?.setReadOnly(true);
          break;
        case FileType.Task:
          inputFunFile.current?.reset();
          inputEditorRef.current?.editor?.setReadOnly(true);
          break;
        case FileType.Free:
          inputFunFile.current?.reset();
          inputEditorRef.current?.editor?.setReadOnly(true);
          break;
        case FileType.Sandpit:
          inputEditorRef.current?.editor?.setReadOnly(false);
          !inputFunFile.current?.aceEditor &&
            inputFunFile.current?.setAceEditor(inputEditorRef.current);
          try {
            inputFunFile.current?.loadFile(
              room?.id as string,
              `fun_input_${question.guid}`,
              FileAccess.Public,
              currentUser?.uid as string,
              color,
            );
          } catch (e) {
            bug(e);
          }

          break;
        default:
          inputEditorRef.current?.editor?.setReadOnly(true);
          break;
      }

      question.inputGeneratorCode !== undefined
        ? pythonCompiler.debugInputCode(question.inputGeneratorCode)
        : pythonCompiler.debugInputCode('');
    }
  }, [
    question,
    bug,
    color,
    reset,
    room?.id,
    currentUser?.uid,
    leaderMode.isLeaderModeOn,
    pythonCompiler,
  ]);

  // ----------------------- GLOBAL EVENT CLOSE INFO DIALOG -------------------------
  useEffect(() => {
    const handleContentDocument = (e: unknown) => {
      compilerIframe?.contentDocument?.addEventListener('click', handleContentDocument);
    };

    return () => {
      compilerIframe?.contentDocument?.removeEventListener('click', handleContentDocument);
    };
  }, [compilerIframe]);

  // ---------------------------- ON LOADED VIEW -----------------------------------
  useEffect(() => {
    if (!compilerIframe) return;
    if (compilerIframe && pythonCompiler.getIframe() !== compilerIframe) {
      pythonCompiler.setIframe(compilerIframe);
    }
    if (pythonCompiler.didQuestionChange(question)) {
      pythonCompiler.setQuestion(question);
    }
    inputFunFile.current.setAceEditor(inputEditorRef.current);
  }, [compilerIframe, pythonCompiler, question]);

  // ------------------------------- STYLES ----------------------------------------
  const theme = {
    'skip-next': Object.assign(
      {},
      {
        fill: white,
        fontSize: defaultIconSize,
        opacity: areRunButtonsDisabled ? disableOpacity : enableOpacity,
      },
    ),
    play: {
      fill: white,
      fontSize: defaultIconSize,
      opacity: areRunButtonsDisabled ? disableOpacity : enableOpacity,
    },
    stop: {
      fill: white,
      fontSize: defaultIconSize,
      opacity: isStopButtonDisabled ? disableOpacity : enableOpacity,
    },

    last_child: { marginLeft: 'auto' },
    flag: {
      fill: white,
      fontSize: defaultIconSize,
      opacity: isSolutionButtonDisabled ? disableOpacity : enableOpacity,
    },
    box: { width: 180 },
    divider: {
      backgroundColor: transformColorFromHexToRGBA(white, 0.12),
    },
    solution: { fill: white, marginLeft: 'auto' },
  };

  useEffect(() => {
    setSvgDisplay(searchGraphicKeywords(question) || question.type === FileType.Free);
  }, [question]);

  return (
    <div className={styles['python-compiler__container']}>
      <div className={styles['python-compiler__buttons']}>
        <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-start">
          <IconButton aria-label="oneStep" onClick={oneStep} disabled={areRunButtonsDisabled}>
            <SkipNextIcon style={theme['skip-next']} />
          </IconButton>
          <IconButton aria-label="start" onClick={debug} disabled={areRunButtonsDisabled}>
            <PlayArrowIcon style={theme.play} />
          </IconButton>
          <IconButton
            aria-label="stop"
            onClick={useCallback(() => handleStop(), [handleStop])}
            disabled={isStopButtonDisabled}
          >
            <StopIcon style={theme.stop} />
          </IconButton>
          <CompilerSlider compilerIframe={compilerIframe} compiler={pythonCompiler} speed={speed} />
          {question.type === FileType.Task && (
            <IconButton
              aria-label="solution"
              onClick={handleSolution}
              style={theme.solution}
              disabled={isSolutionButtonDisabled}
            >
              <FlagIcon style={theme.flag} />
            </IconButton>
          )}
        </Stack>
      </div>
      <ReflexContainer orientation="horizontal">
        <ReflexElement
          className={styles['python-compiler__reflex-wrapper']}
          flex={svgDisplay ? 0.75 : 0}
          minSize={svgDisplay ? 350 : 0}
        >
          <div className={styles['python-compiler__graphic-wrapper']}>
            <div className={styles['python-compiler__graphic-output']}>
              <iframe
                ref={newRef => setCompilerIframe(newRef)}
                id="compilerPage"
                title="compiler"
                width="100%"
                height="100%"
                src="../compilers/python/PythonCompiler.html"
              />
            </div>
          </div>
        </ReflexElement>
        <ReflexSplitter className={styles['python-compiler__spliter']} />
        <ReflexElement flex={0.6} minSize={100} className={styles['python-compiler__inputs']}>
          <div className={styles['python-compiler__inputs__input']}>
            Input
            <AceEditor
              ref={inputEditorRef}
              mode="text"
              width="auto"
              height="100%"
              name="input_code_editor_id"
              fontSize={14}
              tabSize={4}
              theme="pastel_on_dark"
              setOptions={aceOptions}
            />
          </div>
          <Divider style={theme.divider} />
          <div className={styles['python-compiler__inputs__input']}>
            Output
            <AceEditor
              ref={outputRef}
              mode="text"
              theme="pastel_on_dark"
              width="auto"
              height="100%"
              fontSize={14}
              setOptions={aceOptions}
              name="output_code_editor_id"
            />
          </div>
          <Divider style={theme.divider} />
          <div className={styles['python-compiler__inputs__variables']}>
            Variables
            <textarea ref={variablesRef} disabled />
          </div>
        </ReflexElement>
      </ReflexContainer>
    </div>
  );
}

export default memo(PythonCompiler);
