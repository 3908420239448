import { useCallback } from 'react';
import { CheckboxCell, HeaderCell, LinkCell } from './components';
import { AdminLanguage } from 'types/enums';
import { validateDate } from '../../../../../helpers';
import { TableRowProps } from './interface';
import { TableRow as Row, TableCell } from '@mui/material';
import RoomMoreMenu from '../../../../../../../../../sections/@dashboard/room/RoomMoreMenu';

export default function TableRow(props: TableRowProps) {
  const { room, selectedRooms, setSelectedRooms } = props;
  const isItemSelected = selectedRooms.indexOf(room) !== -1;
  const programmingLanguage = Object.entries(AdminLanguage).find(
    ([_, value]) => value === room.language,
  );
  const programmingLanguageName = programmingLanguage ? programmingLanguage[0] : '';
  const creationDate = validateDate(room.creationDate);

  const handleClick = useCallback(() => {
    !isItemSelected && setSelectedRooms(selectedRooms.concat(room));
  }, [isItemSelected, selectedRooms, room, setSelectedRooms]);

  return (
    <Row
      hover
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <CheckboxCell isItemSelected={isItemSelected} onClick={handleClick} />
      <HeaderCell>{room.id}</HeaderCell>
      <TableCell align="left">{room.name}</TableCell>
      <TableCell align="left">{room.description}</TableCell>
      <TableCell align="left">{room.activeLesson}</TableCell>
      <TableCell align="left">{room.activeCourse}</TableCell>
      <TableCell align="left">{programmingLanguageName}</TableCell>
      <LinkCell meetLink={room.meetLink} />
      <TableCell align="left">{creationDate}</TableCell>
      <TableCell align="right">
        <RoomMoreMenu room={room} />
      </TableCell>
    </Row>
  );
}
