import { useMemo } from 'react';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';

export default function FormModalWrapper({
  open,
  handleClose,
  handleSubmit,
  header,
  btnText,
  children,
  loading,
}) {
  const saveBtnStyles = useMemo(() => ({ paddingRight: '5px' }), []);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-form-create">
      <DialogTitle width={950} id="dialog-form-create">
        {header}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="error" startIcon={<CloseIcon />} variant="text" onClick={handleClose}>
          Close
        </Button>
        <LoadingButton loading={loading} color="primary" onClick={handleSubmit} autoFocus>
          <SaveAltIcon sx={saveBtnStyles} />
          {btnText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
