import { clsx } from 'clsx';
import { MemberData } from 'types/MemberTypes';
import { isTutor } from 'helpers/userHelper';
import styles from './topBar.module.css';

export function renderSurveyButton(label: string, cb: () => void, disabled = false) {
  return (
    <div
      data-testid="survey-button"
      className={clsx(
        styles['topbar-survey__content'],
        disabled && styles['topbar-survey--button-disabled'],
      )}
    >
      <a onClick={cb} target="_blank" rel="noopener noreferrer">
        {label}
      </a>
    </div>
  );
}

/**
 *
 * @param members array of users (includes admin, tutor and student)
 * @param activeTutorId active tutor id from realtime db
 * @param currentUserId current user uid
 * @returns
 */
export function sortMembers(
  members: MemberData[],
  activeTutorId = '',
  currentUserId = '',
): MemberData[] {
  const tutor = members.find(m => m.uid === activeTutorId && isTutor(m.role));
  const currentUser = members.find(m => m.uid === currentUserId);

  const firstIndices: MemberData[] = [];

  if (tutor && tutor?.uid === currentUser?.uid) firstIndices.push(tutor);
  else if (tutor && currentUser) {
    firstIndices.push(currentUser, tutor);
  } else if (currentUser) {
    firstIndices.push(currentUser);
  }

  const arr = [
    ...firstIndices,
    ...members
      .filter(m => m.uid !== activeTutorId && m.uid !== currentUserId)
      .sort((a, b) => {
        if (isTutor(a.role) || isTutor(b.role)) return -1;

        return a.name.localeCompare(b.name);
      }),
  ];

  return arr;
}
