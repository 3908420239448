import { SyntheticEvent, Dispatch, SetStateAction } from 'react';
import { clsx } from 'clsx';
import { purple } from 'constants/Colors';
import { selectedSection } from 'types/ExtensionsMenuTypes';
import { NavIcon, NavigationIcons } from './style';
import PaletteIcon from '@mui/icons-material/Palette';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import CodeSharpIcon from '@mui/icons-material/CodeSharp';
import MusicNoteSharpIcon from '@mui/icons-material/MusicNoteSharp';
import styles from './navigationBar.module.css';

interface NavigationBarProps {
  selectedIcon: number;
  setSelectedIcon: Dispatch<SetStateAction<number>>;
}

export default function NavigationBar(props: NavigationBarProps) {
  const { selectedIcon, setSelectedIcon } = props;

  const theme = {
    tab_indicator: {
      style: {
        backgroundColor: purple,
      },
    },
  };

  const navigationButtons = [
    { muiIcon: <PaletteIcon />, disabled: false, value: selectedSection.COLOR },
    { muiIcon: <ImageOutlinedIcon />, disabled: false, value: selectedSection.IMAGE },
    { muiIcon: <CodeSharpIcon />, disabled: true, value: selectedSection.CODE },
    { muiIcon: <MusicNoteSharpIcon />, disabled: false, value: selectedSection.SOUND },
  ];

  function handleChange(_: SyntheticEvent, newValue: number) {
    setSelectedIcon(newValue);
  }

  return (
    <NavigationIcons
      value={selectedIcon}
      TabIndicatorProps={theme.tab_indicator}
      onChange={handleChange}
      aria-label="nav_buttons"
    >
      {navigationButtons.map(button => {
        const navIconClassName = clsx(
          button.value === selectedIcon && styles['navigation-bar__icon-selected'],
        );

        return (
          <NavIcon
            value={button.value}
            key={button.value}
            className={navIconClassName}
            icon={button.muiIcon}
            disabled={button.disabled}
          />
        );
      })}
    </NavigationIcons>
  );
}
