import React from 'react';
import { signOut } from 'firebase/auth';
import { auth, contentAuth } from 'store/FirebaseStore';
import { CurrentUser } from 'types/UserTypes';
import { getItem, setItem } from '../../helpers/LocalStorageHelper';
import { currentUserSchema } from '../../schemas/user.schema';
import { AppContext, Props, State } from './app.types';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export const appContext = React.createContext<AppContext>(undefined!);

export class AppProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // user methods
    this.logout = this.logout.bind(this);
    this.setUser = this.setUser.bind(this);

    const user = getItem<CurrentUser | null>('currentUser', currentUserSchema);

    this.state = {
      context: {
        loginWithPassword: false,
        currentUser: user,
        role: user?.role,
        logout: this.logout,
        setUser: this.setUser,
      },
    };
  }

  logout = async () => {
    try {
      await signOut(auth);
      await signOut(contentAuth);
      setItem('currentUser', null);
      this.setState(prev => ({ ...prev, context: { ...prev.context, currentUser: null } }));
    } catch (e) {
      throw e;
    }
  };

  setUser = (user: CurrentUser) => {
    setItem('currentUser', user);
    this.setState(prev => ({ ...prev, context: { ...prev.context, currentUser: user } }));
  };

  render() {
    const theme = createTheme({
      palette: {
        mode: 'dark',
      },
    });

    return (
      <appContext.Provider value={this.state.context}>
        <ThemeProvider theme={theme}>{this.props.children}</ThemeProvider>
      </appContext.Provider>
    );
  }
}

export const useAppContext = () => {
  const context = React.useContext(appContext);
  if (context === undefined) {
    throw new Error(`useAppContext must be used within a AppProvider`);
  }
  return context;
};
