import { useCallback } from 'react';
import { QuestionBlock, TasksSections } from '../../components';
import { IGetContent } from './interfaces';

export const GetContent = (data: IGetContent) => {
  const {
    idActiveFile,
    lessonContent,
    selectedSection,
    solvedQuestions,
    changeActiveFile,
    setSelectedSection,
  } = data;

  return useCallback(() => {
    const questions = lessonContent?.questions || [];
    if (lessonContent?.sections) {
      return (
        <TasksSections
          idActiveFile={idActiveFile}
          sections={lessonContent.sections}
          questions={questions}
          selectedSection={selectedSection}
          solvedQuestions={solvedQuestions}
          changeActiveFile={changeActiveFile}
          setSelectedSection={setSelectedSection}
        />
      );
    }
    return (
      <QuestionBlock
        isExpanded
        idActiveFile={idActiveFile}
        questions={questions}
        scenarios={lessonContent?.docs}
        selectedSection={selectedSection}
        solvedQuestions={solvedQuestions}
        videos={lessonContent?.video}
        changeActiveFile={changeActiveFile}
        setSelectedSection={setSelectedSection}
      />
    );
  }, [
    idActiveFile,
    lessonContent,
    selectedSection,
    solvedQuestions,
    changeActiveFile,
    setSelectedSection,
  ]);
};
