import { useEffect } from 'react';
import { User } from 'types/UserTypes';
import { filterByAll } from '../../../../../helpers/filters';
import { applySortFilter, getComparator } from '../../helpers';
import { IFilterUsers } from './interfaces';

export const FilterUsers = (props: IFilterUsers) => {
  const {
    activeFilters,
    isOrderReversed,
    orderBy,
    page,
    rowsPerPage,
    searchName,
    userList,
    setFilteredUsers,
  } = props;
  useEffect(() => {
    const searchedUsers: User[] = applySortFilter(
      userList,
      getComparator(isOrderReversed, orderBy),
      searchName,
    );
    const filteredUsers: User[] = filterByAll(searchedUsers, activeFilters);
    const slicedList: User[] = filteredUsers.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
    setFilteredUsers(slicedList);
  }, [
    activeFilters,
    isOrderReversed,
    orderBy,
    page,
    rowsPerPage,
    searchName,
    userList,
    setFilteredUsers,
  ]);
};
