import { useCallback, useState, useEffect, Suspense, useMemo, useRef } from 'react';
import { useBeforeunload } from 'react-beforeunload';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { clsx } from 'clsx';
import { z } from 'zod';
import { ref, onDisconnect, OnDisconnect } from 'firebase/database';
import { getToastProps } from 'constants/ToastProps';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { getItem, setItem } from 'helpers/LocalStorageHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { parsedCourseSchema } from 'schemas/content.schema';
import { membersSchema } from 'schemas/member.shema';
import { roomSchema } from 'schemas/room.schema';
import {
  getCourses,
  getMembersData,
  getMembers,
  getRoom,
  getActiveTutor,
  setActiveTutor as updateActiveTutor,
  addRoomLog,
  updateRoomLog,
  getAppDatabase,
  getRoomType,
  AppName,
} from 'store/FirebaseStore';
import { IBadgesCounter } from 'types/BadgeTypes';
import { QuestionStack } from 'types/ContentTypes';
import { MemberData } from 'types/MemberTypes';
import { CurrentRoomData, RoomLog } from 'types/RoomTypes';
import { UserType } from 'types/UserTypes';
import { CurrentRoomContext, ICurrentRoomContext } from './context';
import { getGrantedBadge, getStudentsBadges } from './helpers/studentBadgesHelper';
import Loader from 'components/atoms/Loader';
import BadgeNotification from './components/BadgeNotification/BadgeNotification';
import FileExplorer from './components/FileExplorer';
import IDE from './components/IDE';
import BadgeModal from './modals/BadgeModal';
import TaskDescriptionModal from './modals/TaskDescriptionModal';
import TopBar from './components/TopBar';
import { isTutor } from 'helpers/userHelper';
import styles from './currentRoom.module.css';
import { RoomType } from 'types/enums';

const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

/**
 * Main component for our app, it contains user tab navigation, sidebar, IDE, compiler etc.
 */
export default function CurrentRoom() {
  const navigate = useNavigate();
  // ---------------------------------------- CONTEXTS ----------------------------------------------
  const {
    activeFile,
    badgesCounter,
    roomLogId,
    setActiveTutor,
    setBadgesCounter,
    setContent,
    setCurrentColor,
    setMembers,
    setRoom,
    setRoomLogId,
    setCurrentUserId,
    activeTutor,
  } = useWorkspaceContext();
  const { fatal, bug } = useErrorContext();
  const { currentUser } = useAppContext();
  // ----------------------------------------- STATES -----------------------------------------------
  const [loadingRoom, setLoadingRoom] = useState(true);
  const [collapsedSidebar, setCollapsedSidebar] = useState(getItem('sidebar') === SHOW_SIDEBAR);
  const [isFileBroswerDisabled, setFileBroswerDisabled] = useState<boolean>(false);
  const [navigationStack, setNavigationStack] = useState<QuestionStack>();
  const [leaderModeSwitchValue, setLeaderModeSwitchValue] = useState(false);
  const [showTaskContent, setShowTaskContent] = useState(false);
  const [badgeUser, setBadgeUser] = useState<MemberData | null>(null);
  const [badgeHighlighted, setBadgeHighlighted] = useState<boolean>(false);
  const roomType = useRef<RoomType>();
  const lastBadgesCounter = useRef<IBadgesCounter>();
  const { roomId } = useParams();

  // ----------------------------------------- HOOKS ------------------------------------------------
  const showTaskModal = useCallback(() => {
    setShowTaskContent(true);
  }, []);

  const closeTaskModal = useCallback(() => {
    setShowTaskContent(false);
  }, []);

  const switchLeaderModeHandler = useCallback(() => {
    setLeaderModeSwitchValue(prev => !prev);
  }, []);

  const toggleSidebar = useCallback(() => {
    setCollapsedSidebar(prevState => {
      setItem('sidebar', prevState ? HIDE_SIDEBAR : SHOW_SIDEBAR);
      return !prevState;
    });
  }, []);

  useEffect(() => {
    if (!currentUser || !currentUser.uid) {
      navigate(`/`);
    } else {
      setCurrentUserId(currentUser?.uid);
    }
  }, [currentUser, navigate, setCurrentUserId]);

  useEffect(() => {
    showTaskModal();
  }, [activeFile, showTaskModal]);

  useEffect(() => {
    if (!currentUser || !roomId)
      return () => {
        null;
      };
    const unsubscribe = getStudentsBadges(currentUser, roomId, setBadgesCounter);
    return () => {
      unsubscribe();
    };
  }, [currentUser, roomId, setBadgesCounter]);

  useEffect(() => {
    const grantedBadge = getGrantedBadge(badgesCounter, lastBadgesCounter.current);
    if (grantedBadge) {
      setBadgeHighlighted(true);
      toast(<BadgeNotification badgeGranted={grantedBadge} />, getToastProps(setBadgeHighlighted));
    }
    lastBadgesCounter.current = badgesCounter;
  }, [badgesCounter]);

  useEffect(() => {
    const currentRoomData: CurrentRoomData = {
      room: null,
      content: null,
      members: null,
    };

    const updateTutorDataForCurrentRoom = async (roomId: string, role: UserType, uid: string) => {
      try {
        if (!(await getRoom(roomId, currentUser))) throw Error('There is no such room!');
        const currentActiveTutor = await getActiveTutor(roomId);
        setActiveTutor(currentActiveTutor?.uid ?? '');
        if (role === UserType.Tutor && !currentActiveTutor) {
          setActiveTutor(uid);
          await updateActiveTutor(roomId, uid);
          return;
        }
        setActiveTutor(currentActiveTutor?.uid ?? '');
      } catch (e) {
        fatal(e);
      }
    };

    const getData = async (members: string[]) => {
      try {
        const p1 = getRoom(roomId as string, currentUser)
          .then(data => {
            currentRoomData.room = data;
            if (!data?.activeLesson) {
              toast.warn(t(i18n)`toast.emptyLesson`, {
                autoClose: false,
              });
            }
          })
          .catch(e => {
            throw e;
          });
        const p2 = getCourses(roomId as string)
          .then(data => {
            currentRoomData.content = data;
          })
          .catch(e => {
            throw e;
          });

        const p3 = getMembersData(members)
          .then(data => {
            currentRoomData.members = data;
          })
          .catch(e => {
            throw e;
          });

        await Promise.all([p1, p2, p3]).then(() => {
          const members = membersSchema.parse(currentRoomData.members);
          const color = members?.find(member => member.uid === currentUser?.uid)?.color;
          setMembers(members);
          color && setCurrentColor(color);
          setRoom(roomSchema.parse(currentRoomData.room));
          setContent(z.array(parsedCourseSchema).parse(currentRoomData.content));
          setLoadingRoom(false);
        });
      } catch (e) {
        throw e;
      }
    };

    const getMembersAndData = async () => {
      try {
        roomType.current = await getRoomType(roomId as string);
        const members = await getMembers(
          roomId as string,
          currentUser?.uid as string,
          roomType.current,
        );
        await getData(Object.keys(members));
      } catch (e) {
        fatal(e);
      }
    };
    if (roomId && currentUser?.uid && currentUser?.role) {
      const { role, uid } = currentUser;
      updateTutorDataForCurrentRoom(roomId, role, uid);
    }
    getMembersAndData();
  }, [
    roomId,
    fatal,
    setRoom,
    setContent,
    setMembers,
    setCurrentColor,
    currentUser,
    setActiveTutor,
  ]);

  // when user closes the tab or leaves a page
  useEffect(() => {
    const disconnectListeners: OnDisconnect[] = [];

    if (roomId && currentUser?.uid) {
      const database = getAppDatabase(AppName.DEFAULT);

      const sessionRef = ref(database, `sessions_data/${roomId}/${currentUser?.uid}`);
      const sessionDisconnect = onDisconnect(sessionRef);
      disconnectListeners.push(sessionDisconnect);
      sessionDisconnect.update({
        isActive: false,
      });

      if (isTutor(currentUser?.role) && activeTutor === currentUser?.uid) {
        // refs
        const roomRef = ref(database, `rooms/${roomId}`);
        const roomDisconnect = onDisconnect(roomRef);
        disconnectListeners.push(roomDisconnect);

        // disconnect db updates
        roomDisconnect.update({
          activeTutor: '',
          isLeaderMode: false,
        });

        // leader mode update if tutor turned it on
        if (leaderModeSwitchValue && roomType.current !== RoomType.Demo) {
          const leaderModDebugeRef = ref(database, `leader_mode_debug/${roomId}`);

          const leaderModeDisconnect = onDisconnect(leaderModDebugeRef);
          disconnectListeners.push(leaderModeDisconnect);

          leaderModeDisconnect.update({ steps: -1 });
        }
      }
    }

    return () => {
      if (disconnectListeners.length > 0) {
        disconnectListeners.forEach(l => l.cancel());
      }
    };
  }, [currentUser?.uid, currentUser?.role, roomId, leaderModeSwitchValue, activeTutor]);

  useEffect(() => {
    const sendRoomLog = async () => {
      try {
        const log: RoomLog = {
          userId: currentUser?.uid as string,
          roomId: roomId as string,
        };
        const id = Boolean(roomLogId);
        if (!id) {
          const id = await addRoomLog(log);
          setRoomLogId(id);
        }
      } catch (e) {
        bug(e);
      }
    };

    sendRoomLog();
  }, [roomId, currentUser, roomLogId, setRoomLogId, bug]);

  const value: ICurrentRoomContext = useMemo(
    () => ({
      showTaskModal,
      collapsedSidebar,
      toggleSidebar,
      isFileBroswerDisabled,
      setFileBroswerDisabled,
    }),
    [showTaskModal, collapsedSidebar, toggleSidebar, isFileBroswerDisabled],
  );

  useBeforeunload(event => {
    const resetBeforeUnload = async () => {
      try {
        await updateRoomLog(roomLogId);
      } catch (e) {
        bug(e);
      }
    };

    event.preventDefault();
    return resetBeforeUnload();
  });

  const currentRoomClassNames = clsx(
    styles['current-room__content'],
    collapsedSidebar && styles['current-room__content--sidebar-collapsed'],
  );

  return (
    <CurrentRoomContext.Provider value={value}>
      <div className={currentRoomClassNames}>
        <Loader size={50} isLoading={loadingRoom} />
        {!loadingRoom && (
          <>
            <div className={styles.header}>
              <TopBar
                badgeHighlighted={badgeHighlighted}
                fileBroswerDisabled={isFileBroswerDisabled}
                navigationStack={navigationStack}
                setBadgeUser={setBadgeUser}
              />
            </div>
            <div className={styles.sidebar}>
              <FileExplorer
                leaderModeSwitchValue={leaderModeSwitchValue}
                switchLeaderModeHandler={switchLeaderModeHandler}
              />
            </div>
            <div className={styles.content}>
              <IDE
                setNavigationStack={setNavigationStack}
                leaderModeSwitchValue={leaderModeSwitchValue}
              />
            </div>
            <Suspense fallback={<Loader size={50} />}>
              <TaskDescriptionModal
                show={showTaskContent}
                onClose={closeTaskModal}
                data={activeFile}
              />
            </Suspense>
            <BadgeModal badgeUser={badgeUser} setBadgeUser={setBadgeUser} />
          </>
        )}
      </div>
    </CurrentRoomContext.Provider>
  );
}
