import clsx from 'clsx';
import styles from './badgeGranted.module.css';

interface BadgeGrantedProps {
  selected: boolean;
}

export default function badgeGranted(props: BadgeGrantedProps) {
  const { selected } = props;
  const badgeGrantedClassNames = clsx(
    styles['badge-granted__content'],
    selected
      ? styles['badge-granted__content--visible']
      : styles['badge-granted__content--invisible'],
  );

  return <div className={badgeGrantedClassNames}>+1</div>;
}
