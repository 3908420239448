import logo from 'assets/images/logo-black.png';

// ----------------------------------------------------------------------

export interface LogoProps {
  disabledLink?: boolean;
}
export default function Logo({ disabledLink = false }: LogoProps) {
  return (
    <a href={disabledLink ? undefined : '/'}>
      <img src={logo} alt="logo" />
    </a>
  );
}
