import { MouseEvent, useCallback } from 'react';
import { IHandleMenu } from './interfaces';

export const HandleMenu = (data: IHandleMenu) => {
  const { setAnchorEl } = data;
  return useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );
};
