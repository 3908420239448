import { getFunctions, httpsCallable } from 'firebase/functions';
import { EmailData, EmailsForParentsInRoomData } from 'schemas/email';
import log from 'log';

/**
 *
 * @param data takes sendgrid email data and calles it with cloud function
 */
export async function sendEmailWithSendGrid(data: EmailData) {
  try {
    const functions = getFunctions();
    const sendEmail = httpsCallable(functions, 'sendEmail');
    await sendEmail(data);
  } catch (e) {
    throw e;
  }
}

/**
 *
 * @param data takes sendgrid email data and calles it with cloud function
 */
export async function sendEmailWithSendGridTemplate(data: EmailData, emailDynamicData: object) {
  try {
    const functions = getFunctions();
    const sendEmailWithTemplate = httpsCallable(functions, 'sendEmailWithTemplate');
    const emailData = { ...data, dynamic_template_data: emailDynamicData };
    await sendEmailWithTemplate(emailData);
  } catch (e) {
    throw e;
  }
}

/**
 *
 * @param data takes sendgrid email data and calles it with cloud function
 */
export async function sendEmailsToParentsInRoom(data: EmailsForParentsInRoomData) {
  try {
    const functions = getFunctions();
    const sendEmail = httpsCallable(functions, 'sendEmailsToParentsInRoom');
    await sendEmail(data);
  } catch (e) {
    throw e;
  }
}

/**
 * creates user with firebase function to get additional user data with from admin
 * @param email user email
 * @param password user password
 * @returns newly created user record
 */
export async function createUserWithFirebaseFn(email: string, password = 'pixblocks1') {
  const functions = getFunctions();
  const signUpUser = httpsCallable(functions, 'signUpUser');
  const data = { email, password };
  const { data: userRecord } = await signUpUser(data);
  return userRecord;
}

/**
 * Deletes user from firebase auth (NOT database, but auth)
 * @param email user email
 */
export async function deleteUserFromAdmin(email: string) {
  const functions = getFunctions();
  const deleteUser = httpsCallable(functions, 'deleteUserFromAdmin');
  const data = { email };
  await deleteUser(data);
}

/**
 * Send message to chat GPT and wait for answer
 * @param message user custom message
 */
export async function sendMessageToChatGPT(message: string): Promise<string> {
  const functions = getFunctions();
  const sendMessage = httpsCallable(functions, 'sendMessageToChatGPT');
  const response = await sendMessage(message);
  return response.data as string;
}

export async function testParentReport() {
  try {
    const functions = getFunctions();
    const parentReport = httpsCallable(functions, 'parentReport');
    await parentReport();
  } catch (e) {
    throw e;
  }
}

export async function testParentReminder() {
  try {
    const functions = getFunctions();
    const parentReminderForLesson = httpsCallable(functions, 'parentReminderForLesson');
    await parentReminderForLesson();
  } catch (e) {
    log.error(e);
    throw e;
  }
}

export async function synchronizeDatabases() {
  try {
    const functions = getFunctions();
    const synchDatabases = httpsCallable(functions, 'synchronizeDatabases');
    const result = await synchDatabases();
    return result;
  } catch (e) {
    throw e;
  }
}

export async function clearMembersDataInRoom(roomId: string) {
  try {
    const functions = getFunctions();
    const clearMembersData = httpsCallable(functions, 'clearMembersDataInRoom');
    const result = await clearMembersData({ roomId });
    return result;
  } catch (e) {
    throw e;
  }
}

export async function zeroResolvedTasks() {
  try {
    const functions = getFunctions();
    const zeroResolvedTasks = httpsCallable(functions, 'zeroResolvedTasks');
    const result = await zeroResolvedTasks();
    return result;
  } catch (e) {
    throw e;
  }
}

export async function archiveRoom(roomId: string) {
  try {
    const functions = getFunctions();
    const archiveRoom = httpsCallable(functions, 'archiveRoom');
    const result = await archiveRoom(roomId);
    return result;
  } catch (e) {
    throw e;
  }
}

export async function restoreRoom(roomId: string) {
  try {
    const functions = getFunctions();
    const restoreRoom = httpsCallable(functions, 'restoreRoom');
    const result = await restoreRoom(roomId);
    return result;
  } catch (e) {
    throw e;
  }
}
