import { useEffect } from 'react';
import { getType } from 'helpers/FilesHelper';
import { ISetFileState } from './interfaces';

export const SetFileState = (data: ISetFileState) => {
  const { canEditStudentFile, fileData, loadedAce, setReadOnly } = data;

  useEffect(() => {
    loadedAce && fileData && setReadOnly(canEditStudentFile, fileData, getType(fileData.type));
  }, [canEditStudentFile, fileData, loadedAce, setReadOnly]);
};
