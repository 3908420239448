import { useEffect } from 'react';
import { onValue, ref } from 'firebase/database';
import { roomSchema } from 'schemas/room.schema';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { Room } from 'types/RoomTypes';
import { z } from 'zod';
import { IGetRooms } from './interfaces';

export const GetRooms = (props: IGetRooms) => {
  const { bug, setRoomList } = props;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    try {
      const database = getAppDatabase(AppName.DEFAULT);
      const roomsRef = ref(database, 'rooms');
      const unsubscribe = onValue(roomsRef, snapshot => {
        const result = snapshot.val();
        const roomsSchema = z.record(roomSchema);
        const parsedRooms = roomsSchema.parse(result);
        const rooms = Object.entries(parsedRooms).reduce((data: Room[], [_, room]) => {
          data.push(room);
          return data;
        }, []);
        setRoomList(rooms);
      });
      return () => {
        unsubscribe();
      };
    } catch (e) {
      bug(e);
    }
  }, [bug, setRoomList]);
};
