import { useCallback, useRef } from 'react';
import { isStudent } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { startTask } from 'store/FirebaseStore';
import { Question } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import { Editor, ExtensionsMenu, InternalKeyboard } from './components';
import { ContentProps } from './interface';
import styles from './ideContent.module.css';
import Box from '@mui/material/Box';

export default function IDEContent(props: ContentProps) {
  const { editorRef, extensionsMenuOpened, isCodeMode, loadedAce, question, getAceInstance } =
    props;
  const { currentUser } = useAppContext();
  const { bug } = useErrorContext();
  const { leaderMode, localCourseId, localLessonId, room } = useWorkspaceContext();
  const lastEditedFile = useRef<Question | null>(null);
  const aceInstance = getAceInstance();
  const isLanguageBlocks = question?.language === ProgrammingLanguage.Blocks;
  const shouldShowKeyboard = isLanguageBlocks && question?.type !== FileType.Sample;
  const isKeyboardDisabled =
    !loadedAce ||
    Boolean(
      currentUser &&
        question &&
        isStudent(currentUser?.role) &&
        leaderMode.isLeaderModeOn &&
        question.type !== FileType.Sandpit,
    );

  const handleTaskEdit = useCallback(async () => {
    if (question !== lastEditedFile.current) {
      lastEditedFile.current = question;
      try {
        await startTask(localCourseId, localLessonId, question, room, currentUser);
      } catch (e) {
        bug(e);
      }
    }
  }, [currentUser, lastEditedFile, localCourseId, localLessonId, question, room, bug]);

  return (
    <Box className={styles['ide-content']}>
      {question?.language === ProgrammingLanguage.Python && (
        <ExtensionsMenu isOpen={extensionsMenuOpened} question={question} />
      )}
      {shouldShowKeyboard && (
        <InternalKeyboard
          isCodeMode={isCodeMode}
          aceEditor={aceInstance?.getAceEditor()}
          aceWrapperRef={aceInstance?.getAceWrapperRef()}
          question={question}
          disabled={isKeyboardDisabled}
        />
      )}
      <div className={styles['ide-content__editor']}>
        <Editor
          ref={editorRef}
          isCodeMode={isCodeMode}
          isLanguageBlocks={isLanguageBlocks}
          questionType={question?.type as FileType}
          height="100vh"
          mode={question?.language as string}
          jsLibrary={question?.jsLibrary}
          onTaskEdit={handleTaskEdit}
        />
      </div>
    </Box>
  );
}
