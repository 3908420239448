import { z } from 'zod';

export const errorMessageSchema = z.object({
  ERROR_MESSAGE: z.string(),
  ERROR_LINE: z.number(),
});

export const responseMessageSchema = z.object({
  OUTPUT: z.string(),
  VARIABLES: z.string(),
  RUN_LINE: z.number(),
  TICK: z.number(),
});

export const endLineMessageSchema = z.object({
  OUTPUT: z.string(),
  VARIABLES: z.string(),
  RUN_LINE: z.number(),
  TICK: z.number(),
  TASK_IS_CORRECT: z.boolean(),
  ERROR_LIST: z.array(
    z.number().or(
      z.object({
        x: z.number(),
        y: z.number(),
      }),
    ),
  ),
});

export const inputMessageSchema = z.object({
  INPUT: z.string(),
});

export const outputMessageSchema = z.object({
  OUTPUT: z.string(),
  MODE: z.boolean(),
});

export const blocksImageMessageSchema = z.object({
  IMAGE: z.string(),
});
