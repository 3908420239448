import { useEffect, useState } from 'react';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { userPointsListener } from 'store/FirebaseStore';
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import styles from './userPoints.module.css';

export default function UserPpoints() {
  const { currentUserId } = useWorkspaceContext();
  const [points, setPoints] = useState<number>(0);

  useEffect(() => {
    const getPoints = () => {
      try {
        const unsubscribe = userPointsListener(currentUserId, setPoints);
        return () => {
          unsubscribe();
        };
      } catch (e) {
        throw e;
      }
    };
    getPoints();
  }, [currentUserId]);

  return (
    <IconButton disabled>
      <StarIcon className={styles['user-points__icon']} />
      <span className={styles['user-points__points']}>{points}</span>
    </IconButton>
  );
}
