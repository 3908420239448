import { useMemo } from 'react';
import { darkViolet } from 'constants/Colors';
import Button, { ButtonProps } from '@mui/material/Button';

export interface MButtonProps extends ButtonProps {
  textColor?: string;
  borderColor?: string;
}

/**
 *
 * @param textColor css color
 * @param borderColor css color
 * @param props default Mui buttom props (like sx etc.)
 * @returns Mui Button with default colors and changed animation
 */
export default function MButton({
  textColor = darkViolet,
  borderColor = darkViolet,
  ...props
}: MButtonProps) {
  const sx = useMemo(
    () => ({
      color: textColor,
      borderColor: darkViolet,

      '&:hover': {
        border: `1px solid ${borderColor}`,
      },
    }),
    [textColor, borderColor],
  );

  return <Button sx={sx} {...props} />;
}
