import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { currentUserSchema } from 'schemas/user.schema';
import { loginWithGoogle } from 'store/FirebaseStore';
import { useAppContext } from 'providers/app/app.provider';
import { useForm } from 'react-hook-form';
import { LoginSchemaData, loginSchema } from 'schemas/login.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useErrorContext } from 'providers/error/error.provider';
import { codeToMessage, isCustomValidationCode } from 'helpers/statusCodeValidation';
import styles from '../login.module.css';
import { StyledLoadingButton } from '../helpers/styled';
import GoogleButton from 'components/molecules/GoogleButton';
import { toast } from 'react-toastify';
import { SubmitUserForm } from './submitHelper';
import { Trans, t } from '@lingui/macro';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { getFirebaseError } from 'providers/error/firebase.error';
import { AUTH_INTERNAL_ERROR } from 'providers/error/const';

export interface TutorViewProps {}

const captionSx = {
  marginLeft: '14px',
  fontSize: '12px',
  fontWeight: 400,
  letterSpacing: '0.4px',
  lineHeight: '20px',
  marginTop: '-4px',
  cursor: 'pointer',
};

export default function TutorView() {
  const { setUser } = useAppContext();
  const { i18n } = useLocaleContext();
  const { bug } = useErrorContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<LoginSchemaData>({
    resolver: zodResolver(loginSchema),
  });

  const getErrorMessage = useCallback(
    (fieldName: keyof LoginSchemaData) => {
      if (!errors[fieldName]) return '';
      return isCustomValidationCode(errors[fieldName]?.message)
        ? codeToMessage(i18n, errors[fieldName]?.message)
        : errors[fieldName]?.message;
    },
    [errors, i18n],
  );

  const navigateToChangePasswordView = useCallback(() => {
    navigate('/auth/forgotPassword');
  }, [navigate]);

  const setAuthError = useCallback(
    (name: keyof LoginSchemaData, message: string, shouldFocus = true) => {
      setError(
        name,
        {
          message,
        },
        {
          shouldFocus,
        },
      );
    },
    [setError],
  );

  const submit = async (data: LoginSchemaData) => {
    try {
      if (!data.password) data.password = 'pixblocks1';
      await SubmitUserForm({ data, setUser, setAuthError, navigate, i18n });
    } catch (e) {
      bug(e);
    }
  };

  const handleGoogleAuth = useCallback(async () => {
    try {
      const user = await loginWithGoogle();
      const currentUser = currentUserSchema.parse({
        ...user,
        color: '',
        isLogged: true,
      });
      setUser(currentUser);
      navigate('/rooms');
    } catch (error) {
      const [code, msg] = getFirebaseError(i18n, error);
      if (code === AUTH_INTERNAL_ERROR) {
        toast.info(msg);
      } else {
        bug(error);
      }
    }
  }, [bug, navigate, setUser, i18n]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <TextField
        margin="dense"
        id="id-email"
        type="email"
        variant="filled"
        label={<Trans id="input.email">E-mail address</Trans>}
        error={Boolean(errors.email)}
        helperText={getErrorMessage('email')}
        size="medium"
        disabled={isSubmitting ? true : false}
        {...register('email')}
        fullWidth
      />
      <TextField
        margin="dense"
        id="id-password"
        type="password"
        variant="filled"
        label={<Trans id="input.password">Password</Trans>}
        error={Boolean(errors.password)}
        helperText={getErrorMessage('password')}
        size="medium"
        required={false}
        disabled={isSubmitting ? true : false}
        {...register('password')}
        fullWidth
      />
      <Typography sx={captionSx} variant="caption" onClick={navigateToChangePasswordView}>
        <Trans id="input.forgotPassword.label">Forgot password?</Trans>
      </Typography>

      <Box width="100%">
        <Stack>
          <StyledLoadingButton loading={isSubmitting} type="submit" variant="contained">
            <Trans id="login.btn">login</Trans>
          </StyledLoadingButton>
        </Stack>
        <GoogleButton className={styles['google-btn']} onClick={handleGoogleAuth}>
          <Trans id="login.googleBtn">Sign in with Google</Trans>
        </GoogleButton>
      </Box>
    </form>
  );
}
