import { get, ref, update, child } from 'firebase/database';
import { getAppDatabase, AppName } from '../../helpers/database';

/**
 *
 * @param sharePrivateFileRef contains ref for firepad file with boolean
 * @returns boolean true if file was shared.
 */
export const getIsPrivateFileShared = async (sharePrivateFileRef: string): Promise<boolean> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const snapshot = await get(child(ref(database), `private_files/${sharePrivateFileRef}/shared`));
    if (snapshot.exists()) {
      return snapshot.val();
    }
    return false;
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param sharePrivateFileRef contains ref for firepad file with boolean
 * @returns
 */
export const updatePrivateFileSharedStatus = async (
  sharePrivateFileRef: string,
  isShared: boolean,
): Promise<void> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const fileToUpdateRef = ref(database, `private_files/${sharePrivateFileRef}`);
    await update(fileToUpdateRef, { shared: isShared });
  } catch (e) {
    throw e;
  }
};
