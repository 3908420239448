export type RGB = {
  red: number;
  green: number;
  blue: number;
};

const validateHexColor = (color: string): string => (color.length === 1 ? `0${color}` : color);

export const transformColorFromHexToObject = (colorString: string): RGB => {
  if (colorString[0] !== '#' || (colorString.length !== 7 && colorString.length !== 4))
    throw Error('Wrong color format!');
  const color = colorString.slice(1);
  return {
    red: parseInt(color.slice(0, 2), 16),
    green: parseInt(color.slice(2, 4), 16),
    blue: parseInt(color.slice(4), 16),
  };
};

export const transformColorFromObjectToRGBA = (color: RGB, opacity: number = 1): string => {
  const { red, green, blue } = color;
  return `rgba(${red}, ${green}, ${blue}, ${opacity})`;
};

export const transformColorFromObjectToHex = (color: RGB): string => {
  const red = validateHexColor(color.red.toString(16));
  const green = validateHexColor(color.green.toString(16));
  const blue = validateHexColor(color.blue.toString(16));
  return `#${red}${green}${blue}`.toUpperCase();
};

export const transformColorFromObjectToPix = (color: RGB): string => {
  const { red, green, blue } = color;
  return `Color(${red}, ${green}, ${blue})`;
};

export const transformColorFromHexToRGBA = (colorString: string, opacity?: number) => {
  const color: RGB = transformColorFromHexToObject(colorString);
  return transformColorFromObjectToRGBA(color, opacity);
};

export const getOppositeColor = (color: RGB): RGB => {
  const { red, green, blue } = color;
  const oppositeColor: RGB = {
    red: 255 - red,
    green: 255 - green,
    blue: 255 - blue,
  };
  return oppositeColor;
};
