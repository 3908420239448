import { useEffect } from 'react';
import { clsx } from 'clsx';
import CustomModal, { CustomModalProps } from '../../../organisms/CustomModal';
import Zoom from '@mui/material/Zoom';
import styles from './disapperaringPopup.module.css';

export interface DisapperaingPopupProps extends CustomModalProps {
  timer?: number;
}

export default function DisapperaingPopup({
  timer = 3000,
  onClose,
  show,
  children,
  ...props
}: DisapperaingPopupProps) {
  useEffect(() => {
    if (onClose && show) {
      setTimeout(() => {
        onClose();
      }, timer);
    }
  }, [onClose, timer, show]);

  return (
    <CustomModal
      show={show}
      modalClassName={clsx(styles['disappearing-popup__content'], props.modalClassName)}
      backdrop
      onClose={onClose}
      {...props}
    >
      <Zoom in={show}>{children as JSX.Element}</Zoom>
    </CustomModal>
  );
}
