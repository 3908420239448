import { purple, white } from 'constants/Colors';
import { enableOpacity } from 'constants/Opacities';
import { SoundItemType } from 'types/SoundTypes';
import { SoundItem } from './style';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface SoundButtonProps {
  selectedSound?: string;
  sound: SoundItemType;
  playSound: (soundName: string) => void;
}

export function SoundButton(props: SoundButtonProps) {
  const { selectedSound, sound, playSound } = props;
  const buttonSelected: boolean = selectedSound === sound.name;

  const theme = {
    playArrowIcon: { opacity: `${enableOpacity} !important` },
    playArrowIconSelected: { opacity: '1 !important' },
    button: { color: `${white} !important` },
    buttonSelected: { color: `${purple} !important` },
  };

  function play() {
    playSound(sound.name);
  }

  return (
    <SoundItem
      label={
        <>
          <span>{sound.name}</span>
          <PlayArrowIcon sx={buttonSelected ? theme.playArrowIconSelected : theme.playArrowIcon} />
        </>
      }
      sx={buttonSelected ? theme.buttonSelected : theme.button}
      onClick={play}
    />
  );
}
