import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { I18nProvider } from '@lingui/react';
import { I18Languages } from 'types/i18nTypes';
import { I18nTypes, I18nProviderWrapperProps } from './i18n.types';
import { getItem, setItem } from 'helpers/LocalStorageHelper';
import { localeUnionSchema } from 'schemas/i18n';
import { dynamicActivate, defaultLocale, localeCodes } from 'helpers/i18n';

export const LocaleContext = React.createContext<I18nTypes>(undefined!);
const { Provider } = LocaleContext;

export function I18nProviderWrapper(props: I18nProviderWrapperProps) {
  const [locale, setLocale] = useState<I18Languages>(
    () => getItem('selectedLanguage', localeUnionSchema) ?? defaultLocale,
  );
  const [i18n] = useState(props.i18n);

  const languageSwitcher = useCallback(
    (locale: I18Languages) => {
      setLocale(locale); // context locale
      dynamicActivate(i18n, locale); // i18n setup locale
      setItem('selectedLanguage', locale); // localstorage
    },
    [i18n],
  );

  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(i18n, locale);
    setItem('selectedLanguage', locale);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      locale,
      languageSwitcher,
      i18n,
    }),
    [locale, languageSwitcher, i18n],
  );

  return (
    <Provider value={value}>
      <I18nProvider i18n={i18n}>{props.children}</I18nProvider>
    </Provider>
  );
}

export function useLocaleContext() {
  const context = useContext(LocaleContext);

  if (context === undefined) {
    throw new Error(`useLocaleContext must be used within a I18nProviderWrapper`);
  }
  return context;
}

I18nProviderWrapper.validateLanguage = function (
  possibleLanguage: string | null | undefined,
): I18Languages {
  if (!possibleLanguage) return defaultLocale;
  const language = localeCodes.find(l => l === possibleLanguage) as I18Languages;
  return language ? language : defaultLocale;
};
