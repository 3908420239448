/* eslint-disable no-loops/no-loops */
import {
  get,
  query,
  ref as firebaseRef,
  getDatabase,
  orderByChild,
  equalTo,
  update,
} from 'firebase/database';
import { Timestamp } from 'firebase/firestore';
import getItemArray from '../getItemArray';
import getMeetLink from '../getMeetLink';
import { RoomCreationForm } from 'schemas/adminForms.schema';
import { User } from 'types/UserTypes';

async function addRoom(room: RoomCreationForm) {
  const updates: Record<string, unknown> = {};
  // eslint-disable-next-line require-unicode-regexp
  const users = room.users.trim() === '' ? [] : room.users.split(/[\s,]+/);

  const newRoom = (roomUrl: string) => {
    const { users, ..._room } = room;

    const newRoom = {
      meetLink: roomUrl,
      creationDate: Timestamp.now(),
      activeTutor: '',
      forcedFeedback: false,
      isLeaderMode: false,
      activeLesson: '',
      activeCourse: '',
      ..._room,
    };

    return newRoom;
  };

  const leaderModeData = () => ({
    roomId: room.id,
  });

  const newMember = (user: string) => ({
    isActive: false,
    idActiveQuestion: '',
    uid: user,
  });

  const newUserRoom = (roomId: string) => ({
    id: roomId,
  });

  function assignUser(roomId: string, usersId: string[], i: number) {
    updates[`members/${roomId}/${usersId[i]}`] = newMember(usersId[i]);
    updates[`users_rooms/${usersId[i]}/${roomId}`] = newUserRoom(roomId);
  }

  function assignUsersToRoom(roomId: string, usersId: string[]) {
    for (let i = 0; i < users.length; i += 1) {
      assignUser(roomId, usersId, i);
    }
  }

  function getUsersId(users: string[], result: User[]) {
    const usersId = [];
    for (let i = 0; i < users.length; i += 1) {
      for (let j = 0; j < result.length; j += 1) {
        if (users[i] === result[j].email) {
          usersId.push(result[j].uid);
          break;
        }
      }
    }
    return usersId;
  }

  async function updateFirebase(result: User[]) {
    const db = getDatabase();
    const meetLink = await getMeetLink();
    const usersId = getUsersId(users, result);
    updates[`/rooms/${room.id}`] = newRoom(meetLink);
    updates[`/leader_mode_data/${room.id}`] = leaderModeData();
    await assignUsersToRoom(room.id, usersId);
    await update(firebaseRef(db), updates);
  }

  function verifyUsers(users: string[], result: User[]) {
    let userInFirestore;
    for (let i = 0; i < users.length; i += 1) {
      userInFirestore = false;
      for (let j = 0; j < result.length; j += 1) {
        if (users[i] === result[j].email) {
          userInFirestore = true;
          break;
        }
      }
      if (!userInFirestore) {
        return false;
      }
    }
    return true;
  }

  function checkUsers(): Promise<any> {
    const db = getDatabase();
    const que = query(firebaseRef(db, 'users'));
    return get(que)
      .then(snapshot => getItemArray(snapshot))
      .then(result => {
        if (!verifyUsers(users, result)) throw Error("Wrong users' emails!");
        return result;
      });
  }

  async function isIdInUse() {
    const db = getDatabase();
    const que = query(firebaseRef(db, 'rooms'), orderByChild('id'), equalTo(room.id));
    await get(que).then(snapshot => {
      if (snapshot.exists()) throw Error('This ID is already in use');
    });
  }

  await isIdInUse()
    .then(() => checkUsers())
    .then(async result => {
      await updateFirebase(result);
    })
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
}

export default addRoom;
