import { filter } from 'lodash';
import { User } from 'types/UserTypes';

const descendingComparator = (a: User, b: User, orderBy: keyof User) => {
  const item1 = a[orderBy];
  const item2 = b[orderBy];

  if (!item1) return -1;
  if (!item2) return 1;
  if (item2 < item1) {
    return -1;
  }
  if (item2 > item1) {
    return 1;
  }
  return 0;
};

const filterProperty = (property: string, text: string) =>
  property.toLowerCase().indexOf(text.toLowerCase()) !== -1;

export const filterByAll = (array: Record<string, any>[], activeFilters: Record<string, any>) => {
  let filteredArray = [...array];
  // eslint-disable-next-line no-restricted-syntax, no-loops/no-loops
  for (const key in activeFilters) {
    if (activeFilters[key] !== '') {
      filteredArray = filteredArray.filter(
        item => item[key].toString() === activeFilters[key].toString(),
      );
    }
  }

  return filteredArray;
};

export const getComparator = (isOrderReversed: boolean, orderBy: keyof User) =>
  isOrderReversed
    ? (a: User, b: User) => descendingComparator(a, b, orderBy)
    : (a: User, b: User) => -descendingComparator(a, b, orderBy);

export const applySortFilter = (
  array: User[],
  comparator: (a: User, b: User) => number,
  query: string,
): User[] => {
  const stabilizedThis: [User, number][] = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  try {
    if (query) {
      return filter(
        array,
        _user =>
          filterProperty(_user.name, query) ||
          filterProperty(_user.lastName, query) ||
          filterProperty(_user.email, query),
      );
    }
  } catch (e) {
    throw e;
  }
  return stabilizedThis.map(el => el[0]);
};
