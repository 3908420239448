import React from 'react';
import { Props, State, WorkspaceContext } from './workspace.types';
import { ActiveFile } from '../../types/Workspace';
import { IBadgesCounter } from 'types/BadgeTypes';
import { Room } from 'types/RoomTypes';
import { ParsedCourse } from 'types/ContentTypes';
import { MemberData } from 'types/MemberTypes';
import LeaderMode from 'types/LeaderMode';
// consts
import { DEFAULT_USER_COLOR } from './constants';
import { appContext } from '../app/app.provider';
import UserActions from 'classes/UserActions';
import { CurrentUser } from 'types/UserTypes';

const workspaceContext = React.createContext<WorkspaceContext>(undefined!);

export class WorkspaceProvider extends React.Component<Props, State> {
  static contextType = appContext;
  context!: React.ContextType<typeof appContext>;

  constructor(props: Props) {
    super(props);
    this.setActiveFile = this.setActiveFile.bind(this);
    this.setActiveTutor = this.setActiveTutor.bind(this);
    this.setBadgesCounter = this.setBadgesCounter.bind(this);
    this.setContent = this.setContent.bind(this);
    this.setCurrentColor = this.setCurrentColor.bind(this);
    this.setLocalCourseId = this.setLocalCourseId.bind(this);
    this.setLocalLessonId = this.setLocalLessonId.bind(this);
    this.setMembers = this.setMembers.bind(this);
    this.setRoom = this.setRoom.bind(this);
    this.setRoomLogId = this.setRoomLogId.bind(this);
    this.setUserActions = this.setUserActions.bind(this);
    this.setActiveTutor = this.setActiveTutor.bind(this);

    this.state = {
      workspace: {
        activeFile: null,
        setActiveFile: this.setActiveFile,
        activeTutor: '',
        setActiveTutor: this.setActiveTutor,
        badgesCounter: undefined,
        setBadgesCounter: this.setBadgesCounter,
        color: DEFAULT_USER_COLOR,
        setCurrentColor: this.setCurrentColor,
        content: [],
        setContent: this.setContent,
        currentUserId: '',
        setCurrentUserId: this.setCurrentUserId,
        leaderMode: new LeaderMode(),
        localCourseId: '',
        setLocalCourseId: this.setLocalCourseId,
        localLessonId: '',
        setLocalLessonId: this.setLocalLessonId,
        members: [],
        setMembers: this.setMembers,
        room: null,
        setRoom: this.setRoom,
        roomLogId: '',
        setRoomLogId: this.setRoomLogId,
        userActions: new UserActions(null, ''),
        setUserActions: this.setUserActions,
      },
    };
  }

  setBadgesCounter = (badgesCounter: IBadgesCounter) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        badgesCounter,
      },
    }));
  };

  setUserActions = (user: CurrentUser, activeTutorId: string) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        userActions: new UserActions(user, activeTutorId),
      },
    }));
  };

  setLocalCourseId = (localCourseId: string) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        localCourseId,
      },
    }));
  };

  setLocalLessonId = (localLessonId: string) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        localLessonId,
      },
    }));
  };

  setCurrentUserId = (currentUserId: string) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        currentUserId,
      },
    }));
  };

  setCurrentColor = (color: string) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        color,
      },
    }));
  };

  setRoom = (room: Room) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        room,
      },
    }));
  };

  setContent = (content: ParsedCourse[]) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        content,
      },
    }));
  };

  setMembers = (members: MemberData[]) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        members,
      },
    }));
  };

  setActiveFile = (file: ActiveFile) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        activeFile: file,
      },
    }));
  };

  setActiveTutor = (tutorId: string) => {
    this.context.currentUser && this.setUserActions(this.context.currentUser, tutorId);
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        activeTutor: tutorId,
      },
    }));
  };

  setRoomLogId = (id: string) => {
    this.setState(prev => ({
      ...prev,
      workspace: {
        ...prev.workspace,
        roomLogId: id,
      },
    }));
  };

  render() {
    return (
      <workspaceContext.Provider value={this.state.workspace}>
        {this.props.children}
      </workspaceContext.Provider>
    );
  }
}

export const useWorkspaceContext = () => {
  const context = React.useContext(workspaceContext);
  if (context === undefined) {
    throw new Error(`useWorkspaceContext must be used within a AppProvider`);
  }
  return context;
};
