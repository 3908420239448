import { IAceEditor } from 'react-ace/lib/types';
import { getSessionMembers, getActiveTutor } from 'store/FirebaseStore';
import Firepad, { BaseOptions } from '../../../../../@types/firepad';
import firebase from 'firebase/compat/app';

// firepad & ace
export function createFirepadInstance(
  firepadRef?: firebase.database.Reference,
  editor?: IAceEditor,
  options?: BaseOptions,
): Firepad | null {
  if (!firepadRef || !editor || !options) return null;

  return window.Firepad.fromACE(firepadRef, editor, options);
}

// returns true if tutor is active and he is on the same question as current student, else returns false
export async function isTutorOnCurrentQuestion(
  roomId?: string,
  currentUserId?: string,
): Promise<boolean> {
  if (!roomId || !currentUserId) return false;
  try {
    const sessionMembers = await getSessionMembers(roomId);

    const activeTutor = await getActiveTutor(roomId);
    if (!activeTutor) return false;
    if (activeTutor.uid === currentUserId) return true;

    const tutorActiveQuestion = sessionMembers[activeTutor.uid].idActiveQuestion; // tutor active question
    const studentActiveQuestion = sessionMembers[currentUserId].idActiveQuestion; // current student active question

    return tutorActiveQuestion === studentActiveQuestion;
  } catch (e) {
    throw e;
  }
}

export function clearFirepadHistory(firepad: Firepad | null): boolean {
  if (!firepad) return false;
  try {
    firepad.setText('');
    return true;
  } catch (e) {
    return false;
  }
}

export function updateFirepadHistory(firepad: Firepad | null, value: string): boolean {
  if (!firepad) return false;
  try {
    firepad.setText(value);
    return true;
  } catch (e) {
    return false;
  }
}

export function compareFirepadHistory(firepad: Firepad | null, value?: string): boolean {
  if (!firepad || !value) return false;
  const firepadHistory = firepad.getText();
  // eslint-disable-next-line require-unicode-regexp
  return firepadHistory.replace(/\s/g, '') === value.replace(/\s/g, '');
}

export function setEditorCode(editor: IAceEditor | null, value?: string): boolean {
  if (!editor || !value) return false;
  editor.setValue(value);
  return true;
}
