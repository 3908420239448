import { HttpsCallable, getFunctions, httpsCallable } from 'firebase/functions';
import { lessonSkillsSchema, skillsTypeSchema } from 'schemas/skills.schema';
import { ISkillsSelection } from 'types/SkillsTypes';
import { z } from 'zod';

export const getSkillsTypes = async () => {
  try {
    const functions = getFunctions();
    const _getSkillsTypes = httpsCallable(functions, 'getSkillsTypes');
    const skillsTypes = await _getSkillsTypes();
    return z.array(skillsTypeSchema).parse(skillsTypes.data);
  } catch (e) {
    throw e;
  }
};

export const getLessonSkillsForUser = async (
  courseId: string,
  lessonId: string,
  roomId: string,
  userId: string,
) => {
  try {
    const functions = getFunctions();
    const _getLessonSkillsForUser = httpsCallable(functions, 'getLessonSkillsForUser');
    const lessonSkills = await _getLessonSkillsForUser({ courseId, lessonId, roomId, userId });
    return z.array(lessonSkillsSchema).parse(lessonSkills.data);
  } catch (e) {
    throw e;
  }
};

export const updateLessonSkills = async (
  courseId: string,
  lessonId: string,
  roomId: string,
  skills: ISkillsSelection[],
  studentUid: string,
  tutorUid: string,
) => {
  try {
    const functions = getFunctions();
    const _updateLessonSkills = httpsCallable(functions, 'updateLessonSkills');
    await _updateLessonSkills({ courseId, lessonId, roomId, skills, studentUid, tutorUid });
  } catch (e) {
    throw e;
  }
};
