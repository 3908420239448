import { SoundItemType } from 'types/SoundTypes';

const eSoundsList: SoundItemType[] = [
  { name: 'e1' },
  { name: 'e2' },
  { name: 'e3' },
  { name: 'e4' },
  { name: 'e5' },
  { name: 'e6' },
  { name: 'e7' },
  { name: 'e8' },
  { name: 'e9' },
  { name: 'e10' },
  { name: 'e11' },
  { name: 'e12' },
  { name: 'e13' },
  { name: 'e14' },
  { name: 'e15' },
  { name: 'e16' },
  { name: 'e17' },
  { name: 'e18' },
  { name: 'e19' },
  { name: 'e20' },
  { name: 'e21' },
];

const mSoundsList: SoundItemType[] = [
  { name: 'm1' },
  { name: 'm2' },
  { name: 'm3' },
  { name: 'm4' },
];

const nSoundsList: SoundItemType[] = [
  { name: 'n1' },
  { name: 'n2' },
  { name: 'n3' },
  { name: 'n4' },
  { name: 'n5' },
  { name: 'n6' },
  { name: 'n7' },
  { name: 'n8' },
  { name: 'n9' },
  { name: 'n10' },
  { name: 'n11' },
  { name: 'n12' },
  { name: 'n13' },
  { name: 'n14' },
  { name: 'n15' },
  { name: 'n16' },
  { name: 'n17' },
  { name: 'n18' },
  { name: 'n19' },
  { name: 'n20' },
  { name: 'n21' },
  { name: 'n22' },
  { name: 'n23' },
  { name: 'n24' },
  { name: 'n25' },
  { name: 'n26' },
  { name: 'n27' },
  { name: 'n28' },
  { name: 'n29' },
  { name: 'n30' },
  { name: 'n31' },
  { name: 'n32' },
  { name: 'n33' },
  { name: 'n34' },
  { name: 'n35' },
  { name: 'n36' },
  { name: 'n37' },
];

export const soundsSource = [
  { key: 'e', list: eSoundsList },
  { key: 'm', list: mSoundsList },
  { key: 'n', list: nSoundsList },
];
