import { useCallback } from 'react';
import { Room } from 'types/RoomTypes';
import { UserListHead } from '../../../../../sections/@dashboard/user';
import { tableSx, TABLE_HEAD, tableCellSx } from './constants';
import { TableContentProps } from './interface';
import { Table, TableRow, TableBody as Body, TableCell, TableContainer } from '@mui/material';
import SearchNotFound from 'components/atoms/SearchNotFound';
import { TableBody } from './components';

export default function TableContent(props: TableContentProps) {
  const {
    rooms,
    isOrderReversed,
    orderBy,
    searchName,
    selectedRooms,
    setIsOrderReversed,
    setOrderBy,
    setSelectedRooms,
  } = props;

  const handleRequestSort = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>, property: string) => {
      const isAsc = orderBy === property && !isOrderReversed;
      setIsOrderReversed(isAsc);
      setOrderBy(property as keyof Room);
    },
    [isOrderReversed, orderBy, setIsOrderReversed, setOrderBy],
  );

  const handleSelectAllClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        setSelectedRooms(rooms);
        return;
      }
      setSelectedRooms([]);
    },
    [rooms, setSelectedRooms],
  );
  const isRoomNotFound = rooms.length === 0;

  return (
    <TableContainer sx={tableSx}>
      <Table>
        <UserListHead
          order={isOrderReversed ? 'desc' : 'asc'}
          orderBy={orderBy}
          headLabel={TABLE_HEAD} //
          rowCount={rooms.length}
          numSelected={selectedRooms.length}
          onRequestSort={handleRequestSort}
          onSelectAllClick={handleSelectAllClick}
        />
        <TableBody
          selectedRooms={selectedRooms}
          rooms={rooms}
          setSelectedRooms={setSelectedRooms}
        />
        {isRoomNotFound && (
          <Body>
            <TableRow>
              <TableCell align="center" colSpan={6} sx={tableCellSx}>
                <SearchNotFound searchQuery={searchName} />
              </TableCell>
            </TableRow>
          </Body>
        )}
      </Table>
    </TableContainer>
  );
}
