import { Tab } from '@mui/material';
import { styled } from '@mui/styles';

const iconSize = { width: 60, height: 32 };
const borderRadius = 16;

export const SoundItem = styled(Tab)({
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.08)',
  borderRadius: `${borderRadius}px`,
  display: 'flex',
  flexDirection: 'row',
  height: `${iconSize.height}px`,
  justifyContent: 'center',
  marginTop: '10px',
  minHeight: 0,
  minWidth: 0,
  opacity: 1,
  textTransform: 'none',
  width: `${iconSize.width}px`,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.12)',
  },
});
