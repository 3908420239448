import { FormControl, Select, InputLabel, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import { useCallback } from 'react';

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export interface MSelectProps {
  currentValue: string;
  name: string;
  items: { name: string; value: string | number }[];
  handleChange: (n: string, v: string) => void;
}

export default function MSelect({ currentValue, name, items, handleChange }: MSelectProps) {
  const label = capitalizeFirstLetter(name);

  const handleSelectChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      const { value } = e.target;
      handleChange(name, value);
    },
    [handleChange, name],
  );

  return (
    <Box minWidth={120}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={currentValue}
          onChange={handleSelectChange}
          label={label}
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {items.map(item => (
            <MenuItem key={item.name} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
