import { clsx } from 'clsx';
import { SWITCH_LEADER_MODE_ACTION } from 'classes/UserActions';
import { isTutor } from 'helpers/userHelper';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { useAppContext } from 'providers/app/app.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { ChangeLessonSectionProps } from './interface';
import { t } from '@lingui/macro';
import MSwitchWithText from 'components/organisms/MSwitchWithText';
import styles from './changeLessonSection.module.css';
import ContentBrowser from './ContentBrowser';

export default function ChangeLessonSection(props: ChangeLessonSectionProps) {
  const { leaderModeSwitchValue, switchLeaderModeHandler } = props;
  const { currentUser } = useAppContext();
  const { collapsedSidebar } = useCurrentRoomContext();
  const { i18n } = useLocaleContext();
  const { userActions } = useWorkspaceContext();
  const showLeaderButton = isTutor(currentUser?.role);
  const canSwitchLeaderMode = userActions.askForAction(SWITCH_LEADER_MODE_ACTION);
  const gridClassName = clsx(
    styles['change-lesson-section__grid'],
    collapsedSidebar && styles['change-lesson-section__grid--collapsed'],
  );
  const textClassName = clsx(
    styles['change-lesson-section__text'],
    leaderModeSwitchValue && styles['change-lesson-section__text--active'],
    collapsedSidebar && styles['change-lesson-section__text--collapsed'],
  );
  const mSwitchText = t(i18n)({ id: 'label.leadMode', message: 'Lead Mode' });

  return (
    <div className={styles['change-lesson-section__content']}>
      {showLeaderButton && (
        <MSwitchWithText
          gridClassName={gridClassName}
          textClassName={textClassName}
          disabled={!canSwitchLeaderMode}
          text={mSwitchText}
          checked={leaderModeSwitchValue}
          onChange={switchLeaderModeHandler}
          data-testid="leader-mode-btn"
        />
      )}
      <ContentBrowser collapsedSidebar={collapsedSidebar} />
    </div>
  );
}
