import { Outlet } from 'react-router-dom';

// Here you can фвв any styles for room (navigation bar, footbar, sidebar etc.)
export default function RoomLayout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}
