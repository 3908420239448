import { useEffect, Dispatch } from 'react';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { QuestionStack } from 'types/ContentTypes';
import { MemberData } from 'types/MemberTypes';
import MembersSection from './MembersSection/MembersSection';
import RightSection from './RightSection/RightSection';
import styles from './topBar.module.css';

export interface TopBarProps {
  badgeHighlighted: boolean;
  fileBroswerDisabled: boolean;
  navigationStack?: QuestionStack;
  setBadgeUser: Dispatch<MemberData | null>;
}

export default function TopBar(props: TopBarProps) {
  const { badgeHighlighted, fileBroswerDisabled, navigationStack, setBadgeUser } = props;
  const { currentUser } = useAppContext();
  const { members, setCurrentColor } = useWorkspaceContext();

  useEffect(() => {
    const color = members.find(item => item.uid === currentUser?.uid)?.color;
    color && setCurrentColor(color);
  }, [setCurrentColor, currentUser?.uid, members]);

  return (
    <div className={styles.topbar__content}>
      <MembersSection fileBroswerDisabled={fileBroswerDisabled} setBadgeUser={setBadgeUser} />
      <RightSection
        badgeHighlighted={badgeHighlighted}
        navigationStack={navigationStack}
        setBadgeUser={setBadgeUser}
      />
    </div>
  );
}
