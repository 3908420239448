import { useState } from 'react';
import { ILessonTree } from 'types/ContentTypes';
import { ModalContentProps } from './interface';
import { CoursesList, ModalFooter, ModalHeader } from './components';

export default function ModalContent(props: ModalContentProps) {
  const { canChangeLesson, courses, isTutorActive, handleClose } = props;
  const [selectedItem, setSelectedItem] = useState<ILessonTree | null>(null);
  return (
    <>
      <ModalHeader />
      <CoursesList courses={courses} setSelectedItem={setSelectedItem} />
      <ModalFooter
        canChangeLesson={canChangeLesson}
        isTutorActive={isTutorActive}
        selectedItem={selectedItem}
        handleClose={handleClose}
      />
    </>
  );
}
