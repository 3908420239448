import { memo, useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { toast } from 'react-toastify';
import { clsx } from 'clsx';
import { onValue, ref, off, DatabaseReference } from 'firebase/database';
import { white } from 'constants/Colors';
import { enableOpacity, disableOpacity } from 'constants/Opacities';
import { getToastProps } from 'constants/ToastProps';
import { toastOptions } from 'constants/ToastOptions';
import { search } from 'helpers/CodeHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { BoardCompilerStatus, ConsolePythonResultType } from 'types/CompilerTypes';
import { Question, SolvedTaskData } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';
import { LeaderModeType } from 'types/LeaderModeTypes';
import { UserType } from 'types/UserTypes';
import {
  setLeaderModeDebugStep,
  getAppDatabase,
  AppName,
  setQuestionBlocksImageInPrivateFiles,
  resolveTaskInRealTime,
  resolveTaskInFirestore,
  setQuestionBlocksImageInPublicFiles,
} from 'store/FirebaseStore';
import { getDebugButtonDisabled } from '../../../helpers/CompilerHelper';
import { t } from '@lingui/macro';
import { RoomType } from 'types/enums';
import { UserData } from 'schemas/email';
import { sendProgressEmail } from 'helpers/emailProgressSender';
import CompilerSlider from '../../Slider';
import styles from './codeBlocksCompiler.module.css';
import BlocksCompiler from './helpers/BlocksCompiler';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FlagIcon from '@mui/icons-material/Flag';
import StopIcon from '@mui/icons-material/Stop';
import Stack from '@mui/material/Stack';
import TaskNotification from '../../TaskNotification';

interface CodeBlocksCompilerProps {
  getCode: Function;
  highlightErrorLine: Function;
  highlightActiveBreakpointLine: Function;
  removehighlightLine: Function;
  question: Question;
  pageUserId: string;
  defaultIconSize?: number;
  defaultSpeed?: number;
}

function CodeBlocksCompiler(props: CodeBlocksCompilerProps) {
  // --------------------------- PROPS ---------------------------
  const {
    getCode,
    highlightErrorLine,
    highlightActiveBreakpointLine,
    removehighlightLine,
    question,
    pageUserId,
    defaultIconSize = 30,
    defaultSpeed = 500,
  } = props;
  // -------------------------- CONTEXT --------------------------
  const { currentUser } = useAppContext();
  const { bug, fatal } = useErrorContext();
  const { i18n } = useLocaleContext();
  const { leaderMode, room, localCourseId, localLessonId, members } = useWorkspaceContext();
  // --------------------------- STATES --------------------------
  const [startedLeaderModeDebug, setStartedLeaderModeDebug] = useState(false);
  const [compilerIframe, setCompilerIframe] = useState<HTMLIFrameElement | null>(null);
  const [lastQuestionEventRef, setLastQuestionEventRef] = useState<DatabaseReference>();
  // ---------------------- BLOCKS COMPILER ----------------------
  const blocksCompiler = useMemo(
    () => new BlocksCompiler(leaderMode, room, currentUser),
    [leaderMode, room, currentUser],
  );
  const [compilerStatus, setCompilerStatus] = useState<number>(BoardCompilerStatus.BOARD_UNSET);
  // --------------------------- STYLES --------------------------
  const areRunButtonsDisabled =
    compilerStatus === BoardCompilerStatus.BOARD_UNSET ||
    compilerStatus === BoardCompilerStatus.END_LINE ||
    compilerStatus === BoardCompilerStatus.ERROR_MODE ||
    compilerStatus === BoardCompilerStatus.SOLUTION ||
    getDebugButtonDisabled(currentUser?.role as UserType, leaderMode.isLeaderModeOn);

  const isStopButtonDisabled =
    compilerStatus === BoardCompilerStatus.BOARD_UNSET ||
    compilerStatus === BoardCompilerStatus.EDIT_MODE ||
    compilerStatus === BoardCompilerStatus.STOP_MODE ||
    getDebugButtonDisabled(currentUser?.role as UserType, leaderMode.isLeaderModeOn);
  const isSolutionButtonDisabled =
    compilerStatus === BoardCompilerStatus.BOARD_UNSET ||
    getDebugButtonDisabled(currentUser?.role as UserType, leaderMode.isLeaderModeOn);
  // --------------------------- REFS ----------------------------
  const speed = useRef(defaultSpeed);

  const buttonTheme = {
    fill: white,
    fontSize: defaultIconSize,
  };
  const theme = {
    play: {
      ...buttonTheme,
      opacity: areRunButtonsDisabled ? disableOpacity : enableOpacity,
    },
    stop: {
      ...buttonTheme,
      opacity: isStopButtonDisabled ? disableOpacity : enableOpacity,
    },
    flag: {
      ...buttonTheme,
      opacity: isSolutionButtonDisabled ? disableOpacity : enableOpacity,
    },
  };

  //-------------------------- METHODS --------------------------
  //-------------------------- HELPERS --------------------------
  const preventGamesOnLeaderMode = useCallback((): boolean => {
    if (leaderMode.isLeaderModeOn && search(getCode(), question.language)) {
      i18n;
      toast.warn(
        t(i18n)({ id: 'leaderMode.messages.debug', message: "You can't run game in Leader Mode" }),
        toastOptions,
      );
      return true;
    }
    return false;
  }, [getCode, i18n, leaderMode.isLeaderModeOn, question.language]);

  //-------------------------- EVENTS ---------------------------
  const oneStep = useCallback(() => {
    if (preventGamesOnLeaderMode()) return;
    blocksCompiler.debugStep(getCode());
  }, [blocksCompiler, getCode, preventGamesOnLeaderMode]);

  const debug = useCallback(() => {
    if (preventGamesOnLeaderMode()) return;
    blocksCompiler.debug(getCode(), speed.current);
  }, [blocksCompiler, getCode, preventGamesOnLeaderMode]);

  const stop = useCallback(
    (forcedStop = true) => {
      try {
        leaderMode.mode === LeaderModeType.TutorLeaderMode &&
          setLeaderModeDebugStep(room?.id as string, forcedStop ? -1 : -2);
        removehighlightLine();
        blocksCompiler.stop();
      } catch (e) {
        bug(e);
      }
    },
    [blocksCompiler, leaderMode.mode, room?.id, bug, removehighlightLine],
  );

  const showSolution = useCallback(() => {
    stop();
    currentUser?.role === UserType.Tutor &&
      leaderMode.isLeaderModeOn &&
      setLeaderModeDebugStep(room?.id as string, -3);
    blocksCompiler.solve();
  }, [blocksCompiler, currentUser?.role, leaderMode.isLeaderModeOn, room?.id, stop]);

  // ---------------- UPDATE BLOCKS COMPILER DATA ----------------
  useEffect(() => {
    compilerIframe &&
      blocksCompiler.getIframe() !== compilerIframe &&
      blocksCompiler.setIframe(compilerIframe);
  }, [blocksCompiler, compilerIframe]);

  useEffect(() => {
    question && blocksCompiler.didQuestionChange(question) && blocksCompiler.setQuestion(question);
  }, [blocksCompiler, question]);

  // ---------------- SET BLOCKS COMPILER METHODS ----------------
  const onHighlightedLineChange = useCallback(
    (value: number) => {
      value < 0 ? removehighlightLine() : highlightActiveBreakpointLine(value);
    },
    [removehighlightLine, highlightActiveBreakpointLine],
  );

  const onErrorChange = useCallback(
    (line: number, message: string) => {
      line >= 0 && highlightErrorLine(line, message);
    },
    [highlightErrorLine],
  );

  const onImageChange = useCallback(
    (value: string) => {
      try {
        if (!room || !value || blocksCompiler.getStatus() !== BoardCompilerStatus.EDIT_MODE) {
          return;
        }
        switch (question.type) {
          case FileType.Sandpit:
            setQuestionBlocksImageInPublicFiles(question, room.id, value);
            break;
          case FileType.Task:
          case FileType.Free:
            !(leaderMode.isLeaderModeOn && currentUser?.role === UserType.Student) &&
              setQuestionBlocksImageInPrivateFiles(question, room.id, pageUserId, value);
            break;
          default:
            break;
        }
      } catch (e) {
        bug(e);
      }
    },
    [blocksCompiler, bug, currentUser?.role, leaderMode.isLeaderModeOn, pageUserId, question, room],
  );

  const onStatusChange = useCallback((value: number) => {
    setCompilerStatus(value);
  }, []);

  const sendEmail = useCallback(
    async (task: SolvedTaskData, userData: UserData) => {
      if (room?.roomType === RoomType.Demo && currentUser?.userType === UserType.Student) {
        try {
          await sendProgressEmail(task, userData);
        } catch (e) {
          bug(e);
        }
      }
    },
    [bug, currentUser, room],
  );

  const onSolutionCodeChecked = useCallback(
    async (response: ConsolePythonResultType) => {
      if (response.isResultCorrect) {
        try {
          const task: SolvedTaskData = {
            userId: currentUser?.uid as string,
            roomId: room?.id as string,
            lessonId: localLessonId,
            points: 1,
            questionId: question.guid,
          };
          await Promise.all([
            await resolveTaskInFirestore(
              localCourseId,
              localLessonId,
              question.guid,
              room?.id as string,
              currentUser?.uid as string,
              1,
            ),
            await resolveTaskInRealTime(task),
            await sendEmail(task, {
              name: currentUser?.name as string,
              lastName: currentUser?.lastName as string,
              parentEmail: currentUser?.parentEmail as string,
            }),
          ]);
          toast(<TaskNotification />, getToastProps());
        } catch (e) {
          fatal(e);
        }
      }
    },
    [fatal, currentUser, question, room, localCourseId, localLessonId, sendEmail],
  );

  useEffect(() => {
    blocksCompiler.onHighlightedLineChange = onHighlightedLineChange;
    blocksCompiler.onErrorChange = onErrorChange;
    blocksCompiler.onSolutionCodeChecked = onSolutionCodeChecked;
    blocksCompiler.onStatusChange = onStatusChange;
    blocksCompiler.onImageChange = onImageChange;
  }, [
    blocksCompiler,
    onErrorChange,
    onHighlightedLineChange,
    onImageChange,
    onSolutionCodeChecked,
    onStatusChange,
  ]);

  // -------------------------- IMAGE -------------------------- TODO move to blocksCompiler.ts
  useEffect(() => {
    const setLastQuestionEventReferenceOff = () => {
      lastQuestionEventRef && off(lastQuestionEventRef);
    };

    const setImageReference = (userUid: string) => {
      const database = getAppDatabase(AppName.DEFAULT);
      const privateFilesPath: string = `private_files/${room?.id}/${userUid}/${question.guid}`;
      const publicFilesPath: string = `public_files/${room?.id}/${question.guid}`;
      const path = question.type === FileType.Sandpit ? publicFilesPath : privateFilesPath;
      const changeQuestionEvent = ref(database, `${path}/image`);
      if (!lastQuestionEventRef?.isEqual(changeQuestionEvent)) {
        setLastQuestionEventReferenceOff();
        setLastQuestionEventRef(changeQuestionEvent);
      }
      onValue(changeQuestionEvent, result => {
        try {
          blocksCompiler.changeImageOnFirebaseChange(path, result);
        } catch (e) {
          bug(e);
        }
      });
    };

    const userUid =
      currentUser?.role === UserType.Student && leaderMode.isLeaderModeOn
        ? leaderMode.currentUserUid
        : pageUserId;
    if (!question || !userUid) return;
    if (question.canEditBlocks) {
      setImageReference(userUid);
      return;
    }
    setLastQuestionEventReferenceOff();
  }, [
    blocksCompiler,
    bug,
    currentUser,
    lastQuestionEventRef,
    leaderMode,
    question,
    pageUserId,
    room?.id,
  ]);

  // -------------------------- LEADER MODE --------------------------
  useEffect(() => {
    if (
      question &&
      currentUser?.role === UserType.Student &&
      leaderMode.mode === LeaderModeType.StudentLeaderMode
    ) {
      leaderMode.onDebugChanged = (steps: number) => {
        switch (steps) {
          case -1:
            return stop();
          case -2:
            return stop(false);
          case -3:
            return showSolution();
          default:
            return blocksCompiler.debugOnLeaderMode(getCode(), steps);
        }
      };
      setStartedLeaderModeDebug(true);
    }
  }, [
    blocksCompiler,
    currentUser?.role,
    getCode,
    leaderMode,
    leaderMode.mode,
    question,
    showSolution,
    startedLeaderModeDebug,
    stop,
  ]);

  useEffect(() => {
    const handleContentDocument = (e: unknown) => {
      compilerIframe?.contentDocument?.addEventListener('click', handleContentDocument);
    };
    return () => {
      compilerIframe?.contentDocument?.removeEventListener('click', handleContentDocument);
    };
  }, [compilerIframe]);

  function handleStop() {
    stop();
  }

  const splitterClassName = clsx(
    styles['code-blocks-compiler__spliter'],
    styles['code-blocks-compiler__spliter--hidden'],
  );

  return (
    <div className={styles['code-blocks-compiler__content']}>
      <div className={styles['code-blocks-compiler__buttons']}>
        <Stack width="100%" direction="row" alignItems="center" justifyContent="flex-start">
          <IconButton aria-label="oneStep" onClick={oneStep} disabled={areRunButtonsDisabled}>
            <SkipNextIcon style={theme.play} />
          </IconButton>
          <IconButton aria-label="start" onClick={debug} disabled={areRunButtonsDisabled}>
            <PlayArrowIcon style={theme.play} />
          </IconButton>
          <IconButton aria-label="stop" onClick={handleStop} disabled={isStopButtonDisabled}>
            <StopIcon style={theme.stop} />
          </IconButton>
          <CompilerSlider compilerIframe={compilerIframe} compiler={blocksCompiler} speed={speed} />
          {question.type === FileType.Task && (
            <IconButton
              aria-label="solution"
              onClick={showSolution}
              className={styles['code-blocks-compiler__buttons__solution-button']}
              disabled={isSolutionButtonDisabled}
            >
              <FlagIcon style={theme.flag} />
            </IconButton>
          )}
        </Stack>
      </div>
      <ReflexContainer orientation="horizontal">
        <ReflexElement
          className={styles['code-blocks-compiler__reflex-wrapper']}
          flex={1}
          minSize={110}
        >
          <div className={styles['code-blocks-compiler__graphic-wrapper']}>
            <div className={styles['code-blocks-compiler__graphic-output']}>
              <iframe
                ref={newRef => setCompilerIframe(newRef)}
                id="compilerPage"
                title="compiler"
                width="100%"
                height="100%"
                src="../compilers/Blocks/blocksCompiler.html"
              />
            </div>
          </div>
        </ReflexElement>

        <ReflexSplitter className={splitterClassName} />
      </ReflexContainer>
    </div>
  );
}

export default memo(CodeBlocksCompiler);
