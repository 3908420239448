import React, { useState, useMemo, useCallback } from 'react';
import { Box, IconButton, Paper, Menu } from '@mui/material';
import { leaveRoom } from 'store/FirebaseStore';
import { PopoverOrigin } from '@mui/material/Popover/Popover';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { Trans } from '@lingui/macro';
import { RoomType } from 'types/enums';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { isAdmin, isTutor } from 'helpers/userHelper';
import { UserType } from 'types/UserTypes';
import LogoutIcon from '@mui/icons-material/Logout';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SchoolSharpIcon from '@mui/icons-material/SchoolSharp';
import MenuItem from '@mui/material/MenuItem';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Typography from '@mui/material/Typography';
import LanguageSelector from 'components/organisms/LanguageSelector';
import styles from './account-menu.module.css';
import ClearMembersDataModal from '../../modals/ClearMembersDataModal';

export interface AccountMenuProps {
  openStudentPresenceForm: () => void;
  openHelpModal: () => void;
}

const anchorPosition: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const sxRight = { marginRight: '16px' };

export default function AccountMenu({ openStudentPresenceForm, openHelpModal }: AccountMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { logout, currentUser } = useAppContext();
  const { fatal } = useErrorContext();
  const { room, color } = useWorkspaceContext();

  const sx = useMemo(
    () => ({
      backgroundColor: color,
      margin: '8px',
      padding: '8px',
      gap: '10px',
      width: '32px',
      height: '32px',
      borderRadius: '64px',
    }),
    [color],
  );

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const showCloseDataModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeDeleteDataModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const logoutCurrentUser = useCallback(async () => {
    try {
      room && currentUser && (await leaveRoom(room, currentUser));
      await logout();
      navigate(`/`);
    } catch (e) {
      fatal(e);
    }
  }, [currentUser, fatal, logout, navigate, room]);

  const handlePresenceIconClick = useCallback(() => {
    handleClose();
    openStudentPresenceForm();
  }, [handleClose, openStudentPresenceForm]);

  const goToRooms = useCallback(async () => {
    try {
      room && currentUser && (await leaveRoom(room, currentUser));
      navigate(`/rooms`);
    } catch (e) {
      fatal(e);
    }
  }, [currentUser, fatal, navigate, room]);

  return (
    <>
      <IconButton
        sx={sx}
        color="inherit"
        role="button"
        aria-haspopup="true"
        aria-controls="menu-appbar"
        aria-label="account"
        onClick={handleMenu}
      >
        <PersonIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={anchorPosition}
        keepMounted
        disableAutoFocus
        disableRestoreFocus
        disableScrollLock
        transformOrigin={anchorPosition}
        open={open}
        onClose={handleClose}
      >
        {/* eslint-disable-next-line react-perf/jsx-no-new-object-as-prop */}
        <Paper sx={{ minWidth: '300px' }}>
          <Box className={styles['account-menu__user-container']}>
            <ManageAccountsIcon />
            <Box className={styles['account-menu__user-container__name']} onClick={handleClose}>
              {`${currentUser?.name} ${currentUser?.lastName}`}
            </Box>
          </Box>

          {currentUser?.role === UserType.Tutor && (
            <>
              <MenuItem data-testid="presence-menu-item" onClick={handlePresenceIconClick}>
                <SchoolSharpIcon sx={sxRight} />
                <Typography>
                  <Trans id="accountDropdown.tutorPanel" />
                </Typography>
              </MenuItem>
              <MenuItem data-testid="rooms-menu-item" onClick={goToRooms}>
                <FormatListBulletedIcon sx={sxRight} />
                <Typography>
                  <Trans id="accountDropdown.changeRoom">Change the room</Trans>
                </Typography>
              </MenuItem>
            </>
          )}
          {room?.roomType === RoomType.TestAndPresentation && isTutor(currentUser?.role) && (
            <>
              <ClearMembersDataModal
                show={showModal}
                onClose={closeDeleteDataModal}
                roomId={room?.id as string}
              />
              <MenuItem data-testid="clear-data-menu-item" onClick={showCloseDataModal}>
                <ClearAllIcon sx={sxRight} />
                <Typography>
                  <Trans id="accountDropdown.clearRoomData">Clear room data</Trans>
                </Typography>
              </MenuItem>
            </>
          )}
          <MenuItem data-testid="logout-menu-item" onClick={logoutCurrentUser}>
            <LogoutIcon sx={sxRight} />
            <Typography>
              <Trans id="accountDropdown.logout">logout</Trans>
            </Typography>
          </MenuItem>
          <LanguageSelector />
          {(room?.roomType === RoomType.Demo || room?.roomType === RoomType.Individual) && (
            <MenuItem data-testid="help-menu-item" onClick={openHelpModal}>
              <QuestionMarkIcon sx={sxRight} />
              <Typography>
                <Trans id="accountDropdown.help">Pomoc</Trans>
              </Typography>
            </MenuItem>
          )}
        </Paper>
      </Menu>
    </>
  );
}
