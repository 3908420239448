import { useCallback } from 'react';
import { CheckboxCell, HeaderCell } from './components';
import { UserType } from 'types/UserTypes';
import { UserMoreMenu } from '../../../../../../../../../sections/@dashboard/user';
import { TableRowProps } from './interface';
import { TableRow as Row, TableCell } from '@mui/material';

export default function TableRow(props: TableRowProps) {
  const { user, selectedUsers, setSelectedUsers } = props;
  const isItemSelected = selectedUsers.indexOf(user) !== -1;
  const userRole = Object.entries(UserType).find(([_, value]) => value === user.role);
  const roleName = userRole ? userRole[0] : '';

  const handleClick = useCallback(() => {
    !isItemSelected && setSelectedUsers(selectedUsers.concat(user));
  }, [isItemSelected, selectedUsers, user, setSelectedUsers]);

  return (
    <Row
      hover
      tabIndex={-1}
      role="checkbox"
      selected={isItemSelected}
      aria-checked={isItemSelected}
    >
      <CheckboxCell isItemSelected={isItemSelected} onClick={handleClick} />
      <HeaderCell renderAvatar>{user.name}</HeaderCell>
      <HeaderCell>{user.lastName}</HeaderCell>
      <TableCell align="left">{user.email}</TableCell>
      <TableCell align="left">{roleName}</TableCell>
      <TableCell align="right">
        <UserMoreMenu user={user} />
      </TableCell>
    </Row>
  );
}
