import { useEffect, useState, useMemo, Dispatch } from 'react';
import { darkViolet } from 'constants/Colors';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import { isTutor } from 'helpers/userHelper';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { LeaderModeType } from 'types/LeaderModeTypes';
import { MemberData } from 'types/MemberTypes';
import { UserLabel } from './UserLabel/UserLabel';
import { Box, Tab, Tabs } from '@mui/material';
import { generateScrollButton } from './helpers/TabsHelpers';

const DEFAULT_VALUE = 0;

const theme = {
  box: { maxWidth: { xs: 320, sm: '100%' } },
  tab_indicator: {
    style: {
      backgroundColor: '#895AFD',
    },
  },
  scrollButtons: true,
};

export interface UsersTabsProps {
  membersData: MemberData[];
  fileBroswerDisabled: boolean;
  startPosition?: number;
  leaderPosition?: number;
  setBadgeUser: Dispatch<MemberData | null>;
}

export default function UsersTabs(props: UsersTabsProps) {
  const { membersData, fileBroswerDisabled, startPosition = DEFAULT_VALUE, setBadgeUser } = props;
  const leaderTabIndex =
    useMemo(() => membersData?.findIndex(member => isTutor(member.role)), [membersData]) ||
    startPosition;

  const [value, setValue] = useState(startPosition);
  const { leaderMode, currentUserId } = useWorkspaceContext();
  const isStudent = leaderMode.mode === LeaderModeType.StudentLeaderMode;
  const isLeaderTabActive = isStudent && leaderMode.isLeaderModeOn;

  useEffect(() => {
    if (leaderMode.isLeaderModeOn) {
      const initialValue = membersData.findIndex(user => user.uid === currentUserId); // FIX
      setValue(initialValue);
    }
  }, [leaderMode.isLeaderModeOn, membersData, currentUserId]);

  function handleChange(event: React.SyntheticEvent, newValue: number) {
    const eventTarget: HTMLElement = event.target as HTMLElement;
    if (eventTarget.tagName === 'path' || eventTarget.tagName === 'svg') return;
    setValue(newValue);
    window.dispatchEvent(
      new CustomEvent('changeUserFiles', {
        detail: { userUID: membersData[newValue].uid },
      }),
    );
  }

  return (
    <Box sx={theme.box}>
      <Tabs
        value={isLeaderTabActive ? leaderTabIndex : value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        TabIndicatorProps={theme.tab_indicator}
        aria-label="users"
        ScrollButtonComponent={generateScrollButton}
      >
        {membersData?.map((user, key) => {
          const color = {
            tab: {
              backgroundColor: value === key ? transformColorFromHexToRGBA(darkViolet, 0.12) : '',
            },
            tabDisabled: {
              opacity: fileBroswerDisabled ? 0.7 : 1,
            },
          };

          return (
            <Tab
              value={key}
              disabled={isStudent || fileBroswerDisabled}
              style={!fileBroswerDisabled ? color.tab : color.tabDisabled}
              key={user.uid}
              label={
                <UserLabel
                  fileBroswerDisabled={fileBroswerDisabled}
                  user={user}
                  setBadgeUser={setBadgeUser}
                  selected={value === key}
                />
              }
              disableFocusRipple
            />
          );
        })}
      </Tabs>
    </Box>
  );
}
