import { useCallback } from 'react';
import { IReset } from './interfaces';

export const Reset = (data: IReset) => {
  const { aceRef, firepadRef } = data;

  return useCallback(() => {
    if (firepadRef.current !== null) firepadRef.current = null;
    aceRef.current?.setValue('');
    aceRef.current?.setReadOnly(false);
  }, [aceRef, firepadRef]);
};
