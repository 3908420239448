import { Dispatch } from 'react';
import { BadgeType } from 'types/enums';
import BadgeGranted from './BadgeGranted/BadgeGranted';
import BadgeSection from './BadgeSection/BadgeSection';

interface TutorBadgeProps {
  badge: BadgeType;
  status: number;
  styles: {
    selected: boolean;
    noBadgesGranted: boolean;
    active: boolean;
  };
  setSelectedBadge: Dispatch<BadgeType | ''>;
}

export default function TutorBadge(props: TutorBadgeProps) {
  const { selected } = props.styles;
  return (
    <>
      <BadgeGranted selected={selected} />
      <BadgeSection {...props} />
    </>
  );
}
