import { Dispatch, SetStateAction, useEffect } from 'react';
import { Question } from 'types/ContentTypes';
import { File, FileType } from 'types/FileTypes';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { ProgrammingLanguage } from 'types/LanguagesTypes';
import { UserType } from 'types/UserTypes';
import { IconButton } from '@mui/material';
import iconStyles from 'shared/styles/icon.module.css';
import styles from './options-bar.module.css';
import CloseIcon from '@mui/icons-material/Close';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';

interface OptionsBarProps {
  file?: File;
  extensionsMenuOpened: boolean;
  question: Question | null;
  setExtensionsMenuOpened: Dispatch<SetStateAction<boolean>>;
  toggleSidebar: () => void;
  showSolutionCode: () => void;
}

export default function OptionsBar(props: OptionsBarProps) {
  const {
    extensionsMenuOpened,
    question,
    setExtensionsMenuOpened,
    showSolutionCode,
    toggleSidebar,
  } = props;

  const { leaderMode } = useWorkspaceContext();
  const { currentUser } = useAppContext();
  const { showTaskModal } = useCurrentRoomContext();
  const taskDescriptionButtonDisabled =
    currentUser?.role && currentUser.role !== UserType.Tutor && leaderMode.isLeaderModeOn;
  const extensionsDisabled =
    !question ||
    question.language === ProgrammingLanguage.Blocks ||
    (leaderMode.isLeaderModeOn &&
      question.type !== FileType.Sandpit &&
      currentUser?.role === UserType.Student);

  useEffect(() => {
    question?.language === ProgrammingLanguage.Blocks && setExtensionsMenuOpened(false);
  }, [question?.language, setExtensionsMenuOpened]);

  function showExtensionsMenu() {
    setExtensionsMenuOpened(!extensionsMenuOpened);
  }

  function showQuestionContent() {
    showTaskModal();
  }

  return (
    <div className={styles.icons_container}>
      <IconButton
        className={iconStyles.icon}
        disabled={extensionsDisabled}
        onClick={showExtensionsMenu}
      >
        {extensionsMenuOpened ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      {(question?.type === FileType.Task || question?.type === FileType.Free) &&
        (currentUser?.role === UserType.Tutor || currentUser?.role === UserType.Admin) && (
          <IconButton aria-label="solution" onClick={showSolutionCode} className={iconStyles.icon}>
            <StarIcon />
          </IconButton>
        )}
      <IconButton
        onClick={showQuestionContent}
        className={iconStyles.icon}
        disabled={taskDescriptionButtonDisabled}
      >
        <LiveHelpIcon />
      </IconButton>
    </div>
  );
}
