import { User } from 'types/UserTypes';
import { firebasePublicFilesChildName } from 'constants/FirebaseChildsNames';
import { FileType } from 'types/FileTypes';
import { child, get, ref, update } from 'firebase/database';
import { getAppDatabase, AppName } from '../../helpers/database';
import { ValidationError } from 'providers/error/error';

// firebase custom functions
import { getUser } from './auth';

/**
 *
 * @param room current room id
 * @param fileGuid current file id
 * @param type file type (see enum interface)
 * @param userId current user id
 * @returns reference to the user private file
 */
export function getFileRef(
  room: string,
  fileGuid: string,
  type: FileType | undefined,
  userId: string,
) {
  let ref = window.firebase.apps[0].database().ref();
  if (type === FileType.Task || type === FileType.Free)
    ref = ref.child('private_files').child(room).child(userId).child(fileGuid);
  else {
    ref = ref.child(firebasePublicFilesChildName).child(room).child(fileGuid);
  }
  if (!ref) {
    throw new ValidationError('Invalid database reference', {
      data: {
        message: 'Reference to database is invalid',
      },
    });
  }
  return ref;
}

/**
 *
 * @param roomId current room id
 * @param fileId current file id
 * @returns reference to public file
 */
export const getPublicFileRef = (roomId: string, fileId: string) => {
  const dbRef = window.firebase.apps[0].database().ref();
  const ref = dbRef.child(firebasePublicFilesChildName).child(roomId).child(fileId);
  if (!ref || !dbRef) {
    throw new ValidationError('Cannot get reference to public files', {
      data: {
        message: 'Bad reference to get public files data',
      },
    });
  }
  return ref;
};

/**
 *
 * @param roomId current room id
 * @param fileId current file id
 * @param userId current user id
 * @returns reference to the user private file (in most cases for firepad)
 */
export const getPrivateFileRef = (roomId: string, fileId: string, userId: string) => {
  const dbRef = window.firebase.apps[0].database().ref();
  const ref = dbRef.child('private_files').child(roomId).child(userId).child(fileId);
  if (!ref || !dbRef) {
    throw new ValidationError('Cannot get reference to private files', {
      data: {
        message: 'Bad reference to get private files data',
      },
    });
  }
  return ref;
};

/**
 *
 * @param roomId current room id
 * @returns active tutor object data | null
 */
export const getActiveTutor = async (roomId: string): Promise<User | null> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const tutorUidData = await get(child(ref(database), `rooms/${roomId}/activeTutor`));
    const tutorUid = tutorUidData.val();
    if (!tutorUid) return null;
    const tutor = await getUser(tutorUid);
    return tutor;
  } catch (e) {
    throw e;
  }
};

/**
 * updates room active tutor
 * @param roomId current room id
 * @param tutorId current active tutor id
 */
export const setActiveTutor = async (roomId: string, tutorId: string): Promise<void> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomRef = ref(database, `rooms/${roomId}`);
    await update(roomRef, { activeTutor: tutorId });
  } catch (e) {
    throw e;
  }
};
