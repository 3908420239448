import { z } from 'zod';
import { firestoreTimestampLooseSchema } from './firebase.schema';

export const contentActivityUpdateSchema = z.object({
  points: z.number(),
  solvedDate: firestoreTimestampLooseSchema.nullable(),
});

export const contentActivitySchema = contentActivityUpdateSchema.extend({
  editedDate: firestoreTimestampLooseSchema,
  courseId: z.string(),
  lessonId: z.string(),
  questionId: z.string(),
  roomId: z.string(),
  userUid: z.string(),
  language: z.string(),
  typeOfQuestion: z.string(),
});
