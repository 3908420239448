export const tableSx = { minWidth: 800 };
export const tableCellSx = { py: 3 };

export const TABLE_HEAD = [
  { id: 'id', label: 'Room ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'description', label: 'Description', alignRight: false },
  { id: 'activeLesson', label: 'Active Lesson', alignRight: false },
  { id: 'activeCourse', label: 'Active Course', alignRight: false },
  { id: 'language', label: 'Programming Language', alignRight: false },
  { id: 'meetLink', label: 'Meet Link', alignRight: false },
  { id: 'creationDate', label: 'Creation Date', alignRight: false },
];
