import { useCallback } from 'react';
import { CustomMenuProps } from './interface';
import { Menu } from '@mui/material';

export default function CustomMenu(props: CustomMenuProps) {
  const {
    children,
    parentRef,
    showMenu,
    className,
    anchorOrigin,
    transformOrigin,
    closeMenu,
    setShowMenu,
  } = props;

  const handleClose = useCallback(() => {
    closeMenu && closeMenu();
    setShowMenu && setShowMenu(false);
  }, [closeMenu, setShowMenu]);

  return (
    <Menu
      className={className}
      anchorEl={parentRef}
      open={showMenu}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {children}
    </Menu>
  );
}
