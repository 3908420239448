import { useCallback, useState } from 'react';
import { clsx } from 'clsx';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { ScenarioProps } from './interface';
import { Trans } from '@lingui/macro';
import Typography from '@mui/material/Typography';
import CustomModal from 'components/organisms/CustomModal';
import alignStyles from 'shared/styles/alignment.module.css';
import styles from './scenario.module.css';
import ContentPasteSharpIcon from '@mui/icons-material/ContentPasteSharp';

export default function Scenario(props: ScenarioProps) {
  const { scenarioUrl } = props;
  const [scenarioModalShow, setScenarioModalShow] = useState<boolean>(false);
  const { collapsedSidebar } = useCurrentRoomContext();

  const scenarioClassNames = clsx(styles.scenario, collapsedSidebar && alignStyles['flex-center']);

  const closeScenarioModal = useCallback(() => {
    setScenarioModalShow(false);
  }, []);

  const handleClick = useCallback(() => {
    setScenarioModalShow(true);
  }, []);

  return (
    <>
      <CustomModal backdrop onClose={closeScenarioModal} show={scenarioModalShow} closeButton>
        <div>
          <Typography
            id="modal-modal-title"
            className={styles.video__title}
            variant="h6"
            component="h2"
          >
            <Trans id="fileExplorer.scenario" />
          </Typography>
          <iframe src={scenarioModalShow ? scenarioUrl : ''} width="853" height="480" />
        </div>
      </CustomModal>
      <div className={scenarioClassNames} onClick={handleClick}>
        <ContentPasteSharpIcon />
        {!collapsedSidebar && (
          <span className={styles.scenario__content}>
            <Trans id="fileExplorer.scenario" />
          </span>
        )}
      </div>
    </>
  );
}
