import { lazy, Dispatch, Suspense, useCallback, useState } from 'react';
import { isStudent } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { QuestionStack } from 'types/ContentTypes';
import { MemberData } from 'types/MemberTypes';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { BadgeButton, StudentSection, TutorSection } from './components';
import { RoomType } from 'types/enums';
import UserPoints from './components/UserPoints';
import Loader from 'components/atoms/Loader';
import AccountMenu from '../../AccountMenu';
import NavigationStack from '../../NavigationStack';
import styles from './rightSection.module.css';

interface RightSectionProps {
  badgeHighlighted: boolean;
  navigationStack?: QuestionStack;
  setBadgeUser: Dispatch<MemberData>;
}

// lazy
const TutorPresenceModal = lazy(() => import('../../../modals/TutorPanelModal'));
const HelpModal = lazy(() => import('../../../modals/HelpModal'));

export default function RightSection(props: RightSectionProps) {
  const { badgeHighlighted, navigationStack, setBadgeUser } = props;
  const { currentUser } = useAppContext();
  const [showStudentPresenceForm, setShowStudentPresenceForm] = useState(false);
  const { room } = useWorkspaceContext();
  const [showHelp, setShowHelp] = useState(room?.roomType === RoomType.Demo);
  const isStudentRole = isStudent(currentUser?.role);

  const openStudentPresenceForm = useCallback(() => {
    setShowStudentPresenceForm(true);
  }, []);

  const closeStudentPresenceForm = useCallback(() => {
    setShowStudentPresenceForm(false);
  }, []);

  const closeHelpForm = useCallback(() => {
    setShowHelp(false);
  }, []);

  const openHelpModal = useCallback(() => {
    setShowHelp(true);
  }, []);

  return (
    <div className={styles['right-section__content']}>
      <Suspense fallback={<Loader size={50} />}>
        <HelpModal close={closeHelpForm} show={showHelp} videoUrl={room?.videoUrl as string} />
      </Suspense>
      <Suspense fallback={<Loader size={50} />}>
        <TutorPresenceModal
          closeStudentPresenceForm={closeStudentPresenceForm}
          showStudentPresenceForm={showStudentPresenceForm}
        />
      </Suspense>
      {!isStudentRole ? (
        <>
          {room?.roomType !== RoomType.Demo && <TutorSection />}
          <div className={styles['right-section__location']}>
            <NavigationStack navigationData={navigationStack} />
          </div>
        </>
      ) : (
        <>
          {room?.roomType !== RoomType.Demo && <StudentSection />}
          <div className={styles['right-section__location']}>
            <NavigationStack navigationData={navigationStack} />
          </div>
          {room?.roomType !== RoomType.Individual && room?.roomType !== RoomType.Demo && (
            <BadgeButton badgeHighlighted={badgeHighlighted} setBadgeUser={setBadgeUser} />
          )}
          <div className={styles['right-section__location']}>
            <UserPoints />
          </div>
        </>
      )}
      <AccountMenu
        openStudentPresenceForm={openStudentPresenceForm}
        openHelpModal={openHelpModal}
      />
    </div>
  );
}
