import { useCallback } from 'react';
import { Controller, ControllerRenderProps } from 'react-hook-form';
import { UserCreationForm } from 'schemas/adminForms.schema';
import { TextControllerProps } from './interface';
import Content from './Content';

type renderPropsType = {
  field: ControllerRenderProps<UserCreationForm, keyof UserCreationForm>;
};

export default function TextController(props: TextControllerProps) {
  const { control, label, name, required, type, value, onChange } = props;

  const renderContent = useCallback(
    (renderProps: renderPropsType) => (
      <Content
        field={renderProps.field}
        label={label}
        required={Boolean(required)}
        type={type}
        value={value}
        onChange={onChange}
      />
    ),
    [label, required, type, value, onChange],
  );

  return <Controller name={name} control={control} render={renderContent} />;
}
