import { useCallback } from 'react';
import { SolvedTask } from 'types/ContentTypes';
import { IUpdateSolvedTasks } from './interfaces';

export const UpdateSolvedTasks = (data: IUpdateSolvedTasks) => {
  const { setSolvedQuestions } = data;

  return useCallback(
    (solvedQuestions: Record<string, SolvedTask>) => {
      // locally we have array of ids for solved tasks
      // if you need other data - just chnage type for state and remove Object.keys
      setSolvedQuestions(Object.keys(solvedQuestions));
    },
    [setSolvedQuestions],
  );
};
