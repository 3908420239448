import { useEffect } from 'react';
import { createCoursesTree } from 'helpers/CourseHelpers';
import { getActiveLessonsHistory } from 'store/FirebaseStore';
import { LanguageType } from 'types/generalTypes';
import { z } from 'zod';
import { IGetContent } from './interfaces';

export const GetContent = (data: IGetContent) => {
  const { content, currentLocale, roomId, setContentData, setLessonsHistory } = data;
  const locale = currentLocale.toUpperCase() as keyof LanguageType;

  useEffect(() => {
    const updateLessonsHistory = (data: Record<string, string[]>) => {
      setLessonsHistory(data);
    };
    try {
      const _roomId = z.string().parse(roomId);
      const unsubscribe = getActiveLessonsHistory(_roomId, updateLessonsHistory);
      const courses = createCoursesTree(content, locale);
      setContentData(courses);

      return () => {
        unsubscribe();
      };
    } catch (e) {
      throw e;
    }
  }, [content, locale, roomId, setContentData, setLessonsHistory]);
};
