import { useCallback, useMemo } from 'react';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { RenderTree } from 'types/ContentBrowserTypes';
import { ILessonTree } from 'types/ContentTypes';
import { CoursesListProps } from './interface';
import { options } from './constants';
import { t } from '@lingui/macro';
import { TreeView } from '@mui/lab';
import { DialogContent } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EmbeddedMenuItem from './EmbeddedMenuItem';

export default function CoursesList(props: CoursesListProps) {
  const { courses, setSelectedItem } = props;
  const { i18n } = useLocaleContext();

  const data: RenderTree = useMemo(
    () => ({
      id: 'root',
      name: t(i18n)({ id: 'dashboard.contentModal.subHeader', message: `Courses` }),
      children: courses,
    }),
    [courses, i18n],
  );

  const selectMenuItem = useCallback(
    (nodes: RenderTree) => {
      if (nodes?.children) {
        const [firstChild] = nodes.children;
        setSelectedItem(firstChild);
        return;
      }
      setSelectedItem(nodes);
    },
    [setSelectedItem],
  );

  return (
    <DialogContent>
      <div>
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={options.expanded}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={options.block}
        >
          <EmbeddedMenuItem nodes={data} selectMenuItem={selectMenuItem} />
        </TreeView>
      </div>
    </DialogContent>
  );
}
