/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import { useForm, Controller } from 'react-hook-form';
import { TextField, Select, MenuItem, InputLabel, FormControl, Box } from '@mui/material';
import { UserType, UserExemplary, Country } from 'types/enums';
import { zodResolver } from '@hookform/resolvers/zod';
import TextArea from 'components/atoms/Textarea';
import { userCreationSchema, UserCreationForm } from 'schemas/adminForms.schema';
import addUser from '../../../../scripts/user/addUser';
import FormModalWrapper from '../../../../modals/FormModalWrapper';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppContext } from 'providers/app/app.provider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { useCallback, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pl';

export interface AddUserFormProps {
  open: boolean;
  handleClose: () => void;
}

export default function AddUserForm({ open, handleClose }: AddUserFormProps) {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<UserCreationForm>({
    resolver: zodResolver(userCreationSchema),
    shouldFocusError: true,
    defaultValues: {
      role: UserType.Student,
      name: '',
      surname: '',
      email: '',
      userType: '0',
      rooms: '',
      age: 0,
      gender: '0',
      city: '',
      country: Country.Poland,
    },
  });
  const { currentUser } = useAppContext();

  const [loading, setLoading] = useState<boolean>(false);
  const inputStyles = useMemo(() => ({ style: { color: 'black' } }), []);
  const [dateOfBirth, setDateOfBirth] = React.useState<Dayjs | null>();

  const isStudent = watch('role', UserType.Student) === UserType.Student;

  const handleAgeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue('age', parseInt(e.target.value, 10));
    },
    [setValue],
  );

  const datePicker = useMemo(() => ['DatePicker'], []);

  async function submit(data: UserCreationForm) {
    try {
      setLoading(true);
      data.createdBy = currentUser?.uid;
      data.yearOfBirth = dateOfBirth?.year();
      await addUser(data);
      window.location.reload();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      throw e;
    }
  }

  return (
    <FormModalWrapper
      open={open}
      handleSubmit={handleSubmit(submit)}
      handleClose={handleClose}
      header="Add user"
      btnText="Add"
      loading={loading}
    >
      <Box width="65%">
        <FormControl fullWidth onSubmit={handleSubmit(submit)}>
          <br />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="role-field">Role</InputLabel>
                <Select labelId="role-field" label="Role" {...field}>
                  <MenuItem value={UserType.Student}>Student</MenuItem>
                  <MenuItem value={UserType.Tutor}>Tutor</MenuItem>
                  <MenuItem value={UserType.Admin}>Admin</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                error={Boolean(errors.name?.message)}
                helperText={errors.name ? `${errors.name.message}` : ''}
                required
                label="Name"
                {...field}
              />
            )}
          />
          <br />
          <Controller
            name="surname"
            control={control}
            render={({ field }) => (
              <TextField
                error={Boolean(errors.surname?.message)}
                helperText={errors.surname ? `${errors.surname.message}` : ''}
                label="Surname"
                {...field}
              />
            )}
          />
          <br />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                error={Boolean(errors.email?.message)}
                helperText={errors.email ? `${errors.email.message}` : ''}
                type="email"
                label="E-mail"
                {...field}
              />
            )}
          />
          <br />
          <Controller
            name="country"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="usertype-field">Kraj</InputLabel>
                <Select labelId="usertype-field" label="Kraj" {...field}>
                  <MenuItem value={Country.Poland}>Polska</MenuItem>
                  <MenuItem value={Country.UnitedKingdom}>United Kingdom</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <FormControl>
                <TextField
                  error={Boolean(errors.city?.message)}
                  helperText={errors.city ? `${errors.city.message}` : ''}
                  type="city"
                  label="Miasto"
                  {...field}
                />
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="age"
            control={control}
            render={({ field: { value, onChange, ref } }) => (
              <TextField
                error={Boolean(errors.age?.message)}
                helperText={errors.age ? `${errors.age.message}` : ''}
                label="Age"
                type="number"
                ref={ref}
                onChange={handleAgeChange}
                value={value}
              />
            )}
          />
          <br />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
            <DemoContainer components={datePicker}>
              <DatePicker
                label="Rok urodzenia"
                value={dateOfBirth}
                // eslint-disable-next-line
                views={['year']}
                onChange={setDateOfBirth}
              />
            </DemoContainer>
          </LocalizationProvider>
          <br />
          {isStudent && (
            <>
              <Controller
                name="parentEmail"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors.parentEmail?.message)}
                    helperText={errors.parentEmail ? `${errors.parentEmail.message}` : ''}
                    label="Parent E-mail"
                    {...field}
                  />
                )}
              />
              <br />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={Boolean(errors.phoneNumber?.message)}
                    helperText={errors.phoneNumber ? `${errors.phoneNumber.message}` : ''}
                    label="Phone Number"
                    {...field}
                  />
                )}
              />
              <br />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel id="gender-field">Gender</InputLabel>
                    <Select labelId="gender-field" label="Gender" {...field}>
                      <MenuItem value="0">Male</MenuItem>
                      <MenuItem value="1">Female</MenuItem>
                      <MenuItem value="2">Other</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              <br />
            </>
          )}

          <Controller
            name="userType"
            control={control}
            render={({ field }) => (
              <FormControl>
                <InputLabel id="usertype-field">User type</InputLabel>
                <Select labelId="usertype-field" label="User type" {...field}>
                  <MenuItem value="0">{UserExemplary[0]}</MenuItem>
                  <MenuItem value="1">{UserExemplary[1]}</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <br />
          <Controller
            name="rooms"
            control={control}
            render={({ field }) => (
              <TextArea inputProps={inputStyles} label="Rooms" rows={3} {...field} />
            )}
          />
        </FormControl>
      </Box>
    </FormModalWrapper>
  );
}
