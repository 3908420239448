import { purple } from 'constants/Colors';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'flex',
    flexGrow: 1,
    backgroundColor: purple,
  },
  '& .MuiTabs-indicatorSpan': {
    width: '100%',
    backgroundColor: purple,
  },
});

export default function MTabs({ children, ...props }: TabsProps) {
  return <StyledTabs {...props}>{children}</StyledTabs>;
}
