import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { mTabsSx, roleLabels, roles } from './constants';
import { Trans } from '@lingui/macro';
import MTab from 'components/atoms/MTab';
import MTabs from 'components/atoms/MTabs';

export default function Tabs() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isTutor = pathname.split('/').pop() === roles[1];
  const [value, setValue] = useState(!isTutor ? 0 : 1);

  const handleChange = useCallback(
    (_: unknown, newValue: number) => {
      setValue(newValue);
      navigate(`/auth/login/${roles[newValue]}`);
    },
    [navigate],
  );

  return (
    <MTabs
      value={value}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={false}
      aria-label="user roles"
      sx={mTabsSx}
    >
      {roleLabels.map((roleDescriptor, idx) => (
        <MTab key={roleDescriptor.id} label={<Trans id={roleDescriptor.id} />} disableFocusRipple />
      ))}
    </MTabs>
  );
}
