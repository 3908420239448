import { Dispatch } from 'react';
import { ref, update, onValue, Unsubscribe } from 'firebase/database';
import { ILessonTree, Question } from 'types/ContentTypes';
import { Room } from 'types/RoomTypes';
import { z } from 'zod';
import { AppName, getAppDatabase } from '../../helpers/database';
import { solvedRoomsSchema } from 'schemas/content.schema';

/**
 * changes room active lesson in realtime db
 * @param room current room id
 * @param lesson new lesson id
 */
export const updateActivedLesson = async (room: string, lesson: ILessonTree): Promise<void> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomsRef = ref(database, `rooms/${room}`);
    const roomPropsSchema = z.object({
      activeLesson: z.string(),
      activeCourse: z.string(),
    });
    const updatedRoomProps = roomPropsSchema.parse({
      activeLesson: lesson.guid,
      activeCourse: lesson.course,
    });

    await update(roomsRef, updatedRoomProps);
  } catch (e) {
    throw e;
  }
};

/**
 * resets active lesson id and active course path in room
 * @param room room object data
 */
export const resetRoomContent = async (room: Room) => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomsRef = ref(database, `rooms/${room.id}`);
    const updatedRoomProps = {
      activeLesson: '',
      activeCourse: '',
    };
    await update(roomsRef, updatedRoomProps);
  } catch (e) {
    throw e;
  }
};

/**
 * sets image for compiler in user private files
 * @param question current question object
 * @param roomId current room id
 * @param currentUserId  current user id
 * @param image image string representation for compiler
 */
export const setQuestionBlocksImageInPrivateFiles = async (
  question: Question,
  roomId: string,
  currentUserId: string,
  image: string,
): Promise<void> => {
  const database = getAppDatabase(AppName.DEFAULT);
  try {
    const changeQuestionEvent = ref(
      database,
      `private_files/${roomId}/${currentUserId}/${question.guid}`,
    );
    await update(changeQuestionEvent, {
      image,
    });
  } catch (e) {
    throw e;
  }
};

/**
 * sets image for compiler in user public files
 * @param question current question object
 * @param roomId current room id
 * @param image image string representation for compiler
 */
export const setQuestionBlocksImageInPublicFiles = async (
  question: Question,
  roomId: string,
  image: string,
): Promise<void> => {
  const database = getAppDatabase(AppName.DEFAULT);
  try {
    const changeQuestionEvent = ref(database, `public_files/${roomId}/${question.guid}`);
    await update(changeQuestionEvent, {
      image,
    });
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param userId current user id
 * @returns number of points
 */
export const userPointsListener = (
  currentUserId: string,
  setUserPoionts: Dispatch<number>,
): Unsubscribe => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const reference = ref(database, `solved_tasks/${currentUserId}`);
    return onValue(reference, result => {
      let points = 0;
      if (result.exists()) {
        const rooms = solvedRoomsSchema.parse(result.val());
        Object.entries(rooms).forEach(([roomId, room]) => {
          Object.entries(room).forEach(([lessonId, lesson]) => {
            Object.entries(lesson).forEach(([questionId, question]) => {
              points += question.points;
            });
          });
        });
      }
      setUserPoionts(points);
    });
  } catch (e) {
    throw e;
  }
};
