import { z } from 'zod';
import { DocumentData, DocumentReference, FieldValue, Timestamp } from 'firebase/firestore';

export const firestoreFieldValueSchema = z.custom<FieldValue>(data => data instanceof FieldValue);

export const firestoreReferenceSchema = z.custom<DocumentReference<DocumentData>>(
  data => data instanceof DocumentReference<DocumentData>,
);

export const firestoreTimestampSchema = z.instanceof(Timestamp);

export const firestoreTimestampLooseSchema = z.union([
  firestoreFieldValueSchema,
  firestoreTimestampSchema,
]);

export const realtime__createDateSchema = z.object({
  nanoseconds: z.number(),
  seconds: z.number(),
});
