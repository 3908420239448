import {
  child,
  Database,
  DatabaseReference,
  DataSnapshot,
  get,
  push,
  ref,
  update,
} from 'firebase/database';
import { Timestamp } from 'firebase/firestore';
import { badgesSchema } from 'schemas/badges.schema';
import { Badge, Badges, IActiveBadges, IBadgesCounter } from 'types/BadgeTypes';
import { MemberData } from 'types/MemberTypes';
import { Room } from 'types/RoomTypes';
import { CurrentUser } from 'types/UserTypes';
import { AppName, getAppDatabase } from '../../helpers/database';

/**
 * updates student badges data in realtime db bases on below data
 * @param currentLesson lesson id
 * @param tutor curreunt tutor data
 * @param selectedBadge typeof of selected badge
 * @param room room id
 * @param student member data object (not the user one)
 */
export const giveBadge = async (
  currentLesson: string,
  tutor: CurrentUser | null,
  selectedBadge: string,
  room: Room,
  student: MemberData,
) => {
  const database: Database = getAppDatabase(AppName.DEFAULT);
  try {
    const id = push(child(ref(database), 'badges')).key;
    if (!id) throw new Error("Couldn't create badge id");
    const badgesRef: DatabaseReference = ref(
      database,
      `badges/${student.uid}/${room.id}/${currentLesson}/${id}/`,
    );
    const data = {
      badgeType: selectedBadge,
      createDate: Timestamp.now(),
      lessonId: room.activeLesson,
      teacherId: tutor?.uid,
    };
    await update(badgesRef, data);
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param lesson lesson id
 * @param room room object data
 * @param user member data object (not the user one)
 * @returns active badges from realtime db
 */
export const getActiveBadges = async (
  lesson: string,
  room: Room,
  user: MemberData,
): Promise<IActiveBadges> => {
  try {
    const database: Database = getAppDatabase(AppName.DEFAULT);
    const badgesRef: DatabaseReference = ref(database, `badges/${user.uid}/${room.id}/${lesson}/`);
    const badges: Badges | null = badgesSchema.parse(await (await get(badgesRef)).val());
    return badges
      ? Object.entries(badges).reduce((_activeBadges: IActiveBadges, [_, value]) => {
          _activeBadges[value.badgeType] = true;
          return _activeBadges;
        }, {})
      : {};
  } catch (e) {
    throw e;
  }
};

const increaseBadgesCounter = (badgesCounter: IBadgesCounter, badges: [string, Badge?][]) => {
  const [badge1, badge2, badge3, badge4] = badges;
  badge1?.[1] && (badgesCounter[badge1[1].badgeType] += 1);
  badge2?.[1] && (badgesCounter[badge2[1].badgeType] += 1);
  badge3?.[1] && (badgesCounter[badge3[1].badgeType] += 1);
  badge4?.[1] && (badgesCounter[badge4[1].badgeType] += 1);
};

export const getUserBadges = async (uid: string, roomId: string): Promise<IBadgesCounter> => {
  const database: Database = getAppDatabase(AppName.DEFAULT);
  const badgesRef: DatabaseReference = ref(database, `badges/${uid}/${roomId}`);
  const result: DataSnapshot = await get(badgesRef);
  const badges: IBadgesCounter = {
    debugger: 0,
    creative: 0,
    lightning: 0,
    perfect: 0,
  };
  if (result.exists()) {
    const lessons: object = result.val();
    Object.entries(lessons).forEach(([_, lesson]) => {
      increaseBadgesCounter(badges, Object.entries(lesson) as [string, Badge?][]);
    });
  }
  return badges;
};
