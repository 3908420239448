import { useCallback } from 'react';
import { ICloseModal } from './interfaces';

export const CloseModal = (props: ICloseModal) => {
  const { setIsCoursesModalOpened } = props;

  return useCallback(() => {
    setIsCoursesModalOpened(false);
  }, [setIsCoursesModalOpened]);
};
