import { z } from 'zod';
import { UserType } from 'types/UserTypes';
import { AdminLanguage, Seniority, LicenseType, CourseType, RoomType, Country } from 'types/enums';

// _____________user
export const userCreationSchema = z.object({
  name: z.string().nonempty(),
  surname: z.string().nonempty(),
  email: z.string().nonempty(),
  role: z.nativeEnum(UserType),
  rooms: z.string(),
  userType: z.string(), // TODO chaNge to enum
  parentEmail: z.string().nonempty().optional(),
  phoneNumber: z.string().nonempty().optional(),
  age: z.number().nonnegative().max(100).optional(),
  gender: z.string().optional(), // TODO change to enum
  yearOfBirth: z.number().optional(),
  country: z.nativeEnum(Country).optional(),
  city: z.string().optional(),
  createdBy: z.string().optional(),
});

// _____________room
export const roomCreationSchema = z.object({
  id: z.string(),
  name: z.string().nonempty(),
  description: z.string().optional(),
  language: z.number(), // change to enum
  courseType: z.nativeEnum(CourseType),
  licenseType: z.nativeEnum(LicenseType),
  // seniority: z.nativeEnum(Seniority),
  roomType: z.nativeEnum(RoomType).optional(),
  seniority: z.string().nonempty(),
  startDate: z.date(),
  endDate: z.date(),
  SKU: z.string().nonempty(),
  users: z.string(),
  contentVisibility: z.array(z.string()).optional(),
  scenarios: z.boolean().optional(),
  video: z.boolean().optional(),
  videoUrl: z.string().optional(),
  dayOfWeek: z.number().optional(),
  time: z.string().optional(),
  lenght: z.string().optional(),
  activeChatWidget: z.boolean().optional(),
});

export type UserCreationForm = z.infer<typeof userCreationSchema>;
export type RoomCreationForm = z.infer<typeof roomCreationSchema>;
