import { MouseEvent, useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAdmin } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useLocaleContext } from 'providers/i18n/i18n.provider';
import { getRoomsForUser } from 'store/FirebaseStore';
import { Room } from 'types/RoomTypes';
import { UserType } from '../../types/UserTypes';
import { AccountMenu, CustomSearch } from './components';
import { StyledDataGrid } from './styles';
import { GridEventListener, GridColDef } from '@mui/x-data-grid';
import { Trans, t } from '@lingui/macro';
import MButton from 'components/atoms/MButton';
import Loader from 'components/atoms/Loader';
import styles from './roomsPage.module.css';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Button from '@mui/material/Button';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// x-data-grid static args
const experimentalFeatures = { newEditingApi: true };
const rowsPerPageOptions = [15];
const boxStyles = { width: '100%', height: '91%' };
const tabStyles = { height: '100%', padding: '10px 0px 0px 0px' };

export default function RoomsPage() {
  const [rooms, setRooms] = useState<Room[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [hoveredRow, setHoveredRow] = useState<string | null>('');
  const { currentUser } = useAppContext();
  const { i18n } = useLocaleContext();
  const { fatal, bug } = useErrorContext();
  const [value, setValue] = useState('1');
  const navigate = useNavigate();

  const isAdminUser = isAdmin(currentUser?.role);

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  }, []);

  const onMouseEnterRow = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      if (isAdminUser) {
        const id = event.currentTarget.getAttribute('data-id');
        setHoveredRow(id);
      } else {
        setHoveredRow(null);
      }
    },
    [isAdminUser],
  );

  const onMouseLeaveRow = useCallback((event: unknown) => {
    setHoveredRow(null);
  }, []);

  const goToRoom = useCallback(
    (roomId: string) => {
      roomId !== '' && navigate(`/rooms/${roomId}`);
    },
    [navigate],
  );

  const goToAdmin = useCallback(() => {
    navigate(`/admin`);
  }, [navigate]);

  const handleRowClick: GridEventListener<'rowClick'> = useCallback(
    params => {
      goToRoom(params.id as string);
    },
    [goToRoom],
  );

  const handleButtonClick = useCallback(
    (roomId: string) => (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      window.open(`${window.location.pathname}/${roomId}`, '_blank', 'noreferrer');
    },
    [],
  );

  useEffect(() => {
    const getRooms = async () => {
      try {
        const userRooms = await getRoomsForUser(currentUser);
        if (userRooms?.length === 1 && currentUser?.role === UserType.Student) {
          goToRoom(userRooms[0].id);
          return;
        }
        setRooms(userRooms);
        setLoading(false);
      } catch (e) {
        fatal(e);
      }
    };

    currentUser?.uid ? getRooms() : navigate(`/`);
  }, [currentUser, navigate, goToRoom, fatal, bug]);

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 230, hide: currentUser?.role === UserType.Admin },
      {
        field: 'name',
        headerName: t(i18n)({ id: 'rooms.column.name', message: 'Room name' }),
        flex: 3,
      },
      {
        field: 'description',
        headerName: t(i18n)({ id: 'rooms.column.description', message: 'Description' }),
        flex: 2,
        editable: false,
      },
      {
        field: 'activeCourse',
        headerName: t(i18n)({ id: 'rooms.column.activeCourse', message: 'Active course' }),
        flex: 1,
        editable: false,
      },
      {
        field: 'actions',
        headerName: '',
        width: 180,
        sortable: false,
        disableColumnMenu: true,
        renderCell: params => {
          if (hoveredRow === params.id) {
            return (
              <MButton
                aria-label="open-room-in-new-tab"
                variant="outlined"
                onClick={handleButtonClick(params.id)}
              >
                Open in new tab
              </MButton>
            );
          }
          return null;
        },
      },
    ],
    [handleButtonClick, hoveredRow, i18n, currentUser?.role],
  );

  const componentProps = useMemo(
    () => ({
      row: {
        onMouseEnter: onMouseEnterRow,
        onMouseLeave: onMouseLeaveRow,
      },
      toolbar: {
        showQuickFilter: true,
        quickFilterProps: { debounceMs: 500 },
      },
    }),
    [onMouseEnterRow, onMouseLeaveRow],
  );

  const components = useMemo(() => ({ Toolbar: isAdminUser ? CustomSearch : null }), [isAdminUser]);

  return (
    <div className={styles['rooms-page__content']}>
      <div className={styles['rooms-page__title']}>
        <div>
          <MeetingRoomIcon /> {<Trans id="rooms.title">All rooms</Trans>}
        </div>
        <div>
          {isAdminUser && (
            <Button variant="outlined" color="error" onClick={goToAdmin}>
              <AdminPanelSettingsOutlinedIcon /> Admin panel
            </Button>
          )}
          <AccountMenu />
        </div>
      </div>
      <div className={styles['rooms-page__grid-container']}>
        {loading && (
          <div className={styles['rooms-page__grid-container__no-rooms-content']}>
            <Loader isLoading={loading} />
          </div>
        )}
        {!loading && rooms && rooms.length > 0 && (
          <Box sx={boxStyles}>
            <TabContext value={value}>
              <Box>
                <TabList onChange={handleChange} aria-label="rooms tabs">
                  <Tab label="Aktywne" value="1" />
                  <Tab label="Zakończone" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1" sx={tabStyles}>
                <StyledDataGrid
                  rows={rooms.filter(r => !r.isArchived)}
                  columns={columns}
                  onRowClick={handleRowClick}
                  pageSize={15}
                  rowsPerPageOptions={rowsPerPageOptions}
                  disableSelectionOnClick
                  disableColumnFilter={!isAdminUser}
                  disableColumnSelector={!isAdminUser}
                  disableDensitySelector={!isAdminUser}
                  experimentalFeatures={experimentalFeatures}
                  components={components}
                  componentsProps={componentProps}
                />
              </TabPanel>
              <TabPanel value="2" sx={tabStyles}>
                <StyledDataGrid
                  rows={rooms.filter(r => r.isArchived)}
                  columns={columns}
                  onRowClick={handleRowClick}
                  pageSize={15}
                  rowsPerPageOptions={rowsPerPageOptions}
                  disableSelectionOnClick
                  disableColumnFilter={!isAdminUser}
                  disableColumnSelector={!isAdminUser}
                  disableDensitySelector={!isAdminUser}
                  experimentalFeatures={experimentalFeatures}
                  components={components}
                  componentsProps={componentProps}
                />
              </TabPanel>
            </TabContext>
          </Box>
        )}
        {!loading && rooms?.length === 0 && (
          <div className={styles['rooms-page__grid-container__no-rooms-content']}>
            <Trans id="rooms.empty">No rooms available</Trans>
          </div>
        )}
      </div>
    </div>
  );
}
