import { IActiveBadges } from 'types/BadgeTypes';
import { MemberData } from 'types/MemberTypes';
import { Room } from 'types/RoomTypes';
import { CurrentUser } from 'types/UserTypes';
import { modalStatus } from '../constants';

export const areThereActiveBadges = (activeBadges?: IActiveBadges): boolean =>
  Boolean(
    activeBadges?.creative ||
      activeBadges?.debugger ||
      activeBadges?.lightning ||
      activeBadges?.perfect,
  );

export const shouldBadgeBeUnset = (
  status: number,
  selectedBadge: string | undefined,
  user: MemberData | null,
  room: Room | null,
  localLessonId: string,
  currentUser: CurrentUser | null,
) => status === modalStatus.UNSET && selectedBadge && user && room && localLessonId && currentUser;
