import { white } from 'constants/Colors';
import { enableOpacity } from 'constants/Opacities';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function ShovelIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      fontSize="medium"
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.392 19.505a5.105 5.105 0 0 1-5.921.942c-.467-.237-.681-.452-.918-.918-1-1.97-.62-4.359.942-5.921l2.315-2.315a.85.85 0 0 1 1.204 0l4.693 4.693a.85.85 0 0 1 0 1.203l-2.315 2.316ZM19.76 7.804a.851.851 0 0 0 0-1.204L17.41 4.25a.85.85 0 0 0-1.203 0l-.695.694a.85.85 0 0 0 0 1.203l2.351 2.351a.85.85 0 0 0 1.203 0l.695-.694ZM16.7 8.3l-5.256 5.22-.963-.962 5.257-5.221.962.963Z"
        fill="#fff"
        fillOpacity={0.56}
      />
    </SvgIcon>
  );
}
