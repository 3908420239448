import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import AdminLayout from './adminLayout';
import RoomLayout from './roomLayout';
//
import AdminUser from 'pages/Admin/pages/User';
import AdminRoom from 'pages/Admin/pages/Room';
import AdminDashboard from 'pages/Admin/pages/DashboardApp';
import Synch from 'pages/Admin/pages/Synch';
import Schedule from 'pages/Admin/pages/Schedule';
import Emails from 'pages/Admin/pages/Emails';
import ChatGPT from 'pages/Admin/pages/ChatGPT';

import LoginPage from 'pages/Auth/pages/Login';
import StudentView from 'pages/Auth/pages/Login/views/StudentView';
import TutorView from 'pages/Auth/pages/Login/views/TutorView';
import ChangePasswordView from 'pages/Auth/pages/ChangePasswordView';
import ForgotPasswordView from 'pages/Auth/pages/ForgotPasswordView';

import CurrentRoom from 'pages/CurrentRoom';
import Rooms from 'pages/Rooms';
import ShareApp from 'pages/Share';
import AppInNewWindow from 'pages/CurrentRoom/components/Compilers/HtmlJsCssCompiler/components/AppInNewWindow';

import ErrorPage from 'pages/ErrorPage/ErrorPage.screen';
import { useAppContext } from 'providers/app/app.provider';

// ----------------------------------------------------------------------

export default function Router() {
  const { currentUser, loginWithPassword } = useAppContext();

  return useRoutes([
    {
      path: '/shared/:shareId',
      element: <ShareApp />,
    },
    {
      path: '/',
      element: <RoomLayout />,
      children: [
        { path: '/', element: <Navigate to={currentUser ? '/rooms' : '/auth'} /> },
        { path: 'rooms', element: <Rooms /> },
        { path: 'rooms/:roomId', element: <CurrentRoom /> },
        { path: '/rooms/:roomId/html', element: <AppInNewWindow /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    {
      path: '/admin',
      element: <AdminLayout />,
      children: [
        { path: '/admin/', element: <Navigate to="/admin/dashboard" /> },
        { path: 'dashboard', element: <AdminDashboard /> },
        { path: 'user', element: <AdminUser /> },
        { path: 'room', element: <AdminRoom /> },
        { path: 'synch', element: <Synch /> },
        { path: 'schedule', element: <Schedule /> },
        { path: 'emails', element: <Emails /> },
        { path: 'chatGPT', element: <ChatGPT /> },
        { path: '*', element: <Navigate to="/" /> },
      ],
    },
    {
      path: '/auth',
      children: [
        { path: '/auth/', element: <Navigate to="/auth/login" /> },
        {
          path: 'login',
          element: <LoginPage />,
          children: !loginWithPassword
            ? [
                { path: '/auth/login/', element: <Navigate to="/auth/login/student" /> },
                { path: 'student', element: <StudentView /> },
                { path: 'tutor', element: <TutorView /> },
              ]
            : [{ path: '/auth/login/', element: <TutorView /> }],
        },
        { path: 'forgotPassword', element: <ForgotPasswordView /> },
        { path: 'changePassword', element: <ChangePasswordView /> },
        { path: '*', element: <Navigate to="/auth/login/student" /> },
      ],
    },

    { path: '*', element: <ErrorPage /> },
  ]);
}
