import { getAppDatabase, AppName } from '../../helpers/database';
import { ref, set, get, serverTimestamp } from 'firebase/database';
import { SolvedTaskData } from 'types/ContentTypes';
import { User } from 'types/UserTypes';
import { userSchema } from 'schemas/user.schema';
import { z } from 'zod';

export const resolveTaskInRealTime = async (task: SolvedTaskData): Promise<void> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const fileRef = ref(
      database,
      `solved_tasks/${task.userId}/${task.roomId}/${task.lessonId}/${task.questionId}`,
    );
    await set(fileRef, {
      createDate: serverTimestamp(),
      points: task.points,
    });
  } catch (e) {
    throw e;
  }
};

export async function getAllUsers(): Promise<Record<string, User>> {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const usersRef = ref(database, `users`);
    const result = await (await get(usersRef)).val();
    const usersSchema = z.record(userSchema);
    return usersSchema.parse(result);
  } catch (e) {
    throw e;
  }
}

export async function addNewUser(user: User): Promise<void> {
  try {
    const database = await getAppDatabase(AppName.DEFAULT);
  } catch (e) {
    throw e;
  }
}

export const updateUser = (user: User): void => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
  } catch (e) {
    throw e;
  }
};

export const deleteUser = (userId: string): void => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
  } catch (e) {
    throw e;
  }
};
