import { Room, RoomValidationType } from 'types/RoomTypes';
import { resetRoomContent } from '../realtime/content';
import { getCourse, getLesson } from './content';

const getValidationValues = async (room: Room): Promise<RoomValidationType> => {
  try {
    const { activeCourse, activeLesson } = room;
    const course = activeCourse ? await getCourse(activeCourse) : null;
    const lesson = activeLesson ? await getLesson(activeLesson) : null;
    const isCourseValid = Boolean(course || activeCourse === '');
    const isLessonValid = Boolean(lesson || activeLesson === '');
    return { isCourseValid, isLessonValid };
  } catch {
    return { isCourseValid: false, isLessonValid: false };
  }
};

/**
 * checks if room contains all important data
 * @param room current room id
 * @returns room object data
 */
export const validateRoom = async (room: Room): Promise<Room> => {
  try {
    const { isCourseValid, isLessonValid } = await getValidationValues(room);
    (!isCourseValid || !isLessonValid) && (await resetRoomContent(room));
    return room;
  } catch (e) {
    throw e;
  }
};
