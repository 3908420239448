import SimpleBarReact, { Props } from 'simplebar-react';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
}));

const SimpleBarStyle = styled(SimpleBarReact)(({ theme }) => ({
  maxHeight: '100%',
  '& .simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '& .simplebar-track.simplebar-vertical': {
    width: 10,
  },
  '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
    height: 6,
  },
  '& .simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// ----------------------------------------------------------------------

export interface ScrollbarProps {
  sx?: SxProps<Theme>;
  children?: React.ReactNode;
}

export default function Scrollbar({ children, sx }: ScrollbarProps) {
  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  // eslint-disable-next-line require-unicode-regexp
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  const _sx: SxProps<Theme> = useMemo(() => ({ overflowX: 'auto', ...sx }), [sx]);

  if (isMobile) {
    return <Box sx={_sx}>{children}</Box>;
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={_sx}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
}
