import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { ChangeLessonSection, FileExplorerTopBar, TasksList } from './components';
import styles from './fileExplorer.module.css';
import UpdateActiveLessonId from './hook';

export interface FileExplorerProps {
  leaderModeSwitchValue: boolean;
  switchLeaderModeHandler: () => void;
}

export default function FileExplorer(props: FileExplorerProps) {
  const { leaderModeSwitchValue, switchLeaderModeHandler } = props;
  const { currentUser } = useAppContext();
  const { bug } = useErrorContext();
  const { room, setLocalCourseId, setLocalLessonId } = useWorkspaceContext();
  try {
    UpdateActiveLessonId({ currentUser, room, setLocalCourseId, setLocalLessonId });
  } catch (e) {
    bug(e);
  }

  return (
    <div className={styles['file-explorer__content']}>
      <FileExplorerTopBar />
      <ChangeLessonSection
        leaderModeSwitchValue={leaderModeSwitchValue}
        switchLeaderModeHandler={switchLeaderModeHandler}
      />
      <TasksList />
    </div>
  );
}
