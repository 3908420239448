import { z } from 'zod';
import { parsedCourseSchema } from 'schemas/content.schema';
import { membersSchema } from 'schemas/member.shema';
import { CourseType, LicenseType, RoomType } from 'types/enums';

export const roomSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  meetLink: z.string(),
  creationDate: z.object({
    nanoseconds: z.number(),
    seconds: z.number(),
  }),
  activeCourse: z.string(),
  activeLesson: z.string(),
  courseType: z.nativeEnum(CourseType).optional(),
  licenseType: z.nativeEnum(LicenseType).optional(),
  SKU: z.string().optional(),
  // seniority: z.nativeEnum(Seniority).optional(),
  roomType: z.nativeEnum(RoomType).optional(),
  seniority: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  isLeaderMode: z.boolean(),
  language: z.number(), // chnage to enum
  activeTutor: z.string(),
  forcedFeedback: z.boolean(),
  activeLessonsHistory: z.record(z.array(z.string())).optional(),
  contentVisibility: z.array(z.string()).optional(),
  video: z.boolean().optional(),
  scenarios: z.boolean().optional(),
  videoUrl: z.string().optional(),
  isArchived: z.boolean().optional(),
  dayOfWeek: z.number().optional(),
  time: z.string().optional(),
  lenght: z.string().optional(),
  activeChatWidget: z.boolean().optional(),
});

export const userRoomSchema = z.object({
  id: z.string(),
});

export const currentRoomDataSchema = z.object({
  room: roomSchema.nullable(),
  content: z.array(parsedCourseSchema).nullable(),
  members: membersSchema.nullable(),
});

export const roomLogSchema = z.object({
  userId: z.string(),
  roomId: z.string(),
});

export const roomValidationSchema = z.object({
  isCourseValid: z.boolean(),
  isLessonValid: z.boolean(),
});
