import { useMemo } from 'react';
import { Box } from '@mui/material';

export default function WelcomeView() {
  const containerStyles = useMemo(
    () => ({
      color: '#FFFFFF',
      alignItems: 'center',
      justifyContent: 'center',
    }),
    [],
  );

  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={containerStyles}>
      Witaj w Chat GPT !
      <br />
      <br />
      Jestem chatbotem, który wykorzystuje sztuczną inteligencję, <br />
      aby rozpocząć zadaj pytanie i kliknij przycisk Wyślij.
    </Box>
  );
}
