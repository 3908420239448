/* eslint-disable no-loops/no-loops */
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import {
  getDatabase,
  query,
  ref,
  get,
  set,
  orderByChild,
  equalTo,
  update,
} from 'firebase/database';
import { createUserWithFirebaseFn } from 'store/functions';
import { UserCreationForm } from 'schemas/adminForms.schema';

import { UserType as Role } from 'types/enums';
import { User } from 'types/UserTypes';
import { Room } from 'types/RoomTypes';
import getItemArray from '../getItemArray';

async function addUser(user: UserCreationForm) {
  const updates: Record<string, unknown> = {};
  // eslint-disable-next-line require-unicode-regexp
  const rooms = user.rooms.trim() === '' ? [] : user.rooms.split(/[\s,]+/);
  const isNotStudent = user.role !== Object.keys(Role)[0];

  const member = (userId: string) => ({
    isActive: false,
    idActiveQuestion: '',
    uid: userId,
  });

  const userRoom = (room: string) => ({
    id: room,
  });

  const newUser = (userId: string) => {
    const _user: User = {
      name: user.name,
      lastName: user.surname,
      email: user.email,
      role: user.role,
      uid: userId,
      userType: user.userType,
      yearOfBirth: user.yearOfBirth,
      country: user.country,
      city: user.city,
      creadtedBy: user.createdBy,
    };
    // only for student
    if (user.role === '0') {
      _user.age = user.age;
      _user.parentEmail = user.parentEmail;
      _user.gender = user.gender;
      _user.phoneNumber = user.phoneNumber;
    }

    return _user;
  };

  function assignUser(userId: string, rooms: string[], i: number) {
    updates[`members/${rooms[i]}/${userId}`] = member(userId);
    updates[`users_rooms/${userId}/${rooms[i]}`] = userRoom(rooms[i]);
  }

  function assignUserToRooms(uid: string, rooms: string[]) {
    for (let i = 0; i < rooms.length; i += 1) {
      assignUser(uid, rooms, i);
    }
  }

  async function addUserToFirebase(userId: string) {
    const db = getDatabase();
    await set(ref(db, `users/${userId}`), {});
  }

  async function updateFirebase(uid: string) {
    const db = getDatabase();
    addUserToFirebase(uid);
    const user = await newUser(uid);
    updates[`users/${uid}`] = user;
    await assignUserToRooms(uid, rooms);
    await update(ref(db), updates);
  }

  async function addUserAuthentication() {
    if (isNotStudent) {
      // create new user accounts for all roles except students
      const _user = (await createUserWithFirebaseFn(user.email)) as User;
      return _user.uid;
    }

    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, user.email, 'pixblocks1');
    return userCredential.user.uid;
  }

  function verifyRooms(rooms: string[], result: Room[]) {
    let roomInFirestore;
    for (let i = 0; i < rooms.length; i += 1) {
      roomInFirestore = false;
      for (let j = 0; j < result.length; j += 1) {
        if (rooms[i] === result[j].id) {
          roomInFirestore = true;
          break;
        }
      }
      if (!roomInFirestore) {
        return false;
      }
    }
    return true;
  }

  function checkRooms() {
    const db = getDatabase();
    const que = query(ref(db, 'rooms'));
    return get(que)
      .then(snapshot => getItemArray(snapshot))
      .then(result => {
        if (!verifyRooms(rooms, result)) throw Error('Wrong room IDs!');
        return result;
      });
  }

  async function isEmailInUse() {
    const db = getDatabase();
    const que = query(ref(db, 'users'), orderByChild('email'), equalTo(user.email));
    await get(que).then(snapshot => {
      if (snapshot.exists()) throw Error('This email is already in use');
    });
  }

  await isEmailInUse()
    .then(() => checkRooms())
    .then(() => addUserAuthentication())
    .then(async uid => {
      await updateFirebase(uid);
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
}

export default addUser;
