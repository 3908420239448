import { Dispatch } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { purple } from './Colors';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './toastProps.module.css';

const theme = {
  content: {
    right: '6em',
    top: '3em',
  },
  progressBar: {
    backgroundColor: purple,
  },
};

export const getToastProps = (onClose?: Dispatch<boolean>): ToastOptions => ({
  position: toast.POSITION.TOP_RIGHT,
  style: theme.content,
  className: styles['toast-props'],
  bodyClassName: styles['toast-props__body'],
  progressStyle: theme.progressBar,
  autoClose: 3000,
  closeOnClick: false,
  onClose: () => onClose && onClose(false),
  closeButton: ({ closeToast }) => (
    <IconButton onClick={closeToast}>
      <CloseIcon />
    </IconButton>
  ),
});
