export function shouldILoadDefaultFont() {
  const secondFontBrowsers = ['Safari', 'Firefox'];

  const foundBrowser = secondFontBrowsers.reduce((_: boolean, browser: string) => {
    if (navigator.userAgent.indexOf(browser) === -1) {
      return true;
    }
    return false;
  }, false);

  return foundBrowser;
}
