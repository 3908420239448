import { useCallback } from 'react';
import { useAppContext } from 'providers/app/app.provider';
import { FileData, FileType } from 'types/FileTypes';
import { ISetReadOnly } from './interfaces';

export const SetReadOnly = (data: ISetReadOnly) => {
  const { getAceInstance } = data;
  const { currentUser } = useAppContext();

  return useCallback(
    (canEditStudentFile: boolean, file: FileData, type?: FileType) => {
      const currentAce = getAceInstance();
      switch (type) {
        case FileType.Sample:
          currentAce?.setReadOnly(true);
          break;
        case FileType.Task:
          !canEditStudentFile && file.userId !== currentUser?.uid
            ? currentAce?.setReadOnly(true)
            : currentAce?.setReadOnly(false);
          break;
        case FileType.Free:
          !canEditStudentFile && file.userId !== currentUser?.uid
            ? currentAce?.setReadOnly(true)
            : currentAce?.setReadOnly(false);
          break;
        case FileType.Sandpit:
          currentAce?.setReadOnly(false);
          break;
        default:
          break;
      }
    },
    [currentUser, getAceInstance],
  );
};
