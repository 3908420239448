import { Question } from 'types/ContentTypes';
import Box from '@mui/material/Box';
import { useMemo, useState, useEffect, useCallback } from 'react';
import { IAceEditor } from 'react-ace/lib/types';
import styles from './internalKeyboard.module.css';
import { getKeyIcon, transformKeywords, textWorker, TextWorkerMethodEnum } from './helpers';
import { v4 as uuid } from 'uuid';

interface InternalKeyboardProps {
  isCodeMode: boolean;
  aceEditor?: IAceEditor;
  question: Question | null;
  disabled: boolean;
  aceWrapperRef?: React.MutableRefObject<HTMLDivElement | null>;
}

interface IHotKey {
  key: string;
  cb: (editor: IAceEditor) => void;
}

const HOT_KEYS: IHotKey[] = [
  {
    key: 'Enter',
    cb(editor) {
      editor.execCommand('addLineAfter');
    },
  },
  {
    key: 'Tab',
    cb(editor) {
      editor.indent();
    },
  },
  {
    key: 'Backspace',
    cb(editor) {
      editor.execCommand('backspace');
    },
  },
  {
    key: 'Paste',
    cb(editor) {
      const text = textWorker(TextWorkerMethodEnum.GET);
      editor.execCommand('paste', text);
    },
  },
];

const CUT_COPY_KEYS: IHotKey[] = [
  {
    key: 'Copy',
    cb(editor) {
      const text = editor.getCopyText();
      editor.execCommand('copy');
      textWorker(TextWorkerMethodEnum.SET, text);
    },
  },
  {
    key: 'Cut',
    cb(editor) {
      const text = editor.getCopyText();
      editor.execCommand('cut');
      textWorker(TextWorkerMethodEnum.SET, text);
    },
  },
];

export default function InternalKeyboard({
  isCodeMode,
  aceEditor,
  question,
  aceWrapperRef,
  disabled,
}: InternalKeyboardProps) {
  const [isCutCopyMode, setIsCutCopyMode] = useState(false);
  const keywords = useMemo(
    () => transformKeywords(isCodeMode, isCutCopyMode, question),
    [isCodeMode, isCutCopyMode, question],
  );
  const panelKeys = isCutCopyMode ? CUT_COPY_KEYS : HOT_KEYS;

  const handleSelect = useCallback(() => {
    const selectedText = aceEditor?.getSelectedText();
    if (selectedText && selectedText.length > 1) {
      setIsCutCopyMode(true);
    } else {
      setIsCutCopyMode(false);
    }
  }, [aceEditor]);

  useEffect(() => {
    const { current } = aceWrapperRef ?? { current: null };
    current?.addEventListener('select', handleSelect);
    return () => {
      current?.removeEventListener('select', handleSelect);
    };
  }, [aceWrapperRef, handleSelect]);

  function handleKeyPress(key: string) {
    if (aceEditor && !disabled) {
      const range = aceEditor.selection.getRange();
      aceEditor.session.replace(range, key);
      aceEditor.selection.setRange(range);
      aceEditor.focus();
      const cursorPosition = aceEditor.getCursorPosition();
      cursorPosition.column += Number.MAX_VALUE;
      aceEditor.moveCursorToPosition(cursorPosition);
    }
  }

  function handleStaticKeyPress(key: IHotKey) {
    if (aceEditor && !disabled) {
      aceEditor.focus();
      key.cb(aceEditor);
    }
  }

  const sx = useMemo(
    () => ({
      opacity: disabled ? '0' : '1',
      width: disabled ? 0 : '160px',
      maxWidth: disabled ? 0 : '160px',
      minWidth: disabled ? 0 : '160px',
      transition: 'all 0.25s ease-in-out 0.25s',
    }),
    [disabled],
  );

  return (
    <Box sx={sx} className={styles['internal-keyboard__content']}>
      <Box
        data-testid="keyboard-default-actions"
        className={styles['internal-keyboard__top-panel']}
      >
        {panelKeys.map(k => (
          <span
            data-testid={`default-panel-key-${k.key}`}
            key={k.key}
            className={styles['internal-keyboard__top-panel']}
            onClick={() => handleStaticKeyPress(k)}
          >
            {getKeyIcon(k.key)}
          </span>
        ))}

        <Box data-testid="keyboard-dynamic-alphabet" className={styles['internal-keyboard__keys']}>
          {keywords &&
            keywords.map(k => (
              <span
                key={uuid()}
                className={styles['internal-keyboard__key']}
                onClick={() => handleKeyPress(k)}
              >
                {k}
              </span>
            ))}
        </Box>
      </Box>
    </Box>
  );
}
