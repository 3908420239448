/* eslint-disable no-loops/no-loops */
import { getDatabase, query, ref, get, Database, update } from 'firebase/database';
import getItemArray from '../getItemArray';
import { Room } from 'types/RoomTypes';
import { User } from 'types/UserTypes';
import { RoomCreationForm } from 'schemas/adminForms.schema';

async function editRoom(room: RoomCreationForm) {
  const deletes: Record<string, null> = {};
  const updates: Record<string, unknown> = {};
  // eslint-disable-next-line require-unicode-regexp
  const users = room.users.trim() === '' ? [] : room.users.split(/[\s,]+/);

  const userRoom = (roomId: string) => ({
    id: roomId,
  });

  const member = (user: string) => ({
    isActive: false,
    idActiveQuestion: '',
    uid: user,
  });

  const editedRoom = () => {
    const { users, ..._room } = room;
    return { ..._room };
  };

  function assignUser(roomId: string, users: string[], i: number) {
    updates[`members/${roomId}/${users[i]}`] = member(users[i]);
    updates[`users_rooms/${users[i]}/${roomId}`] = userRoom(roomId);
  }

  function assignUsersToRoom(roomId: string, users: string[]) {
    for (let i = 0; i < users.length; i += 1) {
      assignUser(roomId, users, i);
    }
  }

  function deleteRoomFromUser(db: Database, allUsers: User[], roomId: string) {
    for (let i = 0; i < allUsers.length; i += 1) {
      deletes[`users_rooms/${allUsers[i].uid}/${roomId}`] = null;
    }
  }

  function deleteRoom(roomId: string, allUsers: User[]) {
    const db = getDatabase();
    deletes[`members/${roomId}`] = null;
    deletes[`sessions_data/${roomId}`] = null;
    deleteRoomFromUser(db, allUsers, roomId);
  }

  function getUsersId(users: string[], result: User[]) {
    const usersId = [];
    for (let i = 0; i < users.length; i += 1) {
      for (let j = 0; j < result.length; j += 1) {
        if (users[i] === result[j].email) {
          usersId.push(result[j].uid);
          break;
        }
      }
    }
    return usersId;
  }

  async function updateFirebase(users: string[], result: User[]) {
    const usersId = getUsersId(users, result);
    const db = getDatabase();
    updates[`rooms/${room.id}`] = editedRoom();
    await assignUsersToRoom(room.id, usersId);
    await deleteRoom(room.id, result);
    await update(ref(db), deletes);
    await update(ref(db), updates);
  }

  function verifyUsers(users: string[], result: User[]) {
    let userInFirestore;
    for (let i = 0; i < users.length; i += 1) {
      userInFirestore = false;
      for (let j = 0; j < result.length; j += 1) {
        if (users[i] === result[j].email) {
          userInFirestore = true;
          break;
        }
      }
      if (!userInFirestore) {
        return false;
      }
    }
    return true;
  }

  function checkUsers() {
    const db = getDatabase();
    const que = query(ref(db, 'users'));
    return get(que)
      .then(snapshot => getItemArray(snapshot))
      .then(result => {
        if (!verifyUsers(users, result)) throw Error("Wrong users' emails!");
        return result;
      });
  }

  await checkUsers()
    .then(async result => {
      await updateFirebase(users, result);
    })
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      // eslint-disable-next-line no-console
      console.warn(error);
    });
}

export default editRoom;
