import { child, get, ref, update, onValue, Unsubscribe } from 'firebase/database';
import { isTutor } from 'helpers/userHelper';
import { roomSchema, userRoomSchema } from 'schemas/room.schema';
import { Room } from 'types/RoomTypes';
import { CurrentUser, User, UserType } from 'types/UserTypes';
import { z } from 'zod';
import { getAppDatabase, AppName } from '../../helpers/database';
import { RoomType } from 'types/enums';

// firebase custom functions
import { getUser } from './auth';
import { deactivateMember } from './session';
import { setLeaderMode } from './leadMode';
import { setActiveTutor } from './general';
import { validateRoom } from '../store/room';

/**
 *
 * @param roomId current room id
 * @returns room data object
 */
export const getRoom = async (
  roomId: string,
  currentUser?: CurrentUser | null,
): Promise<Room | null> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomData = await get(child(ref(database), `rooms/${roomId}`));
    const roomDb = roomData.val();
    if (roomDb === null) return null;
    const room = roomSchema.parse(roomDb);
    if (isTutor(currentUser?.role)) return validateRoom(room);
    return room;
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @returns array of all rooms data object
 */
export async function getAllRooms(): Promise<Record<string, Room>> {
  const database = getAppDatabase(AppName.DEFAULT);
  const roomsRef = ref(database, `rooms`);
  try {
    const rooms = await (await get(roomsRef)).val();
    const roomsData = z.record(roomSchema);
    return roomsData.parse(rooms);
  } catch (e) {
    throw e;
  }
}

/**
 *
 * @param userUid current user id
 * @returns gets all rooms for particular user
 */
export const getRoomsForUser = async (currentUser: CurrentUser | null): Promise<Room[]> => {
  try {
    if (!currentUser) throw new Error('No user loaded!');
    const user: User | null = await getUser(currentUser.uid);
    if (user?.role === UserType.Admin) {
      const rooms = await getAllRooms();
      return Object.values(rooms);
    }
    const database = getAppDatabase(AppName.DEFAULT);
    const userRoomIds = await get(child(ref(database), `users_rooms/${currentUser.uid}`));
    if (!userRoomIds.exists()) return [];
    const roomsDb = userRoomIds.val();
    const roomIdsSchema = z.record(userRoomSchema, {
      invalid_type_error: 'Invalid rooms data',
    });
    const parsedRooms = roomIdsSchema.parse(roomsDb);
    const rooms =
      parsedRooms &&
      (await Promise.all(
        Object.values(parsedRooms).map(async room =>
          roomSchema.parse(await getRoom(room.id, currentUser)),
        ),
      ));
    return rooms;
  } catch (e) {
    throw e;
  }
};

/**
 * updates room data when tutor leaves current room
 * @param room current room id
 * @param currentUser current user id
 */
export const leaveRoom = async (room: Room, currentUser: CurrentUser) => {
  try {
    await deactivateMember(room.id, currentUser.uid);
    if (isTutor(currentUser.role)) {
      await setLeaderMode({ roomId: room.id, isLeaderMode: false });
      await setActiveTutor(room.id, '');
    }
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param roomId string value of current room
 * @param defaultValue  boolean value to know when tutor forces students to see feedback form
 */
export const setForcedFeedbackForRoom = async (
  roomId: string | undefined,
  defaultValue = false,
) => {
  if (!roomId) return;

  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomRef = ref(database, `rooms/${roomId}`);
    await update(roomRef, { forcedFeedback: defaultValue });
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param roomId current room id
 * @param callback function that is triggered when room data history is updated
 * (usually callback is setting state inside some react component )
 * @returns unsubcsrive event to get rid of old listener
 */
export const getActiveLessonsHistory = (
  roomId: string,
  callback: (d: Record<string, string[]>) => void,
): Unsubscribe => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomRef = ref(database, `rooms/${roomId}/activeLessonsHistory`);

    return onValue(roomRef, snapshot => {
      if (snapshot.exists()) {
        callback(snapshot.val());
      } else {
        callback({});
      }
    });
  } catch (e) {
    throw e;
  }
};

/**
 * updates room history field
 * @param roomId current room id
 * @param courseId current course id
 * @param lessonId current lesson id
 */
export const updateActiveLessonsHistory = async (
  roomId: string,
  courseId: string,
  lessonId: string,
) => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomRef = ref(database, `rooms/${roomId}`);
    const prevLessons = await get(child(roomRef, 'activeLessonsHistory'));
    const _lessons = prevLessons.val() ?? {};

    const hasCourse = Boolean(_lessons[courseId]);

    const updatedHistory = {
      ..._lessons,
      [courseId]: hasCourse ? [...new Set([..._lessons[courseId], lessonId])] : [lessonId],
    };

    await update(roomRef, {
      activeLessonsHistory: updatedHistory,
    });
  } catch (e) {
    throw e;
  }
};

/**
 *
 * @param userUid current room id
 * @returns gets all rooms for particular user
 */
export const getRoomType = async (roomId: string): Promise<RoomType> => {
  try {
    const database = getAppDatabase(AppName.DEFAULT);
    const roomTypeRef = ref(database, `rooms/${roomId}/roomType`);
    return (await get(roomTypeRef)).val();
  } catch (e) {
    throw e;
  }
};
