import { useEffect } from 'react';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { ValidationError } from 'providers/error/error';
import { useAppContext } from 'providers/app/app.provider';
import { CurrentUser } from 'types/UserTypes';
import { FileData } from 'types/FileTypes';
import { LeaderModeType, LeaderModeData } from 'types/LeaderModeTypes';
import { isStudent, isTutor } from 'helpers/userHelper';
import { setLeaderMode } from 'store/FirebaseStore';
import { useCurrentRoomContext } from '../../../../../context';
import { IUseLeaderMode } from './interfaces';

export function useLeaderMode(data: IUseLeaderMode) {
  const { currentFile, loadFileAfterReset, reset, setFileData, updateFileView } = data;
  const { currentUser } = useAppContext();
  const { setFileBroswerDisabled } = useCurrentRoomContext();
  const { room, leaderMode } = useWorkspaceContext();
  const { fatal } = useErrorContext();

  useEffect(() => {
    try {
      leaderMode.createLeaderMode(room?.id as string, currentUser as CurrentUser);
    } catch (e) {
      fatal(e);
    }
    const lastFile: FileData = new FileData();

    if (currentUser && isStudent(currentUser?.role)) {
      leaderMode.onStateChanged = (mode: boolean) => {
        currentFile &&
          lastFile.setFileFromObj({
            id: currentFile.id,
            type: currentFile.type,
            userId: currentUser.uid,
          });
        if (mode) {
          setFileBroswerDisabled(true);
          leaderMode.onDataChanged = async (leaderModeData: LeaderModeData) => {
            if (
              !leaderModeData.leaderQuestionId ||
              !leaderModeData.questionType.toString() ||
              !leaderModeData.userId
            ) {
              throw new ValidationError('StudentChangedFileEvent: Empty leader mode data.', {
                data: {
                  leaderModeData,
                },
              });
            }

            const file = new FileData();
            file.setFileFromObj({
              id: leaderModeData.leaderQuestionId,
              type: leaderModeData.questionType.toString(),
              userId: leaderModeData.userId,
            });
            setFileData(file);
            const question = await loadFileAfterReset(file);
            leaderMode.currentUserUid = file.userId;
            leaderMode.question = question;
          };
        } else {
          setFileBroswerDisabled(false);
          lastFile.id ? updateFileView(lastFile) : reset();
          leaderMode.setLeaderModeType(LeaderModeType.None);
        }
      };
    }
  }, [
    currentFile,
    currentUser,
    fatal,
    leaderMode,
    loadFileAfterReset,
    reset,
    room,
    setFileBroswerDisabled,
    setFileData,
    updateFileView,
  ]);

  useEffect(() => {
    room?.id &&
      isTutor(currentUser?.role) &&
      setLeaderMode({ roomId: room?.id, isLeaderMode: false });
  }, [room?.id, currentUser?.role]);
}
