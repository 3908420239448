import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateUserPassword } from 'store/FirebaseStore';
import { useAppContext } from 'providers/app/app.provider';
import { useForm } from 'react-hook-form';
import { ChangePasswordFormData, changePasswordSchema } from 'schemas/login.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useErrorContext } from 'providers/error/error.provider';
import LoginModal from '../../components/LoginModal';
import { StyledLoadingButton, StyledBox, StyledHeader } from '../../helpers/styled';
import { t, Trans } from '@lingui/macro';
import { useLocaleContext } from 'providers/i18n/i18n.provider';

const submitBtnStyles = {
  marginBottom: '30px',
};

export default function ChangePasswordView() {
  const { logout } = useAppContext();
  const { i18n } = useLocaleContext();
  const [isPasswordUpdated, setIsPasswordUpdated] = useState(false);
  const { bug } = useErrorContext();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<ChangePasswordFormData>({
    resolver: zodResolver(changePasswordSchema),
  });

  async function submit(data: ChangePasswordFormData) {
    if (isPasswordUpdated) {
      await logout();
      return navigate('/auth/login');
    }
    const { password, passwordRepeat } = data;
    if (password !== passwordRepeat) {
      return setError(
        'passwordRepeat',
        {
          message: t(i18n)({
            id: 'input.newPassword.error',
            message: `Passwords should be the same`,
          }),
        },
        {
          shouldFocus: true,
        },
      );
    }
    try {
      const isUpdated = await updateUserPassword(password);
      if (!isUpdated) return bug(new Error('Cannot update user password'));
      return setIsPasswordUpdated(true);
    } catch (e) {
      return bug(e);
    }
  }

  return (
    <LoginModal>
      {isPasswordUpdated && (
        <StyledBox>
          <StyledHeader variant="h6" align="center">
            <Trans id="changePassword.info.message">Your password was changed!</Trans>
          </StyledHeader>
        </StyledBox>
      )}
      <form onSubmit={handleSubmit(submit)}>
        {!isPasswordUpdated && (
          <>
            <TextField
              margin="dense"
              id="id-change-password"
              type="password"
              variant="filled"
              label={<Trans id="input.newPassword">New password</Trans>}
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : ''}
              size="medium"
              disabled={isSubmitting ? true : false}
              {...register('password')}
              fullWidth
            />
            <TextField
              margin="dense"
              id="id-change-password-repeat"
              type="password"
              variant="filled"
              label={<Trans id="input.repeatPassword">Repeat new password</Trans>}
              error={Boolean(errors.passwordRepeat)}
              helperText={errors.passwordRepeat ? errors.passwordRepeat.message : ''}
              size="medium"
              disabled={isSubmitting ? true : false}
              {...register('passwordRepeat')}
              fullWidth
            />
          </>
        )}

        <Box width="100%" sx={submitBtnStyles}>
          <Stack>
            <StyledLoadingButton
              loading={isSubmitting ? true : false}
              type="submit"
              variant="contained"
            >
              {isPasswordUpdated ? (
                <Trans id="label.backToLogin">Back to login page</Trans>
              ) : (
                <Trans id="label.changePassword">Change password</Trans>
              )}
            </StyledLoadingButton>
          </Stack>
        </Box>
      </form>
    </LoginModal>
  );
}
