import { useCallback } from 'react';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { z } from 'zod';
import { UpdateSolvedTasks } from '.';
import { IShowFile } from './interfaces';
import { onValue, ref } from 'firebase/database';
import { solvedTaskSchema } from 'schemas/content.schema';

export const ShowFiles = (data: IShowFile) => {
  const { room, setSolvedQuestions } = data;
  const updateSolvedTasks = UpdateSolvedTasks({ setSolvedQuestions });

  return useCallback(
    (userUid: string, lessonId: string) => {
      try {
        const roomId = z.string().parse(room?.id);
        const database = getAppDatabase(AppName.DEFAULT);
        const collectionRef = ref(database, `solved_tasks/${userUid}/${roomId}/${lessonId}`);
        const unsubscribe = onValue(collectionRef, snapshot => {
          if (snapshot.exists()) {
            const solvedQuestionsSchema = z.record(solvedTaskSchema);
            const solvedQuestions = solvedQuestionsSchema.parse(snapshot.val());
            updateSolvedTasks(solvedQuestions);
            return;
          }
          updateSolvedTasks({});
        });
        return unsubscribe;
      } catch (e) {
        throw e;
      }
    },
    [room, updateSolvedTasks],
  );
};
