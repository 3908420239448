import { TryRounded } from '@mui/icons-material';
import {
  query,
  collection,
  where,
  serverTimestamp,
  getDocs,
  addDoc,
  doc,
  QueryDocumentSnapshot,
  DocumentData,
  updateDoc,
} from 'firebase/firestore';
import { contentActivitySchema } from 'schemas/contentActivity.schema';
import { db } from 'store/helpers/firestore';
import {
  ContentActivity,
  ContentActivityLessonGetter,
  ContentActivityUpdate,
} from 'types/ContentActivityTypes';
import { Question } from 'types/ContentTypes';
import { Room } from 'types/RoomTypes';
import { CurrentUser } from 'types/UserTypes';

export const getContentActivities = async () => {
  try {
    const ref = collection(db, 'contentActivity');
    const _query = query(ref);
    const snapshot = await getDocs(_query);
    return snapshot.docs;
  } catch (e) {
    throw e;
  }
};

export const getContentActivitiesByCourse = async (
  courseGetter: ContentActivityLessonGetter,
): Promise<ContentActivity[]> => {
  const { courseId, roomId, lessonId, userUid } = courseGetter;
  try {
    const ref = collection(db, 'contentActivity');
    const _query = lessonId
      ? query(
          ref,
          where('courseId', '==', courseId),
          where('lessonId', '==', lessonId),
          where('roomId', '==', roomId),
          where('userUid', '==', userUid),
        )
      : query(
          ref,
          where('courseId', '==', courseId),
          where('roomId', '==', roomId),
          where('userUid', '==', userUid),
        );
    const snapshot = await getDocs(_query);
    return snapshot.docs.map(document => contentActivitySchema.parse(document.data()));
  } catch (e) {
    throw e;
  }
};

const getActivityDocumentSnapshot = async (
  courseId: string,
  lessonId: string,
  questionId: string,
  roomId: string,
  userUid: string,
): Promise<QueryDocumentSnapshot<DocumentData>> => {
  try {
    const ref = collection(db, 'contentActivity');
    const _query = query(
      ref,
      where('courseId', '==', courseId),
      where('lessonId', '==', lessonId),
      where('questionId', '==', questionId),
      where('roomId', '==', roomId),
      where('userUid', '==', userUid),
    );
    const querySnapshot = await getDocs(_query);
    return querySnapshot.docs[0];
  } catch (e) {
    throw e;
  }
};

export const getResolvedTasksInRoom = async (userId: string, roomId: string): Promise<number> => {
  try {
    const ref = collection(db, 'contentActivity');
    const _query = query(
      ref,
      where('roomId', '==', roomId),
      where('userUid', '==', userId),
      where('points', '==', 1), //change it
    );
    const querySnapshot = await getDocs(_query);
    let points = 0;
    querySnapshot.docs.map(document => (points += document.data().points));
    return points;
  } catch (e) {
    throw e;
  }
};

const getActivity = async (
  courseId: string,
  lessonId: string,
  questionId: string,
  roomId: string,
  userUid: string,
): Promise<ContentActivity | null> => {
  try {
    const snapshot = await getActivityDocumentSnapshot(
      courseId,
      lessonId,
      questionId,
      roomId,
      userUid,
    );
    if (!snapshot?.exists()) return null;
    return contentActivitySchema.parse(snapshot.data());
  } catch (e) {
    throw e;
  }
};

export const startTask = async (
  courseId: string,
  lessonId: string,
  question: Question | null,
  room: Room | null,
  currentUser: CurrentUser | null,
): Promise<void> => {
  try {
    const language = question?.language || '';
    const questionId = question?.guid || '';
    const roomId = room?.id || '';
    const typeOfQuestion = question?.type || '';
    const userUid = currentUser?.uid || '';
    const doesActivityExist = Boolean(
      await getActivity(courseId, lessonId, questionId, roomId, userUid),
    );
    if (doesActivityExist) return;
    const task: ContentActivity = {
      courseId,
      editedDate: serverTimestamp(),
      language,
      lessonId,
      points: 0,
      questionId,
      roomId,
      solvedDate: null,
      typeOfQuestion,
      userUid,
    };
    const ref = collection(db, 'contentActivity');
    await addDoc(ref, task);
  } catch (e) {
    throw e;
  }
};

export const resolveTaskInFirestore = async (
  courseId: string,
  lessonId: string,
  questionId: string,
  roomId: string,
  userUid: string,
  points: number,
) => {
  try {
    const activityDocumentSnapshot = await getActivityDocumentSnapshot(
      courseId,
      lessonId,
      questionId,
      roomId,
      userUid,
    );
    const { id } = activityDocumentSnapshot;
    const ref = doc(db, 'contentActivity', id);
    const newValue: ContentActivityUpdate = {
      points,
      solvedDate: serverTimestamp(),
    };
    await updateDoc(ref, newValue);
  } catch (e) {
    throw e;
  }
};
