import { useMemo } from 'react';
import { IIDEMethods } from 'types/EditorTypes';
import {
  GetAceInstance,
  GetCode,
  GetFirepadInstance,
  HighlightActiveBreakpoint,
  HighlightErrorLine,
  RemoveHighlightLine,
  Reset,
  ShowSolution,
  TransformBlocksToCode,
  TransformCodeToBlocks,
  UpdateFirepad,
} from '../callbacks/useIDEInterface';
import { IGetIDEMethods } from './interfaces';

/**
 * helper hook for IDE component
 * @param aceRef ace editor reference
 * @param firepadRef firepad reference
 * @returns custom methods to communicate with ace editor and firepad (also includes ace and firepad instances)
 */
export const GetIDEMethods = (data: IGetIDEMethods): IIDEMethods => {
  const { aceRef, firepadRef, question } = data;

  const getAceInstance = GetAceInstance({ aceRef });
  const getCode = GetCode({ aceRef, question });
  const getFirepadInstance = GetFirepadInstance({ firepadRef });
  const highlightActiveBreakpointLine = HighlightActiveBreakpoint({ aceRef });
  const highlightErrorLine = HighlightErrorLine({ aceRef });
  const removehighlightLine = RemoveHighlightLine({ aceRef });
  const reset = Reset({ aceRef, firepadRef });
  const showSolution = ShowSolution({ aceRef });
  const transformBlocksToCode = TransformBlocksToCode({ aceRef });
  const transformCodeToBlocks = TransformCodeToBlocks({ aceRef });
  const updateFirepad = UpdateFirepad({ aceRef, firepadRef });

  return useMemo(
    () => ({
      getAceInstance,
      getCode,
      getFirepadInstance,
      highlightActiveBreakpointLine,
      highlightErrorLine,
      removehighlightLine,
      reset,
      showSolution,
      transformBlocksToCode,
      transformCodeToBlocks,
      updateFirepad,
    }),
    [
      getAceInstance,
      getCode,
      getFirepadInstance,
      highlightActiveBreakpointLine,
      highlightErrorLine,
      removehighlightLine,
      reset,
      showSolution,
      transformBlocksToCode,
      transformCodeToBlocks,
      updateFirepad,
    ],
  );
};
