import { useCallback, useEffect, useState } from 'react';
import { useErrorContext } from 'providers/error/error.provider';
import { IDate, ISearch } from 'types/AdminTypes';
import { Room } from 'types/RoomTypes';
import { TableFilters } from '../../../../sections/@dashboard/components';
import { ROWS, roomFilters } from './constants';
import { FilterRooms, GetRooms } from './hooks/effects';
import { Card, TablePagination } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TableContent from './TableContent';

const boxStyles = { width: '100%', typography: 'body1' };

export default function RoomTable() {
  const { bug } = useErrorContext();
  const [roomList, setRoomList] = useState<Room[]>([]);
  const [filteredRooms, setFilteredRooms] = useState<Room[]>([]);
  const [page, setPage] = useState<number>(0);
  const [isOrderReversed, setIsOrderReversed] = useState<boolean>(false);
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([]);
  const [orderBy, setOrderBy] = useState<keyof Room>('id');
  const [searchName, setSearchName] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [activeFilters, setActiveFilters] = useState<ISearch>();
  const [date, setDate] = useState<IDate>({ start: null, end: null });
  const [value, setValue] = useState('1');

  GetRooms({ bug, setRoomList });
  FilterRooms({
    activeFilters,
    isOrderReversed,
    orderBy,
    page,
    rowsPerPage,
    searchName,
    roomList,
    setFilteredRooms,
  });
  useEffect(() => setPage(0), [activeFilters]);

  const clearFilters = useCallback(() => {
    setActiveFilters({
      language: '',
      seniority: '',
    });
  }, []);

  const handleChangePage = useCallback((_: unknown, newPage: number) => setPage(newPage), []);

  const handleDateChange = useCallback((dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setDate({ start, end });
  }, []);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const handleSearchByName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setSearchName(event.target.value),
    [],
  );

  const handleChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  }, []);

  return (
    <Card className="qwerty">
      <Box sx={boxStyles}>
        <TableFilters
          numSelected={selectedRooms.length}
          searchName={searchName}
          onSearchName={handleSearchByName}
          filters={roomFilters}
          activeFilters={activeFilters}
          setActiveFilters={setActiveFilters}
          clearFilters={clearFilters}
          handleDateChange={handleDateChange}
          date={date}
        />
        <TabContext value={value}>
          <Box>
            <TabList onChange={handleChange} aria-label="tabs">
              <Tab label="Active" value="1" />
              <Tab label="Archived" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <TableContent
              rooms={filteredRooms.filter(r => !r.isArchived)}
              isOrderReversed={isOrderReversed}
              orderBy={orderBy}
              searchName={searchName}
              selectedRooms={selectedRooms}
              setIsOrderReversed={setIsOrderReversed}
              setOrderBy={setOrderBy}
              setSelectedRooms={setSelectedRooms}
            />
            <TablePagination
              rowsPerPageOptions={ROWS}
              component="div"
              count={roomList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
          <TabPanel value="2">
            <TableContent
              rooms={filteredRooms.filter(r => r.isArchived)}
              isOrderReversed={isOrderReversed}
              orderBy={orderBy}
              searchName={searchName}
              selectedRooms={selectedRooms}
              setIsOrderReversed={setIsOrderReversed}
              setOrderBy={setOrderBy}
              setSelectedRooms={setSelectedRooms}
            />
            <TablePagination
              rowsPerPageOptions={ROWS}
              component="div"
              count={roomList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Card>
  );
}
