import { useForm } from 'react-hook-form';
import { userCreationSchema, UserCreationForm } from 'schemas/adminForms.schema';
import { IGetFormProps } from './interfaces';
import { zodResolver } from '@hookform/resolvers/zod';

export const GetFormProps = (data: IGetFormProps) => {
  const { rooms, user } = data;
  return useForm<UserCreationForm>({
    resolver: zodResolver(userCreationSchema),
    shouldFocusError: true,
    defaultValues: {
      role: user.role,
      name: user.name,
      surname: user.lastName,
      email: user.email,
      userType: user.userType,
      phoneNumber: user?.phoneNumber,
      gender: user.gender,
      parentEmail: user?.parentEmail,
      rooms: rooms?.join(',\n') ?? '',
      age: user.age ?? 0,
      city: user.city,
      country: user.country,
      yearOfBirth: user.yearOfBirth,
    },
  });
};
