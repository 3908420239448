import { useCallback } from 'react';
import { lessonTreeSchema } from 'schemas/content.schema';
import { z } from 'zod';
import { HandleStudentSubmit, HandleTutorSubmit } from '.';
import { ISubmit } from './interfaces';

export const Submit = (data: ISubmit) => {
  const { canChangeLesson, isTutorActive, roomId, selectedItem, handleClose, setLocalLessonId } =
    data;
  const handleStudentSubmit = HandleStudentSubmit({ handleClose, setLocalLessonId });
  const handleTutorSubmit = HandleTutorSubmit({ roomId, handleClose });

  return useCallback(async () => {
    try {
      const id = z.string().parse(selectedItem?.guid);
      const lesson = lessonTreeSchema.parse(selectedItem);
      !isTutorActive && !canChangeLesson
        ? handleStudentSubmit(id)
        : await handleTutorSubmit(lesson);
    } catch (e) {
      throw e;
    }
  }, [canChangeLesson, isTutorActive, selectedItem, handleStudentSubmit, handleTutorSubmit]);
};
