import { useEffect } from 'react';
import { codeToBlocks } from 'helpers/BlocksCodeTransformer';
import { isTutor, isStudent, isAdmin } from 'helpers/userHelper';
import { useAppContext } from 'providers/app/app.provider';
import { useErrorContext } from 'providers/error/error.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { FileType } from 'types/FileTypes';
import {
  compareFirepadHistory,
  clearFirepadHistory,
  updateFirepadHistory,
  isTutorOnCurrentQuestion,
} from '../../../helpers/aceAndFirepad';
import { IUseFirepad } from './interfaces';

// TODO try to reduce  parameters and imperative logic
export function useFirepad(parameters: IUseFirepad) {
  const {
    question,
    fileData,
    isLanguageBlocks,
    isCodeMode,
    updateFirepad,
    getFirepadInstance,
    getAceInstance,
    setLoadedAce,
  } = parameters.firepadHookParameters;

  const { currentUser } = useAppContext();
  const { bug } = useErrorContext();
  const { room, leaderMode, currentUserId, color } = useWorkspaceContext();
  // ------------------- SET SAMPLE CODE AND PREPARE ACE WHEN FIREPAD IS READY ------------------------
  useEffect(() => {
    const firepadCurrent = getFirepadInstance();
    const expectedText = isLanguageBlocks ? codeToBlocks(question?.code as string) : question?.code;

    function updateFirepad() {
      clearFirepadHistory(firepadCurrent);
      updateFirepadHistory(firepadCurrent, expectedText as string);
    }

    const handleEvent = async () => {
      if (question?.type === FileType.Sample) {
        let isCorrectHistory = compareFirepadHistory(firepadCurrent, expectedText);
        // while leader mode, do not change blocks to code in history
        if (leaderMode.isLeaderModeOn && isLanguageBlocks && isCodeMode && question) {
          updateFirepadHistory(firepadCurrent, codeToBlocks(question?.code));
          isCorrectHistory = true;
        }

        // if text from firepad is not same as from database question
        if (!isCorrectHistory || firepadCurrent?.isHistoryEmpty()) {
          try {
            // if tutor and student are on same file
            const shoudTutorUpdateQuestion = await isTutorOnCurrentQuestion(
              room?.id,
              currentUser?.uid,
            );
            // only tutor can update file
            if (
              (isTutor(currentUser?.role) || isAdmin(currentUser?.role)) &&
              (shoudTutorUpdateQuestion || leaderMode.isLeaderModeOn)
            ) {
              updateFirepad();
              // if tutor is not active - student can update file
            } else if (
              !leaderMode.isLeaderModeOn &&
              isStudent(currentUser?.role) &&
              !shoudTutorUpdateQuestion
            ) {
              updateFirepad();
            }
          } catch (e) {
            bug(e);
          }
        }
      }
    };

    setLoadedAce(true);
    firepadCurrent?.on('ready', handleEvent);

    return () => {
      firepadCurrent?.off('ready', handleEvent);
    };
  }, [
    question,
    leaderMode.isLeaderModeOn,
    isLanguageBlocks,
    isCodeMode,
    currentUser?.role,
    currentUser?.uid,
    getFirepadInstance,
    currentUserId,
    setLoadedAce,
    bug,
    room?.id,
    fileData,
  ]);

  //  ----------------------------- OVERRIDE FIREPAD FILE -----------------------------------
  useEffect(() => {
    const firepadCurrent = getFirepadInstance();
    const editorCurrent = getAceInstance();

    if (firepadCurrent) {
      firepadCurrent.dispose();
      editorCurrent?.setReadOnly(true);
    }

    const changeFirepadData = () => {
      if (fileData && room && currentUserId && editorCurrent) {
        const userId =
          isTutor(currentUser?.role) || isAdmin(currentUser?.role)
            ? currentUser?.uid
            : currentUserId;
        updateFirepad(fileData, userId as string, room.id, color);
      }
    };
    changeFirepadData();
  }, [
    fileData,
    currentUserId,
    room,
    color,
    currentUser,
    getFirepadInstance,
    updateFirepad,
    getAceInstance,
  ]);
}
