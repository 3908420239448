import { Dispatch, SetStateAction } from 'react';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { Question } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';
import { OptionsBar, ShareModal } from './components';
import MSwitchWithText from 'components/organisms/MSwitchWithText';
import styles from './IDE-topbar.module.css';

export interface IDETopbarProps {
  extensionsMenuOpened: boolean;
  question: Question | null;
  setExtensionsMenuOpened: Dispatch<SetStateAction<boolean>>;
  showSolutionCode: () => void;
  shouldNotShowCodeSwitch: boolean;
  isCodeMode: boolean;
  switchCodeModeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function IDETopbar(props: IDETopbarProps) {
  const {
    extensionsMenuOpened,
    question,
    setExtensionsMenuOpened,
    showSolutionCode,
    shouldNotShowCodeSwitch,
    isCodeMode,
    switchCodeModeHandler,
  } = props;
  const { room, currentUserId } = useWorkspaceContext();
  const { toggleSidebar } = useCurrentRoomContext();

  return (
    <div className={styles['editor-header']}>
      <OptionsBar
        extensionsMenuOpened={extensionsMenuOpened}
        question={question}
        setExtensionsMenuOpened={setExtensionsMenuOpened}
        showSolutionCode={showSolutionCode}
        toggleSidebar={toggleSidebar}
      />
      {shouldNotShowCodeSwitch && (
        <MSwitchWithText
          text="&nbsp;&nbsp;&nbsp;PYTHON"
          checked={isCodeMode}
          onChange={switchCodeModeHandler}
          disabled={Boolean(!question)}
          gridClassName={styles.switch}
        />
      )}
      {question?.type !== FileType.Sample &&
        question?.type !== FileType.Sandpit &&
        question?.language === 'htmlJsCss' && (
          <ShareModal
            roomId={room?.id as string}
            userId={currentUserId as string}
            questionId={question?.guid as string}
            language={question?.language as string}
          />
        )}
    </div>
  );
}
