import { useState, useCallback, useEffect } from 'react';
import { white } from 'constants/Colors';
import { Trans } from '@lingui/macro';
import { useErrorContext } from 'providers/error/error.provider';
import { getSharedLink, addSharedLink, removeSharedLink } from 'store/chunks/store/shares';
import { getIsPrivateFileShared, updatePrivateFileSharedStatus } from 'store/chunks/realtime/files';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Switch from '@mui/material/Switch';
import CustomModal from 'components/organisms/CustomModal';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ShareIcon from '@mui/icons-material/Share';
import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';
import md5 from 'md5';
import styles from './shareModal.module.css';

interface ShareModalProps {
  roomId: string;
  userId: string;
  questionId: string;
  language: string;
}

const linkIconStyles = { marginRight: '5px' };
const cancelButtonStyles = { marginRight: '10px' };
const label = { inputProps: { 'aria-label': 'Share' } };
const hostName = `https:\\app.pixblocks.com\\shared\\`; // Change to location.hostname

const theme = {
  'skip-next': Object.assign(
    {},
    {
      fill: white,
      fontSize: '10px',
    },
  ),
  shareIcon: {
    fill: white,
    fontSize: '18px',
  },

  shareButton: { fill: white, marginLeft: 'auto' },
};

export default function ShareModal(props: ShareModalProps) {
  const { roomId, userId, questionId, language } = props;
  const [shareModalShow, setShareModalShow] = useState<boolean>(false);
  const [shareChecked, setShareChecked] = useState<boolean>(false);
  const [link, setLink] = useState<string>('');
  const { bug } = useErrorContext();
  const hashedLink = md5(`${roomId}${userId}${questionId}${language}`);
  const ref = `${roomId}/${userId}/${questionId}`;

  const shareApp = useCallback(() => {
    setShareModalShow(true);
  }, []);

  const closeShareModal = useCallback(() => {
    setShareModalShow(false);
  }, []);

  const copyLink = useCallback(() => {
    navigator.clipboard.writeText(link);
  }, [link]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        updatePrivateFileSharedStatus(ref, event.target.checked);
        setShareChecked(event.target.checked);
        event.target.checked
          ? addSharedLink({
              id: hashedLink,
              questionId: questionId as string,
              userId: userId as string,
              roomId: roomId as string,
              language: language as string,
            })
          : removeSharedLink(hashedLink);
        setLink(`${hostName}${hashedLink}`);
      } catch (e) {
        bug(e);
      }
    },
    [hashedLink, ref, bug, questionId, userId, roomId, language],
  );

  useEffect(() => {
    const getLink = async () => {
      try {
        if (!(await getSharedLink(hashedLink))) {
          await addSharedLink({
            id: hashedLink,
            questionId: questionId as string,
            userId: userId as string,
            roomId: roomId as string,
            language: language as string,
          });
        }
        setLink(`${hostName}${hashedLink}`);
      } catch (e) {
        bug(e);
      }
    };

    const getShareStatus = async () => {
      try {
        const isShared = await getIsPrivateFileShared(ref);
        setShareChecked(isShared);
        if (isShared) {
          getLink();
        }
      } catch (e) {
        bug(e);
      }
    };

    getShareStatus();
  }, [roomId, userId, questionId, language, bug, hashedLink, ref]);

  return (
    <>
      <CustomModal backdrop onClose={closeShareModal} show={shareModalShow}>
        <div>
          <Typography
            id="modal-modal-title"
            className={styles.share_modal__title}
            variant="h6"
            component="h2"
          >
            <Trans id="shareModal.title" />{' '}
            <Switch checked={shareChecked} onChange={handleChange} {...label} />
          </Typography>
          <div className={styles.share_modal__link}>
            <LinkIcon sx={linkIconStyles} /> {shareChecked && link}
          </div>
          <div className={styles.share_modal__buttons}>
            <Button size="small" sx={cancelButtonStyles} onClick={closeShareModal}>
              <Trans id="actions.cancel" />
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={copyLink}
              startIcon={<ContentCopyIcon />}
              disabled={!shareChecked}
            >
              <Trans id="shareModal.copyLink" />
            </Button>
          </div>
        </div>
      </CustomModal>
      <Tooltip title="Share link" placement="bottom">
        <IconButton aria-label="share" onClick={shareApp} style={theme.shareButton}>
          <ShareIcon style={theme.shareIcon} />
        </IconButton>
      </Tooltip>
    </>
  );
}
