import { useCallback } from 'react';
import { codeToBlocks } from 'helpers/BlocksCodeTransformer';
import { ITransformCodeToBlocks } from './interfaces';

export const TransformCodeToBlocks = (data: ITransformCodeToBlocks) => {
  const { aceRef } = data;
  return useCallback(() => {
    if (!aceRef.current) return;
    const value = codeToBlocks(aceRef.current.getValue());
    aceRef.current.setValue(value);
  }, [aceRef]);
};
