import React from 'react';
import RadioButton from 'components/atoms/RadioButton';
import { RadioGroup } from '@mui/material';
import styles from './matrixRadioButtonForm.module.css';
import { TLabels, TState, TValues } from './types';

export interface MatrixRadioButtonFormProps {
  labels: TLabels;
  values: TValues;
  state: TState;
  errorMsg?: string | null;
  callback: (valueId: string, labelId: string) => void;
}

function MatrixRadioButtonForm({
  labels,
  values,
  state,
  errorMsg,
  callback,
}: MatrixRadioButtonFormProps) {
  return (
    <div className={styles['matrix-radio-button-form__content']}>
      {errorMsg && (
        <div
          data-testid="matrix-err-msg"
          className={styles['matrix-radio-button-form__error-message']}
        >
          {errorMsg}
        </div>
      )}
      <div className={styles['matrix-radio-button-form__table']}>
        <div className={styles['matrix-radio-button-form__table-head']}>
          <div className={styles['matrix-radio-button-form__table-header']} />
          {Object.values(values).map(val => (
            <div className={styles['matrix-radio-button-form__table-header']} key={val}>
              {val}
            </div>
          ))}
        </div>
        <div className={styles['matrix-radio-button-form__table-body']}>
          {Object.entries(state).map(([labelId, currentValue]) => (
            <RadioGroup
              className={styles['matrix-radio-button-form__radio-group']}
              key={labelId}
              value={currentValue}
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                callback(e.target.value, labelId)
              }
            >
              <div className={styles['matrix-radio-button-form__question-cell']}>
                {labels[labelId]}
              </div>
              {Object.entries(values).map(([id]) => (
                <div className={styles['matrix-radio-button-form__table-cell']} key={id}>
                  <RadioButton aria-label="matrix-form-radio-input" value={id} />
                </div>
              ))}
            </RadioGroup>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(MatrixRadioButtonForm);
