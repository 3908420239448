import { Question } from 'types/ContentTypes';
import { JsPackage } from 'types/enums';

export const getBlobURL = (code: string, type: string) => {
  const blob = new Blob([code], { type });
  return URL.createObjectURL(blob);
};

export const getPackages = (question: Question, userCode: string, js: string, css: string) => {
  const cssURL = getBlobURL(css, 'text/css');
  const jsURL = getBlobURL(js, 'text/javascript');

  switch (question.jsLibrary) {
    case JsPackage.Babylon: {
      return `
      <html>
        <head>
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <script src="http://${window.location.host}/compilers/Js/packages/babylon.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylon.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/dat.gui.min.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/dat.gui.min.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/Assets.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/Assets.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/ammo.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/ammo.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/cannon.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/cannon.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/Oimo.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/Oimo.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/earcut.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/earcut.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylonjs.materials.min.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylonjs.materials.min.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylonjs.proceduralTextures.min.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylonjs.proceduralTextures.min.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylonjs.postProcess.min.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylonjs.postProcess.min.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylonjs.loaders.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylonjs.loaders.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylonjs.serializers.min.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylonjs.serializers.min.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylon.gui.min.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylon.gui.min.js"></script>
          <script src="http://${window.location.host}/compilers/Js/packages/babylon.inspector.bundle.js"></script>
          <script src="https://${window.location.host}/compilers/Js/packages/babylon.inspector.bundle.js"></script>
          <style>
            html, body {
                overflow: hidden;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;
            }

            #renderCanvas {
                width: 100%;
                height: 100%;
                touch-action: none;
            }
          </style>
      </head>
      <body>
          <canvas id="renderCanvas"></canvas>
          <script>
          var canvas = document.getElementById("renderCanvas");

        var startRenderLoop = function (engine, canvas) {
            engine.runRenderLoop(function () {
                if (sceneToRender && sceneToRender.activeCamera) {
                    sceneToRender.render();
                }
            });
        }

        var engine = null;
        var scene = null;
        var sceneToRender = null;
        var createDefaultEngine = function() { return new BABYLON.Engine(canvas, true, { preserveDrawingBuffer: true, stencil: true,  disableWebGL2Support: false}); };
                window.initFunction = async function() {
                    
                    
                    var asyncEngineCreation = async function() {
                        try {
                        return createDefaultEngine();
                        } catch(e) {
                        console.log("the available createEngine function failed. Creating the default engine instead");
                        return createDefaultEngine();
                        }
                    }

                    window.engine = await asyncEngineCreation();
        if (!engine) throw 'engine should not be null.';
        startRenderLoop(engine, canvas);
        window.scene = createScene();};
        initFunction().then(() => {sceneToRender = scene                    
        });

        // Resize
        window.addEventListener("resize", function () {
            engine.resize();
        });
          ${userCode}
          </script>

          
        </body>
    </html>
    `;
    }
    case JsPackage.p5: {
      return `
      <html>
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <script src="http://${window.location.host}/compilers/Js/packages/p5.js"></script>
        <script src="https://${window.location.host}/compilers/Js/packages/p5.js"></script>
          <style>
            body {
	          margin: 0;
	          padding: 0;
	          overflow: hidden;
            background-color: #DDD;
          }
          </style>
       </head>
       <body>
          <script>
          function windowResized() {
            resizeCanvas(windowWidth, windowHeight);
          }
          ${userCode}
          </script>
        </body>  
          `;
    }
    default: {
      return `
      <html>        
        <head>
          ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
          ${js && `<script src="${jsURL}"></script>`}
        </head>
        <body>
          ${userCode || ''}
        </body>
      </html>
      `;
    }
  }
};
