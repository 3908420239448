import { ProgrammingLanguage } from 'types/LanguagesTypes';
import { Database, child, get, ref, remove, update } from 'firebase/database';
import { collection, getDocs } from 'firebase/firestore';
import { questionSchema } from 'schemas/content.schema';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { db } from 'store/helpers/firestore';
import { FileType } from 'types/FileTypes';
import { Question } from 'types/ContentTypes';

const database: Database = getAppDatabase(AppName.DEFAULT);

interface IIncorrectPrivateFile {
  roomId: string;
  uid: string;
  questionGuid: string;
  image?: string;
}

export const getPrivateFiles = async () => {
  const snapshot = await get(child(ref(database), `private_files`));
  if (!snapshot.exists()) return null;
  return snapshot.val() as object;
};

export const getQuestions = async (): Promise<Question[]> => {
  try {
    const ref = collection(db, 'questions');
    const snapshot = await getDocs(ref);
    const questions: Question[] = snapshot.docs.map(question =>
      questionSchema.parse(question.data()),
    );
    return questions;
  } catch (e) {
    throw e;
  }
};

export const getIncorrectData = (
  privateFiles: object,
  questionsData: Question[],
): IIncorrectPrivateFile[] => {
  const incorrectFiles = Object.entries(privateFiles).reduce(
    (data: IIncorrectPrivateFile[], [roomId, _users]) => {
      const users = _users as object;
      const newData = Object.entries(users).reduce(
        (data: IIncorrectPrivateFile[], [uid, _questions]) => {
          const questions = _questions as object;
          const newData = Object.entries(questions).reduce(
            (data: IIncorrectPrivateFile[], [questionGuid, file]) => {
              const question = questionsData.find(q => q.guid === questionGuid);
              if (
                question?.language === ProgrammingLanguage.Blocks &&
                question.type === FileType.Sandpit
              ) {
                data.push({ roomId, uid, questionGuid, image: file.image });
              }
              return data;
            },
            [],
          );
          return [...data, ...newData];
        },
        [],
      );
      return [...data, ...newData];
    },
    [],
  );
  return incorrectFiles;
};

export const exchangePrivateImagesForPublicImages = async (
  incorrectFiles: IIncorrectPrivateFile[],
) => {
  try {
    await incorrectFiles.reduce(async (_, privateFile) => {
      const { roomId, uid, questionGuid, image } = privateFile;
      const privateFilesPath = `private_files/${roomId}/${uid}/${questionGuid}/image`;
      const privateFilesRef = ref(database, privateFilesPath);
      const publicFilesPath = `public_files/${roomId}/${questionGuid}`;
      const publicFilesRef = ref(database, publicFilesPath);
      await Promise.all([remove(privateFilesRef), update(publicFilesRef, { image })]);
      return;
    }, Promise.resolve());
  } catch (e) {
    throw e;
  }
};
