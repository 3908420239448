import { useCallback } from 'react';
import { IActivateLoadingHandler } from './interfaces';

export const ActivateLoadingHandler = (data: IActivateLoadingHandler) => {
  const { setIsLoading } = data;

  return useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);
};
