import { defineMessage } from '@lingui/macro';

export const mTabsSx = { width: '100%' };

export const roles = ['student', 'tutor']; // for routes

export const roleLabels = [
  defineMessage({ id: 'login.tabs.student', message: 'student' }),
  defineMessage({ id: 'login.tabs.tutor', message: 'tutor' }),
];
