import { white } from 'constants/Colors';
import { transformColorFromHexToRGBA } from 'helpers/ColorHelper';

const defaultIconSize = 30;

export const theme = {
  'skip-next': Object.assign(
    {},
    {
      fill: white,
      fontSize: defaultIconSize,
    },
  ),
  play: {
    fill: white,
    fontSize: defaultIconSize,
  },
  stop: {
    fill: white,
    fontSize: defaultIconSize,
  },
  flag: {
    fill: white,
    fontSize: defaultIconSize,
  },
  last_child: { marginLeft: 'auto' },
  openInNew: {
    fill: white,
    fontSize: '19px',
  },
  box: { width: 180 },
  divider: {
    backgroundColor: transformColorFromHexToRGBA(white, 0.12),
  },
  solution: { fill: white },
  openInNewTab: { fill: white, marginLeft: 'auto' },
};
