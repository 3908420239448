import { sleep } from 'helpers/TimeHelper';
import { isStudent } from 'helpers/userHelper';
import {
  blocksImageMessageSchema,
  endLineMessageSchema,
  errorMessageSchema,
  responseMessageSchema,
} from 'schemas/compiler.schema';
import { AppName, getAppDatabase, setLeaderModeDebugStep } from 'store/FirebaseStore';
import {
  BoardCompilerStatus,
  BlocksImageMessage,
  ErrorMessage,
  ResponseMessage,
  EndLineMessage,
  ConsolePythonResultType,
} from 'types/CompilerTypes';
import { Question } from 'types/ContentTypes';
import { FileType } from 'types/FileTypes';
import { DataSnapshot, ref, update } from 'firebase/database';
import { LeaderModeType } from 'types/LeaderModeTypes';
import { QuestionProps } from 'types/QuestionPropsType';
import { Room } from 'types/RoomTypes';
import { CurrentUser, UserType } from 'types/UserTypes';
import LeaderMode from 'types/LeaderMode';
import log from 'log';

interface IResponse {
  TYPE: string;
  LINE?: number;
  MESSAGE?: string;
  OUTPUT?: string;
  IS_END_LINE?: boolean;
  RUN_LINE?: number;
  TICK?: number;
  VARIABLES?: string;
  IMAGE?: string;
}

export default class BlocksCompiler {
  private iframeLoaded: boolean = false;
  private started: boolean = false;
  private status: number = BoardCompilerStatus.BOARD_UNSET;

  private currentUser: CurrentUser | null = null;
  private iframe: HTMLIFrameElement | null = null;
  private leaderMode: LeaderMode | null = null;
  private question: Question | null = null;
  private room: Room | null = null;

  public onErrorChange?: (line: number, message: string) => void;
  public onHighlightedLineChange?: (value: number) => void;
  public onImageChange?: (value: string) => void;
  public onSolutionCodeChecked?: (value: ConsolePythonResultType) => void;
  public onStatusChange?: (value: number) => void;

  private error = {
    line: -1,
    message: '',
  };
  private highlightedLine: number = -1;
  private imageString: string = '';

  constructor(leaderMode: LeaderMode, room: Room | null, user: CurrentUser | null) {
    this.leaderMode = leaderMode;
    this.room = room;
    this.currentUser = user;
    if (!this.started) {
      this.startListeningEvents();
      this.started = true;
    }
  }

  // ------------------------- GETTERS -------------------------
  public getUser() {
    return this.currentUser;
  }

  public getLeaderMode() {
    return this.leaderMode;
  }

  public getIframe() {
    return this.iframe;
  }

  public getQuestion() {
    return this.question;
  }

  public getRoom() {
    return this.room;
  }

  public getStatus() {
    return this.status;
  }
  // ------------------------- SETTERS -------------------------
  // ---------------------- MAIN SETTERS -----------------------
  public setIframe(iframe: HTMLIFrameElement) {
    this.iframe = iframe;
  }

  public setLeaderMode(leaderMode: LeaderMode) {
    this.leaderMode = leaderMode;
  }

  public setQuestion(question: Question) {
    this.setImage('');
    this.resetHighlights();
    this.question = question;
    this.setNewBoard();
  }

  public setRoom(room: Room) {
    this.question = null;
    this.setImage('');
    this.resetHighlights();
    this.room = room;
  }

  // --------------------- INPUTS SETTERS ----------------------
  private setError(line: number, message: string) {
    this.error.line = line;
    this.error.message = message;
    this.onErrorChange && this.onErrorChange(line, message);
  }

  private setHighlightedLine(value: number) {
    const line = this.status === BoardCompilerStatus.END_LINE ? -1 : value;
    this.highlightedLine = line;
    this.onHighlightedLineChange && this.onHighlightedLineChange(this.highlightedLine);
  }

  private setImage(value: string) {
    this.imageString = value;
    this.onImageChange && this.onImageChange(value);
  }

  private async setStatus(value: number): Promise<null> {
    try {
      if (!this.onStatusChange) {
        await sleep(1);
        return this.setStatus(value);
      }
      this.status = value;
      this.onStatusChange(value);
      return null;
    } catch (e) {
      throw e;
    }
  }

  // ------------------------- PRIVATE METHODS -------------------------
  private async createImageInPrivateFiles(path: string): Promise<string> {
    try {
      const database = getAppDatabase(AppName.DEFAULT);
      const image: string = this.question?.image || '';
      await update(ref(database, path), { image });
      return image;
    } catch (e) {
      throw e;
    }
  }

  private getEditionPermision(): boolean {
    if (!this.question) return false;
    return (
      Boolean(this.question.canEditBlocks) &&
      !(
        this.leaderMode?.isLeaderModeOn &&
        this.currentUser?.role === UserType.Student &&
        (this.question?.type === FileType.Task || this.question?.type === FileType.Free)
      )
    );
  }

  private async getImageFromPrivateFiles(path: string, snapshot: DataSnapshot): Promise<string> {
    try {
      if (!snapshot.val()) {
        const image = await this.createImageInPrivateFiles(path);
        return image;
      }
      return snapshot.val();
    } catch (e) {
      throw e;
    }
  }

  private getSolutionCode(): string {
    if (this.currentUser?.role === UserType.Student && this.leaderMode?.isLeaderModeOn) {
      return this.leaderMode.question?.code || '';
    }
    return this.question?.code || '';
  }

  private prepareDebugging(): boolean {
    if (!this.question) return false;
    this.setIframeFocus();
    this.stopEditMode();
    return true;
  }

  private resetHighlights() {
    this.setHighlightedLine(-1);
    this.setError(-1, '');
  }

  private async sendPostMessage(object: unknown): Promise<null> {
    try {
      if (!this.iframeLoaded || !this.iframe?.contentWindow) {
        await sleep(100);
        return this.sendPostMessage(object);
      }
      this.iframe.contentWindow.postMessage(JSON.stringify(object), '*');
      return null;
    } catch (e) {
      throw e;
    }
  }

  private setBoard(additionalProps: QuestionProps) {
    if (this.status === BoardCompilerStatus.EDIT_MODE && !additionalProps.canEditBlocks) {
      this.stopEditBoard(additionalProps);
      return;
    }
    this.prepareBlocksBoard(additionalProps);
  }

  private setNewBoard() {
    if (!this.question || !this.leaderMode) return;
    const canEditBlocks = this.getEditionPermision();
    const additionalProps: QuestionProps = {
      blocksImage: this.imageString || this.question.image,
      canEditBlocks,
    };
    this.setBoard(additionalProps);
    this.setStatus(canEditBlocks ? BoardCompilerStatus.EDIT_MODE : BoardCompilerStatus.STOP_MODE);
  }

  private stopEditMode() {
    if (this.status === BoardCompilerStatus.EDIT_MODE && this.question) {
      const additionalProps: QuestionProps = {
        blocksImage: this.imageString || this.question.image,
        canEditBlocks: false,
      };
      this.stopEditBoard(additionalProps);
      this.setStatus(BoardCompilerStatus.STOP_MODE);
    }
  }

  // ------------------------- PUBLIC METHODS -------------------------
  public didQuestionChange(question: Question): boolean {
    return this.question?.guid !== question?.guid;
  }

  public async changeImageOnFirebaseChange(path: string, snapshot: DataSnapshot) {
    try {
      const image: string = await this.getImageFromPrivateFiles(path, snapshot);
      this.changeImage(image);
    } catch (e) {
      throw e;
    }
  }

  public setIframeFocus() {
    this.iframe?.focus();
  }

  // ----------------------------- EVENTS -----------------------------
  public changeImage(image: string) {
    this.setImage(image);
    this.changeBlocksImage(image);
  }

  public changeSpeed(speed: number) {
    this.setIframeFocus();
    this.setSpeed(speed);
  }

  public debug(code: string, speed: number) {
    if (!this.prepareDebugging()) return;
    switch (this.status) {
      case BoardCompilerStatus.STOP_MODE:
        this.setStatus(BoardCompilerStatus.DEBUG);
        this.debugCode(code, speed);
        break;
      case BoardCompilerStatus.DEBUG:
        break;
      case BoardCompilerStatus.DEBUG_STEP:
        this.setStatus(BoardCompilerStatus.DEBUG);
        this.debugCodeContinue(speed);
        break;
      default:
        break;
    }
  }

  public debugStep(code: string) {
    if (!this.prepareDebugging()) return;
    switch (this.status) {
      case BoardCompilerStatus.STOP_MODE:
        this.setStatus(BoardCompilerStatus.DEBUG_STEP);
        this.debugCodeStep(code);
        break;
      case BoardCompilerStatus.DEBUG:
      case BoardCompilerStatus.DEBUG_STEP:
        this.setStatus(BoardCompilerStatus.DEBUG_STEP);
        this.debugNextStep();
        break;
      default:
        break;
    }
  }

  public debugOnLeaderMode(code: string, steps: number) {
    this.stopEditMode();
    this.debugLeaderMode(code, steps);
  }

  public solve() {
    this.setStatus(BoardCompilerStatus.SOLUTION);
    const solutionCode: string = this.getSolutionCode();
    this.showSolution(solutionCode);
  }

  public stop() {
    this.resetHighlights();
    this.setNewBoard();
  }

  // ------------------------- LISTENER -------------------------
  private startListeningEvents() {
    const onIframeLoad = () => {
      this.iframeLoaded = true;
    };
    const responseError = (response: ErrorMessage) => {
      this.setStatus(BoardCompilerStatus.ERROR_MODE);
      this.setError(response.ERROR_LINE, response.ERROR_MESSAGE);
    };
    const responseCompiler = (response: ResponseMessage) => {
      this.setHighlightedLine(response.RUN_LINE);
      this.leaderMode?.mode === LeaderModeType.TutorLeaderMode &&
        setLeaderModeDebugStep(this.room?.id as string, response.TICK as number);
    };
    const responseEndLine = (response: EndLineMessage) => {
      this.setStatus(BoardCompilerStatus.END_LINE);
      responseCompiler(responseMessageSchema.parse(response));
      const isStudentOnLeaderMode =
        isStudent(this.currentUser?.role) && this.leaderMode?.isLeaderModeOn;
      if (
        this.question?.type === FileType.Task &&
        this.onSolutionCodeChecked &&
        !isStudentOnLeaderMode
      ) {
        this.onSolutionCodeChecked({
          isResultCorrect: response.TASK_IS_CORRECT,
          errorList: response.ERROR_LIST,
        });
      }
    };
    const blocksEditorResponse = (response: BlocksImageMessage) => {
      this.setImage(response.IMAGE);
    };

    try {
      window.onmessage = function (e) {
        try {
          if (e.data) {
            const response: IResponse = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
            switch (response.TYPE) {
              case 'BLOCKS_EDITOR_RESPONSE':
                blocksEditorResponse(blocksImageMessageSchema.parse(response));
                break;
              case 'COMPILER_RESPONSE':
                responseCompiler(responseMessageSchema.parse(response));
                break;
              case 'COMPILER_END_LINE_RESPONSE':
                responseEndLine(endLineMessageSchema.parse(response));
                break;
              case 'ERROR':
                responseError(errorMessageSchema.parse(response));
                break;
              case 'IFRAME_LOADED':
                onIframeLoad();
                break;
              default:
                break;
            }
          }
        } catch (excepcion) {
          log.error(excepcion);
        }
      };
    } catch (excepcion) {
      log.error(excepcion);
    }
  }

  // ------------------------- MESSAGES -------------------------
  private setSpeed(speed: number) {
    this.sendPostMessage({ type: 'DEBUG_SPEED', speed });
  }

  private debugNextStep() {
    this.sendPostMessage({ type: 'DEBUG_NEXT_STEP' });
  }

  private debugCodeStep(code: string) {
    this.sendPostMessage({
      type: 'DEBUG_CODE_STEP',
      code,
    });
  }

  private debugCode(code: string, speed: number) {
    this.sendPostMessage({
      type: 'DEBUG_CODE',
      code,
      speed,
    });
  }

  private debugCodeContinue(speed: number) {
    this.sendPostMessage({
      type: 'DEBUG_CODE_CONTINUE',
      speed,
    });
  }

  private showSolution(code: string) {
    this.sendPostMessage({
      type: 'SHOW_SOLUTION',
      code,
    });
  }

  private debugLeaderMode(code: string, steps: number) {
    this.sendPostMessage({
      type: 'DEBUG_LEADER_MODE',
      code,
      steps,
    });
  }

  private changeBlocksImage(image: string) {
    this.sendPostMessage({
      type: 'CHANGE_BLOCKS_IMAGE',
      image,
    });
  }

  private prepareBlocksBoard(additionalProps: QuestionProps) {
    this.sendPostMessage({
      type: 'PREPARE_BLOCKS_BOARD',
      question: this.question,
      additionalProps,
    });
  }

  private stopEditBoard(additionalProps: QuestionProps) {
    this.sendPostMessage({
      type: 'STOP_EDIT_MODE',
      question: this.question,
      additionalProps,
    });
  }
}
