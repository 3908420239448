import { sessionDataSchema } from 'schemas/session.schema';
import { Database, onValue, ref } from 'firebase/database';
import { useEffect } from 'react';
import { AppName, getAppDatabase } from 'store/FirebaseStore';
import { IUpdateMemberActiveFile } from './interfaces';

export const UpdateMemberActiveFile = (data: IUpdateMemberActiveFile) => {
  const { currentUserId, room, setIdActiveFile } = data;

  useEffect(() => {
    const database: Database = getAppDatabase(AppName.DEFAULT);
    const meberDataEventRef = ref(database, `sessions_data/${room?.id}/${currentUserId}`);
    setIdActiveFile('');
    const unsubscribe = onValue(meberDataEventRef, async snapshot => {
      if (snapshot.exists()) {
        try {
          const result = await snapshot.val();
          const sessionData = sessionDataSchema.parse(result);
          const activeFileId =
            sessionData.idActiveQuestion && sessionData?.isActive
              ? sessionData.idActiveQuestion
              : '';
          setIdActiveFile(activeFileId);
        } catch (e) {
          throw e;
        }
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUserId, room, setIdActiveFile]);
};
