import { z } from 'zod';
import { firestoreTimestampLooseSchema, firestoreTimestampSchema } from './firebase.schema';

export const questionDataSchema = z.object({
  id: z.number(),
  title: z.string(),
  value: z.number().optional(),
  answer: z.string().optional(),
});

export const questionsDataSchema = z.array(questionDataSchema);

export const feedbackInternalTableSchema = z.record(
  z.object({
    id: z.string(),
    title: z.string(),
    value: z.string(),
  }),
);

export const formFeedbackSchema = z.object({
  userId: z.string(),
  userEmail: z.string(),
  tutorId: z.string(),
  tutorEmail: z.string(),
  roomId: z.string(),
  creationDate: firestoreTimestampLooseSchema,
  questions: z.array(questionDataSchema),
  askedQuestion: z.string().max(256),
  feedBackFormData: feedbackInternalTableSchema,
});

// radio group form
export const radioGroupFormDataSchema = z.object({
  id: z.string(),
  value: z.number(),
});

// checkbox form
export const presenceDataSchema = z.object({
  credentials: z.string(),
  date: firestoreTimestampSchema.optional(),
  email: z.string(),
  parentEmail: z.string(),
  present: z.boolean(),
  roomId: z.string(),
  userUid: z.string(),
});

export const presenseFormSchema = z.array(presenceDataSchema);
