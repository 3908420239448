import { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { Accordion, AccordionDetails, AccordionSummary } from 'components/organisms/Accordion';
import { isTutor } from 'helpers/userHelper';
import { useCurrentRoomContext } from 'pages/CurrentRoom/context';
import { useAppContext } from 'providers/app/app.provider';
import { useWorkspaceContext } from 'providers/workspace/workspace.provider';
import { RoomType } from 'types/enums';
import { FileType } from 'types/FileTypes';
import { Question, Scenario, Video } from './components';
import { QuestionBlockProps } from './interface';
import alignStyles from 'shared/styles/alignment.module.css';
import styles from './questionBlock.module.css';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function QuestionBlock(props: QuestionBlockProps) {
  const {
    blockNumber,
    idActiveFile,
    isExpanded = false,
    questions,
    scenarios,
    selectedSection,
    solvedQuestions,
    title,
    videos,
    changeActiveFile,
    setSelectedSection,
  } = props;
  const { currentUser } = useAppContext();
  const { collapsedSidebar } = useCurrentRoomContext();
  const { room } = useWorkspaceContext();
  const [open, setOpen] = useState<boolean>(isExpanded);
  const [sectionResolved, setSectionResolved] = useState<boolean>(false);
  const accordionSummaryClassNames = clsx(collapsedSidebar && alignStyles['flex-center']);
  const showScenarios = isTutor(currentUser?.role) || room?.roomType === RoomType.Individual;
  const showVideos =
    isTutor(currentUser?.role) || room?.roomType === RoomType.Individual || RoomType.Demo;

  useEffect(() => {
    if (selectedSection !== blockNumber) {
      setOpen(false);
    }
  }, [selectedSection, blockNumber, setSelectedSection]);

  useEffect(() => {
    const filteredQuestions = questions.filter(q => q.type === FileType.Task);
    const filteredSolvedQuestions = questions.filter(
      q => q.type === FileType.Task && solvedQuestions.includes(q.guid),
    );
    const resolvedSection =
      filteredQuestions.length > 0 && filteredSolvedQuestions.length === filteredQuestions.length;
    setSectionResolved(resolvedSection);
  }, [solvedQuestions, questions]);

  return (
    <>
      {!title && showVideos && videos?.map(video => <Video key={video} videoUrl={video} />)}
      {!title &&
        showScenarios &&
        scenarios?.map(scenario => <Scenario key={scenario} scenarioUrl={scenario} />)}
      {!title &&
        questions.map(question => (
          <Question
            key={question.guid}
            question={question}
            idActiveFile={idActiveFile}
            changeActiveFile={changeActiveFile}
            isSolved={solvedQuestions.includes(question.guid)}
          />
        ))}
      <Accordion
        id={blockNumber as number}
        show={open}
        setShow={setOpen}
        setSelectedSection={setSelectedSection}
        expandedClassName={styles['question-block__accordion']}
      >
        {title && (
          <AccordionSummary className={accordionSummaryClassNames}>
            <div className={styles['question-block__header']}>
              <div>
                {blockNumber && `${blockNumber}. `}
                {!collapsedSidebar && <span>{title}</span>}
              </div>
              {sectionResolved && (
                <CheckCircleOutlineIcon
                  className={styles['question-block__resolved-section-icon']}
                />
              )}
            </div>
          </AccordionSummary>
        )}
        <AccordionDetails>
          {showVideos && videos?.map(video => <Video key={video} videoUrl={video} />)}
          {showScenarios &&
            scenarios?.map(scenario => <Scenario key={scenario} scenarioUrl={scenario} />)}
          {questions.map(question => (
            <Question
              key={question.guid}
              question={question}
              idActiveFile={idActiveFile}
              changeActiveFile={changeActiveFile}
              isSolved={solvedQuestions.includes(question.guid)}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
